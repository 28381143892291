@import "./src/scss/base/mixins";
@import "./src/scss/deps";

@import "./mixins/xxlarge.scss";
@import "./mixins/tablet.scss";
@import "./mixins/mobile.scss";
@import "./mixins/sm-mobile.scss";

$carouselCardHeight: calc(100vh - 80px);

:root {
	--mobile-chat-height: 60px;
}

.live-event-waiting {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	backdrop-filter: blur(15px);
	background-color: rgba(255, 255, 255, 0.5);
	transition: opacity 500ms;

	&.fade-out {
		opacity: 0;
		pointer-events: none;
	}
}

.live-event-container {
	//"--bodyTextSecondaryColor" is deprecated, instead where possible use an opaque version of the body text color like such "rgba(var(--bodyTextColor-rgb), 0.5);"
	//keeping this hard coded for now because its used in quite a few auxiliary components and I dont want to cause unknown side effects - Scott Harlan
	--bodyTextSecondaryColor: rgba(131, 138, 161, 1);
	--bodyTextSecondaryColor-rgb: 131, 138, 161;
	font-family: var(--bodyFont);
	color: var(--bodyTextColor);
	background-color: var(--backgroundColor);
	min-height: 100vh;
	min-height: 100svh;

	input {
		font-family: var(--bodyFont);
		color: var(--bodyTextColor);
	}

	h1 {
		font-family: var(--headingFont);
		color: var(--headingTextColor);
	}

	button {
		font-family: var(--bodyFont);
	}

	&.force-rtl {

		[class*="evt-"],
		.banner-title-container,
		.product-item-content,
		.agenda-session-card-content,
		.agenda-sessions-header-title,
		.session-live-card-container,
		.navigation-link,
		.add-live-comment-textarea,
		.footer-nav,
		.registration-start-time,
		.registration-form-inner .sign-in,
		.registration-error,
		.search-input,
		.filter-dropdown,
		.language-dropdown,
		.tag-container-type,
		.people-container .profile-details,
		.profile-modal-content,
		.user-profile-main-content .general-info,
		.user-profile-main-content .profile-card:not(.general-profile-card),
		.user-profile-main-content .card-title,
		.session .speaker-data,
		.session .tab-navigation label {
			text-align: start;
			direction: rtl;
		}
	}

	.module-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 1440px;
		margin: 0 auto;
		padding: 100px 150px;
		background-size: cover;
		background-repeat: no-repeat;

		&.full-width {
			max-width: 100%;
			justify-content: center;
		}
	}

	.marketing-registered-page {
		.module-container {
			@media screen and (min-width: 769px) {
				padding-right: 130px;
				padding-left: 130px;
			}
		}
	}

	img {
		max-width: 100%;
		width: auto;
		max-height: 100%;
		height: auto;

		&.custom-icon {
			// I know, I know, but it's the only way to get the icon to be the right size
			max-width: 24px !important;
			max-height: 24px !important;
		}
	}
}

//This mixin is mostly for the editors sake, this is why there is only the tablet version and mobile version responsive styles here, to match the editor view types. Default being desktop
@mixin baseModuleResponsive($size) {
	@if $size =="xxlarge" {
		@include xxlargeBaseResponsive($size);
	}

	@if $size =="tablet" {
		@include tabletBaseResponsive($size);
	}

	@if $size =="mobile" {
		@include mobileBaseResponsive($size);
	}

	@if $size =="sm-mobile" {
		@include smMobileBaseResponsive($size);
	}
}

// when screen size is between small desktop and 1483,
// the go to session/ add to calendar button text wraps
// for Apollo template vertical card layout
@include break-max($break-xxlrg + 43px) {
	.registered-main-content {
		.agenda {
			&.#{$ev-template-classic}.agenda-vertical_card {
				.agenda-session-card {
					.evt-button {
						padding: 14px 16px;
					}
				}
			}

			&.#{$ev-template-bold}.agenda-vertical_card {
				.agenda-session-card {
					.evt-button {
						padding: 15px 24px;
					}
				}
			}
		}
	}
}

//small desktop
@include media-query($xlrg-down) {
	@include baseModuleResponsive("xxlarge");

	.live-event-container {
		@include baseModuleResponsive("xxlarge");
	}

	// we require this specificity for theme to properly override
	.live-event-container {

		&.apollo,
		&.Apollo {
			@include baseModuleResponsive("xxlarge");
		}
	}
}

// TODO: Refactor to include with module responsive mixin
@include media-query($xlrg-up) {
	.breakouts-footer {
		.mobile-buttons {
			display: none;
		}
	}
}

@include media-query($lrg-down) {
	.breakouts-footer {
		.breakouts-footer-right {
			display: none;
		}
	}
}

@mixin sponsorsCardFull {
	.sponsors {
		&.sponsors-card_full {
			.sponsor-card-title {
				font-size: 18px;
				font-weight: 600;
			}
		}
	}
}

@include media-query($lrg) {
	@include sponsorsCardFull();
}

@include media-query($xlrg) {
	@include sponsorsCardFull();
}

// TODO: Refactor to include with module responsive mixin
@include media-query($med-down) {
	.breakouts-wrapper {
		&.breakouts-wrapper-with-panel {
			.breakout-main-section {
				.breakouts-header {
					.breakouts-header-right {
						display: none;
					}
				}
			}
		}
	}
}

@include media-query($lrg-down) {
	.breakouts-footer {
		grid-template-columns: 0 1fr 0;
		overflow: hidden;

		.mobile-buttons {
			display: flex;
			margin-left: 10px;
		}
	}
}

@include media-query($med-down) {

	// including .App for specificity so responsive design will override standard styling
	.App .live-event-container {
		@include baseModuleResponsive("tablet");
	}
}

// TODO: Refactor to include with module responsive mixin
@include media-query($med-up) {
	.breakouts-wrapper {
		&.breakouts-wrapper-with-panel {
			width: calc(100% - 432px);
		}
	}
}

// TODO: Refactor to include with module responsive mixin
@include media-query($sm-down) {
	.live-event-container {
		overflow-x: hidden;
	}

	.breakouts-wrapper {
		&.breakouts-wrapper-with-panel {
			.breakout-main-section {
				max-width: 50%;
				padding-right: 20px;
			}

			.breakouts-right-panel-container {
				max-width: 50%;

				@media screen and (orientation: landscape) and (max-height: $break-sm) and (max-device-width: 768px) {
					max-width: 100%;
				}
			}
		}

		.breakouts-content {
			padding: 5px 0 10px;
		}
	}

	.breakouts-modal-wrapper {
		width: calc(100% - 52px);
	}
}

@include media-query($sm-down) {

	// including .App for specificity so responsive design will override standard styling
	.App .live-event-container {
		@include baseModuleResponsive("mobile");
	}
}

@include media-query($xs-down) {
	@include baseModuleResponsive("sm-mobile");
}

.editor.tablet {
	@include baseModuleResponsive("tablet");
}

.editor.mobile {
	@include baseModuleResponsive("tablet");
	@include baseModuleResponsive("mobile");
}

// TODO: Refactor to include with module responsive mixin
@media screen and (max-height: $break-sm) {
	.breakouts-modal-wrapper {
		.breakouts-modal-content {
			text-align: center;

			.user-feed-placeholder,
			video {
				width: 50%;
				min-width: initial;
			}

			.user-info {
				display: none;
			}
		}
	}
}

// TODO: rogue code for the live event banner, find a home for it
.editor {
	.live-session-banner {
		.module-container {
			flex-direction: column;
		}
	}

	.live-session-banner,
	.main-event-banner {
		max-width: 100%;

		.module-container {
			padding-left: 130px;
			padding-right: 130px;

			a:not(.ql-action, .ql-remove) {
				pointer-events: none;
			}
		}
	}

	.main-event-banner-live-carousel {
		width: 100%;
		max-width: unset;

		.slick-list {
			width: 100%;
		}

		.slick-slider {
			// MAGIC NUMBER
			max-width: 1343px;
		}
	}

	&.new-top-navigation {
		.main-event-banner-live-carousel {
			.slick-slider {
				max-width: 100%;
			}
		}
	}

	.disable-interactions {
		pointer-events: none;
		user-select: none;
		filter: blur(5px);
	}
}

$tabletModulePadding: 75px;

.editor.tablet {
	.main-event-banner-live-carousel {
		.live-session-banner {
			.module-container {
				padding-left: $tabletModulePadding;
				padding-right: $tabletModulePadding;
			}
		}
	}

	.live-session-banner {
		.module-container {
			padding-left: $tabletModulePadding;
			padding-right: $tabletModulePadding;
		}
	}
}

$mobileModulePadding: 25px;

.editor.mobile {
	.main-event-banner-live-carousel {
		.live-session-banner {
			.module-container {
				padding-left: $mobileModulePadding;
				padding-right: $mobileModulePadding;
			}
		}
	}

	.live-session-banner {
		.module-container {
			padding-left: $mobileModulePadding;
			padding-right: $mobileModulePadding;
		}
	}
}

._404 {
	@include absolute-fill;
	@include flex-center;
	flex-direction: column;
	font-weight: bold;
	background-color: $gray-1000;
	color: $white;
}

.sso-registration {
	@include absolute-fill;
	@include flex-center;
	flex-direction: column;
	font-weight: bold;
	background-color: $gray-1000;
	color: $white;
}

.evt-modal-filters {
	.evt-modal-filters-inner {
		.evt-modal-filters-title {
			@extend .base-evt-heading-4;
		}
	}
}

// general
.base-evt-heading-4 {
	@include eventHeading4();
}

.hide-x-overflow {
	overflow-x: hidden;
}

// Note: naming "zero-margin" because "no-margin" is used specifically as a nested button class
// and I don't want any potential conflicts
.zero-margin {
	margin: 0;
}
