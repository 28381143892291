@import "./src/scss/old-deps";
@import "./src/scss/deps";

.profile-details-actions {
	margin-top: auto;

	.new-top-navigation & {
		margin-top: 0;
	}

	.profile-details-dropdown-container {
		position: relative;
		max-width: 95px;

		.profile-details-dropdown-trigger {
			&.is-editor {
				pointer-events: none;
			}

			img {
				object-fit: cover;
				@include circle(48px, false);
			}

			.profile-details-dropdown-placeholder {
				background-color: var(--accentColor);
				@include circle(48px, false);
				@include flex-center;
				text-transform: uppercase;
			}

			&.inactive {
				pointer-events: none;
			}

			&.top-nav {
				display: flex;
				align-items: center;
				gap: 4px;
				position: relative;
				right: 15px;

				.arrow {
					transform: rotate(90deg);
				}
			}
		}

		.profile-details-dropdown-menu {
			position: absolute;
			bottom: 15px;
			left: 100%;
			background-color: var(--containerColor);
			box-shadow: 0 10px 40px rgba(126, 130, 134, 0.2);
			min-width: 220px;
			border-radius: 16px;
			overflow: hidden;
			max-height: 500px;
			overflow-y: auto;

			.icon-action-wrapper {
				display: flex;
				width: 100%;
				justify-content: space-between;
				align-items: center;

				.left-action {
					display: flex;
					align-items: center;

					svg {
						margin-right: 8px;
					}
				}
			}
		}

		&.isMobile {
			display: flex;
			align-items: center;

			.profile-details-dropdown-trigger {
				padding: 0;
				margin-left: 14px;

				img {
					@include circle(36px, false);
				}

				.profile-details-dropdown-placeholder {
					@include circle(36px, false);
				}
			}

			.profile-details-dropdown-menu {
				left: auto;
				right: 8px;
				top: 40px;
				z-index: 10;
				height: fit-content;

				.see-profile-wrapper {
					p {
						line-height: 24px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 180px;
					}
				}
			}
		}
	}

	.profile-first-level-actions {
		a {
			text-decoration: none;
		}

		button {
			border-radius: 0;
			width: 100%;
			white-space: nowrap;
			color: var(--bodyTextColor);

			&.mobile-hidden {
				display: none;

				@media (min-width: 640px) {
					display: inline-flex;
				}
			}

			&:hover {
				background-color: var(--backgroundColor);
			}

			p {
				margin-bottom: 0;
			}

			.attendee-in-person-toggle-switch {
				padding-left: 10px;
			}
		}

		.see-profile-wrapper {
			text-align: left;

			p {
				margin: 0;
			}
		}

		.see-profile-label {
			font-weight: 400;
			font-size: 12px;
		}
	}

	.profile-nav-language-container {
		button {
			width: 100%;
			white-space: nowrap;
			color: var(--bodyTextColor);
		}

		.profile-second-level-action {
			padding-left: 19px;

			span {
				margin-right: 1px;
			}
		}

		.activeLanguage {
			background: rgba(var(--accentColor-rgb), 0.1);
		}
	}

	&.single-session {
		position: fixed;
		z-index: 1000;
		bottom: 50px;
	}

	&.set-chat-under-video {
		z-index: 5;
	}
}

@include media-query($med-down) {
	.profile-details-actions {
		.profile-details-dropdown-container.isMobile {
			.profile-details-dropdown-menu {
				bottom: -94px;
				left: auto;
				right: 5px;
				min-width: 180px;
			}
		}
	}

	.limelight,
	.Limelight {
		.session-header.new-navigation {
			position: relative;

			header.Limelight {
				.mobile-header-actions-container {
					padding-left: 10px;
				}

				.profile-details-actions {
					position: absolute;
					right: 15px;
				}

				.session-titlebox.new-navigation {
					left: 60px;
				}
			}
		}
	}
}

@mixin navV1SingleSessionTabletDownProfileDropdownPosition {
	.profile-details-actions {
		.profile-details-dropdown-container.isMobile {
			.profile-details-dropdown-menu {
				&.is-session-page {
					left: 0;
					right: auto;
				}
			}
		}
	}
}

@include media-query($med) {
	@include navV1SingleSessionTabletDownProfileDropdownPosition;
}
@include media-query($sm) {
	@include navV1SingleSessionTabletDownProfileDropdownPosition;
}

@include media-query($xs-down) {
	.profile-details-actions {
		.profile-details-dropdown-container.isMobile {
			.profile-details-dropdown-menu {
				bottom: -94px;
				left: auto;
				right: 5px;
				min-width: 180px;
			}
		}
	}

	.limelight,
	.Limelight {
		.session-header.new-navigation {
			position: fixed;

			header.Limelight {
				.profile-details-actions {
					right: 50px;
				}
			}
		}
	}
}

@include media-query($med-down) {
	.profile-details-actions {
		&.single-session {
			display: none;
		}
	}
}

.dark-mode {
	.profile-first-level-actions {
		.icon-action-wrapper {
			svg {
				path {
					fill: var(--bodyTextColor);
				}
			}
		}
	}
}