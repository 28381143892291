@import './src/scss/deps';

.in-person-message-container {
	background-color: var(--user-mode-background);
	color: var(--user-mode-color);
	padding: 8px 12px 10px 12px;
	border-radius: 12px;

	h4 {
		margin: 0;
	}

	&.is-on-demand {
		margin: auto;
    max-width: calc(100% - 100px);

		@include media-query($med-down) {
			margin-left: 20px;
			max-width: calc(100% - 400px); // 350 for chat overlay width, plus an extra 50 for padding
		}
		
		@include media-query($xs-down) {
			margin-left: auto;
			max-width: calc(100% - 40px);
		}
	}
}
