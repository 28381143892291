@import './src/scss/deps';

.marketing-registered-page {
	display: flex;

	&.new-top-navigation {
		display: block;
	}

	.registered-main-content {
		flex-grow: 1;
	}

	.registered-page-container {
		max-width: 1440px;
		margin: 0 auto;
		padding: 40px 130px 100px;
	}

	.module-container {
		padding-right: 130px;
		padding-left: 130px;
	}

	.registered-page-title {
		margin-bottom: 30px;
		font-size: 25px;
		line-height: 30px;
	}

	.registered-page-header {
		display: flex;
		margin-bottom: 30px;

		.registered-page-search {
			position: relative;
			flex-grow: 1;
			width: auto;
			margin: 0;
			border: none;

			input {
				height: 55px;
				margin: 0;
				padding: 17px 43px;
				font-size: 15px;
				line-height: 21px;
				font-weight: 600;
				color: var(--bodyTextColor);
				border: 1px solid rgba(131, 138, 161, 1);
			}

			.search-input-search-btn,
			.search-input-close-btn,
			.search-input-close-placeholder {
				position: absolute;
				top: 50%;
				left: 15px;
				width: 20px;
				height: 20px;
				margin-top: -10px;

				svg {
					width: 18px;
					height: 18px;
				}
			}

			.search-input-close-btn {
				left: auto;
				right: 15px;
			}
		}
	}
}

@include media-query($xlrg-down) {

	.marketing-registered-page.new-top-navigation {

		.registered-main-content {
			max-width: 100%;
		}
	}

	.registered-main-content {
		// max-width: calc(100% - 95px);
	}

	.live-event-container .marketing-registered-page,
	.marketing-registered-page {
		.module-container {
			padding: 100px 40px;
		}

		.registered-page-container {
			padding: 40px;
		}
	}
}

@include media-query($med-down) {
	.registered-main-content {
		max-width: 100%;
	}

	.live-event-container .marketing-registered-page,
	.marketing-registered-page {
		min-height: 100vh;


		.module-container {
			padding: 75px;
		}

		.registered-page-container {
			padding: 40px 75px;
		}
	}
}

@include media-query($sm-down) {

	.live-event-container .marketing-registered-page,
	.marketing-registered-page {

		.module-container {
			padding: 50px 25px;
		}

		.registered-page-container {
			padding: 40px 25px;
		}
	}
}