@import "./src/scss/old-deps";
@import "./src/scss/deps";

.panel-scroll-overlay {
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 75px;
	z-index: 1;

	&.panel-scroll-top-overlay {
		top: 0;
		background: linear-gradient(var(--containerColor, $dark-gray), rgba(0, 0, 0, 0));
	}

	&.panel-scroll-bottom-overlay {
		bottom: 0;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0), var(--containerColor, $dark-gray));
	}
}

.panel-slider, // let's always use dark colors for the panel-slider because our panel is always dark
.dark-mode {
	.panel-scroll-overlay {
		&.panel-scroll-top-overlay {
			background: linear-gradient($gray-1000, rgba(0, 0, 0, 0));
		}

		&.panel-scroll-bottom-overlay {
			background: linear-gradient(rgba(0, 0, 0, 0), $gray-1000);
		}
	}
}
