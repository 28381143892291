@import './src/scss/old-deps';
@import './src/scss/deps';

.profile-main,
.settings-main {
	width: 100vw;
	margin-top: 114px;

	.settings-container {
		margin: 58px auto;
		display: grid;
		grid-template-columns: 180px minmax(0, 1fr);
		gap: 20px;


		&.profile {
			display: flex;
			flex-direction: column;
			margin-top: 0;
			margin-bottom: 0;
			width: 1088px;

			h2 {
				margin-bottom: 0;
			}
		}

		h2 {
			font-weight: 600;
			font-size: 20px;
			width: 1240px;
			max-width: 100%;
			margin: 58px auto -50px;
		}

		.settings-tabs-container {
			min-width: 184px;
			margin-right: 30px;

			.settings-tab {
				width: 100%;
				border-radius: 20px;
				height: 40px;
				margin-bottom: 4px;
				font-size: 14px;
				color: $white;
				font-weight: 500;
				display: flex;
				align-items: center;
				padding-left: 12px;
				cursor: pointer;
				color: $gray-300;

				&:hover {
					background-color: rgba(38, 36, 43, 0.5);
				}

				svg {
					margin-right: 6px;

					path {
						fill: $gray-300;
					}
				}

				&.active {
					background-color: $gray-1100;
					color: $white;

					svg {
						margin-right: 6px;

						path {
							fill: $white;
						}
					}
				}
			}
		}

		.right-container {
			width: 1030px;
		}

		.tab-wrapper {
			display: flex;
			height: 56px;
			border-bottom: 1px solid $gray;
			margin-bottom: 24px;

			.tab-container {

				.tab-header {
					height: 100%;
					padding-top: 18px;

					button {
						font-weight: 700;
						color: $alt-gray;

						&.active {
							color: $white;
						}
					}

					.tab-header-underline {
						width: 20px;
						margin-top: 17px;

					}
				}
			}
		}

		.settings-card-container {
			margin-bottom: 30px;

			.settings-card {

				.settings-card-header {
					display: flex;
					flex-flow: row nowrap;
					justify-content: space-between;
					padding-right: 16px;
					margin-bottom: 14px;
				}

				.settings-card-body {
					width: 100%;
					background-color: $gray-900;
					border-radius: 12px;
					box-shadow: 0px 8.72113px 18.169px rgba(0, 0, 0, 0.0456578);
					padding: 32px 40px 24px 32px;
					display: flex;
					flex-flow: column nowrap;
					align-items: stretch;

					.primary-card {
						display: flex;
						flex-flow: row nowrap;

						.avatar {
							position: relative;
							width: 180px;
							height: 180px;
							border-radius: 12px;
							overflow: hidden;
							margin-right: 32px;
							border: 1px solid $image-gray;

							img {
								width: 100%;
								height: 100%;
								object-fit: contain;
							}

							.camera-button {
								position: absolute;
								left: 0;
								right: 0;
								margin-left: auto;
								margin-right: auto;
								bottom: 16px;
								width: 48px;
								height: 48px;
								background: $bl-lemonade;
								border-radius: 50%;
								display: flex;
								justify-content: center;
								align-items: center;
								z-index: $z-index-user-menu-btn;
								cursor: pointer;

								&:hover {
									background: $bl-lemonade-hover;
								}
							}
						}

						.primary-info {
							display: flex;
							flex-flow: row nowrap;
							align-items: center;
							padding: 10px 0;

							.primary-info-icon {
								width: 32px;
								height: 32px;
								background-color: $image-gray;
								border-radius: 50%;
								display: flex;
								justify-content: center;
								align-items: center;
								margin-right: 16px;

								svg {
									display: block;
								}
							}

							.s3 {
								font-size: 14px;
								line-height: 20px;
								font-weight: 500;
								color: $alt-gray;
							}

							.p1 {
								font-size: 16px;
								margin: 0;
							}

							.sender-tag {
								border: none;
								border-radius: 12px;
								background-color: $rose;
								font-size: 14px;
								padding: 4px 8px;
								margin-left: 8px;

								&.verified {
									background-color: $green;
								}
							}
						}

						.disclaimer {
							margin-top: 10px;
							margin-left: 48px;
							color: $alt-gray;
							font-size: 14px;
							font-style: italic;

							a {
								color: inherit;
								transition: color .2s;

								&:hover {
									color: $white;
								}
							}
						}

						.row {
							margin-left: 48px;

							.email-btn {
								margin-right: 12px;
							}
						}

					}
				}
			}
		}
	}
}

.edit-modal {
	.entry-error {
		height: 20px;
		margin: -20px 24px 0 24px;
		color: $red;
		font-size: $_14px;
		text-align: right;
	}

	.select-label {
		margin-left: 16px;
		font-size: 12px;
		line-height: 16px;
		font-weight: 700;
	}
}

.edit-primary-contact-modal {
	.field-group.select .select-container {
		height: 48px;
		border: 1px solid $gray-400;
		font-weight: 500;

		.selected-option {
			span {
				font-size: 14px;
				margin-right: 4px;
			}
		}

		.select-dropdown {
			top: unset !important;
			left: 40px !important;
			max-height: 300px;
		}
	}
}