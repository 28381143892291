@import './src/scss/old-deps';
@import './src/scss/deps';

.streamed-date-text.ignore-modal-styling {
	position: relative;
	display: flex;
	gap: 3px;
	color: #a1a1a1; //var(--secondaryAccentColor);
	flex-wrap: wrap;

	.streamed-date-text-container {
		cursor: pointer;
		position: relative;

		&:hover {
			.streamed-date-text-container {
				p.evt-typography-subtitle-3 {
					color: #cfc9c9;
				}
			}
		}
	}

	p {
		transition: color 0.1s ease-in-out;
	}
}
