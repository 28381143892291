
@import '../../../scss/deps';

.profile-details-wrapper {
	position: absolute;
	left: 84px;
	bottom: 20px;
	z-index: 1;
	display: flex;
	flex-direction: column;
	width: 220px;
	max-height: 260px;
	opacity: 0;
	overflow-y: auto;
	background-color: $med-gray;
	border-radius: 24px;
	box-shadow: 0 8px 24px rgba(0, 0, 0, 0.25);
	transform: translateY(-8px);
	transition: 150ms opacity, 150ms transform;
	pointer-events: none;

	&.open {
		opacity: 1;
		transform: translateY(0);
		pointer-events: auto;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		color: $light-gray;

		li {
			display: flex;
			width: 100%;
			height: 40px;
			margin-top: 4px;
			padding: 12px 24px;
			font-size: 14px;
			font-weight: 500;
			font-style: normal;
			transition: background-color $hover-transition-duration;
			align-items: center;

			&:first-child {
				height: 44px;
				padding-top: 16px;
				margin-top: 0;
			}

			&:last-child {
				height: 44px;
				padding-bottom: 16px;
				margin-bottom: 0;
			}

			&:hover {
				background-color: $gray-active;
				transition: background-color $hover-transition-duration;
			}

			a {
				display: flex;
				align-items: center;
				width: 100%;
				height: 100%;
			}

			button {
				justify-content: flex-start;
				width: 100%;
				height: 100%;
				padding: 0;

				svg {
					margin-right: 8px;
				}
			}
		}

		a, .profile-details-top {
			text-decoration: none;
			color: $light-gray;

			li {
				display: flex;
				width: 100%;
				height: 40px;
				padding: 12px 24px;
				font-size: 14px;
				font-weight: 500;
				font-style: normal;

				button {
					justify-content: flex-start;
					width: 100%;
					height: 100%;
					padding: 0;
				}

				svg {
					margin-right: 8px;
				}
			}

			.profile-details-header {
				justify-content: space-between;
				align-items: center;
				width: 100%;
				height: 70px;
				margin-top: 0;
				padding-right: 16px;
				background-color: $gray;

				.profile-details-header-left {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					width: 190px;
					padding-top: 4px;
					font-size: 12px;
					line-height: 16px;

					.name {
						font-size: 16px;
						line-height: 22px;
						font-weight: 500;
					}
				}

				.profile-details-header-right {
					display: flex;
					align-items: center;
					height: 100%;
					margin-bottom: 0;
					padding-bottom: 0;
				}
			}
		}

		svg {
			margin-right: 8px;
		}
	}

	&.top {
		top: 66px;
		left: auto;
		right: 20px;
		transform: translateY(+8px);
		transition: 150ms opacity, 150ms transform;
	}
}
