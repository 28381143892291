@import "../../../../scss/base/mixins";
@import "./src/scss/deps";

.person-details-modal {
	background-color: transparent;

	.modal {
		max-height: 410px;
		background-color: var(--backgroundColor);
		border-radius: 0;

		&.normal {
			width: 855px;
		}

		color: var(--bodyTextColor);

		h3,
		h4,
		p {
			font-family: inherit;
		}
	}

	&.ev-modal-wrapper {
		.modal-body {
			flex-grow: 1;
			max-height: none;
		}
	}

	.modal-title {
		display: none;
	}

	.person-details-modal-content {
		display: grid;
		grid-template-rows: max-content;
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			"image content"
			"image sessions"
			"x sessions";
	}

	.person-details-image {
		position: relative;
		grid-area: image;
		margin-bottom: 15px;
		margin-left: 40px;

		&::before {
			content: "";
			display: block;
			padding-bottom: 100%;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			aspect-ratio: 1;
			object-fit: cover;
			border-radius: var(--customImageBorderRadius, 0);

			&.rounded-speaker-image {
				border-radius: 50%;
			}
		}
	}

	.person-details-sessions {
		grid-area: sessions;
		min-width: 0;
		padding-left: 35px;

		.person-details-sessions-header {
			display: flex;
			justify-content: space-between;
			margin-bottom: $ev-gap-x2;

			h4 {
				color: var(--bodyTextColor) !important;
			}
		}

		.session-label {
			margin-bottom: 0;
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}

		.session-item {
			display: flex;
			margin-bottom: 10px;
			padding: 15px;
			background-color: var(--containerColor);

			&:last-child {
				margin-bottom: 0;
			}
		}

		.session-item-image {
			@include size(88px);
			flex-shrink: 0;
			margin-right: 15px;

			img {
				height: 100%;
				object-fit: cover;
			}
		}

		.session-item-content {
			display: flex;
			flex-direction: column;
			min-width: 0;
		}

		.session-item-title {
			color: var(--bodyTextColor) !important;
		}

		.session-item-date {
			display: block;
			color: var(--bodyTextSecondaryColor);
		}

		.session-item-link {
			margin-top: auto;
			color: var(--accentColor);
			line-height: 30px;
			font-weight: 700;
			font-size: 15px;
		}
	}

	.person-details-content {
		grid-area: content;
		padding-left: 35px;
		word-break: break-word;

		.tag-container.tag-container-speaker {
			word-break: normal;
			color: var(--headingTextColor);
			background-color: var(--secondaryAccentColor);
		}

		.person-details-description-show-more {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			button {
				margin-bottom: 40px;
			}
		}
	}

	.person-details-name {
		display: flex;
		align-items: center;
		padding-right: $ev-gap-x5;
	}

	.person-details-job {
		max-height: 55px;
		overflow: scroll;
	}

	.tag-container {
		margin-left: $ev-gap-x4;
	}

	.person-details-position {
		font-size: 16px;
		margin-bottom: 6px;
		color: var(--bodyTextColor) !important;
	}

	.social-links {
		display: flex;
		margin: 0 0 8px 0;
		padding: 0;
		list-style: none;

		path {
			fill: var(--accentColor);
		}

		li {
			margin-right: 18px;

			&:last-child {
				margin-right: 0;
			}
		}

		a {
			display: flex;
		}
	}

	.person-details-description {
		color: var(--bodyTextColor);
		white-space: pre-wrap;

		&.reduced {
			display: -webkit-box;
			overflow: hidden;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 7;
		}
	}

	.personal-data-list {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.personal-data-list-icon {
			@include circle(32px);
			margin-right: $ev-gap-x4;
			background-color: rgba(var(--accentColor-rgb), 0.1);
		}
	}
}

@include media-query($med-down) {
	.person-details-modal {
		.modal {
			&.normal {
				max-width: 100%;
				width: 100%;
				max-height: 100%;
				height: 100%;
			}
		}

		&.ev-modal-wrapper {
			.modal {
				padding-bottom: 75px;

				.modal-body {
					padding-right: 75px;
					padding-left: 75px;
				}
			}
		}

		.person-details-modal-content {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.person-details-image {
			margin-left: 0px;
			margin-right: 0px;
			width: 164px;
			margin-bottom: 25px;
		}

		.person-details-content {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-left: 0;
		}

		.person-details-name {
			justify-content: center;
			padding-right: 0;
		}

		.person-details-position,
		.person-details-description {
			text-align: center;
		}

		.personal-data-list {
			margin-bottom: $ev-gap-x8;
		}

		.person-details-sessions {
			width: 100%;
		}
	}
}

@include media-query($sm-down) {
	.person-details-modal {
		&.ev-modal-wrapper {
			.modal {
				max-height: calc(100svh - 40px);
				max-width: calc(100vw - 40px);
				padding-bottom: $ev-gap-x10;

				.modal-body {
					padding-right: 25px;
					padding-left: 25px;
				}
			}
		}

		.person-details-name {
			flex-direction: column;
		}

		.tag-container {
			margin-top: 10px;
			margin-left: 0;
		}
	}
}

@include dark-mode-theme {
	.person-details-modal {
		.modal {
			color: var(--gray-100);
		}
	}
}