.fade-container {
	position: relative;
	height: 100%;
	overflow: hidden;

	.fade-scroller {
		height: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
		padding-bottom: 10px;

		.top-element {
			// 50 is just a good number. It there because to make sure if some of the parent elements have padding, the top-element will still be visible.
			top: 50px;
			position: relative;
		}

	}

}