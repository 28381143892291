@import "./src/scss/deps";

.panel-top-body-v2 {
	position: relative;
	height: 100%;

	.panel-wrapper-v2 {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;

		.panel-header-v2 {
			justify-content: center;
			height: 82px;
			display: flex;
			flex-direction: column;
			padding: 0 24px;

			.title-left {
				display: flex;
				align-items: center;

				h6 {
					margin: 0;
				}

				.no-style {
					margin: 0;
					padding: 0;
					margin-right: 8px;
				}
			}
		}

		.panel-body-v2 {
			display: flex;
			flex-direction: column;
			flex: 1;
			overflow: hidden;
		}

		&#adding-registration-question {
			height: calc(100vh - 200px);
		}

		&#create-registration-question {
			height: calc(100vh - 200px);
		}


	}
}