@import '../../../../../../../scss/deps';

.gating-option-card {
	@mixin rainbowText {
		background: $gradient-alt;
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		margin-bottom: 8px;

		&:before {
			content: "+";
			margin-right: 4px;
			background: $gradient-alt;
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	width: 100%;
	min-height: 64px;
	border-radius: 12px;
	background-color: $gray-800;

	padding: 16px;
	margin-top: 8px;

	.card-header {
		display: flex;
		justify-content: space-between;
		width: 100%;

		.left {
			display: flex;
			gap: 12px;
		}

		.right {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.gating-icon {
			width: 40px;
			height: 40px;

			display: flex;
			height: 40px;
			padding: 10px;
			justify-content: center;
			align-items: center;

			border-radius: 8px;
			background: var(--Grayscale-600, #4F4C57);
		}

		.gating-description {
			font-size: 12px;
			font-style: normal;

			.name {
				color: var(--Grayscale-100-white, #FFF);
				font-weight: 600;
				line-height: 20px;
				letter-spacing: 0.24px;
				gap: 4px;
				display: flex;
			}

			.subtitle {
				color: $gray-300;
				/* P2 */
				font-family: Poppins;
				font-weight: 500;
				line-height: 16px;
				/* 133.333% */
				letter-spacing: 0.24px;
			}
		}

		.bl-rainbow {
			color: $off-black;
			text-align: center;
			font-size: 11px;
			font-style: normal;
			font-weight: 600;
			line-height: 16px;
			letter-spacing: 0.22px;

			display: flex;
			padding: 8px 16px;
			justify-content: center;
			align-items: center;
			gap: 6px;
			border-radius: 100px;
			background: $gradient-alt;
		}
	}

	.card-body {
		height: 0;
		display: none;

		&.expanded {
			padding-top: 12px;
			height: auto;
			display: flex;
			flex-direction: column;
			gap: 12px;
		}
	}

	label {
		font-size: 14px;
		display: flex;
		align-items: center;
	}

	p {
		font-size: 12px;
		color: $gray-300;
		margin: 4px 0 0;
	}

	// The gating option styles
	.gating-option-form {
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	.display-options {
		display: flex;
		padding: 12px;
		border-radius: 8px;
		flex-direction: column;
		align-items: flex-start;
		gap: 12px;
		position: relative;
		background: $gray-active;

		.tag-select {
			width: 100%;
		}

		.field-group {
			width: 100%;
			padding-bottom: 10px;

			input[type].evt-field-input {
				height: 46px;
			}

			.tag-select {
				margin-bottom: 0;
			}

			.error-message {
				position: relative;
				margin-top: 4px;
			}
		}

		.options-button {
			top: 12px;
			right: 12px;
			position: absolute;

			padding: 1px;
			margin-right: 0;

			&.copy-passcode {
				top: unset;
				bottom: 12px;
			}
		}

		.popover-wrapper {
			border-radius: 12px;
			position: absolute;
			right: 4px;
			left: inherit;
			top: 20px;
			width: 100px;
			padding: 1px;

			.row {
				padding: 8px 16px;
				display: flex;
				align-items: center;

				&:hover {
					background-color: $border-gray;
				}

				&:first-child {
					border-top-left-radius: 12px;
					border-top-right-radius: 12px;
				}

				&:last-child {
					border-bottom-left-radius: 12px;
					border-bottom-right-radius: 12px;
				}
			}

			button {
				font-size: 14px;
				font-weight: normal;
				border-radius: 0;
				background-color: transparent;
				width: 100%;
				padding: 0;
			}
		}

		span.tooltip-text {
			max-width: 200px;
		}
	}

	.download-template-container {
		align-items: flex-start;
		margin-top: 8px;

		.download-template-button {
			padding: 10px;
		}
	}

	.requirement-footer {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		gap: 8px;

		button {
			padding: 8px 16px;
		}

		.primary {
			background: $gradient-alt;
		}
	}

	.display-option-block {
		width: 100%;

		.field-group {
			width: 100%;
			padding-bottom: 10px;

			.tag-select-dropdown,
			.select-dropdown,
			.enter-select-dropdown {
				margin: 0;
			}

			.tag-select-dropdown {
				left: unset;
				right: unset;
				width: 312px;
			}

			input[type].evt-field-input {
				height: 46px;
			}
		}

		.display-option-title {
			color: $gray-300;
			font-family: Poppins;
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px;
			letter-spacing: 0.24px;
			display: flex;
			justify-content: space-between;
		}

		button.add-new {
			@include rainbowText;
		}

		.display-option-content {
			color: var(--Grayscale-100-white, #FFF);
			font-feature-settings: 'clig' off, 'liga' off;

			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px;

			ul.tag-list {

				margin-block-start: 0;
				margin-block-end: 0;
				margin-inline-start: 0px;
				margin-inline-end: 0px;
				padding-inline-start: 0px;

				list-style-type: none;
				display: flex;
				gap: 8px;
				flex-wrap: wrap;

				li.tag {
					&:hover {
						background: $gradient-alt;
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;

					}
				}

			}
		}
	}

	.add-requirement {
		.large-button {
			svg {
				display: none;
			}

			label {
				@include rainbowText;
				margin-bottom: 0;
			}
		}
	}

	.audience-list-upload {
		.large-button {
			label span {
				@include rainbowText;
			}
		}
	}
}