@import './src/scss/old-deps';

.engage-progress-bar {
	width: 100%;
	height: 3px;
	background-color: #ecedf1;
}

.engage-progress-bar-value {
	height: 100%;
	background-color: var(--secondaryAccentColor);
	transform-origin: center left;
	transition: transform 0.2s ease-in;
}

.dark-mode {

	.engage-progress-bar {
		background-color: $border-gray;
	}
}
