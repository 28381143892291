@import "./src/scss/old-deps";
@import "./src/scss/deps";

.speaker-card {
	align-items: center;
	height: 137px;
	gap: 10px;
	border: 2px solid transparent;

	.speaker-image {
		height: 125px;
		min-width: 125px;
		border-radius: 12px;
		background-size: cover;
	}

	.speaker-data {
		display: flex;
		flex-direction: column;
		width: 200px;
		line-height: 20px;
		font-weight: 600;
		font-size: 12px;
		.speaker-name {
			font-size: 16px;
			margin-bottom: 4px;
			@include line-clamp(2);
		}
		.speaker-job {
			letter-spacing: 0.24px;
			color: var(--grayscale-300-grey, $med-light-gray);
			font-feature-settings: 'clig' off, 'liga' off;
			@include line-clamp(2);
		}
		.speaker-description {
			font-weight: 500;
			line-height: 16px;
			letter-spacing: 0.24px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;  
			overflow: hidden;
			margin-top: 8px;
			color: var(--grayscale-300-grey, $med-light-gray);
			font-feature-settings: 'clig' off, 'liga' off;
		}
	}

	&.selected {
		border-radius: 16px;
		border: 2px solid var(--primary-ev-500-key, $light-blue);
		background: var(--grayscale-800, $image-gray);
	}
}