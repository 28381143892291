@import "../../../../../../../scss/deps";

.registration-panel-container {
	.registration-steps-header {
		color: $gray-300;
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 12px;
		display: flex;
		justify-content: space-between;

		button.no-style {
			color: $cyan;
			font-size: 12px;
			font-weight: 700;
			padding: 0;

			svg {
				margin-right: 4px;
			}
		}
	}

	.card-container {
		margin-top: 8px;
		display: flex;
		align-items: center;

		.drag-icon {
			position: relative;
			left: -20px;
			opacity: 0;
			cursor: grab;
		}

		&:hover {
			.drag-icon {
				opacity: 1;
			}
		}

		&:active {
			.drag-icon {
				cursor: grabbing;
			}
		}
	}

	.settings-card {
		width: 100%;
		min-height: 64px;
		border-radius: 12px;
		background-color: $gray-800;
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-align: left;
		padding: 16px;
		margin-top: 8px;
		transition: background-color $hover-transition-duration;

		&:hover {
			background-color: $gray-active;
			transition: background-color $hover-transition-duration;
		}

		label {
			font-size: 14px;
			display: flex;
			align-items: center;
		}

		p {
			font-size: 12px;
			color: $gray-300;
			margin: 4px 0 0;
		}
	}

	.rsvp-registration {
		padding: 0 30px 0 30px;

		p {
			font-size: 12px;
			font-weight: 600;
			line-height: 20px;
			letter-spacing: 0em;
			color: $gray-300;
			margin-bottom: 30px;
		}

		.rsvp-admin-link {
			color: white;
			font-size: 12px;
			text-decoration: underline;

			svg {
				vertical-align: bottom;
				margin-left: 2px;
			}
		}

	}
}

.toggle-card {
	width: 100%;
	min-height: 64px;
	border-radius: 12px;
	background-color: $gray-800;
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: left;
	padding: 16px;
	margin-left: -16px;
	transition: background-color $hover-transition-duration;

	// need to bypass padding by registration-option-v2
	&.in-padded-container {
		margin: -16px;
		width: calc(100% + 32px);
	}

	&.close-registration-card,
	&.recaptcha-card {
		margin: 0;
	}

	div {
		display: flex;
	}

	.edit-button {
		opacity: 0;
		padding: 0;
	}

	label {
		font-size: 14px;
		display: flex;
		align-items: center;
	}

	.switch {
		margin-left: 16px;
		height: 100%;
	}

	&:hover {
		background-color: $gray-active;
		transition: background-color $hover-transition-duration;

		.edit-button {
			opacity: 1;
		}
	}
}