
@import './src/scss/old-deps';
@import './src/scss/deps';

.editable-text {
	z-index: 3;
	position: relative;
	border: 1px solid transparent;
	transition: border 150ms;
	word-break: break-word;
	white-space: pre-wrap;
	
	&.slim-container {
		position: relative;
		&>* {
			margin: 0;
		}
		
		.max-character-count {
			position: absolute;
			top: -35px;
			right: -35px;
			font-size: 12px;
			background-color: gray;
			white-space: nowrap;
			padding: 4px;
		}
	}

	h1 {
		white-space: pre-wrap;
	}

	.edit-text-button {
		position: absolute;
		top: -16px;
		right: -16px;
		opacity: 0;
		transition: 150ms opacity;
		pointer-events: none;
	}
	.editable-tag {
		outline: none;
		border: none;
		&.active,
		&:focus,
		&:focus-visible{
			border: 1px solid $cyan;
			background-color: $cyan-hover-rgba;
		}
		
	}
	[contenteditable=true] {
		outline: none;
		&:focus,
		&:focus-visible{
			outline: none;
		}
	}

	&:hover {
		border: 1px solid $cyan;
		background-color: $cyan-hover-rgba;
		.edit-text-button {
			z-index: 1;
			opacity: 1;
			pointer-events: auto;
		}
	}

	// active is the same as focus below but safari won't find the styling when it's grouped together and the component is in a modal
	// super random but it's safari so... :(
	&.active  {
		background-color:transparent;
		&.post-register {
			z-index: 10;
		}
	}
	&:focus,
	&:focus-visible {
		background-color:transparent;
		&.post-register {
			z-index: 10;
		}
	}

	.toolbar {
		@include radius-full;
		position: absolute;
		top: -32px;
		left: 50%;
		display: flex;
		align-items: center;
		padding: 12px;
		opacity: 0;
		background-color: $image-gray;
		box-shadow: 0 5px 40px rgba(0, 0, 0, 0.25);
		transform: translateX(-50%);
		transition: 150ms opacity, 150ms top;
		pointer-events: none;
		z-index: 1;

		.evt-select {
			color: $white;
			border: none;
			font-size: 14px;
			font-weight: 500;
			padding: 0 16px;
		}

		.editor-controls-container {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 8px;
			border-left: 1px solid gray;

			button {
				padding: 8px;
				margin: 0;
				background-color: transparent;
				
				&:hover {
					background-color: $border-gray;
				}

				&.active {
					svg {
						path {
							fill: $gray-200;
						}
					}
				}
			}
		}

		.alignment-container {
			display: flex;
			height: 24px;
			padding: 0 16px 0 10px;
			margin: 0 16px;
			border-left: 1px solid $gray-600;
			border-right: 1px solid $gray-600;
			input[type="radio"] {
				display: none;
				&+label {
					border-radius: 4px;
					padding: 3px;
					margin-left: 6px;
					&:hover {
						cursor: pointer;
					}
				}
				&:checked+label {
					background-color:$gray-600;
					path{
						fill: $white
					}
				}
			}
		}

		.undo, .redo {
			margin-right: 6px;
			padding: 0;
			z-index: 4;
			&:hover {
				path {
					fill: $white
				}
			}
		}
		.color-display {
			background-color: $gray-600;
			width: 32px;
			height: 32px;
			margin-right: 16px;
		}
		.navigation-dropdown {
			z-index: 3;
			width: 80px;
			margin: 0 16px;
			svg {
				margin-left: 6px;
			}
		}
		&.slim {
			.color-display {
				margin: 0 10px;
			}
		}
		.spacing-options {
			position: absolute;
			opacity: 0;
			.evt-select {
				width: 18px;
				padding: 0;
			}		
		}

	}
	.link-editor {
		span {
			color: $white;
			padding-left: 16px;
			font-size: 14px;
		}
		.field-group {
			margin-bottom: 0;
			padding: 0 8px;
		}
	}
	
	// active is the same as focus below but safari won't find the styling when it's grouped together and the component is in a modal
	// super random but it's safari so... :(
	&.active { 
		border: 1px solid $cyan;
		outline: none;

		.edit-text-button {
			opacity: 0;
			pointer-events: none;
		}

		.toolbar {
			top: -86px;
			opacity: 1;
			pointer-events: auto;
			&.slim {
				top: -70px
			}
			&.link-editor {
				width:fit-content;
				top: -162px;
			}
		}
	}
	&:focus,
	&:focus-visible {
		border: 1px solid $cyan;
		outline: none;

		.edit-text-button {
			opacity: 0;
			pointer-events: none;
		}

		.toolbar {
			top: -86px;
			opacity: 1;
			pointer-events: auto;
			&.slim {
				top: -70px
			}
			&.link-editor {
				width:fit-content;
				top: -162px;
			}
		}
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.7;
		filter: blur(1px);
	}

	&.hypertext {
		min-width: 100%;
		border: 1px solid $alt-gray;
		border-radius: 12px;
		padding: 14px 16px;

		&:hover {
			background-color: transparent;
			border: 1px solid $alt-gray;
		}

		button.link {
			padding: 12px;
		}
		
		.toolbar {
			left: unset;
			transform: unset;
			right: 70px;
		}

		.toolbar:not(.link-editor) {
			padding: 0;
			right: 50%;
			top: 100px;
			position: fixed;
		}

		&.active {
			.toolbar {
				position: fixed;
				z-index: 100;

				&:not(.link-editor) {
					top: 63px;
					right: 50%;
				}

				&.link-editor {
					top: 40px;
					right: -10%;
				}
			}
		}
	}
}

.rejected-passcode-message.modal-wrapper {
	.modal-body {
		display: flex;
		align-items: flex-end;
		padding-top: 30px;
	}
}

//Have to use !important, not my favorite, to override inline style and make sure only affect the event title. 
.left > .event-title > .title-top > .event-title-editor > .editable-text.slim-container {
	white-space: unset !important;
}