@import './src/scss/old-deps';

.transparent-nav-modal {

	&.modal-wrapper {

		.modal {
			padding: 16px;

			&.normal {
				width: 332px;
			}
		}

		.modal-header {
			display: none;
		}

		.modal-body {
			padding: 0;
		}

		.modal-footer {
			margin-top: 0;
		}
	}

	.sketch-picker {
		margin-bottom: 12px;
		padding: 0 !important;
		background: none !important;
		box-shadow: none !important;

		// color area
		& > div:first-child {
			margin-bottom: 8px;
			border-radius: 8px;

			// hue and transparency sliders with result color square
			& + div {

				& > div {

					// hue and transparency sliders
					&:first-child {
						padding: 0 !important;

						// slider wrappers
						& > div {
							overflow: visible !important;
							border-radius: 10px;

							* {
								border-radius: inherit !important;
							}

							// slider handle
							div div div div {
								width: 16px !important;
								height: 16px !important;
								margin-top: -3px !important;
								border-radius: 50% !important;
								box-shadow: 0 4px 7px rgb(0 0 0 / 0.35) !important;
								transform: none !important;
							}

							// hue slider wrapper
							&:first-child {
								margin-top: 3px;
							}

							// transparency slider wrapper
							&:last-child {
								margin-top: 10px !important;
								margin-bottom: 3px;

								// a wrapper
								& > div {

									// one of handle wrappers
									& > div:last-child {
										margin: 0 16px 0 0 !important;
									}

									// transparent background
									& > div > div {
										background-size: contain !important;
									}
								}
							}
						}
					}

					// result color square
					&:last-child {
						width: 36px !important;
						height: 36px !important;
						margin: 0 0 0 8px !important;
						border-radius: 4px !important;

						div {
							border-radius: inherit !important;
							box-shadow: none !important;
						}
					}
				}
			}
		}

		// fix to the hue slider to make it capable of handling a bigger handle
		.hue-horizontal {
			margin-right: 16px !important;
			padding: 0 !important;
			background: none !important;

			&::before {
				content: '';
				position: absolute;
				inset: 0;
				margin-right: -16px;
				background: linear-gradient(to right, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);
				border-radius: inherit;
			}
		}

		// color values
		& > div:nth-last-child(2) {
			position: absolute;
			bottom: 0;
			padding-top: 0 !important;

			input {
				order: 1;
				width: 48px !important;
				height: 32px;
				padding: 0 10px !important;
				text-align: center;
				color: $white;
				background: none;
				border: 1px solid $border-light-gray !important;
				border-radius: 16px;
				outline: 0;
				-moz-appearance: textfield;

				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					display: none;
				}
			}

			label {
				margin-bottom: 4px;
				padding: 0 !important;
				font-size: 12px !important;
				line-height: 16px;
				text-transform: uppercase !important;
				color: $light-gray !important;
			}

			// value wrappers
			& > div {
				flex: 0 !important;
				margin-left: 4px;
				padding-left: 0 !important;

				&:nth-child(2) {
					margin-left: 0;
				}

				&:first-child {
					flex-grow: 1 !important;
					margin-right: 8px;
					margin-left: 0;

					input {
						width: 88px !important;
					}
				}

				// input and label holder
				& > div {
					display: flex;
					flex-direction: column;
				}
			}
		}
	}

	.transparent-nav-modal-picker {
		position: relative;
		padding-bottom: 64px;
	}

	.transparent-nav-modal-blur {
		display: flex;
		justify-content: space-between;
		align-items: center;

		span {
			font-size: 12px;
			font-weight: 600;
			color: $light-gray;
		}

		input {
			width: 54px;
			height: 32px;
			padding: 0 10px;
			text-align: center;
			color: $white;
			background: none;
			border: 1px solid $border-light-gray;
			border-radius: 16px;
			outline: 0;
			-moz-appearance: textfield;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				display: none;
			}
		}
	}
}
