@mixin baseSessionResponsive($size) {
	@if $size =="med-desktop" {

		.session .session-header-and-stream .session-header>header .session-titlebox,
		.session .session-header.new-navigation>header .session-titlebox {
			left: 100px;
		}

		.session-header header .session-titlebox.new-navigation {
			top: auto;
			height: 55px;
		}

		.session-header.new-navigation:not(.breakout-session):not(.has-banner-announcement) {
			top: -11px;
		}

		.session .session-header-and-stream .session-header>header.is-mobile .session-titlebox {
			left: 20px;

			@media screen and (orientation: landscape) {
				left: 100px;
			}
		}
	}

	@if $size =="small-desktop" {
		.host-control-container+.horizontal {
			margin-top: unset;
		}

		.session,
		.session-editor-wrapper {
			.session-header {
				left: 0;

				&.new-navigation {
					&.is-desktop {
						&.has-navigation {
							left: 0;
						}
					}
				}

				&.is-desktop {
					&.has-navigation {
						left: 95px;
					}
				}

				header {
					padding: 16px;

					.session-titlebox {
						button {
							padding-left: 15px;
						}

						&.new-navigation {
							button {
								padding-left: 0;
							}
						}
					}
				}

				&.breakout-session.in-google-meet-session {
					header {
						padding: 0;
					}

					@include media-query($med-down) {
						background: none;

						header {
							padding: 16px;
						}
					}
				}

				.session-main-content {
					&.fireside-session {
						.modules-container.display-language-dropdown {
							.language-dropdown-container {
								top: -45px;
							}
						}
					}
				}
			}
		}
	}

	@if $size =="mobile" {

		.session,
		.session-editor-wrapper {
			.host-control-container {
				padding: 85px 25px 0;
			}

			.session-header {
				width: 100%;

				header {
					flex-direction: row-reverse;

					.session-titlebox {
						button {
							padding-left: 0;
						}
					}

					&.display-language-dropdown {
						justify-content: flex-end;
						flex-direction: unset;

						.language-dropdown {
							margin: 0 16px 2px 0;

							.new-dropdown-value-container {
								@include flex-center;
								padding: 4px 8px;
								height: 36px;

								.new-dropdown-value {
									margin: 0 8px 0 0;
								}
							}
						}
					}

					&.has-banner-announcement {
						position: unset;
					}
				}
			}

			&:not(.Limelight) {

				.modules-container,
				.modules-container.vertical {
					.page-container-body {
						.session-module {
							padding-left: 25px;
							padding-right: 25px;
						}
					}
				}
			}

			.modules-container,
			.modules-container.vertical {
				.page-container-body {
					width: 100%;
					max-width: unset;

					.session-module {
						padding-left: 25px;
						padding-right: 25px;
					}
				}
			}

			.session-main-content {
				.sticky-component-wrapper {
					padding: 0 25px;
				}
			}

			&:not(.breakout-session) {
				padding-top: calc(126px + 56.25%);
				position: relative;

				&.video-v2 {
					padding-top: calc(var(--mobile-chat-height) + 56.25% + var(--banner-height, 0px));

					&.left-nav {
						padding-top: calc(var(--mobile-chat-height) + 56.25%);
					}
				}

				&.meet-fireside {
					padding-top: calc(var(--mobile-chat-height) + 100% + var(--banner-height, 0px) + var(--fireside-host-banner-height, 0px));

					&.in-meet-waiting-room {
						padding-top: 100svh;

						.session-stream-container {
							height: 100%;

							.meet-fireside {
								height: 100%;
								padding-bottom: 100svh !important;

								.video-player-v2-wrapper {
									height: 100%;
								}
							}
						}
					}
				}
			}

			&.fireside-session {
				padding-top: calc(64px + 56%);
			}
		}

		.menu-tablet-popup {
			top: 60px;
		}

		.session-header .menu-tablet .icon-large {
			padding: 0;
			padding-right: 0;
		}

		.module-container .evt-heading-2,
		.evt-heading-2 {
			&:not(.stable) {
				font-size: 25px;
				line-height: 30px;
			}
		}
	}

	@if $size =="tablet" {

		.session,
		.session-editor-wrapper {
			.session-header {
				width: 100%;
				left: 0;

				header {
					display: flex;
					padding: 16px;
				}
			}

			//including template class names here so that the level of specificity will override previous styling
			.marketing-page-navigation {
				display: none;
			}

			.host-control-container {
				padding: 24px 75px 0;
			}

			.host-control-container+.horizontal {
				margin-top: unset;
			}

			&:not(.Limelight) {

				.modules-container,
				.modules-container.vertical {
					.page-container-body {
						.session-module {
							padding-left: 75px;
							padding-right: 75px;
						}
					}
				}
			}

			.modules-container,
			.modules-container.vertical {
				.page-container-body {
					width: 100%;
					max-width: unset;

					.session-module {
						padding-left: 75px;
						padding-right: 75px;
					}
				}

				&.display-language-dropdown {
					.tab-navigation {
						li {
							// need to z-index over the language dropdown for these buttons in the vertical tab layout
							z-index: 1;
						}
					}
				}
			}

			.session-main-content {
				.sticky-component-wrapper {
					padding: 0 75px;
				}
			}

			// &:not(.breakout-session) {
			// 	&.video-v2 {
			// 		&.pre-live {
			// 			// pre-live poster height
			// 			padding-top: calc(360px);
			// 		}
			// 	}
			// }

			.modules-container {
				.language-dropdown-container {
					padding: 0 75px 0;
					top: 45px;
				}
			}

			.modules-container,
			.session-header header.display-language-dropdown {
				.language-dropdown-container {
					.new-dropdown-panel {
						right: 0;
						left: unset;
						width: unset;
						height: unset;
						top: unset;
						position: absolute;
						padding: 10px;
					}
				}
			}

			.modules-container.vertical {
				.tab-navigation {
					max-width: unset;
					padding: 0 75px;
				}
			}

			.questions-container {
				&.focused {
					.main-question {
						.ask-question-container {
							background-color: var(--backgroundColor);

							.ask-question-input-container {
								background-color: transparent;

								input {
									background-color: var(--containerColor);
								}
							}
						}
					}
				}
			}

			.products {
				&.products.products-vertical-tabs {
					.product-list {
						.product {
							display: block;
							padding: 0;
							width: 325px;
							height: 515px;
							background-color: transparent;

							.product-item-image-container {
								width: 325px;
								height: 270px;
							}

							.product-item-content {
								padding: 0;
							}
						}
					}
				}
			}

			.speakers {
				.container {
					.speakers-list {
						.speaker {
							width: 100%;
						}
					}
				}
			}
		}

		.module-container .evt-heading-2,
		.evt-heading-2 {
			&:not(.stable) {
				font-size: 35px;
				line-height: 40px;
			}
		}

		.evt-heading-4 {
			&:not(.stable) {
				font-size: 20px;
				line-height: 25px;
			}
		}

		.evt-body-text-1 {
			&:not(.stable) {
				font-size: 15px;
			}
		}

		.evt-caption-1 {
			&:not(.stable) {
				font-size: 12px;
			}
		}
	}

	@if $size =="xxs" {
		.session {
			.description {
				.description-content {
					.title {
						line-height: 30px;
					}

					.description {
						font-size: 16px;
					}
				}
			}
		}
	}
}