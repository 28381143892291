.waiting-indicator-telly {
	display: flex;
	align-content: center;

	.telly {
		width: 40%;
		height: auto;
		margin-bottom: -12px;
		margin-left: 10px;
		transform: scaleX(-1);
	}

	img {
		background-color: $darker-gray !important;
	}
}

.waiting-indicator-container {
	@include flex-center;
	min-height: 100px;
}
