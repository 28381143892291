@mixin smMobileBaseResponsive($size) {
	@if $size == "sm-mobile" {

		.video-call-settings-popover-wrapper {
			width: calc(100% - 52px);

			select {
				width: 100%;
			}
		}

		.breakouts-modal-wrapper {
			.breakouts-modal-content {
				.user-info {
					display: none;
				}

				.video-call-button {
					background-color: rgba(#EBEEFF, 0.2);

					svg {
						path {
							fill: $white;
						}
					}
				}
			}
		}

		.breakouts-wrapper {
			.breakouts-warning-popover {
				top: 45%;

				&.show {
					right: calc((100% - 360px) / 2);
				}
			}

			&.breakouts-wrapper-with-panel {
				.breakout-main-section {
					max-width: initial;
					z-index: 1;
				}

				.breakouts-right-panel-container {
					z-index: 1;
					padding-top: 75px;
					background-color: rgba(0, 0, 0, 0.7);
					max-width: initial;
					width: 100%;

					.breakouts-right-panel {
						background-color: var(--backgroundColor);
					}
				}
			}

			.breakout-main-section {
				.breakouts-header {
					.breakouts-header-right {
						display: none;
					}
				}
			}

			.breakouts-video-feeds-wrapper {
				display: block;
				
				.breakouts-video-feed-wrapper {
					margin-bottom: 8px;
					max-width: 100%;
					flex: 1;
				}
			}
		}

		//NOTE: When consolidating styling, this was original under the mobile mixin but it was inline with the break max
		// for sm-mobile here

		// adding session main content as a wrapper for more specificity so that the .session-header styles work
		// but we don't want to wrap all the other styles below with .session-main-content or the editor will break
		.session-main-content {
			.breakout-session-banner {
				position: relative;
				// because breakout banner header is transparent background, we don't want it to be sticky:
				.session-header {
					position: absolute;
					header {
						position: static;
					}
				}
			}
		}

		.breakout-session-banner {
			.breakout-session-banner-container {
				padding-left: 25px;
				padding-right: 25px;
				padding-bottom: 40px;
				margin: auto 0 0 0;

				.breakout-session-banner-buttons {
					display: flex;
					flex-direction: column;
					gap: 15px;
				}

				.breakout-session-banner-tags {
					.tag-container {
						font-size: 12px;
					}
				}

				.breakout-session-banner-title {
					font-size: 25px;
				}
				.breakout-session-add-calendar {
					width: 100%;

					.breakout-session-add-calendar-content {
						.breakout-session-add-calendar-title {
							font-size: 16px;
						}
						.breakout-session-add-calendar-subtitle  {
							font-size: 12px;
						}
					}
					button.evt-button {
						font-size: 13px;
						padding: 10px 19px;
					}
				}
			}
		}
	}
}