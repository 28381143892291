.control-buttons {
	position: absolute;
	top: 8px;
	right: 16px;
	opacity: 0;
	z-index: 5;

	.control-buttons-image-actions {
		display: inline-block;
	}

	button {
		margin-left: 8px;
	}
}

// Any Parent
*:hover>.control-buttons {
	opacity: 1;
}

.editor.registration-v2.registration-above-the-fold-r.desktop {
	.control-buttons {
		right: 470px;
	}
}
