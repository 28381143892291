@import "./src/scss/old-deps";
@import "./src/scss/deps";
@import "./src/scss/base/colors";
@import "./src/scss/abstracts/colors";

@mixin below-the-fold-responsive($size) {
	@if $size =="small-desktop" {}

	@if $size =="tablet" {

		.below-the-fold,
		.above-the-fold {
			--side-spacing: 50px;
		}

		.page-container-below-the-fold {
			width: 100%;
			margin-left: 0;
			padding-top: 0;

			.page-container-body {
				padding: 0 var(--side-spacing) 20px var(--side-spacing);
			}

			.page-container-below-the-fold-tabs-container {
				position: relative;
				margin-top: 0;
			}

			.tab-navigation {
				padding-left: var(--side-spacing);
			}

			.scrolled-past-the-fold-active {
				.tab-navigation {
					padding-left: 0;
				}
			}
		}

		.video-gutter {
			display: none;
		}

		&.editor-main-container {
			.page-container-below-the-fold-tabs-container {
				&:not(.scrolled-past-the-fold-active) {
					width: auto;
					margin-right: var(--side-spacing);
				}
			}

			.page-container-below-the-fold {
				margin-right: 0;

				.module-title {
					width: auto;
					margin-right: var(--side-spacing);
				}

				.page-container-body {
					.container {
						min-width: 0;
						margin-right: var(--side-spacing);
					}
				}
			}
		}
	}

	@if $size =="mobile" {

		.below-the-fold,
		.above-the-fold {
			--side-spacing: 25px;
			position: relative;

			.page-container-below-the-fold-tabs-container {
				margin-top: 0;
			}

			.page-container-below-the-fold-tabs-container.scrolled-past-the-fold-active {
				position: fixed;
				z-index: 100;
			}
		}

		&.chat-open {
			.below-the-fold {
				.page-container-below-the-fold-tabs-container.scrolled-past-the-fold-active {
					z-index: 1;
				}
			}
		}

		.page-container-below-the-fold {

			.editor-wrapper.products-below-the-fold-tabs,
			.editor-wrapper.similar-sessions,
			.editor-wrapper.documents,
			.editor-wrapper.products,
			.editor-wrapper.questions,
			.editor-wrapper.surveys,
			.editor-wrapper.description,
			.editor-wrapper.breakout-rooms,
			section.session-module.products.products-below-the-fold-tabs,
			.documents.session-module,
			.session-module.similar-sessions.agenda,
			.questions-container.session-module,
			section.surveys.session-module,
			.session-module.products,
			.session-module.description,
			.session-module.breakout-rooms {
				padding-right: 0 !important;
				padding-left: 0 !important;
			}
		}
	}

	@if $size =="mobile-xs" {

		.below-the-fold,
		.above-the-fold {
			.page-container-below-the-fold-tabs-container.scrolled-past-the-fold-active:not(.breakout) {
				top: calc((100vw - var(--scrollbarWidth, 0px)) * 0.5625 + var(--mobile-chat-height) + var(--banner-height, 0px));
				margin-top: -1px;
				padding-left: 0;
			}

			.page-container-below-the-fold-tabs-container.scrolled-past-the-fold-active.breakout.scroll-up {
				top: 55px;
			}
		}
	}
}

.scrolled-past-placeholder {
	height: 80px;
}

.below-the-fold {
	--side-spacing: 100px;
	max-width: 100%;
	border-top: $above-the-fold-outline-color solid 1px;
}

@media screen and (max-width: 768px) {
	.below-the-fold {
		border-top: none;
	}
}

.scrolled-past-the-fold-active {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 100;
	filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.1));
	background-color: var(--user-mode-background);
	// padding-left: calc(var(--side-spacing) - 20px);

	li:first-child {
		padding-left: var(--side-spacing);
	}

	li:last-child {
		padding-right: 25px;
	}
}

.page-container-below-the-fold {
	// --backgroundColor: #f5f7ff;
	// --containerColor: #ffffff;
	display: flex;
	flex-direction: column;
	min-width: 0;
	width: 840px;
	margin-left: var(--side-spacing);
	// padding-top: 25px;

	.module-title {
		@include get-typography(h6);
		flex: none;
		flex-grow: 0;
		order: 0;
		margin-bottom: 10px;
		font-family: var(--headingFont);
		padding-top: 20px;

		&.hide-title {
			height: 0;
			margin: 0;
			visibility: hidden;
		}
	}
}

.module-title {
	&.hide-title {
		height: 0;
		margin: 0;
		visibility: hidden;
	}
}

.scrolled-past-placeholder {
	height: 80px;
	width: 100%;
}

.below-the-fold .page-container-below-the-fold-tabs-container,
.page-container-below-the-fold-tabs-container {
	width: 100%;
	overflow: auto;

	&:not(.scrolled-past-the-fold-active) {
		position: relative;
		margin-top: 25px;

		li:first-child {
			padding-left: var(--side-spacing);
		}

		li:last-child {
			padding-right: 25px;
		}
	}

	// right-side gradient for side-scrolling tabs 
	// should not need to be shown/hidden because the padding 
	// is enough that it will never be visible unless the buttons
	// are behind it and the background colors match up with the
	// container's background color.
	&::after {
		pointer-events: none;
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		width: 30px;
		height: 100%;
		background: linear-gradient(to right, rgba(var(--user-mode-background-rgb), 0), rgba(var(--user-mode-background-rgb), 1));
	}

	// left-side gradient for side-scrolling tabs
	&::before {
		pointer-events: none;
		z-index: 1;
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 30px;
		height: 100%;
		background: linear-gradient(to left, rgba(var(--user-mode-background-rgb), 0), rgba(var(--user-mode-background-rgb), 1));
	}

	&.scrolled-past-the-fold {
		display: none;


		@media screen and (max-width: 768px) {
			display: block;
			visibility: hidden;
		}
	}

	.page-container-below-the-fold-tabs,
	.page-container-below-the-fold-tabs.tab-navigation {
		display: flex;
		gap: 10px;
		margin: 0;
		padding: 20px 0;
		list-style-type: none;
		-webkit-user-select: none;
		user-select: none;
		overflow-x: scroll;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

		li {
			button {
				padding: 0 15px;
				font-size: 13px;
				font-weight: 700;
				text-decoration: none;
				background: rgba(var(--accentColor-rgb), 0.1);
				border-radius: 100px;
				border: 2px solid rgba(var(--accentColor-rgb), 0);
				height: 40px;
				transition: background-color 200ms;
				color: color-mix(in srgb, var(--accentColorOnSessionBackgroundAndAccent20PercentBlend), #fff 30%);
				white-space: nowrap;

				span {
					height: 21px;
					line-height: 21px;
				}

				&:hover:not(.active) {
					background-color: rgba(var(--accentColor-rgb), 0.3);
				}
			}

			&.active {
				button {
					color: var(--accentColorOnSessionBackgroundAndAccent20PercentBlend);
					background-color: rgba(var(--accentColor-rgb), 0.2);
					border: solid var(--accentColor) 2px;
					transition: background-color 200ms;

					&:hover {
						opacity: 1;
						background-color: rgba(var(--accentColor-rgb), 0.2);
					}
				}
			}
		}
	}
}

.page-container-below-the-fold-tabs-container {
	&.scrolled-past-the-fold-active {
		.page-container-below-the-fold-tabs.tab-navigation {
			padding: 20px 0 20px 0;
		}
	}
}

.below-the-fold-with-gutter {
	display: flex;
	width: 100%;
	overflow-x: hidden;
	flex-direction: column;

	.video-gutter {
		flex-grow: 1;
	}

	.page-container-below-the-fold {

		.editor-wrapper.products-below-the-fold-tabs,
		.editor-wrapper.similar-sessions,
		.editor-wrapper.documents,
		.editor-wrapper.products,
		.editor-wrapper.questions,
		.editor-wrapper.surveys,
		.editor-wrapper.description,
		.editor-wrapper.breakout-rooms,
		.editor-wrapper.blank-section-v2,
		section.session-module.products.products-below-the-fold-tabs,
		.documents.session-module,
		.session-module.similar-sessions.agenda,
		.questions-container.session-module,
		section.surveys.session-module,
		.session-module.products,
		.session-module.description,
		.session-module.breakout-rooms {
			padding: 0 0 25px;
			justify-content: left; // the default in the editor for V1 is center, but for V2 in the live site it is left aligned. 

			.container {
				padding: 0;
			}
		}

		// specific engage card styles
		.editor-wrapper.surveys,
		section.surveys.session-module {
			padding: 0;

			// select the last sibling that has a class of .surveys
			// we need to use has method, because if we just use :last-child it will select the last child of the parent
			&:not(:has(~ .surveys)) {
				padding-bottom: 25px;
			}
		}

	}

	// only if vertical nav
	&:not(.horizontal-nav) {
		.page-container-below-the-fold-tabs-container {
			&.scrolled-past-the-fold-active {

				// if session page and vertical navbar and greater than 1024px
				@include media-query($lrg-up) {
					top: var(--banner-height, 0);
				}
			}
		}
	}
}

div.dark-mode {

	.below-the-fold,
	.above-the-fold {
		color: $white;
		background-color: $dark-gray;
		border-top-color: rgb($above-the-fold-dark-mode-outline-color);

		.page-container-body {
			background: none;
		}

		.page-container-below-the-fold-tabs-container {
			background-color: var(--user-mode-background);

			&:not(.scrolled-past-the-fold-active) {
				position: relative;
			}

			// change color of gradients for dark mode
			&::after {
				background: linear-gradient(to right, transparent, var(--user-mode-background));
			}

			&::before {
				background: linear-gradient(to left, transparent, var(--user-mode-background));
			}

			&.scrolled-past-the-fold-active {
				&::after {
					background: linear-gradient(to right, transparent, var(--user-mode-container-color));
				}

				&::before {
					background: linear-gradient(to left, transparent, var(--user-mode-container-color));
				}
			}

			.page-container-below-the-fold-tabs,
			.page-container-below-the-fold-tabs.tab-navigation {
				li {
					button {
						background: $gray;
						font-family: var(--bodyFont);
						border-color: none;

						&:hover {
							opacity: 1;
							background-color: $gray-hover;
						}
					}

					&.active {
						button {
							opacity: 0.8;
							background-color: rgba(var(--accentColor-rgb), 0.25);
							border-color: var(--accentColor);
							border-width: 2px;
						}
					}
				}
			}
		}



		@keyframes pastTheFoldAppear {
			from {
				background-color: var(--user-mode-background);
			}

			to {
				background-color: $med-gray;
			}
		}

		.scrolled-past-the-fold-active {
			animation: pastTheFoldAppear 250ms forwards;
			background-color: $med-gray;
			filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.3));

			.page-container-below-the-fold-tabs,
			.page-container-below-the-fold-tabs.tab-navigation {
				background-color: inherit;
			}
		}
	}
}

// Editor
.editor-main-container {
	.session-modules-container-below-the-fold {

		.module-group-controls {
			display: none;
		}

		&:hover {
			.module-group-controls {
				display: flex;
			}
		}
	}

	.page-container-below-the-fold-tabs-container {
		&:not(.scrolled-past-the-fold-active) {
			width: 100%;
		}

		&.page-container-below-the-fold-tabs-container.scrolled-past-the-fold-active {
			position: sticky;
			top: 0;
		}
	}

	.scrolled-past-placeholder {
		display: none;
	}

	.page-container-below-the-fold {

		.module-title {
			max-width: 840px;
			width: 100%;
			margin-left: 0;
		}

		.page-container-body {
			.container {
				max-width: 840px;
				width: 100%;
				margin-left: 0;
			}
		}
	}
}

.session:not(.editor) {
	@include media-query($xlrg-down) {
		@include below-the-fold-responsive("small-desktop");
	}

	@include media-query($med-down) {
		@include below-the-fold-responsive("tablet");
	}

	@include media-query($sm-down) {
		@include below-the-fold-responsive("mobile");
	}

	@include media-query($xs-down) {
		@include below-the-fold-responsive("mobile-xs");
	}
}

.tablet {
	@include below-the-fold-responsive("tablet");

	.page-container-below-the-fold {
		.page-container-body {
			.session-modules-container-below-the-fold {
				.editor-wrapper {
					padding-right: 0;
					padding-left: 0;
				}
			}
		}
	}
}

.mobile {
	@include below-the-fold-responsive("tablet");
	@include below-the-fold-responsive("mobile");
	@include below-the-fold-responsive("mobile-xs");

	.below-the-fold,
	.above-the-fold {
		.page-container-below-the-fold-tabs-container.scrolled-past-the-fold-active {
			position: sticky;
			top: 0;
		}
	}
}
