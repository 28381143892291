.event-tags-container {

	.event-tag-container {
		display: flex;
		align-items: center;
		
		.event-tag-name {
			flex: 2;
			margin-right: 8px;
		}
	
		.event-tag-color-container {
			flex: 1.1;
			margin-bottom: 24px;
	
			> label {
				font-size: 12px;
				font-weight: bold;
			}
		}
	
		.event-tag-delete-container {
			button {
				height: 3rem;
				width: 3rem;
			}
		}
	
	}
	
	.event-tag-add-tag {
		padding: 8px 16px;
	}
}