@mixin responsive-sizes($size) {
	$scrollbar-width: 10px;
	$scrollbar-top-padding: 16px;

	// This allows us to use the & selector in the mixin to target the parent class
	&.registration-above-the-fold-r,
	&.registration-above-the-fold-l {
		@if $size =="desktop" {
			.registration-heading-container {
				overflow: auto;
			}
		}

		@if $size =="tablet" {
			flex-direction: column;

			&.editor {
				padding-bottom: 150px;
				overflow: scroll;

				.long-description-mobile-cta {
					bottom: 24px;
				}
			}

			.registration-heading-container {
				overflow: unset;
			}

			&.left-type-layout {
				flex-direction: column;

				.registration-form-container {
					flex-direction: column;
				}
			}

			.logo-container {
				display: flex;
			}

			.project-overview-content {
				max-width: 100%;
				margin: 122px 75px;
			}

			.registration-form-container {
				width: 100%;
				align-items: center;
				padding-top: 16px;

				.registration-slider {
					max-width: 400px;
					padding: 50px 25px;
					width: 100%;

					.registration-logo {
						display: none;
					}
				}
			}
		}

		@if $size =="mobile-xs" {
			flex-direction: column;

			&.editor {
				overflow: scroll;

				.long-description-mobile-cta {
					bottom: 24px;
				}
			}

			.registration-heading-container {
				overflow: unset;
			}

			.logo-container {
				display: flex;
			}

			.project-overview-content {
				max-width: 100%;
				margin: 122px 25px 50px;
			}

			.registration-form-container {
				width: 100%;
				align-items: center;
				height: auto;

				.registration-slider {
					max-width: 400px;
					padding: 50px 25px;
					width: 100%;

					.registration-logo {
						display: none;
					}
				}
			}
		}
	}

	&.registration-landing-r,
	&.registration-landing-l {
		@if $size =="small-desktop" {
			.registration-form-container {
				width: 350px;

				.registration-slider {
					max-width: 350px;
					padding: 80px 20px;
				}
			}
		}

		@if $size =="tablet" {
			flex-direction: column;
			height: 100%;

			&.editor {
				.landing-page-v2 {
					padding-bottom: 60px;
				}

				.long-description-mobile-cta {
					bottom: -28px;
				}
			}

			//Last child of the parent container
			.landing-page-v2>*:last-child {
				padding-bottom: 150px;
			}

			.registration-form-container {
				display: none;
				position: absolute;
			}

			.project-overview-content {
				padding: 40px 25px;
			}

		}

		@if $size =="mobile-xs" {
			flex-direction: column;
			height: 100%;

			&.editor {
				.landing-page-v2 {
					padding-bottom: 60px;
				}

				.long-description-mobile-cta {
					bottom: -28px;
				}
			}

			//Last child of the parent container
			.landing-page-v2>*:last-child {
				padding-bottom: 150px;
			}

			.registration-form-container {
				display: none;
				position: absolute;
			}

			.project-overview-content {
				padding: 40px 25px;
			}

			.long-description-mobile-cta {
				display: flex;
			}
		}
	}

	&.registration-hero-r,
	&.registration-hero-l {
		@if $size =="tablet" {
			flex-direction: column;
			height: 100%;
			overflow: scroll;


			&.editor {
				.long-description-mobile-cta {
					bottom: 52px;
				}
			}

			&.left-type-layout .registration-form-container,
			&.big-hero-layout .registration-form-container,
			.registration-form-container {
				flex-direction: column-reverse;
				padding: 50px 60px;
				height: auto;
			}

			.project-overview-content {
				max-width: 100%;
			}

			.logo-container {
				display: flex;
			}

			.registration-panel-popout {
				padding: 0;
				min-width: 0;
				width: 100%;

				.registration-logo {
					display: none;
				}

				.registration-slider {
					min-width: 100%;
					padding: 40px;
				}
			}
		}

		@if $size =="mobile-xs" {
			flex-direction: column;
			height: 100%;
			overflow: scroll;


			&.editor {
				.long-description-mobile-cta {
					bottom: 52px;
				}
			}

			.registration-heading-container {
				height: 150px;
				min-height: 150px;
			}

			&.left-type-layout .registration-form-container,
			&.big-hero-layout .registration-form-container,
			.registration-form-container {
				flex-direction: column-reverse;
				padding: 50px 25px;
				height: auto;
			}

			.logo-container {
				display: flex;
			}

			.registration-panel-popout {
				padding: 0;
				min-width: 0;

				.registration-logo {
					display: none;
				}

				.registration-slider {
					padding: 25px;
				}
			}
		}
	}

	&.registration-modal {
		@if $size =="mobile-xs" {
			height: 100%;

			&.left-type-layout {
				.registration-form-container {
					flex-direction: column;
				}
			}

			.registration-form-container {
				width: calc(100% - 50px);
				overflow: scroll;

				.registration-slider {
					width: 100%;
					overflow: scroll;
					padding: 25px;
				}

				.registration-panel-popout {
					width: 100%;
				}
			}
		}
	}

	@if $size =="mobile-xs" {
		.registration-form-container {
			.registration-slider {
				.registration-panel-v2 {
					.registration-actions {
						padding-top: 12px;

						&.secondary {
							justify-content: space-between;

							&>div {
								gap: 4px;
							}

							.registration-button {
								padding: 10px 20px;
								height: 44px;
							}
						}
					}
				}
			}
		}
	}
}