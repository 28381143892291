@import "src/scss/deps";

@keyframes reactionX {
	to {
		right: 120.5px;
	}
}

@keyframes reactionXChatOverlay {
	to {
		right: 350.5px;
	}
}

@keyframes reactionY {
	to {
		bottom: 90%;
	}
}

@keyframes scaleFade {
	0% {
		opacity: 0;
	}

	5% {
		opacity: 1;
	}

	75% {
		opacity: 0.5;
		transform: scale(0.65);
	}

	100% {
		opacity: 0;
		transform: scale(0.25);
	}
}

canvas.float-container {
	z-index: 1;
	position: absolute;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	max-width: 1280px;
	max-height: 720px;
	pointer-events: none;
	mask-image: linear-gradient(transparent 15%, black 75%, transparent 100%);
}

.float-container {
	z-index: 1;

	.floating-reaction {
		align-items: center;
		border-radius: 100px;
		display: flex;
		height: 40px;
		justify-content: center;
		opacity: 0;
		position: absolute;
		width: 40px;
		right: 120px;
		bottom: 60px;
		z-index: 1;

		&.reaction-0 {
			animation: reactionX 1.4s infinite cubic-bezier(0.5, 400, 0.5, -400) alternate,
				reactionY 1.4s linear infinite alternate, scaleFade 1.4s linear;
			animation-delay: 0ms;
		}

		&.reaction-1 {
			animation: reactionX 1.4s infinite cubic-bezier(0.5, -200, 0.5, 200) alternate,
				reactionY 1.4s linear infinite alternate, scaleFade 1.4s linear;
			animation-delay: 150ms;
		}

		&.reaction-2 {
			animation: reactionX 1.4s infinite cubic-bezier(0.5, 400, 0.5, -400) alternate,
				reactionY 1.4s linear infinite alternate, scaleFade 1.4s linear;
			animation-delay: 300ms;
		}

		&.reaction-3 {
			animation: reactionX 1.4s infinite cubic-bezier(0.5, -200, 0.5, 200) alternate,
				reactionY 1.4s linear infinite alternate, scaleFade 1.4s linear;
			animation-delay: 450ms;
		}

		img {
			height: 30px;
			width: 30px;
		}

		&.chat-overlay-enabled {
			right: 350px;
		}

		&.chat-overlay-enabled.reaction-0 {
			animation: reactionXChatOverlay 1.4s infinite cubic-bezier(0.5, 400, 0.5, -400) alternate,
				reactionY 1.4s linear infinite alternate, scaleFade 1.4s linear;
			animation-delay: 0ms;
		}

		&.chat-overlay-enabled.reaction-1 {
			animation: reactionXChatOverlay 1.4s infinite cubic-bezier(0.5, -200, 0.5, 200) alternate,
				reactionY 1.4s linear infinite alternate, scaleFade 1.4s linear;
			animation-delay: 150ms;
		}

		&.chat-overlay-enabled.reaction-2 {
			animation: reactionXChatOverlay 1.4s infinite cubic-bezier(0.5, 400, 0.5, -400) alternate,
				reactionY 1.4s linear infinite alternate, scaleFade 1.4s linear;
			animation-delay: 300ms;
		}

		&.chat-overlay-enabled.reaction-3 {
			animation: reactionXChatOverlay 1.4s infinite cubic-bezier(0.5, -200, 0.5, 200) alternate,
				reactionY 1.4s linear infinite alternate, scaleFade 1.4s linear;
			animation-delay: 450ms;
		}
	}
}

@mixin videoResponsive($size) {
	@if $size == "mobile" {
		.float-container {
			.floating-reaction {
				height: 25px;
				width: 25px;
			}
		}
	}
}

@include media-query($xs-down) {
	:not(.editor) {
		@include videoResponsive("mobile");
	}
}
