
@import '../../../scss/deps';

.ev-modal-wrapper.modal-wrapper {
	z-index: 999;

	.modal {
		padding: 0;

		.close {
			top: 50%;
			color: $gray-100;
			transform: translateY(calc(-1 * 50% + ((#{$ev-gap-x6} - #{$ev-gap-x4}) / 2)));

			svg {

				path {
					fill: $gray-100;
				}
			}
		}

		.modal-body {
			margin-bottom: 0;
			padding: 0 $ev-gap-x10;
		}

		.modal-footer {
			padding: $ev-gap-x6 $ev-gap-x10;

			button + button {
				margin-left: $ev-gap-x4;
			}
		}

		hr {
			margin: $ev-gap-x6 0;
			border: 0;
			border-top: 1px solid $gray-700;
		}

		.modal-subtitle {
			margin: $ev-gap-x2 0 0;
		}
	}

	.modal-header {
		position: relative;
		padding: $ev-gap-x6 $ev-gap-x18 $ev-gap-x4 $ev-gap-x10;
	}

	.modal-title {
		margin-bottom: 0;
		font-weight: 500;
	}

	&.transparent-background {
		background-color: transparent;
	}
}
