@import './src/scss/deps';

.main-event-banner,
.marketing-page-header,
.call-out {
	.call-to-action-url {
		@include flex-center(inline-flex);
		padding: 14px 24px;
		font-size: 15px;
		line-height: 25px;
		font-weight: 700;
		text-decoration: none;
		border: 1px solid transparent;

		&.primary {
			color: var(--buttonTextColor, var(--containerColor));
			background-color: var(--accentColor);

			&:active:not(.disabled),
			&:hover:not(.disabled) {
				background-color: rgba(var(--accentColor-rgb), 0.7);
			}

			&:focus:not(.disabled) {
				background-color: rgba(var(--accentColor-rgb), 0.8);
				border-color: var(--accentColor);
			}
		}
	}
}
