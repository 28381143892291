@import './src/scss/deps';

.long-description-mobile-cta {
	height: 180px;
	width: 100%;
	background-color: var(--secondaryBackgroundColor);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: fixed;
	bottom: 0;
	z-index: 11;
	font-family: var(--bodyFont);

	&.editor {
		position: sticky;
		margin-left: 0;
		min-height: 180px;
		height: 180px;
	}

	&:not(.editor) {
		@media screen and (min-width: 1024px) {
			display: none;
		}
	}

	span {
		color: var(--headingTextColor, white);
		font-size: 30px;
		font-weight: 700;
		margin-bottom: 24px;
	}

	button {
		background: var(--accentColor);
		color: var(--headingTextColor, white);
		font-family: var(--headingFont);
	}

	.call-out-title {
		margin-bottom: 24px;
	}

	.call-out-description {
		margin-bottom: 0;
	}
}
