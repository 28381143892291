$br-xxs: 320px;
$br-xs: 480px;
$br-sm: 640px;
$br-med: 768px;
$br-lrg: 1024px;
$br-xlrg: 1120px;
$br-xxlrg: 1440px;

$xxs: 'xxs';
$xxs-down: 'xxs-down';
$xs: 'xs';
$xs-up: 'xs-up';
$xs-down: 'xs-down';
$sm: 'sm';
$sm-down: 'sm-down';
$sm-up: 'sm-up';
$med: 'med';
$med-down: 'med-down';
$med-up: 'med-up';
$lrg: 'lrg';
$lrg-down: 'lrg-down';
$lrg-up: 'lrg-up';
$xlrg: 'xlrg';
$xlrg-down: 'xlrg-down';
$xlrg-up: 'xlrg-up';
$xxlrg: 'xxlrg';

/* 
	How to think about these breakpoints:
	The max-width includes all pixels up to the next size, this is so we can also have our min widths set properly.
 */
$breakpoints-list: (

	$xxs '(max-width: #{$br-xs})', // since this is the "first" breakpoint, we start at zero so we don't need a min width
	$xxs-down '(max-width: #{$br-xs})',
	
	$xs '(min-width: #{$br-xs}) and (max-width: #{$br-sm})',
	$xs-down '(max-width: #{$br-sm})',
	$xs-up '(min-width: #{$br-xs})',

	$sm '(min-width: #{$br-sm}) and (max-width: #{$br-med})',
	$sm-down '(max-width: #{$br-med})',
	$sm-up '(min-width: #{$br-sm})',
	
	$med '(min-width: #{$br-med}) and (max-width: #{$br-lrg})',
	$med-down '(max-width: #{$br-lrg})',
	$med-up '(min-width: #{$br-med})',

	$lrg '(min-width: #{$br-lrg}) and (max-width: #{$br-xlrg})',
	$lrg-down '(max-width: #{$br-xlrg})',
	$lrg-up '(min-width: #{$br-lrg})',
	
	$xlrg '(min-width: #{$br-xlrg}) and (max-width: #{$br-xxlrg})',
	$xlrg-down '(max-width: #{$br-xxlrg})',
	$xlrg-up '(min-width: #{$br-xlrg})',
		
	$xxlrg '(min-width: #{$br-xxlrg})'
);

// media query mixin (see end of file for usage example)
@mixin media-query($media-query) {
	$breakpoint-found: false;

	@each $breakpoint in $breakpoints-list {
		$name: nth($breakpoint, 1);
		$declaration: nth($breakpoint, 2);

		@if $media-query == $name and $declaration {
			$breakpoint-found: true;

			@media only screen and #{$declaration} {
				@content;
			}
		}
	}

	@if $breakpoint-found == false {
		@warn 'Breakpoint "#{$media-query}" does not exist';
	}
}

// usage:
/*
	.some-class {
		color: red;

		@include media-query($med) {
			color: blue;
		}
	}
*/
