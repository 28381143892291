@import "../../../scss/deps";

// Coloring
$message-card-bg: #312f38;
$message-card-header-icon-color: #b2b2bf;
$message-card-time-color: #878793;
$message-card-title-color: #b2b2bf;
$message-card-status-color: #878793;
$message-card-quote-border-bg: #625e70;
$message-card-quote-text-color: #bfbbcf;
$message-card-text-color: #fcfcfc;

$reply-massage-icon-color: #000;
$reply-massage-icon-hover-color: #fff;

// Metrics
$message-card-border-radius: 16px;

$reply-massage-line-height: 16px;

.card-more-messages-container {
	position: sticky;
	bottom: 0;
	display: flex;
	justify-content: center;

	.card-more-messages-btn {
		width: 50px;
		height: 50px;
	}
}

.message-card {
	position: relative;
	padding: $ev-gap-x4;
	background: $message-card-bg;
	border-radius: $message-card-border-radius;

	.message-card-header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		svg {
			flex-shrink: 0;
			margin-right: $ev-gap-x1;

			&.default-gray {
				path {
					fill: $message-card-header-icon-color;
				}
			}

			&.cyan {
				margin: -1px 0 0 $ev-gap-x1;
				vertical-align: middle;
			}
		}

		time {
			margin-left: $ev-gap-x1;
			font-size: 12px;
			line-height: 20px;
			color: $message-card-time-color;
		}

		& + .message-card-body {
			margin-top: $ev-gap-x3;
		}
	}

	.message-card-header-main {
		@include flex-center($justify-content: null);
		min-width: 0;
	}

	.message-card-title {
		font-size: 14px;
		line-height: 20px;
		font-weight: 500;
		color: $message-card-title-color;
	}

	.message-card-status {
		flex-shrink: 0;
		padding-left: $ev-gap-x2;
		font-size: 12px;
		line-height: 20px;
		color: $message-card-status-color;
	}

	.message-card-quote {
		position: relative;
		margin: $ev-gap-x2 0 $ev-gap-x2 $ev-gap-x2;
		padding-left: 10px;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 2px;
			height: 100%;
			background-color: $message-card-quote-border-bg;
		}

		.message-card-text {
			color: $message-card-quote-text-color;
		}
	}

	.message-card-text {
		font-size: 14px;
		line-height: 20px;
		color: $message-card-text-color;

		& + .message-card-gallery {
			margin-top: $ev-gap-x4;
		}

		.message-card-text-content {
			white-space: pre-wrap; // preserves new lines
		}
	}

	.message-card-gallery {
		div {
			background-position: 50% 50%;
			background-size: cover;
		}

		&.single-image {
			height: 194px;

			div {
				height: 100%;
				border-radius: 12px;
			}
		}

		&.double-sided {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: $ev-gap-x1;

			div {
				padding-bottom: 100%;

				&:first-child {
					border-radius: 12px 0 0 12px;
				}

				&:last-child {
					border-radius: 0 12px 12px 0;
				}
			}
		}
	}

	.message-card-actions {
		position: absolute;
		top: 10px;
		right: 16px;
		left: 0;
		display: flex;
		justify-content: flex-end;
		min-width: 186px;
		opacity: 0;
		visibility: hidden;
		background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba($message-card-bg, 0.5) 100%);
		transition: visibility 0.3s $easing, opacity 0.3s $easing;

		.smart-tooltip {
			margin-left: $ev-gap-x2;
		}
	}

	&:hover {
		.message-card-actions {
			opacity: 1;
			visibility: visible;
		}
	}

	& + .reply-message-card {
		margin-top: 2px;
	}
}

.reply-message-card {
	position: relative;
	padding: $ev-gap-x4 $ev-gap-x14 $ev-gap-x4 $ev-gap-x5;
	background: $message-card-bg;
	border-radius: $message-card-border-radius;

	.reply-message {
		max-height: $reply-massage-line-height * 4;
		font-size: 14px;
		line-height: $reply-massage-line-height;
		overflow: auto;
		outline: none;

		.reply-user {
			margin-right: $ev-gap-x1;
			color: $primary;
		}
	}

	.reply-textarea {
		display: none;
	}

	.reply-button {
		position: absolute;
		right: $ev-gap-x2;
		bottom: $ev-gap-x2;

		svg {
			path {
				fill: $reply-massage-icon-color;
			}
		}

		&:hover {
			svg {
				path {
					fill: $reply-massage-icon-hover-color;
				}
			}
		}
	}
}
