
@import './src/scss/deps';

.marketing-registered-page.agenda-wrapper {
	background-color: var(--backgroundColor);
	.registered-main-content {
		width: 100%;

		// to keep footer at bottom
		display: flex;
    flex-direction: column;
		@include mainContentMinHeight();
		section {
			flex-grow: 1;
		}
	}
	.module-container {
		max-width: 100%;
	}
	.agenda .module-container {
		max-width: 1440px;
		margin: auto;
		padding-top: 26px;
		.upper-agenda-title, .agenda-title, .agenda-description {
			display: none;
		}
	}
	.marketing-registered-agenda-title {
		font-family: var(--headingFont);
		font-weight: 700;
		font-size: 40px;
		line-height: 55px;
		padding: 0 130px;
		max-width: 1440px;
		margin: auto;
		text-align: start;
		margin-bottom: -50px;
	}

	.live-session-banner {
		// margin-bottom: 40px;
		.module-container {
			text-align: left;
		}
	}
}

@include media-query($xlrg-down) {
	.marketing-registered-page.agenda-wrapper {
		.marketing-registered-agenda-title {
			padding: 0 40px;
		}
	}
}

@include media-query($med-down) {
	.marketing-registered-page.agenda-wrapper {
		.marketing-registered-agenda-title {
			padding: 0 75px;
		}
	}
}
@include media-query($sm-down) {
	.marketing-registered-page.agenda-wrapper {
		.marketing-registered-agenda-title {
			padding: 0 25px;
		}
	}
}