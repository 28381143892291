@import './src/scss/old-deps';
@import './src/scss/base/mixins';
@import './src/scss/deps';

.admin-side-nav {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	width: 96px;
	height: 100vh;
	background: $med-gray;
	border-right: 1px solid $border-gray;
	z-index: 99;
	transition: width 0.2s ease-in-out 0ms;
	align-items: flex-start;

	.admin-side-nav-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100vh;
	}


	a {
		margin: 32px 20px 18px;
	}

	svg.brandlive-icon {
		min-height: 20px;
		max-height: 20px;

		path {
			fill: $white;
		}
	}

	img {
		height: 36px;
		width: 36px;
	}

	.channels-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		overflow-y: scroll;
		padding-left: 18px;

		.channel-wrapper {
			height: 60px;
			width: 100%;
			cursor: pointer;
			display: flex;
			align-items: center;
			margin: 5px 0;
			justify-content: flex-start;


			&:last-child {
				margin-bottom: 165px;
			}

			&.active {
				.colored-border {
					background: linear-gradient(108.66deg, $magenta -4.67%, $yellow 69.07%, $greenroom 120.95%);
				}

				.channel-name {
					color: $white;
				}
			}

			&:hover {
				.colored-border {
					background: linear-gradient(108.66deg, $magenta -4.67%, $yellow 69.07%, $greenroom 120.95%);
				}

				.channel-name {
					color: $white;
					display: block;
					transition: color 0.2s ease-in-out 0s;
				}
			}

			.channel-name {
				color: $border-med-gray;
				line-height: 60px;
				opacity: 0;
			}

			.colored-border {
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 15px;
				padding: 2.5px;
				margin-right: 10px;

				.transparent-container {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 55px;
					width: 56px;
					background-color: $gray-900;
					border-radius: 12px;

					.channel-avatar {
						height: 48px;
						width: 48px;
						margin-right: 0;
					}
				}
			}

			.smart-tooltip {
				position: unset;
				height: 60px;
				width: 60px;

				.smart-tooltip-content {
					position: relative;
					top: 0px;
					transform: translateY(-50px);
					display: inherit;
					width: fit-content;
					z-index: 99;
				}
			}
		}
	}

	.channel-search-container {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		background: linear-gradient(0deg, $gray-900 48.83%, rgba(38, 36, 43, 0.85) 78.86%, rgba(38, 36, 43, 0) 100%);
		height: 176px;
		width: 100%;
		position: absolute;
		bottom: 0px;
		padding-bottom: 20px;

		.search-input {
			border-color: transparent;
			transition: border-color 0.2s ease-in-out 0s;
			justify-content: center;
			height: 32px;

			button {
				svg {
					width: 24px;
					height: 24px;
					transition:
						width 0.2s ease-in-out 0.2s,
						height 0.2s ease-in-out 0s;

					path {
						fill: white;
						transition: fill 0.2s ease-in-out 0s;
					}
				}
			}

			input {
				width: 0;
				transition: width 0.2s ease-in-out 0s;
			}

			.search-input-close-placeholder {
				display: none;
			}
		}

		svg {
			cursor: pointer;
		}
	}

	.search-container {
		z-index: 999;
		position: absolute;
		bottom: 0px;
		left: 0px;
		padding: 20px;
		display: flex;
		justify-content: center;
		background-color: $gray-1000;
		align-items: center;
		border-radius: 20px;
		width: 364px;
		height: 280px;
		top: 620px;
		padding: 24px 0px 0px 0px;
		border-radius: 24px;
	}


	&:hover:not(.clicked) {
		width: 400px;
		transition: width 0.2s ease-in-out 600ms;

		.channels-wrapper {
			.channel-wrapper {
				align-items: flex-start;

				.channel-name {
					@include line-clamp(1);
					opacity: 1;
					transition: opacity 0.2s ease-in-out 600ms;

				}
			}
		}


		.channel-search-container {
			padding: 0 18px 20px;

			.search-input {

				width: 100%;
				border-color: $border-med-gray;
				transition-delay: .2s;

				button {
					svg {
						width: 16px;
						height: 16px;
						transition-delay: .2s;

						path {
							fill: $border-med-gray;
							transition-delay: .2s;
						}
					}
				}

				input {
					width: 100%;
					transition-delay: .2s;
				}

				.search-input-close-placeholder {
					display: block
				}
			}
		}
	}

}