@import "./src/scss/old-deps";
@import "./src/scss/deps";

.e-suggested-card {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	padding: 15px;
	background:
		linear-gradient(180deg, rgba($gray-1000, 0), rgba($gray-1000, 0.9)) no-repeat left bottom / 100% 50%,
		var(--image-url) 0 0 / cover;
	border-radius: 12px;
	aspect-ratio: 16 / 9;

	.e-suggested-card-time {
		height: 24px;
		padding: 0 8px;
		color: $white;
		background-color: rgba($off-black, 0.8);
		border-radius: 100px;
	}

	.e-suggested-card-title {
		margin-top: 15px;
		margin-bottom: 0;
		text-shadow: 0 1px 1px rgb(0 0 0 / 0.7);
	}

	.session-panel-dropdown {
		position: absolute;
		top: 5px;
		right: 5px;
		opacity: 0;
		transform: scale(0.9);
		transition:
			150ms opacity,
			150ms transform;

		& > button {
			width: 20px;
			height: 20px;
			padding: 0;
		}

		.navdrop-title {
			display: flex;
		}
	}

	&:hover {
		.session-panel-dropdown {
			opacity: 1;
			transform: scale(1);
		}
	}

	&.selected {
		box-shadow:
			0 0 0 1px $white,
			0 0 0 1px $white inset;
	}
}
