@mixin scrollbar($scrollbar-width) {
	&::-webkit-scrollbar {
		display: block;
		visibility: visible;
		width: $scrollbar-width;
		background-color: rgba(var(--bodyTextColor-rgb), 0.000);
		border-radius: $scrollbar-width/2;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(var(--bodyTextColor-rgb), 0.25);
		border-radius: $scrollbar-width/2;
		display: block;
		visibility: visible;

		&:hover {
			background-color: rgba(var(--bodyTextColor-rgb), 0.5);
		}
	}
}