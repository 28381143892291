@use "sass:meta";
@use "sass:map";
@use "sass:list";
@import "../live-event/base/mixins//breakpoints.scss";
@import "../live-event/base/mixins//theme-packs.scss";

// From _colors.scss
@mixin shadow-popover() {
	-moz-box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.1);
	box-shadow: 0 2px 3px 1px rgba(8, 8, 8, 0.1);
}

// From _layout.scss
@mixin radius-16 {
	border-radius: 16px;
}

@mixin radius-12 {
	border-radius: 12px;
}

@mixin radius-8 {
	border-radius: 8px;
}

@mixin radius-4 {
	border-radius: 4px;
}

@mixin radius-full {
	border-radius: 100px;
}

@mixin radius-32 {
	border-radius: 32px;
}

@mixin flex-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin absolute-fill {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

// From _mixins-responsive.scss

/************************************************************
Responsive mixins

Usage:
@include break-min($break-sm) {STYLES}
In the example above STYLES will be rendered when the viewport is 640px or greater

************************************************************/

// Width reference (variables defined in _vars-global.scss)
// $break-xs = 480px
// $break-sm = 640px
// $break-med = 768px
// $break-lrg = 1024px
// $break-xlrg = 1120px;

@mixin break-min($point) {
	@media screen and (min-width: $point) {
		@content;
	}
}

@mixin break-max($point) {
	@media screen and (max-width: $point) {
		@content;
	}
}

@mixin break-min-height($point) {
	@media screen and (min-height: $point) {
		@content;
	}
}

@mixin break-max-height($point) {
	@media screen and (max-height: $point) {
		@content;
	}
}

@mixin orientation($orientation) {
	@media only screen and (orientation: $orientation) {
		@content;
	}
}

@mixin break-between($min, $max) {
	@media only screen and (min-width: $min) and (max-width: $max) {
		@content;
	}
}

@mixin smallDesktop {
	@include break-max($break-lrg) {
		@content;
	}
}

@mixin tablet {
	@include break-max($break-med) {
		@content;
	}
}

@mixin mobile {
	@include break-max($break-sm) {
		@content;
	}
}

// min heights so footer remains at bottom if not enough content to fill page
@mixin mainContentMinHeight {
	min-height: 100vh;

	@include media-query($med-down) {
		min-height: calc(100vh - 80px); // subtract header height
	}

	@include media-query($sm-down) {
		min-height: calc(100vh - 60px); // subtract header height
	}
}

@mixin eventHeading1 {
	font-size: 50px;
	line-height: 60px;
	font-weight: 700;
}

@mixin eventHeading2 {
	font-size: 40px;
	line-height: 50px;
	font-weight: 700;
}

@mixin eventHeading3 {
	font-weight: 700;
	font-size: 35px;
	line-height: 40px;
}

@mixin eventHeading4 {
	font-weight: 700;
	font-size: 25px;
	line-height: 30px;
}

@mixin eventHeading5 {
	font-size: 20px;
	line-height: 25px;
	font-weight: 600;
}

@mixin eventCaption1 {
	line-height: 20px;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
}

@mixin eventCaption2 {
	line-height: 20px;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 13px;
}

@mixin eventCaption3 {
	font-size: 12px;
	line-height: 20px;
	font-weight: 600;
}

@mixin eventBodyText1 {
	font-size: 18px;
	line-height: 30px;
	font-weight: 400;
}

@mixin eventBodyText2 {
	line-height: 25px;
	font-weight: 400;
	font-size: 15px;
}

// New typography (needs implementing throughout the project and is used in Agenda and Session v2)
$new-events-typography: (
	h1: (font-size: 50px,
		line-height: 60px,
		font-weight: 700),
	h2: (font-size: 40px,
		line-height: 55px,
		font-weight: 700),
	h3: (font-size: 25px,
		line-height: 35px,
		font-weight: 700),
	h4: (font-size: 20px,
		line-height: 25px,
		font-weight: 700),
	h5: (font-size: 18px,
		line-height: 25px,
		font-weight: 700),
	h6: (font-size: 16px,
		line-height: 20px,
		font-weight: 700),
	h7: (font-size: 14px,
		line-height: 20px,
		font-weight: 700),
	subtitle-1: (font-size: 18px,
		line-height: 25px,
		font-weight: 600),
	subtitle-2: (font-size: 16px,
		line-height: 20px,
		font-weight: 600),
	subtitle-3: (font-size: 14px,
		line-height: 20px,
		font-weight: 600),
	subtitle-4: (font-size: 12px,
		line-height: 20px,
		font-weight: 600),
	subtitle-b1: (font-size: 18px,
		line-height: 30px,
		font-weight: 400),
	subtitle-b2: (font-size: 16px,
		line-height: 25px,
		font-weight: 400),
	subtitle-b3: (font-size: 14px,
		line-height: 20px,
		font-weight: 400),
	subtitle-b4: (font-size: 12px,
		line-height: 15px,
		font-weight: 400),
	caption-1: (font-size: 13px,
		line-height: 20px,
		font-weight: 600),
	caption-2: (font-size: 11px,
		line-height: 20px,
		font-weight: 600),
	caption-l1: (font-size: 13px,
		line-height: 25px,
		font-weight: 700),
	caption-l2: (font-size: 11px,
		line-height: 20px,
		font-weight: 700),
	caption-l3: (font-size: 10px,
		line-height: 15px,
		font-weight: 700)
);

$admin-typography: (
	h1: (font-size: 44px,
		line-height: 64px,
		font-weight: 700),
	h2: (font-size: 30px,
		line-height: 40px,
		font-weight: 700),
	h3: (font-size: 23px,
		line-height: 32px,
		font-weight: 700),
	h4: (font-size: 20px,
		line-height: 32px,
		font-weight: 600),
	h5: (font-size: 16px,
		line-height: 20px,
		font-weight: 600),
	h6: (font-size: 14px,
		line-height: 20px,
		font-weight: 600),
	subtitle-1: (font-size: 14px,
		line-height: 20px,
		font-weight: 600),
	subtitle-2: (font-size: 12px,
		line-height: 20px,
		font-weight: 600,
		letter-spacing: 0.24px),
	subtitle-3: (font-size: 12px,
		line-height: 20px,
		font-weight: 600,
		letter-spacing: 0.24px),
	paragraph-1: (font-size: 14px,
		line-height: 24px,
		font-weight: 500),
	paragraph-2: (font-size: 12px,
		line-height: 16px,
		font-weight: 500,
		letter-spacing: 0.24px),
	caption-1: (font-size: 11px,
		line-height: 16px,
		font-weight: 600,
		letter-spacing: 0.22px),
	caption-2: (font-size: 11px,
		line-height: 16px,
		font-weight: 500,
		letter-spacing: 0.22px),
	caption-3: (font-size: 10px,
		line-height: 14px,
		font-weight: 600,
		letter-spacing: -0.2px),
	label-1: (font-size: 13px,
		line-height: 16px,
		font-weight: 600),
	label-2: (font-size: 11px,
		line-height: 16px,
		font-weight: 600,
		letter-spacing: 0.22px),
	label-3: (font-size: 11px,
		line-height: 16px,
		font-weight: 600,
		letter-spacing: 1.3px,
		text-transform: uppercase)
);

@mixin generate-typography-classes($typography: $new-events-typography, $prefix: ".evt-") {
	@each $key, $config in $typography {
		#{$prefix}typography-#{$key} {
			@each $prop, $val in $config {
				#{$prop}: $val;
			}
		}
	}
}

@mixin get-typography($key, $exclude: ()) {
	$config: map.get($new-events-typography, $key);

	@if meta.type-of($config)==map {
		@each $prop, $val in $config {
			@if list.index($exclude, $prop)==null {
				#{$prop}: $val;
			}
		}
	}
}

@mixin get-admin-typography($key, $exclude: ()) {
	$config: map.get($admin-typography, $key);

	@if meta.type-of($config)==map {
		@each $prop, $val in $config {
			@if list.index($exclude, $prop)==null {
				#{$prop}: $val;
			}
		}
	}
}

@function get-typography-prop-value($key, $prop) {
	$config: map.get($new-events-typography, $key);

	@if meta.type-of($config)==map {
		$val: map.get($config, $prop);

		@if $val !=null {
			@return $val;
		}
	}
}

@function get-admin-typography-prop-value($key, $prop) {
	$config: map.get($admin-typography, $key);

	@if meta.type-of($config)==map {
		$val: map.get($config, $prop);

		@if $val !=null {
			@return $val;
		}
	}
}

@mixin new-navigation {
	&.new-navigation {
		// content-visibility: auto;
		position: sticky;
		left: -100px;
		flex-shrink: 0;
		margin-left: -95px;
		transition:
			transform 0.2s ease-in-out,
			visibility 0.2s ease-in-out;
		will-change: transform;

		&.navigation-toggle-active {

			&.show-navigation,
			&.navigation-fixed {
				visibility: visible;
				transform: translateX(100%);
				z-index: 1001;
			}
		}
	}

	&.new-navigation {
		.section-controls-wrapper {
			top: 0;
			right: -2px;
			z-index: 1;
			height: auto;

			.button-container {
				opacity: 0;
			}
		}

		.navbar-left {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
		}
	}

	&.new-top-navigation {
		.section-controls-wrapper {
			top: 2px;
			left: 12px;
			right: unset;
		}
	}

	&:hover {
		.section-controls-wrapper {
			.button-container {
				opacity: 1;
			}
		}
	}

	&.not-session-page {
		margin-left: 0;

		&:not(.new-top-navigation) {
			transform: none !important;
		}
	}

	.navigation-fixed-button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		margin-bottom: 8px;
		color: var(--bodyTextColor);
		transition: 150ms transform;

		path {
			fill: currentColor;
		}

		&:hover {
			cursor: pointer;
			color: var(--accentColor);
			transform: scale(1.1);
		}
	}

	&.new-top-navigation {
		@include new-top-navigation;

		.navbar-left {
			position: relative;
			left: 24px;
			width: 140px;
			flex-direction: row;
		}

		&.not-session-page {
			position: relative;
		}

		&.sticky-top-navigation {
			position: sticky;
			margin-top: -80px;

			&.not-session-page {
				top: 0;
				margin-top: 0;
				transform: none;
			}
		}
	}

	&.single-session-nav.new-navigation {
		@include new-top-navigation;

		position: sticky;
		margin-top: -80px;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		visibility: visible;
		opacity: 1;
	}
}

@mixin new-top-navigation {
	position: absolute;
	top: -80px;
	left: 0;
	right: 0;
	flex-direction: row;
	width: 100%;
	height: 80px;
	margin-left: 0;
	padding: 0;
	backdrop-filter: blur(calc(var(--blur-level, 0) * 1px));

	animation: 1s fadeIn;
	animation-fill-mode: forwards;
	visibility: hidden;

	.navigation-fixed-button {
		margin-bottom: 0;
		transition: 150ms transform;

		&:hover {
			transform: scale(1.1);
		}
	}

	.navigation {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding-top: 0;
		padding-right: 100px;

		.profile-details-actions {
			position: absolute;
			right: 0;

			.profile-details-dropdown-menu {
				top: 70px;
				bottom: auto;
				left: auto;
				right: 35px;
			}
		}
	}

	&.navigation-toggle-active {

		&.show-navigation,
		&.navigation-fixed {
			transform: translateY(100%);
		}
	}
}

@mixin primaryButton($darkMode) {
	@if $darkMode {
		color: var(--buttonTextColor, var(--headingTextColor));
		background-color: var(--accentColor);

		&:hover {
			background-color: rgba(var(--accentColor-rgb), 0.9);
		}

		&:active,
		&:focus {
			background-color: rgba(var(--accentColor-rgb), 0.9);
			border-color: var(--accentColor);
		}

		&.with-progress {
			background-color: rgba(var(--accentColor-rgb), 0.25);
			border-color: transparent;
			position: relative;
			overflow: hidden;
			border-width: 3px;
			padding: 11px 21px;

			&:hover {
				background-color: rgba(var(--accentColor-rgb), 0.4);
			}

			&:active,
			&:focus {
				background-color: rgba(var(--accentColor-rgb), 0.4);
				border-color: var(--accentColor);
			}

			&::before {
				content: " ";
				background-color: rgba(var(--accentColor-rgb), 0.5);
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: var(--progress);
			}

			span {
				display: block;
				position: relative;
			}
		}
	}

	@else {
		color: var(--buttonTextColor, var(--accentColor));
		background-color: var(--accentColor);

		&:active:not(.disabled),
		&:hover:not(.disabled) {
			background-color: rgba(var(--accentColor-rgb), 0.9);
		}

		&:focus:not(.disabled) {
			background-color: rgba(var(--accentColor-rgb), 0.9);
			border-color: var(--accentColor);
		}

		&.with-progress {
			background-color: rgba(var(--accentColor-rgb), 0.25);
			border-color: transparent;
			position: relative;
			overflow: hidden;
			border-width: 3px;
			padding: 11px 21px;

			&:hover {
				background-color: rgba(var(--accentColor-rgb), 0.4);
			}

			&:active,
			&:focus {
				background-color: rgba(var(--accentColor-rgb), 0.4);
				border-color: transparent;
			}

			&::before {
				content: " ";
				background-color: rgba(var(--accentColor-rgb), 0.5);
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: var(--progress);
			}

			span {
				display: block;
				position: relative;
			}
		}
	}
}