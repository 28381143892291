@import "./src/scss/old-deps";
@import "./src/scss/deps";

.tab-header-container {
	position: relative;
	height: 100%;

	--active-line-height: 2px;

	.tab-header-buttons {
		display: flex;
		align-items: center;
		height: 100%;

		.tab-header-button {
			height: 100%;
		}
	}

	button {
		height: 100%;
		position: relative;
		margin: 0;
		margin-right: 35px;
		padding: 0;
		font-size: 14px;
		font-weight: 500;
		color: $light-gray;
		background: none;
		transition: 150ms color;

		&:hover {
			color: $white;
		}

		&.active {
			color: $white;
		}

		&.active::after {
			content: "";
			position: absolute;
			bottom: 0px;
			left: 0;
			right: 0;
			height: var(--active-line-height);
			background: var(--accentColor);
			border-radius: 1.5px;
			animation: tabAnimate 150ms;
		}

		&.tabs-as-pills {
			border-radius: 100px;
			border: 2px solid $border-gray;
			font-size: 12px;
			line-height: 16px;
			font-weight: 500;
			padding: 8px 16px;
			margin-right: 8px;

			&.active {
				border: 2px solid $cyan;
			}

			&::after {
				content: unset;
			}
		}
	}

	.append-value {
		position: relative;
		left: -32px;
		width: 0;
		margin-right: 8px;
		font-size: 16px;
		font-weight: 500;
		text-transform: capitalize;
		color: $alt-gray;
		transition: 150ms color;
	}

	.tab-header-underline {
		position: absolute;
		height: 3px;
		margin-top: -1px;
		background-color: $cyan;
		border-radius: 1.5px;
		transition: 150ms transform, width 150ms;
		transition-timing-function: cubic-bezier(0, 0.51, 0.28, 1.02);
	}

	&.evt-tab-header {
		margin-bottom: 25px;
		overflow: auto;

		.tab-header-buttons {
			.tab-header-button {
				button {
					padding: 0px 0px 16px;
					color: var(--textColor);
					transition: 150ms color;
					text-transform: capitalize;

					&:hover {
						color: var(--accentColor);
					}
				}

				.active {
					color: var(--accentColor);

					&::after {
						background-color: var(--accentColor);
					}
				}
			}
		}

		.tab-header-underline {
			left: 0;
			bottom: 0;
			background-color: var(--accentColor);
			border-radius: 100px;
		}

		.tab-header-button {
			white-space: nowrap;

			&+.tab-header-button {
				margin-left: 32px;
			}

			button {
				margin: 0;
				font-size: 13px;
				line-height: 30px;
				font-weight: 600;
			}
		}
	}

	&.Limelight:not(.evt-tab-header) {
		button {
			&.active::after {
				background: linear-gradient(45deg, $magenta, $yellow);
			}
		}
	}

	&.with-divider:not(.evt-tab-header) {

		&::before {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 1px;
			background-color: rgba(var(--secondaryBackgroundColor-rgb), 0.1);
		}
	}
}

.panel-container-v2 {
	.tab-header-container {
		button {
			margin-right: 20px;
		}
	}
}

// The class "side-nav" is only related to the Admin Console and is always one child below the class "App" 
.side-nav {
	.tab-header-container:not(.evt-tab-header) {
		button {
			&.active::after {
				background: linear-gradient(45deg, $magenta, $yellow);
			}
		}
	}
}

.tab-header-container.bottom-border {
	border-bottom: 1px solid $border-gray;
}

.tab-header-container.force-underline {
	button {
		&.active::after {
			background: linear-gradient(45deg, $magenta, $yellow);
		}
	}
}

button.active.magenta-yellow-gradient-active-underline::after {
	background: linear-gradient(45deg, $magenta, $yellow);
	bottom: -4px;
}
