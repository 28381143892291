@import "../../../scss/deps";

$popover-width: 264px;
$row-padding: 12px 16px 12px 16px;
$font-size: 13px;
$size-transition:
	150ms opacity,
	150ms transform;
$slide-transition:
	300ms transform,
	150ms opacity;
$off-position: translateY(20px);
$visible-position: translateY(0);

@mixin flex-left {
	display: flex;
	align-items: center;
}

@mixin flex-right {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

@keyframes popover-with-nav-slide-in {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(0);
	}
}

.popover-with-nav-container {
	position: relative;
	bottom: 40px;
	right: 40px;
	opacity: 0;
	transform: $off-position;
	transition: $size-transition;
	z-index: 2;

	&.open {
		opacity: 1;
		transform: $visible-position;
	}

	.popover-with-nav {
		position: absolute;
		right: 0;
		bottom: 0;
		min-width: $popover-width;
		border-radius: 12px;
		box-shadow: 0px 13px 30px 0px rgba(0, 0, 0, 0.25);
		background-color: $gray-800;
		display: flex;
		flex-direction: column;
		color: white;
		cursor: pointer;
		transition: 300ms height;
		transition-timing-function: cubic-bezier(0.2, 0.43, 0.08, 0.99);

		.popover-with-nav-inner {
			border-radius: 12px;
			overflow: hidden;
			display: flex;
			max-width: $popover-width;
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			min-width: $popover-width;
			font-size: $font-size;
			font-weight: 500;
			white-space: nowrap;

			li {
				.subselected {
					font-weight: normal;
					opacity: 0.85;
				}
			}
		}

		.popover-with-nav-main {
			transform: translateX(0);
			opacity: 1;
			transition: $slide-transition;

			.popover-with-nav-link {
				height: 40px;
				display: flex;
				justify-content: space-between;

				&:hover {
					background-color: $gray-700;
				}

				div {
					padding: $row-padding;
					svg {
						margin-bottom: -1px;
					}
				}

				.left {
					@include flex-left;

					svg {
						margin-right: 8px;
					}
				}

				.right {
					@include flex-right;

					.popover-with-nav-link-selected {
						span:nth-child(1){
							font-weight: bold;
						}
						svg {
							margin-right: 4px;
						}
						svg#icon-definition-sd {
							path {
								fill: $gray-500;
							}
						}
					}

					svg {
						margin-left: 8px;
					}
				}
			}
		}

		&.page-selected {
			.popover-with-nav-main {
				transform: translateX(-100%);
				opacity: 0;

				.popover-with-nav-link {
					&:hover {
						background-color: $gray-800;
					}
				}
			}
		}
	}

	.popover-with-nav-sub {
		transform: translateX(0);
		transition: $slide-transition;
		opacity: 0;

		&.in {
			transform: translateX(-100%);
			opacity: 1;
		}

		.popover-with-nav-back {
			@include flex-left;
			height: 40px;
			padding: $row-padding;
			font-size: $font-size;
			border-bottom: 1px solid $gray-600;
			font-weight: 500;

			&:hover {
				background-color: $gray-700;
			}

			svg {
				margin-right: 8px;
			}
		}

		ul {
			.popover-with-nav-sub-link {
				height: 40px;
				display: flex;
				align-items: center;
				padding: $row-padding;
				padding-left: 40px;

				&:hover {
					background-color: $gray-700;
				}

				.selected {
					position: absolute;
					margin-left: -24px;
				}

				.label-icon {
					position: relative;
					top: 2px;
				}

				&.selected {
					font-weight: 600;
				}
			}
		}
	}
}

@include media-query($med-down) {
	.popover-with-nav-container:not(.fullscreen) {
		position: fixed;
		top: 0px;
		right: 0;
		left: 0;
		bottom: 0;
		padding: 16px 20px;
		min-height: 100vh; // header is visible at
		min-height: 100svh; // header is visible at
		max-height: 100vh; // header is visible at
		max-height: 100svh; // header is visible at
		background: rgba(0, 0, 0, 0.6);
		pointer-events: none;
		content-visibility: hidden;

		&.open {
			pointer-events: auto;
			content-visibility: visible;
		}

		.popover-with-nav {
			bottom: 16px;
			left: 20px;
			right: 20px;

			.popover-with-nav-inner {
				width: 100%;
				max-width: 100%;
				min-width: 100%;

				.popover-with-nav-main {
					width: 100%;
					max-width: 100%;
					min-width: 100%;

					.popover-with-nav-link {
						height: 56px;
					}
				}

				.popover-with-nav-sub {
					min-width: 100%;

					.popover-with-nav-back {
						height: 56px;
					}

					ul {
						width: 100%;
						max-width: 100%;
						min-width: 100%;

						li {
							height: 56px;
						}
					}
				}
			}
		}
	}
}

@include media-query($xs-down) {
	.popover-with-nav-container:not(.fullscreen) {
		top: 65px;
		min-height: calc(100vh - 65px); // header is visible at
		min-height: calc(100svh - 65px); // header is visible at
		max-height: calc(100vh - 65px); // header is visible at
		max-height: calc(100svh - 65px); // header is visible at
	}
}
