@import './src/scss/old-deps';
@import './src/scss/deps';

.empty-module {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	padding: 32px 16px;
	text-align: center;
	border: 1px dashed #bfcaff;
	border-radius: 8px;
	font-family: $font-family-poppins;


	.empty-module-icon {
		width: 65px;
		height: auto;
		margin-bottom: 6px;
	}

	.empty-module-message {
		margin-bottom: 0;
		color: $classic-gray-text;
	}

	.above-the-fold-layout & {
		position: fixed;
		top: var(--above-the-fold-tabs-height);
		right: 0;
		width: var(--above-the-fold-width);
		height: calc(100% - var(--above-the-fold-tabs-height));
		border: 0;
		pointer-events: none;

		&.clickable {
			pointer-events: auto;
			cursor: pointer;
		}

		.empty-module-message {
			padding-right: 20px;
			padding-left: 20px;
		}
	}
}