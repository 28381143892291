@import "../../../scss/deps";

.temp-card {
	position: relative;
	display: grid;
	grid-template-columns: 40px 1fr;
	gap: 12px;
	border-radius: 12px;
	background-color: $gray-600;
	padding: 16px;
	font-size: 14px;
	font-weight: 400;
	.key-gradient {
		width: 40px;
		height: 40px;
		border-radius: 20px;
		background: $gradient;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	p,
	label {
		width: 200px;
	}
	label {
		font-weight: 700;
	}
	button.primary {
		width: 100%;
	}
	.close-button {
		position: absolute;
		top: 10px;
		right: 10px;
		padding: 0;
	}
}
