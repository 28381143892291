.lightbox-no-scroll {
  overflow: hidden !important;
}

.lightbox-trigger-btn {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
}

.lightbox-default-img {
	cursor: pointer; // I tried "zoom-in" but it's too ugly
}

.lightbox {

	.lightbox-modal-container {
		display: flex;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: zoom-out;
		transition: opacity 200ms ease-in-out;
	
		.lightbox-overlay {
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			background-color: rgba(0, 0, 0, .8);
			transition: opacity 200ms ease-in-out;
		}
	
		.lightbox-loading-indicator {
			position: fixed;
			margin: auto;
			font-size: 20px;
			color: #fff;
			transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
		}
	
		.lightbox-image {
			position: fixed;
			width: 90vw;
			height: auto;
			max-height: 80vh;
			object-fit: contain;
			margin: auto;
			transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
		}
	}
}
