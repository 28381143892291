@import './src/scss/old-deps';
@import './src/scss/deps';

.section-controls-wrapper {
	height: 80px;
	width: 421px;
	position: absolute;
	top: -10px;
	right: 0;

	.button-container {
		opacity: 0;
		transition: 150ms opacity;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-right: 8px;

		button {
			height: 32px;
			pointer-events: none;
			transition: 150ms opacity;
			margin-right: 8px;
			padding: 0;
			border-radius: 100px;
		}

		.add-content {
			height: 32px;
			font-weight: 500;
			size: 14px;
			padding: 8px 16px;
		}

		.arrow-up {
			margin-left: 8px;
		}

		.arrow-down {
			margin-right: 16px;
			transform: rotate(180deg);
		}
	}

	&:hover {
		.button-container {
			opacity: 1;
			button {
				pointer-events: auto;
			}
		}
	}
}

.edit-layout-modal {
	.modal.large {
		max-height: 99vh;
	}
}

.edit-layout-modal-container {

	.modal {

		// width: 960px;
		// min-height: 500px;

		.modal-body {
			.tab-header-container {
				height: 40px;
				line-height: 22px;
				// margin-bottom: 30px;
				background-color: pink;
			}
		}

		.edit-layout-modal-content {
			display: flex;
			align-items: flex-start;
			overflow-x: hidden;

			@include media-query($med-down) {
				flex-direction: column;
			}

			.module-list {
				max-height: calc(100vh - 280px);
				margin: 0;
				padding: 2px;
				list-style-type: none;
				overflow: scroll;

				@include media-query($med-down) {
					display: flex;
					max-width: 100%;
					overflow: scroll;

					.module-list-button {
						white-space: nowrap;
					}
				}

				.module-list-button {
					width: 100%;
					margin-bottom: 1px;
					text-align: left;
				}
			}

			.module-layout-display-items {
				flex: 1;


				.module-layout-items {
					display: flex;
					flex-wrap: wrap;
					width: 100%;

					.module-layout-item {
						width: calc(50% - 10px);
						margin: 5px;
						background-color: $dark-gray;
						border: 2px solid transparent;
						border-radius: 12px;

						&.saving-module-layout {
							background-color: rgba($dark-gray, 0.05);
						}

						button {
							width: 100%;
							height: 100%;
							border-radius: 12px;

							.module-layout-selector-content {
								position: relative;
								color: $white;

								.layout-thumbnail {
									width: 40px;
								}

								.module-layout-selector-content-waiting-indicator {
									position: absolute;
									top: 0;
									left: 0;
									right: 0;
									bottom: 0;
									display: flex;
									justify-content: center;
									align-items: center;
									width: 100%;
									height: 100%;
								}
							}

							&.selected-module-layout-button {
								cursor: default;
							}
						}

						@include media-query($xs-down) {
							width: 100%;
						}
					}

					.selected-module-layout {
						border: 2px solid $cyan;
					}
				}
			}
		}
	}
}

.modal {
	width: 600px;
	.style-modal-content {
		display: grid;

		.style-options {
			z-index: 3;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			max-width: 100%;
			overflow: scroll;

			&.show-overflow {
				overflow: visible;
			}

			.upload-background-image {
				width: 100%;
				label {
					color: $cyan;
					svg path {
						fill: $cyan;
					}
				}
			}

			p {
				color: $alt-gray;
			}

			.options {
				display: flex;
				justify-content: center;
				align-content: center;
				align-self: flex-start;
				color: white;

				p {
					margin: 3px;

					align-self: center;
					color: $alt-gray;
					padding: 2px;
				}

				button {
					height: 40px;
					width: 40px;
					margin: 3px;
				}

				.selected-theme {
					border: 1px solid $cyan;
				}

				.color-option-container {
					position: relative;
					display: inline-flex;

					.color-option {
						border: 1px solid $border-med-gray;
						margin: 3px;
						background-size: cover;
						background-repeat: no-repeat;
						background-position: center center;
					}

					.custom-color-edit-btn {
						display: none;
						position: absolute;
						top: -20px;
						right: -20px;
						background-color: transparent;
					}

					&.editable {
						.custom-color-edit-btn {
							svg {
								transition: transform 0.3s;
							}
						}
					}

					&.editable:hover {
						.custom-color-edit-btn {
							display: block;
							z-index: 1;

							&:hover {
								svg {
									transform: scale(1.2);
								}
							}
						}
					}
				}

				.selected {
					border: transparent;
					&:after {
						width: 38px;
						height: 34px;
						content: "";
						border-radius: 100px;
						border: 3px solid $cyan;
					}
				}
			}


			.color-icon-divider {
				position: relative;
				top: -2px;
				border-right: 1px solid var(--true-white);
				display: inline-flex;
				height: 34px;
				margin: 0 8px
			}
		}
		.custom-color-editor {
			display: flex;
			justify-content: flex-start;
			align-items: flex-end;

			button {
				height: 40px;
				width: 40px;
				margin: 3px 6px;

			}

			.color-editor {
				label {
					margin-left: 4px;
				}
			}

			.color-picker-wrapper {
				z-index: 1;
			}
		}
	}
	.modal-footer {
		.no-style.restore-default {
			align-self: baseline;
			position: absolute;
			left: 0;
			padding-left: 0;
		}
	}
}

.edit-footer-modal-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.container-title {
		width: 90%;
		padding: 0 8px 8px 8px;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		position: relative;

		.site-menu {
			color: $med-light-gray;
			margin: 0;
		}

		button {
			color: $cyan;
			padding: 0;
		}
	}

	.nav-container {
		width: 90%;
		display: flex;
		justify-content: center;

		&:hover {
			.nav-item {
				.drag {
					display: block;
				}
				.remove {
					display: block;
				}
			}
		}

		&.dragging {
			.nav-item {
				.drag {
					display: block;
				}
			}
			// turn off hover for siblings below this element
			&~.nav-container {
				.nav-item {
					.drag {
						display: none;
					}
				}
			}
		}

		.nav-item {
			margin: 4px;
			height: 100px;
			width: 100%;
			background-color: $image-gray;
			color: $white;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-radius: 12px;
			position: relative;
			padding: 16px;

			.call-to-action-settings {
				display: grid;
				align-items: center;
				grid-template-columns: 58% 1fr;
				grid-template-rows: 100px;
				width: 100%;

				.call-to-action-lower {
					margin-left: 8px;
				}

				.field-group.select {
					margin: 0;

					.select-dropdown {
						position: absolute;
						top: initial !important;
						left: initial !important;
						margin-right: 0;
					}
				}
			}

			.drag {
				position: absolute;
				display: none;
				left: -20px;
				cursor: grab;
			}

			.remove {
				position: absolute;
				display: none;
				right: -20px;
				cursor: pointer;
			}
		}
	}

}
.edit-header-modal-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.container-title {
		width: 90%;
		padding: 0 8px 8px 8px;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		position: relative;

		.site-menu {
			color: $med-light-gray;
			margin-bottom: 0;
		}

		button {
			color: $cyan;
			padding: 0;
		}
	}

	.add-list {
		display: flex;
		flex-direction: column;
		flex: wrap;

		.item-to-add {
			display: flex;
			justify-content: space-between;
			align-items: center;

			h5 {
				margin-right: 40px;
			}
		}
	}

	.nav-container {
		width: 90%;
		display: flex;
		justify-content: center;

		&:hover {
			.nav-item {
				.drag {
					display: block;
				}
				.remove {
					display: block;
				}

			}
		}

		&.dragging {
			.nav-item {
				.drag {
					display: block;
				}
			}
			// turn off hover for siblings below this element
			&~.nav-container {
				.nav-item {
					.drag {
						display: none;
					}
				}
			}
		}

		.nav-item {
			position: relative;
			margin: 4px;
			height: 100px;
			width: 100%;
			background-color: $image-gray;
			color: $white;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			border-radius: 12px;
			padding: 16px;

			.call-to-action-settings {
				display: grid;
				align-items: center;
				grid-template-columns: 58% 1fr;
				grid-template-rows: 100px;
				width: 100%;

				.call-to-action-lower {
					margin-left: 8px;
				}

				.field-group.select {
					margin: 0;

					.select-dropdown {
						position: absolute;
						top: initial !important;
						left: initial !important;
						margin-right: 0;
					}
				}
			}

			.drag {
				position: absolute;
				display: none;
				left: -20px;
				cursor: grab;
			}

			.remove {
				position: absolute;
				display: none;
				right: -20px;
				cursor: pointer;

				.trash {
					padding: 0;
				}
			}
		}
	}

	.module-layout-display-items {

		.layout-items {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.isSelected {
				border: 1px solid $cyan;
				border-radius: 12px;
			}

			button {
				padding: 4px;
			}

			button:active {
				border-radius: 12px;
			}
		}
	}
}
