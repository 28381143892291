@import "./src/scss/deps";

.color-editor {

	label {
		display: inline-block;
		margin-bottom: 5px;
		margin-left: 16px;
		font-size: 12px;
		line-height: 16px;
		font-weight: 700;
		color: $border-light-gray;
	}

	.color-input {
		@include radius-full;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 48px;
		font-size: 14px;
		border: 1px solid $border-med-gray;

		.color-input-left {
			display: flex;
			align-items: center;

			.color-bubble {
				min-width: 40px;
				width: 40px;
				height: 40px;
				margin-left: 2px;
				border: 1px solid $border-gray;
				@include radius-full;
			}

			.hex-readout {
				display: flex;
				align-items: center;
				margin-left: 8px;
				color: $border-light-gray;

				input {
					width: fit-content;
					font-size: 14px;
					color: $white;
					background-color: transparent;
					border: none;
					outline: none;
				}
			}
		}

		.color-input-right {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 80px;
			height: 48px;
			font-size: 14px;
			border-left: 1px solid $border-med-gray;

			input {
				width: 24px;
				padding: 0;
				font-family: 'Roboto', sans-serif;
				font-size: 14px;
				text-align: right;
				color: $off-white;
				background-color: transparent;
				border: none;
				outline: none;

				&::-webkit-inner-spin-button {
					display: none;
				}
			}
		}
	}
}
