
@import './src/scss/old-deps';

.search-input {
	display: flex;
	align-items: center;
	width: 176px;
	margin-right: 8px;
	padding: 0;
	border: 1px solid $border-med-gray;
	border-radius: 16px;

	.search-input-search-btn {
		width: 30px;
		height: calc(100% - 2px);
		margin-left: 8px;
	}

	.search-input-close-placeholder,
	.search-input-close-btn {
		width: 30px;
		height: calc(100% - 2px);
		margin-right: 8px;
	}

	svg {
		cursor: pointer;
		margin-right: 6px;
	}

	input {
		width: 100%;
		padding: 0;
		font-family: inherit;
		font-size: 14px;
		color: $white;
		background-color: inherit;
		border: 0;
		outline: none;
	}

	.hide {
		display: none;
	}
}
