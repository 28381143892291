// TODO: created a new component to not interfere with the tooltip bound to forms
@import "../../../scss/deps";

.smart-tooltip {
	position: relative;

	&.top-center {
		.smart-tooltip-content {
			left: 50%;
			bottom: 100%;
			padding-bottom: 10px;
			transform: translate(-50%);

			.smart-tooltip-text {
				&::before {
					left: 50%;
					bottom: -5px;
					border-top: 5px solid $gray-600;
					border-right: 5px solid transparent;
					border-left: 5px solid transparent;
					transform: translate(-50%);
				}
			}
		}
	}

	&.center {
		.smart-tooltip-content {
			left: 50%;
			bottom: 50%;
			padding-bottom: 10px;
			transform: translate(-50%);

			.smart-tooltip-text {
				&::before {
					left: 50%;
					bottom: -5px;
					border-top: 5px solid $gray-600;
					border-right: 5px solid transparent;
					border-left: 5px solid transparent;
					transform: translate(-50%);
				}
			}
		}
	}

	&.top-right {
		.smart-tooltip-content {
			left: 50%;
			bottom: 100%;
			padding-bottom: 10px;
			transform: translate(-24px);

			.smart-tooltip-text {
				&::before {
					left: 24px;
					bottom: -5px;
					border-top: 5px solid $gray-600;
					border-right: 5px solid transparent;
					border-left: 5px solid transparent;
					transform: translate(-50%);
				}
			}
		}
	}

	&.top-left {
		.smart-tooltip-content {
			right: 50%;
			bottom: 100%;
			padding-bottom: 10px;
			transform: translate(34px);

			.smart-tooltip-text {
				&::before {
					right: 24px;
					bottom: -5px;
					border-top: 5px solid $gray-600;
					border-right: 5px solid transparent;
					border-left: 5px solid transparent;
					transform: translate(-50%);
				}
			}
		}
	}

	&.bottom-center {
		.smart-tooltip-content {
			left: 50%;
			top: 100%;
			padding-top: 10px;
			transform: translate(-50%);
			z-index: 1;

			.smart-tooltip-text {
				&::before {
					left: 50%;
					top: -5px;
					border-bottom: 5px solid $gray-600;
					border-right: 5px solid transparent;
					border-left: 5px solid transparent;
					transform: translate(-50%);
				}
			}
		}
	}

	&.bottom-left {
		.smart-tooltip-content {
			right: 50%;
			top: 100%;
			padding-top: 10px;
			transform: translate(15px);

			.smart-tooltip-text {
				&::before {
					right: 15px;
					top: -5px;
					border-bottom: 5px solid $gray-600;
					border-right: 5px solid transparent;
					border-left: 5px solid transparent;
					transform: translate(50%);
				}
			}
		}
	}

	&.left {
		.smart-tooltip-content {
			right: 100%;
			top: 50%;
			padding-right: 10px;
			transform: translate(0, -50%);

			.smart-tooltip-text {
				&::before {
					right: -5px;
					top: 50%;
					border-left: 5px solid $gray-600;
					border-top: 5px solid transparent;
					border-bottom: 5px solid transparent;
					transform: translate(0, -50%);
				}
			}
		}
	}

	&.left-no-style {
		width: inherit;
		margin: 0;

		.smart-tooltip-content {
			right: 100%;
			top: 50%;
			padding-right: 10px;
			transform: translate(0, -50%);

			.smart-tooltip-text {
				&::before {
					right: -5px;
					top: 50%;
					border-left: 5px solid $gray-600;
					border-top: 5px solid transparent;
					border-bottom: 5px solid transparent;
					transform: translate(0, -50%);
				}
			}
		}
	}

	&.right {
		.smart-tooltip-content {
			font-size: 12px;
			left: 100%;
			top: 50%;
			padding-left: 10px;
			transform: translate(0, -50%);

			.smart-tooltip-text {
				&::before {
					left: -5px;
					top: 50%;
					border-right: 5px solid $gray-600;
					border-top: 5px solid transparent;
					border-bottom: 5px solid transparent;
					transform: translate(0, -50%);
				}
			}
		}
	}

	&.bottom-right {
		.smart-tooltip-content {
			left: 100%;
			top: 100%;
			padding-top: 10px;
			transform: translate(0, -50%);

			.smart-tooltip-text {
				&::before {
					left: -5px;
					top: 50%;
					border-right: 5px solid $gray-600;
					border-top: 5px solid transparent;
					border-bottom: 5px solid transparent;
					transform: translate(0, -50%);
				}
			}
		}
	}

	&.non-absolute {
		.smart-tooltip-content {
			left: 50%;
			bottom: 100%;
			padding-bottom: 10px;
			transform: translate(-24px);

			.smart-tooltip-text {
				&::before {
					left: 24px;
					bottom: -5px;
					border-top: 5px solid $gray-600;
					border-right: 5px solid transparent;
					border-left: 5px solid transparent;
					transform: translate(-50%);
				}
			}
		}
	}

	&:hover {
		.smart-tooltip-content {
			opacity: 1;
			visibility: visible;
		}
	}

	.smart-tooltip-content {
		position: absolute;
		font-size: 14px;
		line-height: 20px;
		font-weight: 400;
		white-space: nowrap;
		opacity: 0;
		visibility: hidden;
		transition:
			visibility 0.3s $easing,
			opacity 0.3s $easing;
	}

	.smart-tooltip-text {
		position: relative;
		display: block;
		padding: $ev-gap-x2;
		background-color: $gray-600;
		border-radius: 4px;

		a {
			color: $cyan;
		}

		&::before {
			content: "";
			position: absolute;
		}
	}
}
