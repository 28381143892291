@import "./src/scss/deps";
@import "./src/scss/base/mixins";

.navigation-toggle {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 20px;
	left: 28px;
	z-index: 102;
	width: 40px;
	height: 40px;
	color: #fff;
	transition: 150ms transform;

	&.dark-icon {
		color: var(--gray-1000);
	}

	&.has-banner-announcement {
		@media screen and (min-width: 1024px) and (max-width: 1279px) {
			top: 79px;
		}

		@media screen and (min-width: 1280px) {
			top: 68px;
		}
	}

	&.navigation-toggle-hide {
		display: none;
	}

	span::before {
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.7);
	}

	&:hover {
		transform: scale(1.1);
	}
}
