@import './src/scss/old-deps';

.collapsing-list-container {
	margin-bottom: 8px;
	--list-container-scrollbar-width: 10px;

	.collapsing-list-header {
		display: flex;
		justify-content: space-between;
		align-items: center;


		.collapsing-list-header-button {
			width: 100%;
			text-align: left;
		}

		&.auto-width-trigger {

			.collapsing-list-header-button {
				width: auto;
			}
		}

		span {
			position: relative;
			width: 100%;
			white-space: nowrap;
			cursor: pointer;

			svg {
				position: relative;
				top: 3px;
				width: 16px;
				height: 16px;
				margin-right: 8px;
				transform: rotate(0deg);
				transition: 150ms transform;

				path {
					fill: $white;
				}

				&.small-arrow {
					position: absolute;
					top: 7px;
					right: $_8px;
					width: 10px;
					height: 10px;
					transition: 0.4s;
				}
			}

			label {
				line-height: 18px;
				font-weight: 700;
				color: $white;
				cursor: pointer;

				&.normal {
					font-size: 14px;
				}

				&.medium {
					font-size: 16px;
				}

				&.large {
					font-size: 18px;
				}
			}
		}
	}

	.collapsing-list-contents {
		max-height: 0;
		opacity: 0;
		overflow: hidden;
		transition: max-height 200ms, opacity 150ms;

		.colors-container {
			.color-container {
				.color {
					.heading-row {
						h6 {
							max-width: 220px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
			}
		}

		&.show-transition-scroll-margin {
			margin-right: var(--list-container-scrollbar-width); // this is to prevent scrollbar jank when collapsing the list
		}

		&.show-scrollbar {
			>div {
				margin-right: var(--list-container-scrollbar-width);
			}

			&::-webkit-scrollbar-thumb {
				border-radius: var(--card-radius);
				background: var(--gray-700);
				visibility: visible;

				&:hover {
					background: var(--gray-600);
				}
			}

			&::-webkit-scrollbar {
				width: var(--list-container-scrollbar-width);
				margin-left: calc(var(--list-container-scrollbar-width) * -1);
				visibility: visible;
			}
		}

		ul {
			margin: 0;
			padding: 10px 0;

			li {
				@include radius-full;
				padding: 11px 24px;
				font-size: 14px;
				line-height: 20px;
				list-style-type: none;
				background-color: transparent;
				transition: 150ms background-color;
				cursor: pointer;

				&:hover {
					background-color: rgba($med-gray, 0.5);
				}

				&.selected {
					background-color: $med-gray;
				}
			}
		}
	}

	&.open {

		.collapsing-list-contents {
			max-height: 300px;
			opacity: 1;
		}

		.collapsing-list-header {

			.default-arrow.open {
				transform: rotate(90deg);
			}

			.small-arrow {
				transform: rotateX(180deg);
			}
		}
	}
}
