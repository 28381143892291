@import "./src/scss/old-deps";
@import "./src/scss/base/colors";
@import "./src/scss/abstracts/colors";

.add-tab-container {

	.add-tab-button {
		width: 100%;
		border-radius: 12px;
		color: $cyan;
		height: 69px;
		background-color: rgba(49, 47, 56, 0.5);
		transition: 150ms background-color;

		span {
			background: var(--Gradient-Sunset, linear-gradient(90deg, #FFFD62 0%, #FF6AE6 100%));
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		&:hover {
			background-color: $gray-800;
		}
	}
}