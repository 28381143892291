@import "./src/scss/base/mixins";
@import "./src/scss/deps";

@keyframes slideDown {
	from {
		transform: translateY(90px * -3) scale(1.1);
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
		opacity: 0;
	}

	to {
		transform: translateY(0) scale(1);
		box-shadow: 0 8px 24px rgba(0, 0, 0, 0.25);
		opacity: 1;
	}
}

@mixin video-titles {
	gap: 20px;

	.video-titles-container {
		display: flex;
		flex-direction: column;
		gap: 4px;
		width: 100%;
	}

	.session-live-card-metadata {
		font-family: var(--bodyFont);
		color: var(--dark-mode-color); // video player is always dark mode
		font-size: 13px;
		font-style: normal;
		font-weight: 600;
		line-height: 16px;
		display: flex;
		gap: 5px;
	}

	.session-live-card-title {
		font-family: var(--headingFont);
		text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.7);
		font-size: 50px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		margin-bottom: 0;
		line-height: 60px;
		color: white;
		@include line-clamp(4);
	}

	.session-live-card-description {
		display: block;
		max-width: 640px;
		font-family: var(--bodyFont);
		color: white;
		/* Text Shadow */
		text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.7);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px;
		/* 187.5% */
		margin-bottom: 0;
	}

	.live-in-card-text {
		display: flex;
		gap: 5px;

		p {
			font-size: 13px;
			font-weight: 600;
			line-height: 18px;
			margin: 0;
			color: white;
		}

		.tracks,
		.track {
			display: flex;
			gap: 5px;
		}

		.space-dot {
			width: 8px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.live-in-card-v2 {
		padding: 2px 10px;
		background-color: rgba(255, 255, 255, 0.3);
		justify-content: space-between;
		max-width: 100%;
		display: inherit;
		border-radius: var(--customContainerBorderRadius, 12px);

		h3 {
			color: var(--dark-mode-color);
			font-size: 11px;
			font-style: normal;
			font-weight: 700;
			line-height: 20px;
			margin: 0;
		}
	}

	.session-live-card-actions,
	.paused-button-cover-actions {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 10px;
	}
}

.session-stream-wrapper {
	--user-mode-background: #ffffff;
	background-color: #ffffff;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	position: relative;
	display: flex;
	justify-content: center;
	z-index: 5;
	height: calc(100svh - var(--banner-height, 0px));
	overflow-x: hidden;

	&.new-navigation {
		width: 100%;
	}

	&.moderator-view {
		height: 100%;
		background-color: $gray-900;
		border-radius: 12px;
		overflow: hidden;

		.session-stream {
			max-height: 100%;
			min-height: 100%;

			// moderator view is not 16/9
			aspect-ratio: unset;
		}

		// overriding styles that are used to make the player viewable on iPad, but blow out the video in moderator.
		.embed-wrapper {
			padding-bottom: unset;
			height: 100%;
		}

		.session-stream {
			margin: unset;

			.session-video-player-container {
				.ivs-player-container {
					padding-bottom: unset;
					height: 100%;

					video.ivs-player-video {
						object-fit: contain;
					}
				}
			}
		}
	}

	.session-stream-container {
		display: flex;
		width: 100%;
		overflow: hidden;

		&:not(.version-2) {
			border-bottom: 1px solid $border-gray;
		}

		&.version-2 {
			background: $dark-gray;
		}
	}

	&.dark-mode {
		--user-mode-background: #1a1a1f;

		.session-stream-container {
			background-color: $dark-gray;
		}
	}

	&.fireside-session {
		&.fireside-full-screen {
			position: absolute;
			top: 0;
			left: 0;
			width: 100vw;
			z-index: 500;
			padding: 0;
			height: 100%;

			.session-stream-container {
				width: 100%;

				// adjust fireside chat widths for full screen
				.live-chat-wrapper {
					&.active {
						width: 350px;
					}

					.live-chat-container {
						&.is-open {
							width: 340px;
						}
					}
				}
			}

			.session-stream {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}

		&.pre-live {
			padding: 0;

			.session-stream-container {
				width: 100%;
			}
		}

		.fireside-content {
			display: flex;
			align-items: center;
			width: 100%;
			height: 100%;
			padding: 1%;
		}
	}

	.session-stream-container-inner {
		display: flex;
		position: relative;
		flex-direction: row;
		flex: 1;
	}

	.session-stream {
		width: 100%;
		position: relative;
		transform: translateX(0);
		transition: transform 150ms;
		margin: auto;
		min-height: 100vh;

		.session-video-placeholder {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-position: center center;
			background-size: cover;

			&.is-on-demand {
				display: flex;
				flex-direction: column;
			}

			&.no-image {
				background-color: var(--secondaryBackgroundColor);
			}

			&.fireside-session {
				&.pre-live {
					display: flex;
				}

				.fireside-attendee-view {
					width: 100%;
					height: 100%;
					border: none;
				}

				.player-button {
					margin: 0 5px;
				}

				.chat-close.waiting {
					display: inline-flex;
					position: relative;
					right: auto;
					bottom: auto;
					@include radius-full;
					@include size(50px);
					padding: 0;

					svg {
						margin: 0;
					}
				}
			}
		}

		.session-video-player-container {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			max-height: calc(100vh);

			.ivs-player-container {
				max-width: 100%;
				min-width: 100%;
				height: 0;
				position: relative;
				padding-bottom: 56.25%;

				video.ivs-player-video {
					max-width: 100%;
					min-width: 100%;
					min-height: 100%;
					height: 100%;
					@include absolute-fill;
					object-fit: cover;
				}

				.cc-container {
					position: absolute;
					bottom: 55px;
					left: 40px;
					right: 40px;
					color: white;
					@include flex-center;

					p {
						font-size: 24px;
						background-color: rgba(0, 0, 0, 0.7);
						text-align: center;
						padding: 6px 18px;
						line-height: 30px;
						border-radius: 12px;
						white-space: pre-line;
					}

					&.chat-overlay {
						right: 286px;
					}
				}
			}

			&.is-fullscreen {
				background-color: black;
			}
		}

		&.scrolled-past {
			transform: unset;

			.session-video-player-container {
				position: fixed;
				top: 24px;
				right: 24px;
				height: 90px * 3;
				width: 160px * 3;
				left: initial;
				animation: 300ms slideDown;
				z-index: 100;
				box-shadow: 0 8px 24px rgba(0, 0, 0, 0.25);
			}

			// Should only display play/pause, mute & full screen when the player is scrolled below the fold
			.player-controls {

				.progress-wrapper,
				button.player-button.chat-close,
				button.player-button.settings,
				button.player-button.raise-hand {
					display: none;
				}
			}

			// scroll for more NOT inside player controls
			.scroll-for-more {
				display: none;
			}
		}

		.session-live-card-container {
			position: absolute;
			left: 10%;
			right: 10%;
			display: flex;
			flex-direction: column;
			align-content: center;
			justify-content: center;
			align-items: flex-start;
			height: 100%;

			.session-live-card-title {
				word-break: break-word;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				max-width: 100%;
				overflow: hidden;
			}

			// this view does not exist in V1, if things get in disagreement, remove this
			.session-live-card-description {
				display: none;
			}

			&.past-session {
				min-width: initial;
			}

			.live-in-card {
				background-color: var(--backgroundColor);
				padding: 15px 20px;
				justify-content: space-between;
				max-width: 100%;
				display: inherit;

				.live-in-card-fireside-content {
					position: absolute;
					margin-top: -130px;
					margin-left: -17px;

					.tag-container-type {
						margin-bottom: 10px;
					}

					.live-in-card-session-title {
						font-size: 40px;
						margin: 0;
						font-family: var(--headingFont);
						color: var(--headingTextColor);
					}
				}

				.live-in-card-text {
					h3 {
						font-size: 20px;
						margin-bottom: 0;
						font-family: var(--headingFont);
					}

					p {
						font-size: 15px;
						font-weight: 300;
					}
				}

				.add-remove-my-list-btn {
					.evt-button {
						height: 64px;
						margin-left: 10px !important;
					}
				}
			}
		}

		.session-video-placeholder.version-2 {
			.session-live-card-container {
				@include video-titles;
			}
		}

		.paused-cover-container {
			@include video-titles;
		}

		.chat-close.waiting {
			position: absolute;
			right: 24px;
			bottom: 24px;
			@include radius-full;
			@include size(50px);
			@include flex-center;
			padding: 0;
			border-radius: var(--customContainerBorderRadius, 0);

			svg {
				margin: 0;
			}
		}
	}

	&.chat-closed {
		padding-right: 0;
	}

	&.overlay-chat {
		padding-right: 0;
	}

	&.ended {
		padding-right: 0;
	}
}


.session-stream-wrapper:not(.chat-closed) {
	&.overlay-chat {
		.session-live-card-container {
			width: calc(80% - 350px);
		}
	}
}

@mixin sessionVideoMobile($size) {
	@if $size =="desktop" {
		.session-stream-container {
			.session-video-player-container {
				.ivs-player-container {
					.cc-container {
						p {
							font-size: 18px;
							padding: 6px 12px;
							line-height: 24px;
						}
					}
				}
			}
		}
	}

	@if $size =="small-desktop" {

		// remove chat bubble because chat becomes mobile view chat bar
		.session-stream-wrapper {
			height: unset;

			&.fireside-session {
				.session-stream-container {
					.session-stream-container-inner {
						.session-stream {
							.session-video-placeholder {
								.chat-close {
									display: none;
								}
							}
						}
					}
				}
			}
		}

		.session-stream-container {
			padding: 0;

			.session-stream {
				.session-video-placeholder.version-2 {
					.session-live-card-container {
						.action-bar-container {
							&.in-person-attendee-mode {
								display: none;
							}
						}

						.video-titles-container {
							&.in-person-attendee-mode {
								display: none;
							}

							gap: 4px;

							.session-live-card-title {
								font-size: 32px;
								line-height: 48px;

								@include line-clamp(2);
							}

							.session-live-card-description {
								@include line-clamp(3);
							}
						}
					}
				}
			}

			.session-video-player-container {
				.ivs-player-container {
					border-radius: 0;

					video.ivs-player-video {
						border-radius: 0;
					}

					.cc-container {
						right: 286px;

						p {
							font-size: 18px;
							padding: 6px 12px;
							line-height: 24px;
						}

						&.chat-overlay {
							right: 286px;
						}
					}
				}
			}

			.session-stream {
				.live-in-card {
					&.fireside-live-in-card {
						bottom: 95px;
					}
				}
			}
		}
	}

	@if $size =="tablet" {
		.editor-wrapper.broadcast {
			padding: 0;
		}

		.session-stream-container {
			padding: 0;

			.session-stream {
				.session-video-placeholder {
					border-radius: 0;
				}

				.session-video-player-container {
					.ivs-player-container {
						border-radius: 0;

						video.ivs-player-video {
							border-radius: 0;
						}
					}
				}

				.live-in-card {
					left: 40px;
					bottom: 20px;
					right: initial;
					min-width: 420px;

					&.fireside-live-in-card {
						bottom: 95px;
					}

					.live-in-card-text {
						h3 {
							font-size: 16px;
							margin-bottom: 0;
						}

						p {
							font-size: 12px;
							font-weight: 300;
						}
					}
				}

				.session-video-placeholder.version-2.pre-live {
					height: 360px;

					.session-live-card-container {
						gap: 20px;
					}

					.video-titles-container {
						gap: 4px;

						.session-live-card-title {
							font-size: 32px;
							line-height: 48px;

							@include line-clamp(2);
						}

						.session-live-card-description {
							display: none;
						}
					}

					.live-in-card-v2 {
						.live-in-card-text {
							h3 {
								font-size: 18px;
							}
						}
					}
				}
			}
		}
	}

	@if $size =="mobile" {
		.editor-wrapper.broadcast {
			padding: 0;
		}

		.session-stream-wrapper {
			padding: 0;

			&.fireside-session {
				.session-stream {
					padding-bottom: 56.25%;
				}

				&.fireside-full-screen {
					.session-stream-container {
						height: 100%;
						top: 0;

						.session-stream {
							padding-bottom: 0;
						}
					}
				}

				.player-button.chat-close,
				.player-button.chat-close.waiting {
					display: none;
					opacity: 0;
				}
			}
		}

		.session-stream-wrapper .session-stream-container,
		.session-stream-container {
			padding: 0;
			position: fixed;
			top: calc(65px + var(--banner-height, 0px));
			left: 0;
			right: 0;
			z-index: 4;
			width: 100%;

			.session-stream {
				&.scrolled-past {
					.session-video-player-container {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						height: 100%;
						width: 100%;
					}
				}

				.session-video-placeholder {
					display: flex;
					align-items: flex-end;
					padding: 25px;
					border-radius: 0;

					&.fireside-session.live {
						padding: 0;
					}
				}

				.session-video-placeholder .live-in-card,
				.live-in-card {
					align-items: center;
					position: initial;
					padding: 9px 13px;
					min-width: 100%;

					.live-in-card-text {
						display: flex;
						flex-direction: column;
						justify-content: center;

						h3 {
							font-size: 16px;
							margin-bottom: 0;
							line-height: 20px;
						}

						p {
							font-size: 12px;
							font-weight: 300;
							line-height: 25px;
							margin-bottom: 0;
						}
					}

					button {
						padding: 10px 20px;
						font-size: 12px;
						margin-left: 0;
					}

					&.fireside-live-in-card {
						position: absolute;
						right: 20px;
						left: 20px;
						bottom: 20px;
						min-width: initial;

						.live-in-card-fireside-content {
							margin-top: -160px;
							margin-left: -13px;

							.live-in-card-session-title {
								font-size: 25px;
								line-height: 30px;
							}
						}
					}
				}

				// hide session description on mobile
				.session-video-placeholder.version-2 {
					.session-live-card-container {
						.session-live-card-description {
							display: none;
						}
					}
				}

				.session-video-placeholder.version-2 {
					.session-live-card-container {
						.session-live-card-title {
							@include line-clamp(2);
							font-size: 24px;
							line-height: 1.1em;
						}
					}
				}

				.session-video-placeholder.version-2.pre-live {
					height: calc(100svw * 0.5625);

					.session-live-card-container {
						gap: 20px;
					}

					.video-titles-container {
						gap: 4px;

						.session-live-card-metadata {
							font-size: 11px;
						}

						.session-live-card-title {
							@include line-clamp(2);
							font-size: 24px;
							line-height: 1.1em;
							margin-bottom: -10px;
						}

						.session-live-card-description {
							display: none;
						}
					}

					.live-in-card-v2 {
						// hiding on mobile to allow space for nav buttons
						// because we are making the pre-live view 16/9 now
						visibility: hidden;
						width: 100%;

						.live-in-card-text {
							h3 {
								font-size: 16px;
							}
						}

						button {
							height: 55px;
							width: 55px;
							padding: 0;

							span {
								display: none;
							}

							svg {
								display: block;
								margin: 0;
								height: 24px;
								width: 24px;
							}

							&.bookmark {
								svg {
									height: 20px;
									width: 20px;
								}
							}
						}
					}
				}

				.session-video-player-container {
					.ivs-player-container {
						border-radius: 0;

						video.ivs-player-video {
							border-radius: 0;
						}

						.cc-container {
							right: 24px;
							left: 24px;
							bottom: 24px;

							p {
								font-size: 12px;
								padding: 6px 12px;
								line-height: 18px;
							}

							&.chat-overlay {
								right: 286px;
							}
						}
					}
				}

				.chat-close {
					display: none;
				}
			}

			&.version-2 {
				top: var(--banner-height, 0px);
			}
		}

		.session-stream-wrapper .session-stream .session-live-card-container {
			bottom: 30px;
			left: 25px;
			right: 25px;
			height: fit-content;

			.live-in-card {
				width: 100%;
				max-width: initial;
			}

			.session-live-card-title {
				font-size: 22px;
				line-height: 36px;
			}
		}
	}
}

@include media-query($xlrg-down) {
	:not(.editor) {
		@include sessionVideoMobile("desktop");
	}
}

@include media-query($med-down) {
	:not(.editor) {
		@include sessionVideoMobile("small-desktop");
	}
}

@include media-query($sm-down) {
	:not(.editor) {
		@include sessionVideoMobile("tablet");
	}
}

@include media-query($xs-down) {
	:not(.editor) {
		@include sessionVideoMobile("mobile");
	}
}

// targets landscape mobile devices
// the aspect ratio means this will only trigger when the screen size is
// actually at 16/9 ratio, not just whenver the height is greater than the width.
// this only targets certain older iphones (max-device-width)
@media screen and (orientation: landscape) and (min-aspect-ratio: 16/9) and (max-device-width: 768px) {
	.session-stream-wrapper:not(.supports-fullscreen) {
		&.fireside-session {
			.player-button.full-screen-button {
				display: none;
				opacity: 0;
			}
		}

		.session-stream {
			height: 100svh;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
}

// targets landscape mobile devices
// the aspect ratio means this will only trigger when the screen size is
// actually at 16/9 ratio, not just whenver the height is greater than the width.
// targets lanscape mobile and tablets
@media screen and (orientation: landscape) and (min-aspect-ratio: 16/9) and (max-width: 1024px) {
	.session-stream-wrapper {
		.session-stream-container {
			.session-stream-container-inner {
				min-height: calc(100svh - var(--banner-height, 0px));
				min-height: calc(100svh - var(--banner-height, 0px));

				.session-stream:not(.is-fullscreen) {
					height: 100vh;
					height: calc(100svh - var(--banner-height, 0px));
					padding-bottom: unset !important;

					.video-player-v2-wrapper {
						height: 100vh;
						height: calc(100svh - var(--banner-height, 0px));

						.video-controls-layer {
							.captions-container {
								bottom: 25px;
								align-items: flex-start;

								.captions-text {
									border-radius: 2px;
									overflow: hidden;

									div {
										color: var(--grayscale-100-white, #fff);
										font-size: 18px;
										font-style: normal;
										font-weight: 500;
										line-height: normal;
										padding: 0 5px;
										max-width: calc(100% - 20px);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.session,
.post-register-preview-container {
	&:not(.editor) {
		.session-stream {
			@supports (aspect-ratio: auto) {
				max-height: calc(100svh - var(--banner-height, 0px));
				min-height: calc(100svh - var(--banner-height, 0px));
			}

			@supports not (aspect-ratio: auto) {
				padding-bottom: 56.25%;
			}
		}
	}

	&.editor {
		.session-stream-wrapper:not(.fireside-session) {
			height: unset;
		}

		.session-stream-wrapper.fireside-session {
			min-height: 810px;
			height: 810px;
		}

		&.desktop {
			.session-stream-wrapper {
				.session-stream {
					// used by single-session only - does not account for left-hand sidebar
					min-height: ($editor-desktop-width / (16/9));
					max-height: ($editor-desktop-width / (16/9));
				}
			}
		}

		&.has-homepage.desktop {
			.session-stream-wrapper {
				.session-stream {
					// unknown why this adjustment needed to be 55 and not 95 (the width of the nav bar)
					// but caused a scrollbar to be present without it.
					min-height: ($editor-desktop-width / (16/9)) - 55px;
				}
			}
		}

		&.mobile {
			.session-stream {
				min-height: $editor-mobile-width / (16/9);
			}
		}

		&.tablet {
			.session-stream {
				min-height: $editor-tablet-width / (16/9);
			}
		}
	}
}

@media screen and (max-width: $br-lrg) {
	.session {
		:not(.editor) {
			.session-stream {
				padding-bottom: 56.25%;
				max-height: calc(100svh - var(--banner-height, 0px));
				min-height: unset !important;
			}
		}
	}
}

.editor.mobile,
.session-preview-wrapper.mobile {
	@include sessionVideoMobile("mobile");

	// needed to fix weird height issue in editor on on-demand
	.session-stream-wrapper .session-stream-container .session-stream .session-video-placeholder.version-2.pre-live,
	.editor.mobile .session-stream-container .session-stream .session-video-placeholder.version-2.pre-live,
	.session-preview-wrapper.mobile .session-stream-wrapper .session-stream-container .session-stream .session-video-placeholder.version-2.pre-live,
	.session-preview-wrapper.mobile .session-stream-container .session-stream .session-video-placeholder.version-2.pre-live {
		height: unset;
	}
}

.editor.tablet,
.session-preview-wrapper.tablet {
	@include sessionVideoMobile("tablet");

	// needed to fix weird height issue in editor on on-demand
	.session-stream-wrapper .session-stream-container .session-stream .session-video-placeholder.version-2.pre-live,
	.editor.mobile .session-stream-container .session-stream .session-video-placeholder.version-2.pre-live,
	.session-preview-wrapper.mobile .session-stream-wrapper .session-stream-container .session-stream .session-video-placeholder.version-2.pre-live,
	.session-preview-wrapper.mobile .session-stream-container .session-stream .session-video-placeholder.version-2.pre-live {
		height: unset;
	}
}