@keyframes avatar {
	from {
		transform: scale(0);
	}

	to {
		transform: scale(1);
	}
}

.event-header-wrapper {
	width: calc(100vw - 96px);
	height: 72px;

	p, h1, h2, h3, h4, h5, h6, label {
		color: $white;
	}

	&.full-page-width {
		width: 100%;
	}

	.event-header-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 100%;
		color: $light-gray;
		background-color: $image-gray;
		border-bottom: 1px solid #3D3A42;

		&.create-event-header {
			background-color: transparent;
			border-bottom: 1px solid $gray;
		}

		.left {
			display: grid;
			align-items: center;
			min-width: 190px;
			height: auto;
			overflow: auto;
			flex: 1;
			grid-template-columns: 50px fit-content(100%) 1fr;

			&.with-logo {
				grid-template-columns: 100px fit-content(100%) 1fr;
			}

			.header-telly-logo-wrapper {
				margin-right: 9px;
				margin-left: 24px;
				padding-right: 24px;
				border-right: 1px solid $gray;

				.header-telly-logo {
					width: 48px;
					height: 48px;
				}
				.channel-avatar {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 48px;
					height: 48px;
					border-radius: 12px;
					pointer-events: none;

					img {
						width: 24px;
						height: 24px;
						text-indent: -9999px;
						object-fit: contain;

						&[alt]::after {
							display: none;
						}
					}
				}
			}

			.pathing-arrow {
				margin: 16px;

				.pathing-arrow-text {
					margin-left: 16px;
				}

				a {
					display: flex;
					align-items: center;
					text-decoration: none;
					color: #fff;
				}
			}

			.event-title {
				display: flex;
				flex-direction: column;
				height: 100%;
				justify-content: center;
   				margin: 0;
				white-space: nowrap;

				.title-top {
					display: flex;
					align-items: center;
					max-height: 50%;

					
					.event-title-editor {
						border: 1px solid transparent;
						h6 {
							margin-bottom: 0;
						}
						&.error {
							border: 1px solid $rose;
						}
						.smart-tooltip.top-center {
							.smart-tooltip-content {
								bottom: 0;
								top: 110%;
								color: $rose;
								
								.smart-tooltip-text::before {
									bottom: inherit;
									border-top: none;
									top: -5px;
									border-bottom: 5px solid #4f4c57;
								}
							}
						}
					}
				}

				.title-bottom {
					height: 50%;
					.event-start {
						padding: 2px;
						font-size: 14px;
						line-height: 20px;
						font-weight: 500;
						color: $med-light-gray;
					}
				}
			}

			.event-autosave-status {
				display: flex;
				align-items: center;
				width: 100px;
				margin-left: 6px;
				height: 16px;
				font-size: 12px;
				line-height: 16px;
				letter-spacing: 0.4px;
				color: $med-light-gray;
			}

			.connected-admins {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding-right: 16px;
				flex-direction: row-reverse;

				.avatar {
					height: 32px;
					width: 32px;
					border-radius: 100%;
					margin-left: -8px;

					img {
						max-height: 100%;
						max-width: 100%;
						border-radius: 100%;
						border: 1px solid $dark-gray;
						object-fit: cover;
						height: 32px;
						width: 32px;
					}

					span {
						height: 32px;
						width: 32px;
						min-height: 32px;
						min-width: 32px;
						@include flex-center;
						border-radius: 100%;
						background-color: $med-gray;
						font-weight: bold;
						text-transform: uppercase;
						border: 1px solid $dark-gray;
					}
				}	
			}
		}

		.test-broadcast {
			display: flex;
			margin-right: 2vw;
			padding: 2px;
			font-size: 14px;
			line-height: 20px;
			font-weight: 500;
			color: $med-light-gray;

			.test-broadcast-tooltip {
				top: inherit;
				right: 40px;
				margin-top: -5px;
			}

			.smart-tooltip-content {
				bottom: 0;
				top: 100%;
				z-index: 1;
				width: 650px;
				white-space: pre-line;

				.smart-tooltip-text::before {
					bottom: inherit;
					border-top: none;
					top: -5px;
				}
			}

			.test-broadcast-switch {
				margin-left: 8px;
			}
		}

		.right {
			display: flex;
			margin-right: 2vw;

			.profile-button {
				margin-left: 16px;
				padding: 0;
				padding-left: 16px;
				border-left: 1px solid $gray;
				border-radius: 0;

				&.no-button-border {
					border-left: none;
				}

				.profile-pic {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 48px;
					height: 48px;
					margin-right: 8px;
					overflow: hidden;
					background-color: $gray;
					border-radius: 100px;

					img {
						width: 48px;
						height: 48px;
						object-fit: contain;
					}
				}
			}

			.profile-details-wrapper {
				bottom: initial;
			}

			.template-button {
				margin-right: 8px;
				padding: 7px 16px;
				font-size: 12px;
				font-weight: 600;
				color: $white;
			}
		}
	}
}

.create-template-modal {
	&.modal-wrapper .modal {
		.modal-title {
			margin-bottom: 8px;
			}

		.modal-subtitle {
			margin-bottom: 0;
		}
	}
}