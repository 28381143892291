.custom-color-picker-wrapper {
	position: relative;
	transition: box-shadow 0.2s ease-in-out;
	outline: none;

	&.color-picker-open,
	&:hover {
		box-shadow: 0 0 0 1px var(--cyan);
	}

	.color-picker-popup {
		position: absolute;
		display: flex;
		height: fit-content;
		transform: translateY(0%);
		transition: opacity 0.2s, transform 0.2s;
		top: 0%;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		justify-content: center;
		align-items: center;
		opacity: 0;

		&:not(.color-picker-open) {
			pointer-events: none;
		}

		&.color-picker-open {
			opacity: 1;
			transform: translateY(calc(-50% - 10px));
		}

		.color-picker-popup-btn-wrapper {
			padding: 12px;
			transform: translateY(-50%);
			background-color: var(--gray-800);
			box-shadow: 0 5px 40px rgba(0, 0, 0, 0.25);
			border-radius: 100px;
			width: 75px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
