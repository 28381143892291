.nav-language-selector {
	display: flex;
	align-items: center;
	flex-direction: column;

	button {
		position: absolute;
		top: 20px;
		left: 30px;
		z-index: 10;
	}

	.nav-languages-list {
		display: flex;
		align-items: center;
		flex-direction: column;

		.language-list-item {
			cursor: pointer;

			&.selected {
				color: var(--accentColor);
			}
		}
	}
}