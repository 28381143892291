@import '../../../scss/old-deps';

@keyframes slideUp {
	from {
		transform: translateY(50px) scale(0.95);
		opacity: 0;
	}

	to {
		transform: translateY(0) scale(1);
		opacity: 1;
	}
}

.date-with-portal {
	z-index: 999 !important;
	
	.react-datepicker__navigation--next--with-time {
		margin-right: 35px;
	}

	.react-datepicker {
		animation: slideUp 150ms;
		box-shadow: 0 8px 24px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
	}

	.react-datepicker__input-container input[type='text'] {
		font-family: $font-family-poppins;
		font-size: 14px;
		font-weight: 400;
		color: $white;
		background-color: transparent;
		border: none;

		&:active,
		&:focus {
			outline: none;
		}
	}

	.react-datepicker,
	.react-datepicker__header,
	.react-datepicker__time {
		font-family: $font-family-poppins;
		font-size: 14px;
		font-weight: 400;
		background-color: $image-gray;
		border: none;
		border-bottom-right-radius: 16px;
	}

	.react-datepicker .react-datepicker__triangle,
	.react-datepicker__triangle {
		border-top-color: $image-gray !important;
		margin-bottom: -6px !important;
	}

	.react-datepicker__current-month,
	.react-datepicker__navigation,
	.react-datepicker__header--time {
		margin-bottom: 24px;
		font-size: 18px;
		line-height: 21px;
		font-weight: bold;
	}

	.react-datepicker-time__header {
		font-size: 18px;
		line-height: 21px;
		font-weight: bold;
	}

	.react-datepicker__time-list {
		height: 300px !important;
	}

	.react-datepicker__navigation,
	.react-datepicker__header--time {
		margin-top: 28px;
	}

	.react-datepicker__navigation--next {
		margin-right: 72px;
	}

	.react-datepicker__navigation--previous {
		margin-left: 36px;
	}

	.react-datepicker__current-month,
	.react-datepicker-time__header,
	.react-datepicker__day,
	.react-datepicker__day-name {
		color: $white;
	}

	.react-datepicker__day:hover {
		color: $med-gray;
		border-radius: 100px;
	}

	.react-datepicker__day-name {
		width: 32px;
		margin: 0 4px;
	}

	.react-datepicker__month-container {
		padding: 28px;
	}

	.react-datepicker__day {
		width: $_32px;
		height: $_32px;
		margin: 4px;
		font-family: $font-family-poppins;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		outline: none;
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}

	.react-datepicker__day--disabled,
	.react-datepicker__day--outside-month {
		color: $gray;
	}

	.react-datepicker__day--today {
		background-color: transparent;
		border: 2px solid rgba(255, 255, 255, 0.4);
		border-radius: 100px;
	}

	.react-datepicker__day--selected,
	.react-datepicker__day--keyboard-selected {
		background-color: $cyan;
		color: $dark-gray;
		border-color: transparent;
		border-radius: 100px;
		outline: none;
	}

	.react-datepicker__time-container {
		width: 120px;
		border-left: 2px solid $gray;

		.react-datepicker__time-box {
			width: 100%;

			.react-datepicker__time-list {
				color: $white;
				scrollbar-width: none;
				-ms-overflow-style: none;

				.react-datepicker__time-list-item:hover {
					color: $gray;
				}

				.react-datepicker__time-list-item--selected {
					background-color: $cyan!important;
					color: $dark-gray!important;
				}

				&::-webkit-scrollbar {
					display: none;
				}
			}
		}
	}
}
