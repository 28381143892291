@import "../../../../../scss/deps.scss";

@mixin absolute-fill {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@keyframes slideInVideoPip {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes liveBlink {
	0% {
		opacity: 1;
		box-shadow: 0 0 6px 3px rgba($rose, 0.25);
	}

	100% {
		opacity: 0.75;
		box-shadow: 0 0 6px -3px rgba($red, 0.25);
	}
}

@keyframes loadingAppear {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes rotateSettings {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(-90deg);
	}
}

@media screen and (min-width: $xs) {
	.session-stream-wrapper.version-2 {
		&.is-pip {
			z-index: 101;
		}
	}
}

@mixin upperControls($position) {
	.upper {
		position: $position;
		top: 0;
		right: 0;
		padding: 16px 15px 16px 28px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 3;
		width: 100%;
		background: transparent;
		transition: 150ms background;

		.left {
			display: flex;
			align-items: center;
			transition: 150ms opacity;

			// Navigation Menu (default has absolute position)
			button {
				position: static;
			}
		}

		.right {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			transition: 150ms opacity;
		}
	}
}

@mixin tooltipLabel {
	padding: 8px;
	background-color: $gray-1000;
	border-radius: 4px;
	position: absolute;
	top: -40px;
	left: 50%;
	opacity: 0;
	transform: translateX(-50%) translateY(50%);
	transition:
		100ms opacity,
		150ms transform;
	white-space: nowrap;
	pointer-events: none;
	content-visibility: hidden;
	z-index: 2;
	color: var(--grayscale-100-white, #fff);
	text-align: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	/* 133.333% */
	letter-spacing: 0.24px;
	user-select: none;
}

.floating-chat-toggle {
	right: 20px;
	transition: right 150ms ease;
	position: absolute;
	bottom: 23px;
	z-index: 10;

	button {
		label {
			transform: translate(-100%, 50%);
			left: 100%;
		}
	}

	button {
		&:hover {
			label {
				transform: translate(-100%, 0%);
			}
		}
	}
}

.overlay-chat {
	.floating-chat-toggle {
		right: 380px;
	}

	&.chat-closed {
		.floating-chat-toggle {
			right: 20px;
		}
	}
}

.session-video-placeholder.version-2 {
	@include upperControls(absolute);
}

@media screen and (min-width: 659px) {
	.session-video-placeholder {
		.upper {
			display: none;
		}
	}
}

.video-player-v2-wrapper {
	@include absolute-fill();

	&.is-fullscreen {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: var(--dark-mode-color);
	}

	&.force-light-theme {

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: var(--user-mode-color);
		}

		background-color: var(--user-mode-background);

		.video-player-layer {
			background-color: var(--user-mode-background);
		}

		.video-controls-layer {
			.video-controls-wrapper {
				opacity: 1;

				.lower {
					background-image: none;

					svg {
						g {
							filter: none;
						}

						path {
							fill: var(--user-mode-color);
						}
					}
				}
			}
		}
	}

	.video-player-layer {
		@include absolute-fill();
		overflow: hidden;

		.loading-placeholder {
			@include absolute-fill();
			background-color: black;
		}

		.ivs-player {
			@include absolute-fill();
		}

		.hls-player {
			@include absolute-fill();
		}

		video {
			@include absolute-fill();
			object-fit: contain;
			height: 100%;
			width: 100%;

			&::cue {
				color: var(--grayscale-100-white, #fff);
				background-color: rgba(0, 0, 0, 0.25);
			}
		}

		.link-player {
			@include absolute-fill();
			padding-bottom: calc(56.25%);
			margin: auto;
			position: relative;
			top: 50%;
			transform: translateY(-50%);

			.link-player-container {
				@include absolute-fill();
			}
		}

		.embed-player {
			// not using aspect-ratio because of problems on Safari
			// using old-school padding-bottom trick
			padding-bottom: calc(56.25% + 50px);
			margin: auto;
			position: relative;
			top: 50%;
			transform: translateY(-50%);

			.embed-wrapper {
				@include absolute-fill();
				top: 50px;
				bottom: 50px;
				max-height: calc(100vh - 100px);
				margin: auto;

				&.auto-scale {
					iframe {
						height: 100%;
						width: 100%;
					}
				}
			}
		}

		.google-meet-player-wrapper {
			@include absolute-fill();
			// this must not be 0 or chat cannot be toggled
			bottom: 60px;
			// this must not be 100% and must not cover the bottom 60px of the controls or chat cannot be toggled
			height: calc(100% - 60px);
			width: 100%;
			z-index: 1000;

			@include tablet {
				height: 100%;
			}

			.google-meet-player {
				@include absolute-fill();
				margin: auto;

				iframe {
					@include absolute-fill();
					height: 100%;
					width: 100%;
					border: none;
				}
			}
		}


		.join-button {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.video-controls-layer {
		@include absolute-fill();

		.settings-container {
			.control-button-container {
				@include flex-center;
			}

			.control-button {
				padding: 10px;
				height: 40px;
				width: 40px;

				.idle-icon {
					@include absolute-fill();
					@include flex-center;

					svg {
						height: 24px;
						width: 24px;
						position: relative;
						margin: -4px;
						left: 1.5px;
						opacity: 1;
						transition: opacity 150ms;
						transition-delay: 100ms;
					}
				}

				.active-icon {
					@include absolute-fill();
					@include flex-center;

					svg {
						path {
							fill: white;
						}

						height: 19px;
						width: 19px;
						position: relative;
						top: 1px;
						left: 0;
						margin: 0;
						opacity: 0;
						filter: drop-shadow(-1px 0 1px black);
						transform: rotate(0deg);
						transition: opacity 150ms 150ms,
						transform 150ms 0ms;
					}
				}

				.menu-button {
					&.hidden {
						display: none;
					}
				}

				&.active {
					svg {
						path {
							fill: white;
						}
					}

					.idle-icon {
						svg {
							opacity: 0;
							transition-delay: 0ms;
						}
					}

					.active-icon {
						svg {
							opacity: 1;
							transform: rotate(-90deg) scale(1.1);
							transition:
								opacity 0ms 0ms,
								transform 150ms 0ms;
						}
					}
				}
			}
		}

		.video-controls-wrapper {
			@include absolute-fill();
			transition: 150ms opacity;
			opacity: 0;
			cursor: none;

			.buffering-view {
				@include absolute-fill;
				@include flex-center;
				flex-direction: column;
				gap: 16px;
				opacity: 0;
				// this animation starts at 50%, so the buffering view will only show
				// if the video is still buffering after 300ms
				animation: 600ms loadingAppear forwards;

				p {
					color: $gray-200;
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 20px;
					/* 142.857% */
				}
			}


			&.hover,
			&.paused {
				opacity: 1;

				.upper {
					background: linear-gradient(180deg, #09090a 0%, rgba(26, 26, 26, 0) 100%, rgba(9, 9, 10, 0) 100%);
				}

				.mobile-controls {
					opacity: 1 !important;
					transition: 150ms opacity;
				}

				cursor: auto;
			}

			&.paused {
				.upper {
					.left {
						opacity: 1;
						content-visibility: visible;
					}
				}
			}

			&.overlay-opened {
				opacity: 0;
			}

			@include upperControls(absolute);

			.lower {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				border-radius: 1px;
				// prevent pan/scroll on mobile when touching in this area
				touch-action: none;
				background-image: linear-gradient(180deg,
						rgba(9, 9, 10, 0) 0%,
						rgba(9, 9, 10, 0.6) 52.08%,
						rgba(9, 9, 10, 0.95) 100%);

				.video-metadata {
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					margin: auto 0 0 0;
					color: white;
					padding: 15px 15px 15px 22px;
					padding-bottom: 5px;
					user-select: none;
					transform: translateY(0);

					@include media-query($lrg-up) {
						transition: all 0.3s;
						max-width: 480px;
						cursor: pointer;
					}

					.video-time-row {
						// TODO: make theme light text color
						color: var(--grayscale-200-light-grey, #e5e4ec);
						font-size: 13px;
						font-style: normal;
						font-weight: 600;
						line-height: 20px;
						/* 153.846% */
						margin-bottom: 5px;

						.video-separator {
							width: 18px;
							display: inline-block;
							text-align: center;
						}
					}

					.video-title {
						font-size: 20px;
						display: flex;

						h4 {
							cursor: pointer;
							font-family: var(--headingFont);
							margin-bottom: 0;
						}

						.reveal-description {
							margin: 0;
							padding: 0;
							background-color: transparent;
							margin-left: 8px;
							margin-top: 0;
							transition: all 200ms;

							svg path {
								fill: $icon-gray;
							}

							&.revealed {
								svg {
									transform: rotate(180deg);

									path {
										fill: white;
									}
								}
							}
						}
					}

					.video-meta-actions {
						display: flex;
						gap: 10px;
						margin-top: 10px;
						margin-bottom: 20px;

						.video-meta-action-btn {
							border: 2px solid $icon-gray;
							margin-right: 10px;
							transition: all 200ms;

							&:hover {
								border-color: white;
								background-color: transparent;
								scale: 1.1;
							}
						}
					}

					.video-meta-content {
						opacity: 0;
						transition: opacity 0.3s;

						&.revealed {
							opacity: 1;
						}

						.video-meta-description {
							font-size: 14px;
							line-height: 20px;
							font-weight: 400;
							width: 480px;
							line-height: 20px;
						}
					}

					&:hover {
						.reveal-description {
							svg {
								path {
									fill: white;
								}
							}

							scale: 1.3;
						}
					}
				}

				.video-control-actions {
					display: flex;
					flex-direction: column;
					width: 100%;

					.track-gutter-container {
						width: 100%;
						height: 28px;
						padding-top: 14px;
						margin-top: -14px;
						cursor: pointer;
						position: relative;
						z-index: 1;

						&:hover:not(.live) {
							.track-gutter {
								height: 8px;
								margin-top: -2px;

								.thumb-trigger {
									.thumb {
										transform: translate(-50%, -50%) scale(1.5);
									}
								}
							}

							label {
								opacity: 1;
							}
						}

						.track-gutter {
							position: absolute;
							left: 15px;
							right: 15px;
							height: 4px;
							border-radius: 10px;
							box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
							background-color: $gray-700;
							margin-top: 0px;
							transition:
								150ms height,
								150ms margin-top;

							.loaded-track {
								background-color: $gray-500;
								border-radius: 10px;
								position: absolute;
								top: 0;
								bottom: 0;
								left: 0;
								width: 50%;
							}

							.played-track {
								background-color: $gray-100;
								border-radius: 10px;
								position: absolute;
								top: 0;
								bottom: 0;
								left: 0;
								width: 25%;
							}

							// not visible bounding rect to make a larger target
							.thumb-trigger {
								height: 40px;
								width: 40px;
								position: absolute;
								top: -18px;
								transform: translateX(-50%);
								cursor: pointer;

								// visible thumb element offset within the box
								.thumb {
									height: 12px;
									width: 12px;
									border-radius: 50%;
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%) scale(1);
									transform-origin: center 2px;
									background-color: $gray-100;
									transition: 150ms transform;
									cursor: grab;

									&:active {
										cursor: grabbing;
									}
								}

								&.hidden {
									visibility: hidden;
								}

								&:active {
									.thumb {
										transform: translate(-50%, -50%) scale(1.5);
									}
								}
							}
						}

						.thumbnails-container {
							position: absolute;
							left: 15px;
							right: 15px;
							height: 4px;
							pointer-events: none;
							user-select: none;

							.thumbnail-pin {
								flex: 1;

								.thumbnail-image {
									width: 160px;
									height: calc(160px * 9 / 16);
									min-width: 160px;
									min-height: calc(160px * 9 / 16);
									opacity: 0;
									pointer-events: none;
									top: calc((-160px * (9 / 16)) - 20px);
									left: calc(-160px / 2);
									position: absolute;
									border-radius: 4px;
									background-size: cover;
									background-position:
										center center,
										center center;
									background-repeat: no-repeat, no-repeat;
									content-visibility: hidden;
									transform: translateY(-30px);
								}

								&.active {
									.thumbnail-image {
										opacity: 1;
										content-visibility: visible;
									}
								}
							}
						}

						label {
							@include tooltipLabel();
							// opacity: 0;
							content-visibility: visible;
							transform: translateX(-50%) translateY(-100%);
							top: unset;
							bottom: -5px;
							color: rgba($gray-1000, 0.75);
							position: absolute;
							background-color: $gray-100;

							&::before {
								background-color: $gray-100;
							}
						}

						&.live {
							cursor: default;

							.track-gutter {
								background: $gray-100;
							}
						}
					}

					.buttons-row {
						display: flex;
						align-items: center;
						justify-content: space-between;
						width: 100%;
						padding-left: 10px;
						padding-right: 15px;
						padding-bottom: 10px;

						.left {
							display: flex;
							align-items: center;
							justify-content: flex-start;

							.play-pause-container {
								margin-left: 1px;

								.indeterminate {
									opacity: 0.7;
								}
							}

							.volume-container {
								display: flex;
								flex-direction: row-reverse;
								align-items: center;
								position: relative;

								.volume-slider-container {
									position: absolute;
									width: 0;
									opacity: 0;
									transform: translateX(-40px);
									transition:
										250ms opacity,
										150ms transform;
									height: 40px;
									pointer-events: none;
									left: 0px;
									@include flex-center;
									content-visibility: hidden;

									.track-gutter-container {
										height: 4px;

										.track-gutter {
											left: 40px;
											right: 40px;
											filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.3));
										}
									}
								}

								&:hover {
									padding-right: 140px;

									.volume-slider-container {
										width: 160px;
										pointer-events: auto;
										opacity: 1;
										transform: translateX(0);
										content-visibility: visible;
									}

									&.muted {
										.volume-slider-container {
											opacity: 0.5;
										}
									}

									&+.current-time-indicator {
										pointer-events: none;
										left: -140px;
										opacity: 0;
										transform: translateX(20px);
									}

									&+.live-indicator {
										pointer-events: none;
										left: -140px;
										opacity: 0;
										transform: translateX(20px);
									}
								}
							}

							.live-indicator {
								position: relative;
								opacity: 1;
								transform: translateX(0);
								transition:
									250ms opacity,
									150ms transform;
								background-color: $gray-800;
								border-radius: 20px;
								color: #fff;
								font-size: 10px;
								font-style: normal;
								font-weight: 700;
								line-height: 15px;
								/* 150% */
								text-transform: capitalize;
								padding: 8px;
								margin-left: 8px;
								@include flex-center;
								content-visibility: visible;

								.live-dot {
									background: $red;
									height: 8px;
									width: 8px;
									border-radius: 50%;
									display: block;
									margin-left: 2px;
									margin-right: 4px;
									animation: 1500ms liveBlink infinite alternate linear;
								}
							}

							.current-time-indicator {
								position: relative;
								opacity: 1;
								transform: translateX(0);
								transition:
									250ms opacity,
									150ms transform;
								margin-left: 8px;
								color: $gray-100;
								font-feature-settings:
									"clig" off,
									"liga" off;
								font-size: 12px;
								font-style: normal;
								font-weight: 600;
								line-height: 20px;
								/* 166.667% */
								content-visibility: visible;
							}
						}

						.right {
							display: flex;
							align-items: center;
							justify-content: flex-end;

							.reactions-container {
								z-index: 5;
							}

							.description-modal-button-for-embeded-videos {
								cursor: pointer;
							}

							.raise-hand {
								background-color: var(--accentColor);
								color: var(--backgroundColor);
								font-size: 11px;
								font-style: normal;
								font-weight: 700;
								line-height: 16px;
								/* 145.455% */
								padding: 8px 15px;
								margin: 0 4px;

								svg {
									margin-right: 5px;
									height: 12px;
									width: 12px;
								}

								&.active {
									background-color: rgba(var(--accentColor-rgb), 0.1);
									color: var(--accentColor);

									svg {
										path {
											fill: var(--accentColor);
										}
									}
								}
							}
						}
					}
				}
			}

			.lower,
			.upper {
				&.link-player {
					z-index: 2;
				}
			}

			.center-control.link-player {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
				cursor: pointer;
			}
		}

		.captions-container {
			pointer-events: none;
			position: absolute;
			bottom: 78px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-end;
			left: 0;
			right: 0;
			user-select: none;
			// float: left;

			.captions-text {
				border-radius: 2px;
				overflow: hidden;
				padding: 0px 24px;

				div {
					// display: block;
					background: rgba(26, 26, 31, 0.8);
					color: var(--grayscale-100-white, #fff);
					text-align: left;
					font-feature-settings:
						"clig" off,
						"liga" off;
					font-size: 26px;
					font-style: normal;
					font-weight: 600;
					line-height: 40px;
					margin-right: 4px;
				}

				div:empty {
					display: none;
				}
			}
		}
	}

	.scroll-for-details {
		@include flex-center;
		position: absolute;
		bottom: 10px;
		z-index: 10;
		left: 0;
		right: 0;
		text-align: center;
		color: var(--grayscale-200-light-grey, #e5e4ec);
		height: 40px;
		pointer-events: none;

		/* text shadow */
		text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.7);
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px;

		p {
			margin: 0;
		}

		.new-dropdown-icon {
			margin-left: 6px;
			transform: rotate(-90deg);
		}

		&.hidden {
			display: none;
		}
	}

	&.embed {
		background-image: linear-gradient(180deg,
				rgba(9, 9, 10, 0) 0%,
				rgba(9, 9, 10, 0.6) 52.08%,
				rgba(9, 9, 10, 0.95) 100%);
		background-size: 100% 250px;
		background-position: bottom;
		background-repeat: no-repeat;

		.video-player-layer {
			max-height: calc(100vh - 100px);
			margin: auto;
		}

		.video-controls-layer {
			pointer-events: none;

			.video-controls-wrapper {
				opacity: 1;
				content-visibility: visible;

				.upper {
					pointer-events: auto;
				}

				.lower {
					height: 50px;
					background-color: transparent;
					pointer-events: auto;

					.video-metadata {
						display: none;
					}

					.video-control-actions {
						.buttons-row {
							justify-content: flex-end;
						}
					}
				}

				.float-container {
					min-height: 75vh;
					min-width: 75vw;
					width: 75vw;
				}
			}
		}
	}

	.paused-cover-container {
		@include absolute-fill();
		@include flex-center;
		flex-direction: column;
		align-items: flex-start;
		padding: 0 100px;
		background: rgba(19, 19, 19, 0.75);
		display: none;
		pointer-events: none;
		opacity: 0;
		transition: 200ms opacity;
		z-index: 5;
		content-visibility: hidden;

		@include upperControls(absolute);

		&.show {
			display: flex;
			pointer-events: auto;
			animation: fadeIn 300ms;
			opacity: 1;
			content-visibility: visible;
		}

		&.closing {
			opacity: 0;
			pointer-events: none;
		}

		.paused-button-cover-actions {
			margin-top: 0px;
			display: flex;
			gap: 10px;

			button.negative {
				height: 40px;
				width: 40px;
				@include flex-center;
				padding: 0;
			}
		}
	}

	.unmute-autoplay {
		@include absolute-fill();
		@include flex-center;
		align-items: flex-end;
		justify-content: flex-end;
		padding-bottom: 84px;

		@include media-query($med-down) {
			padding-bottom: 13px;
		}

		button {
			font-size: 11px;
			font-weight: 700;
			background: transparent;
			border: 2px solid rgba(255, 255, 255, 0.5);
			transition: border 150ms ease-in-out, opacity 150ms ease-in-out;
			margin: 0 auto;
			padding: 3px 13px;

			span,
			svg {
				opacity: 0.8;
			}

			svg {
				margin-right: 5px;

				g {
					filter: none;

					path {
						fill: var(--dark-mode-color);
					}
				}
			}

			&:hover {
				border: 2px solid rgba(255, 255, 255, 1);

				span,
				svg {
					opacity: 1;
				}
			}
		}
	}

	&.force-light-theme {
		.unmute-autoplay {
			button {
				svg {
					path {
						fill: var(--user-mode-color);
					}
				}
			}
		}
	}

	&.pip {
		.paused-cover-container {
			display: none;
		}

		.thumbnails-container {
			display: none;
		}

		.video-player-layer {
			.google-meet-player-wrapper {
				height: 100%;
			}
		}

		.video-controls-layer {
			.video-controls-wrapper {
				.upper {
					.left {
						visibility: hidden;
					}
				}
			}

			.scroll-for-details {
				display: none;
			}
		}
	}

	&.is-fullscreen {
		.scroll-for-details {
			display: none;
		}

		.menu-button {
			display: none;
		}
	}

	// Changes to the pop out video to fill and stay in line with the left edge of the video gutter
	@media screen and (min-width: 1400px) {
		--pip-video-width: 400px;

		&.pip {
			left: calc(100% - var(--pip-video-width) - 15px);
		}
	}

	@media screen and (max-width: 1400px) {
		--pip-video-width: 400px;

		&.pip {
			left: calc(100% - var(--pip-video-width) - 15px);
		}
	}

	@media screen and (min-width: 768px) {
		&.pip {
			position: fixed;

			/*

		dimensions for bottom-right positioning - leaving in just in case we need to swap back
		
		left: calc(100vw - 25vw - 40px);
		top: calc(100vh - 25vw * (9 / 16) - 40px);
		*/

			top: 90px;
			width: var(--pip-video-width);
			aspect-ratio: 16/10;
			z-index: 10;
			border-radius: 12px;
			box-shadow: 0px 13px 30px 0px rgba(0, 0, 0, 0.25);
			// removing the show animation until we get something that look right
			// because the pip view can be moved around by the user, getting the slide in right just works weirdly
			// animation: slideInVideoPip 500ms;
			// opacity: 1;

			.video-controls-layer,
			.video-player-layer {
				border-radius: 12px;
				overflow: hidden;

				.embed-player {
					.embed-wrapper {
						top: 0;
						bottom: 0;
					}
				}
			}

			.video-controls-layer {
				.upper {
					padding-right: 10px;
					border-top-left-radius: 12px;
					border-top-right-radius: 12px;

					.left {
						pointer-events: none;
					}
				}

				.control {
					display: none;
				}

				.video-metadata {
					visibility: hidden;
				}

				.play-pause-container,
				.volume-container,
				.fullscreen-container,
				.pip-container,
				.captions-enable-container {
					display: block;
				}

				// .current-time-indicator {
				// 	span {
				// 		:not(#current-second) {
				// 			display: none;
				// 		}
				// 	}
				// }

				.volume-container {
					&:hover {
						padding-right: 100px !important;
					}
				}
			}

			video {
				object-fit: cover;
				border-radius: 12px;
			}

			.captions-container {
				position: absolute;
				top: 150px;
				left: 0px;
				width: 348px;
				justify-content: flex-start;

				.captions-text {
					overflow: visible;

					div {
						box-shadow: 0px 13px 30px 0px rgba(0, 0, 0, 0.25);
						font-size: 18px;
						line-height: 32px;
						display: block;
						border-radius: 3px;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1190px) {
		.scroll-for-details {
			display: none;
		}
	}

	.mobile-controls {
		display: none;
	}
}

.control-button-container {
	position: relative;

	.control-button {
		background-color: transparent;
		border-radius: 0;
		padding: 10px;
		transition: 150ms transform;
		position: relative;

		&::before {
			content: "";
			display: block;
			border-radius: 100%;
			background-color: transparent;
			transform: scale(0.5);
			transition:
				150ms transform,
				150ms background-color;
			@include absolute-fill();
		}

		&.active {
			svg {
				z-index: 1;
			}

			&::before {
				background-color: $gray-700;
				transform: scale(1);
			}

			&:hover {
				transform: scale(1);
			}
		}

		&:hover {
			transform: scale(1.1);
		}

		&:active {
			transition: 48ms transform;
			transform: scale(0.95);
		}
	}

	label {
		@include tooltipLabel();

		&.under {
			transform: translateX(-50%) translateY(-50%);
			top: 40px;

			&::before {
				top: -1px;
			}
		}
	}

	&:hover {
		label {
			transition-delay: 1500ms;
			opacity: 1;
			transform: translateX(-50%);
			content-visibility: visible;
		}
	}
}

svg {
	path {
		&.red {
			fill: $rose !important;
		}
	}
}

svg#icon-settings-sd {
	path {
		&.gray {
			fill: $gray-500 !important;
		}
	}
}

@mixin video-small-desktop {

	// WARNING: Trickery
	// the designs have the thumb trigger for the player overpainting the UI below it
	// however due to the structure of the application this is not possible.
	// To account for it, we're padding the bottom of the session stream container to add
	// an extra 15 pixels to it and shortening the controls by 15 pixels, then making the background
	// of the extra space have the same BG color as the modules container below.
	.session-stream-wrapper {
		overflow: visible; // needed to allow the thumb to overpaint the rest of the UI below
	}

	.session-stream-wrapper.version-2 .session-stream-container {
		background-color: transparent;
		border-bottom: none;
		overflow: visible; // also needed to allow the thumb to overpain the rest of the UI below

		.session-stream-container-inner {
			background-color: var(--dark-mode-background);
		}

		.session-stream {
			&:not(.is-fullscreen) {
				padding-bottom: calc(56.25%) !important;
				// background-color: var(--user-mode-background-alt);

				/* Height is set from another media query in session-stream.scss:1020
					media screen and (orientation: landscape) and (min-aspect-ratio: 16/9) and (max-width: 1024px) 
				*/

				&.meet-fireside {
					.session-video-placeholder.version-2.pre-live {
						height: unset;
					}
				}

				@media (orientation: landscape) and (min-aspect-ratio: 16/9) {
					&.meet-fireside {
						padding-bottom: unset !important;
					}
				}

				@media (orientation: portrait) {
					&.meet-fireside {
						padding-bottom: 60% !important;
					}
				}
			}

			.video-player-v2-wrapper {
				background-color: $gray-1000;
				;
				// background-color: var(--user-mode-background-alt);
				// bottom: 20px;

				.video-controls-layer {
					.video-controls-wrapper {
						.lower {
							.video-metadata {
								padding-bottom: 0;

								.video-title {
									h4 {
										font-size: 16px;
										margin-bottom: 0;
										line-height: 20px;
										font-family: var(--headingFont);
									}

									.reveal-description {
										display: none;
									}
								}

								.video-description {
									display: none;
								}
							}

							.video-control-actions {
								.progress.track-gutter-container {
									position: absolute;
									bottom: -13px;
									left: 0;
									right: 0;

									.track-gutter {
										height: 2px;
										box-shadow: none;
										left: 0;
										right: 0;

										.thumb-trigger {
											margin-top: -18.5px;

											.thumb {
												height: 16px;
												width: 16px;
												filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.25));
												transform: translate(-50%, 51%) scale(1);
											}
										}
									}

									&.live {
										.track-gutter {
											background: $gray-100;
										}
									}

									&:hover:not(.live) {
										.track-gutter {
											height: 2px;
											margin-top: initial;
										}
									}
								}

								.buttons-row {
									.left {
										.play-pause-container {
											display: none;
										}

										.seek-to-beginning-container {
											display: none;
										}

										.seek-back-ten-container {
											display: none;
										}

										.seek-forward-ten-container {
											display: none;
										}
									}
								}
							}
						}
					}

					.mobile-controls {
						position: absolute;
						top: 120px;
						left: 120px;
						right: 120px;
						bottom: 120px;
						z-index: 5;
						opacity: 0;
						@include flex-center;

						.control {
							@include flex-center;
						}

						&.hide {
							z-index: 1;
							opacity: 0;
						}
					}
				}

				&.is-fullscreen {
					position: fixed;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
				}

				&.embed {
					.video-controls-layer {
						.chat-container {
							display: none;
						}
					}
				}

				.google-meet-player-wrapper {
					height: 100%;
				}
			}

			.menu-button {
				display: none;
			}
		}
	}

	.session.video-v2 {
		.session-header {
			display: none;
		}

		.session-header header .video-controls-container {
			padding-right: 0;
			padding-top: 12px;
		}
	}


	.control-button-container {
		label {
			display: none;
		}
	}

	.session-stream-wrapper.version-2 {
		.session-stream-container {
			.session-stream {
				.video-player-v2-wrapper {
					.video-controls-layer {
						.video-controls-wrapper {
							.lower {
								.video-control-actions {
									.buttons-row {
										.right {
											.chat-container {
												display: none;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@mixin video-tablet {
	.session.video-v2.pre-live {
		.session-stream-wrapper {
			.session-stream-container {

				.session-stream {
					&:not(.is-fullscreen) {
						&.meet-fireside {
							padding-bottom: 60% !important;
						}
					}

					.video-player-v2-wrapper {
						.scroll-for-details {
							display: none;
						}

						.reaction-container {
							right: 15px;
							bottom: 44px;
						}
					}
				}
			}
		}
	}
}

@mixin video-mobile {

	// setting to 380 for pre/post live ensures there isn't a space between the chat bar and the video placeholder banner

	.session-stream-wrapper.version-2 {
		z-index: 5;

		.session-stream-container {
			overflow: visible;

			.session-stream {
				padding-bottom: 56.25% !important;

				&:not(.is-fullscreen) {
					&.meet-fireside {
						padding-bottom: calc(100%) !important;
					}
				}

				.video-player-v2-wrapper {
					.google-meet-player-wrapper {
						bottom: 0;
						height: 100%;
					}

					.video-controls-layer {
						.video-controls-wrapper {
							.lower {
								z-index: 5;
								height: unset;

								.video-metadata {
									visibility: hidden;
								}

								.reaction-container {
									right: 34px;
									bottom: 40px;
								}

								.video-control-actions {
									.buttons-row {
										.right {
											.chat-container {
												display: none;
											}
										}
									}
								}
							}

							&.hover,
							&.paused {
								+.captions-container {
									.captions-text {
										margin-bottom: 40px;
									}
								}
							}
						}

						.captions-container {
							bottom: 16px;
							align-items: flex-start;

							.captions-text {
								border-radius: 2px;
								overflow: hidden;

								div {
									color: var(--grayscale-100-white, #fff);
									font-size: 14px;
									font-style: normal;
									font-weight: 500;
									line-height: normal;
									padding: 0 5px;
									max-width: calc(100% - 20px);
								}
							}
						}
					}

					.paused-cover-container {
						padding: 25px;
						z-index: 5;

						.session-live-card-title {
							/* Text Shadow */
							text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.7);
							font-size: 25px;
							font-style: normal;
							font-weight: 700;
							line-height: 35px;
							/* 140% */
						}

						.session-live-card-description {
							display: none;
						}

						button.primary {
							font-size: 0;
							height: 40px;
							width: 40px;
							padding: 0;
							@include flex-center;

							svg {
								margin-right: 0;
							}

							#icon-arrow-circle {
								position: relative;
								top: -2px;
							}
						}
					}
				}

				.session-video-placeholder {
					.session-live-card-title {
						font-size: 36px;
						line-height: 44px;
						@include line-clamp(2);
					}

					.session-live-card-description {
						display: none;
					}
				}
			}
		}

		.floating-chat-toggle {
			display: none;
		}
	}
}

@include media-query($med-down) {
	@include video-small-desktop();
}

@include media-query($sm-down) {
	@include video-tablet();
}

@include media-query($xs-down) {
	@include video-mobile();
}

.editor.tablet {
	@include video-small-desktop();
	@include video-tablet();
}

.editor.mobile {
	@include video-small-desktop();
	@include video-mobile();
}

iframe.meetDialog {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10000;
	height: 100vh;
	width: 100vw;
	border: none;
}

.meet-fireside-loading-spinner-container {
	@include absolute-fill();
	@include flex-center();
	display: none;

	&.visible {
		display: flex;
	}
}

.meet-embed-nav-trigger {
	position: fixed;
	// fireside host banner has nav controls already
	// if the banner height is set we want to hide these
	// but passing props around and doing it in the component is
	// a lot of processing for one little change. 
	// so we are just going to hide this by the height of the host banner (60px or 0px)
	top: calc(var(--fireside-host-banner-height) * -1);
	left: 0;
	right: 0;
	height: 60px;
	z-index: 1000;
}

mux-player {
	--media-control-display: none;
	--media-dialog-display: none;

	div[pseudo="-webkit-media-controls"] {
		display: none;
	}
}