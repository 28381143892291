@import './src/scss/old-deps';
@import './src/scss/base/colors';
@import './src/scss/abstracts/colors';

$background-color: $med-gray;

.panel-block {
	@include radius-12;
	padding: 16px;
	background-color: $image-gray;
	margin-bottom: 16px;
	transition: background-color $hover-transition-duration;

	&.isAdmin {
		--headingFont: var(--editorHeadingFont, 'Poppins', sans-serif);
		--bodyFont: var(--editorBodyFont, 'Poppins', sans-serif);
	}

	.disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	.panel-block-header {
		@include flex-between;
		margin-bottom: 16px;

		h5 {
			font-weight: 600;
			font-size: 14px;
		}

		span {
			color: var(--grayscale-100-white, #FFF);
			display: block;
			font-family: var(--editorHeadingFont);
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px;

		}

		button {
			background: none;
			margin: 0;
			padding: 0;
		}
	}

	.settings-section {
		margin-bottom: 16px;

		h6 {
			font-size: 12px;
			color: $med-light-gray;
			letter-spacing: 0.4px;
		}

		p {
			font-size: 14px;
			font-weight: 500;
		}

		.video-link {
			@include flex-between;
			align-items: flex-start;

			p {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				max-width: 80%;
				text-overflow: ellipsis;
				overflow: hidden;
				-webkit-line-clamp: 4;
			}

			button {
				margin: 0;
				margin-top: 8px;
				padding: 0;
				background-color: transparent;

				svg {
					path {
						transition: 150ms fill;
					}
				}

				&.hover {
					svg {
						path {
							fill: $white;
						}
					}
				}
			}
		}

		.firesides-hosts-settings-container {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		.tags-subcontainer {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
		}

		.tag-container {
			display: flex;
			padding: 0;
			max-width: 100%;
			flex-wrap: wrap;
			overflow-y: scroll;

			.tag {
				margin: 2px 2px;
				padding: 8px 16px;
				background-color: $dark-gray;
				border: 2px solid $border-gray;
				@include radius-full;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-size: 12px;
				font-weight: 500;
				color: $light-gray;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			.tag-container-show-more-btn {
				font-size: 12px;
				color: $cyan;
				padding: 0;
				margin: 2px 6px;
			}

			.tag-container-show-more-btn:hover {
				cursor: pointer;
			}

			&.breakout-room-name-tag-container {
				max-height: none;
				overflow: hidden;

				.breakout-room-name-tag {
					white-space: normal;
					word-wrap: break-word;
				}
			}
		}

		&:last-child {
			margin-bottom: 0;
		}

		.breakout-room-chip-container {
			display: flex;
			flex-wrap: wrap;
		}
	}

	.settings-section.categories {
		.tag {
			text-transform: none;
		}
	}

	.settings-type {
		background: $gray;
		@include radius-8;
		display: flex;
		align-items: center;
		padding: 8px;
		margin-bottom: 16px;

		.icon-gradient {
			background: $gradient;
			height: 24px;
			width: 24px;
			@include flex-center;
			@include radius-4;
			margin-right: 8px;
		}

		.icon-greenroom {
			background: $greenroom;
			height: 24px;
			width: 24px;
			@include flex-center;
			@include radius-4;
			margin-right: 8px;

			img {
				width: 16px;
			}
		}

		label {
			font-size: 14px;
			font-weight: 500;
			color: white;
			margin: 0
		}
	}

	&#layout-theme {
		svg {
			height: 32px;
			width: 32px;
		}
	}

	&#layout-page-style {
		svg {
			height: 72px;
			width: 144px;
		}
	}

	&#layout-chat-type {
		svg {
			height: 43px;
			width: 89px;
		}
	}

	&#layout-chat-style {
		svg {
			height: 80px;
			width: 140px;
		}
	}

	.layout-button-group {
		display: grid;
		gap: 8px;
		justify-content: space-between;
		grid-template-columns: 1fr 1fr;

		&.three-columns {
			grid-template-columns: 1fr 1fr 1fr;
		}

		.layout-tabs-button-text {
			font-size: 11px;
			color: $border-light-gray;
		}

		.lemonade-wrapper {
			width: 100%;
			border-radius: 12px;
			padding: 3px;

			&.active {
				background: $bl-lemonade;
			}

			&.disabled {
				background: none;
			}
		}

		button {
			border-radius: 10px;
			padding: 5px;
			background-color: #3C3A42;
			flex-direction: column;
			gap: 8px;
			margin-right: 0px;
			width: 100%;

			svg {
				margin-right: 0;
			}


			&.layout-tabs-button {

				&.active {

					&.disabled {
						outline: none;
					}

					// To keep the center black while active
					#black-triangle {
						fill: $gray-800;
					}

					#grey-svg {
						fill: $alt-gray;
					}

					.layout-tabs-button-text {
						color: $light-gray;
					}
				}
			}
		}
	}

	label {
		display: inline-block;
		margin-bottom: 5px;
		font-size: 12px;
		line-height: 16px;
		font-weight: 500;
		color: $border-light-gray;
	}


	#option-chat-approval {
		.smart-tooltip {
			z-index: 5 !important;
		}
	}
}
