.session-editor-absolute-container,
.admin-theme-panel {
	width: 100%;

	&.registration-modal {
		height: 100%;
		width: inherit;

		.registration-form-container {
			z-index: 1;
		}
	}

	&.reg-image-overlay.has-no-image {
		.registration-heading-container .registration-background-video {
			filter: grayscale(1);
		}

		.registration-heading-container::after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(var(--accentColor-rgb), 0.8);
			mix-blend-mode: lighten;
		}
	}

	.reveal-description,
	.play-pause-toggle,
	.streams-action-button,
	.control-button,
	.icon-toggle-label {
		svg,
		svg * {
			pointer-events: none; // allows the label to be clickable via click.hooks.ts when rendering in admin
		}
	}
}
