@import "./src/scss/deps";
@import "./src/scss/base/colors";

.tab-filter {
	display: flex;
	gap: 4px;
	margin-bottom: 16px;
	flex-wrap: wrap;
	margin-top: 5px;

	.module-tab {
		position: relative;

		.lemonade-wrapper {
			border-radius: 100px;
			padding: 2px;

			&.selected {
				background: $bl-lemonade;
			}
		}

		.tab-actions-container {
			position: fixed;
			display: flex;
			opacity: 0;
			visibility: hidden;
			z-index: 1;
		}

		.delete-icon,
		.edit-icon {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			border: none;
			background-color: $gray-700;
			padding: 0;
			width: 25px;
			height: 25px;
		}

		&.editable-tab {
			&:hover {
				.tab-actions-container {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		.tab-name-input {
			input.evt-field-input {
				font-size: 11px;
				line-height: 16px;
				font-weight: 600;
				letter-spacing: 0.22px;
				height: 32px;
				padding: 0 14px;
				color: $gray-300;
				background-color: $gray-1000;
				border: 2px solid $gray-700;
				white-space: nowrap;
			}
		}
	}

	.lemonade-wrapper.input {
		background: $bl-lemonade;
		border-radius: 100px;
		padding: 2px;

		> .new-tab-name-input > input {
			border-color: $gray-1000;
		}
	}

	.new-tab-name-input.field-group.text.normal.new-tab-name-input {
		&.saving {
			opacity: 0.8;
		}

		> input.evt-field-input {
			font-size: 11px;
			line-height: 16px;
			font-weight: 600;
			letter-spacing: 0.22px;
			height: 32px;
			padding: 0 8px 0 14px;
			color: $white;
			background-color: $dark-gray;
			outline: none;
			border-radius: 32px;
			font-family: inherit;
			width: auto;
			display: flex;
			align-items: center;
			transition: 150ms border-color;

			span {
				outline: none;
				border: none;
				white-space: nowrap;
			}

			&.exists {
				border-color: $rose;
			}
		}
	}

	p {
		@include get-admin-typography(label-2);
		height: 32px;
		padding: 0 14px;
		color: $white;
		background-color: $dark-gray;
		border: 2px solid $border-gray;
		outline: none;
		border-radius: 32px;
		font-family: inherit;
		width: auto;
		display: flex;
		align-items: center;
		border-color: $cyan;
		transition: 150ms border-color;

		span {
			outline: none;
			border: none;
			white-space: nowrap;
		}

		&.saving,
		&.exists {
			opacity: 0.8;
		}

		&.exists {
			border-color: $rose;
		}
	}

	button {
		@include get-admin-typography(label-2);
		height: 32px;
		padding: 0px 14px;
		color: $med-light-gray;
		background-color: $dark-gray;
		border: 2px solid $border-gray;
		white-space: nowrap;
		margin: -2px;

		&.add {
			background-color: $gray-600;
			border-color: $gray-600;
			color: $gray-200;

			svg {
				position: relative;
				top: 2px;

				path {
					fill: $gray-200;
				}
			}
		}

		&:hover {
			color: $white;
		}

		&.selected {
			height: 28px;
			color: $white;
			border: none;
			margin: 0px;
		}

		&.evt-button.secondary.btn-sm.selected {
			height: 40px;
		}
	}
}

.delete-custom-tab-modal.modal-wrapper {
	.modal.normal {
		.modal-footer {
			button {
				&.confirm-delete-custom-tab-btn {
					background-color: $red;

					&:hover {
						background-color: $rose;
					}
				}
			}
		}
	}
}
