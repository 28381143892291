@import "../../../scss/deps";

.tab-filter:not(.panel-tab-filter) {
	display: flex;
	flex-wrap: nowrap;
	margin-bottom: 20px;
	gap: 10px;
	padding: 5px 20px 0 20px;
	overflow-x: scroll;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	>div {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	button.evt-button.secondary.btn-sm {
		text-transform: none;
		color: var(--accentColorOnSessionBackgroundAndAccent20PercentBlend);
		transition: background-color 200ms ease-out;
		border: 2px solid transparent;
		margin: 0;
		font-family: var(--bodyFont);

		&:active:not(.disabled),
		&:hover:not(.disabled) {
			opacity: 1;
			background-color: rgba(var(--accentColor-rgb), 0.3);
		}

		&:focus:not(.disabled),
		&.selected {
			color: var(--accentColorOnSessionBackgroundAndAccent20PercentBlend);
			background-color: rgba(var(--accentColor-rgb), 0.2);
			border-color: var(--accentColor);
		}
	}
}
