@import "./src/scss/deps";

.sub-panel-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: $gray-900;
	z-index: 3;

	.sub-panel-header {
		justify-content: center;
		height: 82px;
		display: flex;
		flex-direction: column;
		padding: 0 24px;

		.title-left {
			display: flex;
			align-items: center;

			h6 {
				margin: 0;
			}

			.no-style {
				margin: 0;
				padding: 0;
				margin-right: 8px;
			}
		}
	}

	.panel-body-v2 {
		flex: 1;
		overflow-y: auto;
		overflow-x: hidden;
	}
}