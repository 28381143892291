@mixin mobileBaseResponsive($size) {
	@if $size =="mobile" {
		.about-us {
			.module-container {
				padding: 50px 25px;
			}

			.about-us-title h2 {
				font-size: 35px;
			}

			&.about-us-standard,
			&.about-us-standard_flip,
			&.about-us-standard_full,
			&.about-us-standard_full_flip {
				.about-us-gallery {
					height: 265px;
				}
			}

			&.about-us-standard,
			&.about-us-standard_flip {
				.about-us-gallery {
					gap: 5px;
				}
			}

			&.about-us-standard_full,
			&.about-us-standard_full_flip {
				.module-container {
					padding-top: 50px;
					padding-bottom: 50px;
				}

				.about-us-text {
					padding-right: 25px;
					padding-left: 25px;
				}
			}

			&.about-us-center,
			&.about-us-center_full,
			&.about-us-center_split {
				.about-us-gallery {
					&-2 {
						height: 200px;
					}
				}
			}

			&.about-us-center,
			&.about-us-center_full {
				.about-us-gallery {
					&-3 {
						grid-template-rows: repeat(3, 130px);
						grid-template-columns: auto;
						height: auto;
					}
				}
			}

			&.about-us-center,
			&.about-us-center_split {
				.about-us-gallery {
					gap: 5px;
				}
			}

			&.about-us-center {
				.about-us-gallery-4 {
					grid-template-columns: repeat(14, 1fr);
					height: 265px;

					img {
						&:first-child {
							grid-column: 1 / span 9;
						}

						&:nth-child(2) {
							grid-column: 10 / span 5;
						}

						&:nth-child(3) {
							grid-column: 1 / span 5;
						}

						&:last-child {
							grid-column: 6 / span 9;
						}
					}
				}
			}

			&.about-us-center_full {
				.about-us-text {
					padding-right: 25px;
					padding-left: 25px;
				}

				.about-us-gallery-4 {
					grid-template-columns: repeat(19, 1fr);
					height: 260px;

					img {
						&:first-child {
							grid-column: 1 / span 12;
						}

						&:nth-child(2) {
							grid-column: 13 / span 7;
						}

						&:nth-child(3) {
							grid-column: 1 / span 7;
						}

						&:last-child {
							grid-column: 8 / span 12;
						}
					}
				}
			}
		}

		.main-event-banner {

			// min-height: 0;
			.module-container {
				padding: 50px 25px;
			}

			.banner-title h1 {
				font-size: 40px;
			}

			.banner-title-container {
				height: 300px;
			}

			&.main-event-banner-standard,
			&.main-event-banner-right_full,
			&.main-event-banner-left_small,
			&.main-event-banner-right_small {
				.banner-preview-container {
					height: 300px;
				}
			}

			&.main-event-banner-standard,
			&.main-event-banner-right_full {
				.banner-title-container {
					padding: 50px 25px;
				}
			}

			&.main-event-banner-left_small,
			&.main-event-banner-right_small {
				.banner-title-container {
					padding-bottom: 50px;
				}
			}
		}

		.speakers {
			.module-container {
				padding: 50px 25px;
			}

			.speakers-custom_heading {
				font-size: 12px;
			}

			.speakers-title h1 {
				font-size: 25px;
				line-height: 30px;
			}

			.speakers-description {
				font-size: 16px;
			}

			.speaker {
				.speaker-name {
					margin-top: 15px;
					font-size: 20px;
					line-height: 25px;
				}

				.speaker-job-title {
					font-size: 16px;
				}

				.social-links {
					margin-top: 10px;
				}
			}

			&.speakers-standard,
			&.speakers-staggered,
			&.speakers-small_horizontal,
			&.speakers-small_vertical,
			&.speakers-long_card {
				.speakers-list {
					margin: 40px 0 -25px;
				}

				.speakers-column {
					flex-basis: 100%;
					width: 100%;
					padding: 0 0 25px;
				}
			}

			&.speakers-staggered,
			&.speakers-small_horizontal {
				.speaker {
					max-width: 100%;

					img {
						width: calc(50% - 15px);
					}

					&:nth-child(even) {
						flex-direction: row;

						.speaker-name-container {
							margin: 0 0 0 15px;
						}
					}
				}

				.speaker-name-container {
					min-width: 0;
					margin-left: 15px;
				}

				.speaker-name {
					display: inline-block;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					margin-top: 0;
				}

				.speaker-about {
					@include line-clamp(2);
				}
			}

			&.speakers-staggered {
				.module-container {
					padding: 50px 25px;
				}

				.speakers-column {
					&:nth-child(even) {
						.speaker {
							.speaker-name-container {
								margin: 0 0 0 16px;
							}
						}
					}
				}

				.speaker-name-container {
					margin: 0 0 0 16px;
				}
			}

			&.speakers-solid {
				.speakers-list {
					grid-template-columns: 1fr;
					margin-top: 40px;
				}
			}

			&.speakers-small_vertical {

				.speaker-name,
				.speaker-job-title,
				.speaker-about {
					text-align: left;
				}

				.social-links {
					justify-content: flex-start;
					margin-left: 0;
				}
			}

			&.speakers-long_card {

				.speakers-title,
				.speakers-title h1 {
					text-align: left;
				}

				.speaker {
					height: 325px;
				}

				.speaker-name {
					padding: 20px 20px 0;
				}

				.speaker-job-title {
					padding: 0 20px;
					margin-bottom: 20px;
				}
			}
		}

		.agenda {
			padding-top: 24px;

			.module-container {
				padding: 50px 25px;
			}

			.upper-agenda-title p {
				font-size: 12px;
			}

			.agenda-title h1 {
				font-size: 35px;
				line-height: 40px;
			}

			.agenda-sessions-header {
				margin-bottom: 15px;

				.agenda-sessions-header-title {
					@include get-typography(h4, (font-weight));
				}

				&.agenda-broadcast-sessions-header {
					.search-input {
						flex: 1 0 0;
						min-width: 50%;
						width: auto;
					}

					.days-filter {
						flex: 0 0 auto;
						width: fit-content;
					}
				}
			}

			.agenda-filters {
				flex-wrap: wrap;

				.search-input {
					max-width: none;

					&:focus-within {
						max-width: none;
					}
				}

				.new-dropdown {
					flex: 1 0 calc(50% - 5px);
				}

				.evt-select {
					margin: 10px 10px 0 0;

					&+.evt-select {
						margin-left: 0;
					}
				}
			}

			.agenda-sessions-list {
				display: block !important;
			}

			.agenda-show-more-wrapper {
				margin-top: 25px;
			}

			&.agenda-standard {
				.agenda-session-card {
					padding: 72px 20px 20px;
				}
			}

			.agenda-featured-container.agenda-featured-container,
			&.agenda-standard,
			&.agenda-contained_card,
			&.agenda-ltr,
			&.agenda-ltr_even_split,
			&.agenda-vertical_card,
			&.agenda-vertical_card_with_featured,
			&.agenda-standard_no_image,
			&.agenda-contained_card_no_image {
				.agenda-session-card {
					display: block;

					&+.agenda-session-card {
						margin-top: 20px;
					}
				}
			}

			&.agenda-contained_card,
			&.agenda-ltr,
			&.agenda-ltr_even_split,
			&.agenda-vertical_card,
			&.agenda-vertical_card_with_featured,
			&.agenda-standard_no_image,
			&.agenda-contained_card_no_image {
				.agenda-session-card-buttons {
					.primary {
						flex-grow: 1;
					}
				}
			}

			&.agenda-ltr,
			&.agenda-ltr_even_split,
			&.agenda-contained_card {
				.agenda-session-card {
					.agenda-session-card-tags-wrapper {
						top: 15px;
						left: 15px;
					}
				}

				.agenda-session-card-image {
					margin-bottom: 20px;
				}
			}

			&.agenda-standard,
			&:not(.new-event) .agenda-featured-container.agenda-featured-container {
				.agenda-session-card {
					display: flex;
				}

				.agenda-session-card-tags-wrapper {
					top: 20px;
				}
			}

			&:not(.new-event) .agenda-featured-container.agenda-featured-container,
			&.agenda-standard {
				.agenda-session-card {
					min-height: 355px;
				}

				.agenda-session-card-title {
					white-space: normal;
				}

				.agenda-session-card-buttons {
					flex-direction: column;
					align-items: stretch;
					width: 100%;
				}
			}

			&.agenda-ltr,
			&.agenda-ltr_even_split {
				.agenda-session-card-image {
					max-width: 100%;
					width: 100%;
				}

				.agenda-session-card-content {
					display: block;
					padding-left: 0;
				}

				.agenda-session-card-timestamp {
					margin-bottom: 10px;
				}

				.agenda-session-card-description {
					display: -webkit-box;
					line-clamp: 4;
					-webkit-line-clamp: 4;
				}

				.agenda-session-card-buttons {
					margin-top: 20px;
				}
			}

			&.agenda-contained_card {
				.agenda-session-card {
					padding: 0;
					aspect-ratio: auto;
				}

				.agenda-session-card-image {
					position: relative;
					aspect-ratio: 16 / 9;

					&::before {
						content: none;
					}
				}

				.agenda-session-card-title {
					margin-bottom: 10px;
					color: inherit;
				}

				.agenda-session-card-description {
					@include line-clamp(4);
				}
			}

			&.agenda-vertical_card {
				.agenda-session-card:not(.featured-card) {
					.agenda-session-card-content {
						display: block;
					}

					.agenda-session-card-tags-wrapper {
						top: 15px;
					}

					.agenda-session-card-title {
						margin-bottom: 10px;
					}
				}
			}

			&.agenda-standard_no_image,
			&.agenda-contained_card_no_image {
				.agenda-session-card {
					padding: 20px;
				}
			}

			&.agenda-standard_no_image {
				.agenda-session-card-image {
					.agenda-session-card-tags-wrapper {
						margin-bottom: 20px;
					}
				}

				.agenda-session-card-title {
					@include line-clamp(2);
					white-space: normal;
				}

				.agenda-session-card-description {
					line-clamp: 4;
					-webkit-line-clamp: 4;
				}
			}

			&.agenda-contained_card_no_image {
				.agenda-session-card-content {
					display: block;
				}

				.agenda-session-card-description {
					line-clamp: 4;
					-webkit-line-clamp: 4;
				}
			}

			&.new-event {
				.agenda-featured-container.agenda-featured-container {
					.agenda-session-card-image {
						max-width: 100%;
						width: 100%;
						margin-bottom: 20px;
					}

					.agenda-session-card-content {
						display: block;
						padding-left: 0;
					}

					.agenda-session-card-timestamp {
						margin-bottom: 10px;
					}

					.agenda-session-card-description {
						display: -webkit-box;
						line-clamp: 4;
						-webkit-line-clamp: 4;
					}

					.agenda-session-card-buttons {
						margin-top: 20px;

						.primary {
							flex-grow: 1;
						}
					}
				}
			}

			&.agenda-calendar {
				.list-view {
					.agenda-session-card {
						display: block;
						height: fit-content;
						margin-bottom: 10px;

						.agenda-session-card-image {
							border-radius: 0;
						}

						.agenda-session-card-content {
							display: block;
							padding: 15px;

							h3.agenda-session-card-title {
								@include line-clamp(2);
								white-space: normal;
							}

							.agenda-session-card-description {
								margin-bottom: 0;
								line-clamp: 3;
								-webkit-line-clamp: 3;
							}

							.agenda-session-card-tags {
								margin-top: 10px;
							}
						}

						.agenda-session-card-buttons {
							padding: 0 15px 15px;
							width: calc(100% - var(--customContainerBorderRadius, 12px));
							margin: 0 auto;

							.primary {
								flex-grow: 1;
								order: -1;
							}
						}
					}
				}
			}
		}

		.registered-main-content,
		.marketing-page {
			.agenda {
				.agenda-sessions-header {
					.evt-select {
						display: none;
					}
				}
			}
		}

		.products {
			.products-top {
				flex-wrap: wrap;
				margin-bottom: 25px;

				.filter-dropdown {
					margin-left: 0;
					min-width: initial;
				}
			}

			.product-list {
				width: 100%;
			}

			.dropdown-container {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 15px;
				width: 100%;
			}

			.products-custom-heading p {
				font-size: 12px;
			}

			.evt-select {
				width: 100%;

				&+.evt-select {
					margin-left: 0;
				}
			}

			&.products-standard {
				.product-list {
					grid-template-columns: 1fr;
				}
			}

			&.products-grid {
				.module-container {
					.product-list {
						.product {
							display: block;
							height: 545px;
							width: 100%;
						}
					}
				}
			}

			&.products-grid,
			&.products-vertical_list {
				.product {
					display: flex;
					flex-direction: column;
				}
			}

			&.products-standard,
			&.products-grid,
			&.products-vertical_list {
				.module-container {
					padding: 50px 25px;

					.product-list {
						gap: 15px;

						.product {
							height: auto;
							width: 100%;

							.slick-slider img {
								width: 100%;
							}
						}
					}
				}

				.product-item-image-container {
					height: 270px;
					margin-bottom: 15px;
					width: calc(100vw - 50px);
				}

				.product-item-content {
					align-self: auto;
					max-width: calc(100vw - 50px);

					.evt-button {
						width: 100%;
					}
				}

				.product-item-description {
					min-height: 50px;
					@include line-clamp(2);

					&.increase-description {
						line-clamp: 2;
						-webkit-line-clamp: 2;
					}
				}
			}

			&.editor-wrapper {
				.product-item-content {
					max-width: 323px;
				}
			}
		}

		.session-module.products.products-vertical_list.products-horizontal-tabs,
		.session-module.products {
			.product-list {
				.product {
					display: block;
					padding: 0;
					width: 325px;
					height: 515px;
					background-color: transparent;

					.product-item-image-container {
						width: 325px;
						height: 270px;
					}

					.product-item-content {
						padding: 0;
					}
				}
			}
		}

		.videos {
			.module-container {
				padding: 50px 25px;
			}

			.video-title h2 {
				font-size: 25px;
				line-height: 30px;
			}

			.video-custom-heading p {
				font-size: 12px;
			}

			&.videos-standard,
			&.videos-wide,
			&.videos-full {
				.slick-slider {
					.slick-slide {
						height: 240px;
					}
				}
			}

			&.videos-standard {
				.module-container {
					padding-right: 25px;
					padding-left: 25px;
				}
			}

			&.videos-wide {
				.module-container {
					padding: 50px 0;
				}

				.video-title-container {
					padding-right: 25px;
					padding-left: 25px;
				}
			}

			&.videos-full {
				.module-container {
					padding: 50px 0;
				}
			}

			&.videos-ltr {
				.slick-slider {
					.slick-list {
						margin-right: -7px;
						margin-left: -7px;
					}

					.slick-slide {
						width: 268px;
						height: 198px;
						margin-right: 7px;
						margin-left: 7px;
					}
				}
			}
		}

		.sponsors {

			.sponsors-title,
			.sponsors-title h2 {
				font-size: 25px;
				line-height: 40px;
			}

			&:not(.sponsors-standard) {
				.sponsors-description {
					display: block;
					margin-bottom: 20px;
				}
			}

			.sponsors-custom-heading p {
				font-size: 12px;
			}

			.sponsors-text-wrapper {
				width: 100%;
			}

			.sponsor-card-image {
				height: 105px;
			}

			.sponsor-cards-container {
				gap: 20px 15px;
				margin-top: 30px;
			}

			&.sponsors-standard,
			&.sponsors-card_full,
			&.sponsors-card_short,
			&.sponsors-split {
				.module-container {
					padding: 50px 25px 55px;
				}

				.sponsor-cards-container {
					grid-template-columns: 1fr;
					gap: 15px;
					margin-top: 20px;
				}
			}

			&.sponsors-card_short,
			&.sponsors-split {
				.sponsor-card {
					padding: 30px 40px;
					width: 100%;
				}
			}

			&.sponsors-standard {
				.sponsor-card {
					width: calc(100% / 2 - 15px);
				}

				.sponsor-cards-container {
					gap: 20px 15px;
				}
			}

			&.sponsors-card_full {
				.sponsor-card {
					padding: 30px;
				}
			}

			&.sponsors-card_short {
				.sponsors-description {
					margin-bottom: 20px;
				}
			}

			&.sponsors-split {
				.sponsors-text-wrapper {
					width: 100%;
					margin-right: 0;
					text-align: center;
				}
			}
		}

		.accordion {
			.module-container {
				padding: 50px 25px;
			}

			.accordion-custom_heading {
				font-size: 12px;
			}

			.accordion-description {
				font-size: 16px;
			}
		}

		//NOTE: When consolidating styling, this was original under the tablet mixin but it was inline with the break max
		// for mobile here
		.breakout-session-banner {
			min-height: 400px;

			.breakout-session-banner-header-container,
			.breakout-session-banner-container {
				padding-right: 75px;
				padding-left: 75px;
			}

			.breakout-session-banner-header-container {
				padding-top: 8px;
				padding-bottom: 8px;
			}

			.breakout-session-banner-header {
				p {
					@include text-ellipsis;
				}

				img {
					margin-right: 8px;
				}
			}

			.breakout-session-banner-header-button {
				padding: 10px;
			}

			.breakout-session-banner-header-info {
				min-width: 0;
			}

			.breakout-session-banner-container {
				padding-bottom: 25px;
			}

			.breakout-session-banner-title {
				margin: 15px 0 30px;
			}

			.breakout-session-banner-buttons {
				gap: 15px;
				flex-wrap: wrap;

				.evt-button {
					width: 100%;
				}
			}

			.breakout-session-add-calendar {
				display: inline-flex;
				padding: 15px;

				.evt-button {
					padding: 12px;
				}

				.breakout-session-add-calendar-title {
					font-size: 16px;
				}
			}

			.breakout-session-add-calendar-content {
				margin-right: $ev-gap-x3;
			}
		}

		.live-session-banner {
			.module-container {
				padding-right: 25px;
				padding-left: 25px;
				padding-bottom: 25px;
			}
		}

		.marketing-page-footer {
			.footer-container {
				padding: 18px 24px 36px;
			}

			.footer-left {
				flex-basis: 100%;
				text-align: center;

				&.hide-mobile {
					display: none;
				}
			}

			.footer-right {
				flex-basis: 100%;
				margin-top: 35px;
				text-align: center;
				display: flex;
				justify-content: center;
			}

			.footer-nav {
				flex-direction: column;
				margin-top: 35px;

				.footer-nav-item {
					line-height: 20px;
					text-align: center;

					&:not(:last-child) {
						margin-bottom: 18px;
					}
				}
			}

			.footer-copyright {
				padding: 10px 0 15px;
			}

			&.#{$ev-template-fun} {
				.footer-container {
					&::before {
						margin-bottom: 36px;
					}
				}
			}
		}

		.people-container {
			.people-title {
				margin-bottom: 30px;
			}

			.people-content {
				grid-template-columns: repeat(1, 1fr);
				gap: 15px;
			}
		}

		.call-out {
			&.call-out-standard {
				.module-container {
					padding: 50px 25px;
				}

				.call-out-inner {
					padding: 40px 16px;
				}
			}

			&.call-out-ltr {
				.module-container {
					flex-direction: column;
				}

				.call-out-inner {
					align-items: center;
					height: auto;
					padding: 50px 25px;
					text-align: center;
					width: 100%;
				}

				.call-out-image {
					height: 375px;
					width: 100%;
				}
			}
		}

		.hide-x-overflow-mobile {
			overflow-x: hidden;
		}

		.cookie-banner-container {
			display: block;
			width: 618px;

			.cookie-banner-actions {
				justify-content: center;

				button {
					width: calc(50% - 8px);

					&.learn-more {
						margin-right: 15px;
					}
				}

				svg {
					position: absolute;
					top: 15px;
					right: 25px;
					width: 20px;
				}
			}
		}

		.blank-section {
			.module-container {
				padding: 50px 25px;
			}
		}

		.video-player-v2-wrapper {
			.video-controls-layer {
				.video-controls-wrapper {
					.upper {
						pointer-events: none;
					}

					&.paused,
					&.hover {
						.upper {
							pointer-events: auto;
						}
					}

					.lower {
						.video-metadata {
							transform: translateY(0);

							.video-meta-content {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
