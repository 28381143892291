.custom-section-modal {
	ul {
		list-style: none;
		padding: 0;
		.basic-card {
			margin-bottom: 8px;
			border: 1px solid transparent;
			
			button {
				width: 100%;
				text-align: left;

				&.custom-section {
					background-color: transparent;
				}
			}
		}
		li.basic-card.active {
			border: 1px solid cyan;
		}
	}
	.custom-section-layouts {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}