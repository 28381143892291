@import './src/scss/deps';

.error-boundary-list {
	background-color: black;

	.collapsing-list-header {
		padding: 4px;
	}

	.collapsing-list-contents {
		:first-child {
			display: flex !important;
			flex-direction: column;
		}

		.basic-card {
			border-radius: 0;
			margin: 2px;
			color: white;
		}
	}
}

.error-boundary-fallback {
	width: 100%;
	min-height: 185px;
	background-color: var(--containerColor);
	display: flex;
	align-items: center;
	justify-content: center;

	.error-message-container {
		width: 100%;
		max-width: 1440px;
		min-height: 140px;
		border: 1px solid var(--bodyTextColor);
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 40px;
		color: var(--bodyTextColor);
		border-radius: 12px;

		.icon-container {
			width: 80px;
			height: 80px;
			border-radius: 100px;
			background-color: rgba(var(--bodyTextColor-rgb), .1);
			@include flex-center;

			svg path {
				fill: rgba(var(--bodyTextColor-rgb), .5)
			}
		}

		.text-container {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			margin-left: 25px;
			white-space: nowrap;
		}
	}

	// fallback specific styling
	&.Homepage-marketing-nav,
	&.Agenda-page-marketing-nav,
	&.Session-marketing-nav {
		width: unset;
		align-items: flex-start;

		.error-message-container {
			width: 96px; // same as side nav
			height: 100%;
			flex-direction: column;
			position: relative;
			border-radius: 0;
			border-right: 1px solid var(--bodyTextColor);

			.text-container {
				display: none;
			}
		}
	}

	&.On-demand-or-embed-player,
	&.Live-player,
	&.Fireside-player {
		height: 100%;

		.error-message-container {
			height: 100%;
			border-radius: 0;
			border-right: 1px solid var(--bodyTextColor);
			margin: 0;
		}
	}

	&.Live-chat-not-overlay {
		width: unset;
		background-color: var(--containerColor);

		.error-message-container {
			border-radius: 0;
			height: 100%;
		}
	}

	&.General-registration-step,
	&.Profile-registration-step,
	&.Avatar-registration-step,
	&.Ticketing-registration-step {
		position: absolute;
		left: 0;
		top: 0;

		.error-message-container {
			border: 0;
			flex-direction: column;
			align-items: center;

			.text-container {
				margin-top: 25px;
				text-align: center;
				margin-left: 0;

				* {
					margin: auto;
				}
			}
		}
	}
}

.Limelight {
	.error-boundary-fallback {
		background-color: var(--user-mode-background);

		.error-message-container {
			background-color: inherit;
			color: var(--user-mode-color);

			.icon-container {
				background-color: inherit;

				svg {
					path {
						fill: var(--accentColor);
					}
				}
			}

			.text-container {
				white-space: unset;

			}
		}
	}
}

@mixin responsiveErrorBoundaryStyles($screenSize) {
	@if $screenSize =='tablet' {
		.error-boundary-fallback {

			&.Session-stream {
				position: fixed;
				top: 95px;
				z-index: 1;
			}

			&.Live-chat-overlay,
			&.Live-chat-not-overlay {
				display: none;
			}

			.error-message-container {
				margin: 16px;
				padding: 8px;
			}

			.error-message-container .text-container {
				margin-left: 8px;
				white-space: unset;
			}

			&.Homepage-marketing-nav,
			&.Agenda-marketing-nav,
			&.Session-marketing-nav {
				display: none;
			}
		}

	}
}

// tablet
@include media-query($med-down) {
	@include responsiveErrorBoundaryStyles('tablet')
}