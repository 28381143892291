@import "./src/scss/deps";

.registration-panel-content {
	max-height: calc(100vh - 270px);
	margin-bottom: 16px;
	padding: 0 16px;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.registration-option-v2-container {
	display: flex;
	gap: 0;
	align-items: center;
	transition: gap 0.2s ease-in-out;

	.drag-handle {
		width: 0;
		transition: width 0.2s ease-in-out 0.1s;
		cursor: grab;

		svg {
			width: 0;
		}
	}

	&:hover {
		gap: 4px;

		.drag-handle {
			width: 18px;

			svg {
				width: 18px;
			}
		}
	}

	&.expanded {
		.edit-registration-fields-v2 {
			.registration-question-label {
				@include line-clamp(100);
			}
		}

		.registration-preview {
			display: none;
		}

		cursor: default;
	}
}

.lemonade-wrapper {
	padding: 2px;
	background: none;
	border-radius: 12px;

	&.selected {
		background: $bl-lemonade;
	}
}

.registration-option-v2 {
	width: 100%;
	display: flex;
	padding: 16px;
	background-color: $gray-800;
	border-radius: 12px;
	gap: 12px;

	.registration-question-label {
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
		margin-bottom: 0px;
		max-width: 225px;

		@include line-clamp(1);
	}

	.registration-preview {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;

		.question-description {
			display: flex;
			gap: 4px;

			>* {
				font-size: 12px;
				color: $gray-300;
				font-weight: 500;
				line-height: 16px;
				letter-spacing: 0.24px;
			}

			> p {
				margin: 0;
			}
		}

		.registration-option-hover {
			display: none;
		}

		&:hover {
			.registration-option-hover {
				cursor: pointer;
				display: flex;
				gap: 20px;


				.registration-option-hover-icon.delete {
					svg path {
						transition: fill 0.1s ease-in-out;
					}

					&:hover {
						svg path {
							fill: $rose;
						}
					}
				}
			}
		}
	}

	.card-container {
		display: flex;
		width: 100%;

		.drag-icon {
			display: none;
		}

		.toggle-card {
			display: flex;

			justify-content: space-between;
			gap: 8px;

			&:not(.in-padded-container) {
				width: 100%;
			}
		}
	}
}
