@import "./src/scss/old-deps";
@import "./src/scss/deps";

.empty-state-panel-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: calc(100svh - 160px);
}

.editor-empty-panel {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
	text-align: center;
	justify-content: center;
	background-color: $gray-900;

	&.fade-enter {
		opacity: 1;
	}

	&.fade-exit {
		opacity: 0;
	}

	p {
		&:first-child {
			margin-top: -200px;
		}

		margin-bottom: 0;
	}

	.editor-empty-panel-text {
		max-width: 312px;
		margin-top: 8px;
		color: $med-light-gray;
	}

	.navigation-dropdown {
		margin-top: 16px;

		.navdrop-list {
			min-width: 160px;
		}
	}
}