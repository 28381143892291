@import "./src/scss/old-deps";
@import "./src/scss/deps";

@mixin meatballMenuButton {
	@include flex-center;
	height: 24px;
	width: 24px;
	padding: 0;
	margin-left: 4px;
	transition: 150ms background-color;

	.navdrop-title {
		line-height: 0;
	}

	&.open {
		background-color: $gray-600;
	}
}