@import "scss/deps.scss";

.fireside-host-banner {
	height: 60px;
	width: 100%;
	background-color: var(--user-mode-container-color);
	color: var(--user-mode-color);
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	padding: 0 20px;
	display: flex;

	.navigation-toggle {
		position: static;
	}

	button.live-button {
		padding: 5px 10px;
		font-weight: 600;
		font-size: 12px;
		background-color: var(--accentColor);
		transition: 150ms background-color;

		&.live {
			background-color: var(--user-mode-background-alt);
			color: var(--user-mode-color);
		}

		&:hover {
			background-color: $red;
			color: var(--dark-mode-color);
		}
	}

	.live-indicator {
		position: relative;
		opacity: 1;
		transform: translateX(0);
		transition:
			250ms opacity,
			150ms transform;
		background-color: var(--user-mode-background-alt);
		border-radius: 20px;
		color: var(--user-mode-color);
		font-size: 10px;
		font-style: normal;
		font-weight: 700;
		line-height: 15px;
		/* 150% */
		text-transform: capitalize;
		padding: 8px;
		margin-left: 8px;
		@include flex-center;
		content-visibility: visible;

		.live-dot {
			background: $red;
			height: 8px;
			width: 8px;
			border-radius: 50%;
			display: block;
			margin-left: 2px;
			margin-right: 4px;
			animation: 1500ms liveBlink infinite alternate linear;
		}
	}
}

.fireside-host-banner-wrapper {
	display: none;

	&.visible {
		display: flex;

		&+.video-player-v2-wrapper {
			top: 60px;
		}
	}
}