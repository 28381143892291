
@import '../../../scss/deps';

.switch {
	display: flex;
	align-items: center;
}

.ev-switch {
	display: inline-flex;
	align-items: center;
	position: relative;

	&.focused {

		.gutter {
			box-shadow: 0 0 0 2px rgba($gray-400, 0.2);
		}
	}

	&.label-before {
		flex-direction: row-reverse;

		.ev-switch-label {
			padding-right: $ev-gap-x4;
			padding-left: 0;
		}
	}

	.thumb {
		display: block;
	}

	.ev-switch-label {
		padding-left: $ev-gap-x4;
		font-size: 14px;
		line-height: 16px;
		font-weight: 500;
		color: $gray-100;
	}
}
