@keyframes animateIn {
	0% {
		opacity: 0;
		transform: translateX(-100px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.stagger-children {
	scrollbar-gutter: unset;

	&:not(.fades-enabled) {
		overflow: scroll;
		flex: 1;
	}

	&:not(.disable-animation) {
		.stagger-child {
			position: relative;
			opacity: 0;
			transform: translateX(-100px);
			animation-name: animateIn;
			animation-duration: 0.3s;
			animation-timing-function: ease;
			animation-delay: calc(0ms + var(--stagger-children-index) * 100ms);
			animation-fill-mode: forwards;
			animation-iteration-count: 1;

			&:hover {
				z-index: 1;
			}
		}
	}

	&.flex-grow {
		display: flex;
		flex-direction: column;
		flex: 1;
	}
}