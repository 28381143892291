.image-edit-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-top: 16px;

	.image-edit-left {

		button {
			font-weight: 500;
			color: $cyan;
			background: none;

			svg {
				margin-right: 4px;

				path {
					fill: $cyan;
				}
			}
		}
	}
}
