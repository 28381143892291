.navbar__legacy-menu {
	display: none;
}

.navbar__session.O2,
.navbar__session.Studio54,
.navbar__session.Studio,
.navbar__session.Apollo {
	.navbar__legacy-menu {
		display: block;
	}

	.navbar__legacy-menu-button {
		position: absolute;
		left: 40px;
		top: 0;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;

		svg path {
			fill: var(--background);
		}
	}

	&.navbar__open {
		.navbar__legacy-menu-button {
			z-index: 0;
		}
	}

	.session-stream-container {
		top: 0;
	}
}

.navbar.Studio {
	.navbar__profile-details-dropdown-placeholder {
		color: var(--background);
	}
}

@mixin navbarLegacyTablet {

	.navbar__session.O2,
	.navbar__session.Studio54,
	.navbar__session.Apollo {
		.navbar__content {
			display: flex;
			background: transparent;
			box-shadow: none;

			.navbar__logo {
				display: none;
			}
		}
	}
}

@mixin navbarLegacyMobile {

	.navbar__session.O2,
	.navbar__session.Studio54,
	.navbar__session.Apollo {
		.navbar__legacy-menu-button {
			display: none;
		}

		.navbar__content {
			height: 65px;
			display: flex;
			background: var(--background);
			box-shadow: none;

			.navbar__logo {
				display: none;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.live-event-container:not(.editor) {
		@include navbarLegacyTablet();
	}
}

@media screen and (max-width: 640px) {
	.live-event-container:not(.editor) {
		@include navbarLegacyMobile();
	}
}

.editor.tablet {
	@include navbarLegacyTablet();
}

.editor.mobile {
	@include navbarLegacyMobile();
}