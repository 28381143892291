
@import './src/scss/old-deps';
@import './src/scss/deps';

.add-passcode-list-modal {
    .label {
        margin-left: 16px;
        font-size: 11px;
        line-height: 16px;
        font-weight: 600;
        letter-spacing: 0.02em;
        color: $gray-400;
    }

    .add-audience-list-form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .field-group {
            width: 272px;

            input {
                height: 48px;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0.02em;
            }

            .select-container {
                border: 1px solid $gray-400;

                .select-dropdown {
                    left: 330px !important;
                    top: unset !important;
                }
            }
        }

        div[class$="-container"] {
            width: 272px;
            height: 48px;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.02em;
            color: $white;

            svg {
                path {
                    fill: $white;
                }
            }

            div[class$="-control"] {
                border: 1px solid $gray-400;
            }

            div[class$="-placeholder"] {
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                color: $white;
            }
        } 
    }

    .field-group.large-button {
        border: 1px dashed $gray-400;
        // background:
        //     linear-gradient($gray-1000 0 0) padding-box,
        //     linear-gradient(108.66deg, $magenta -55.58%, $yellow 69.07%, $greenroom 120.95%) border-box;
        // margin-top: 6px !important;
        
        .upload-primary-text {
            display: flex;
            align-items: center;
            height: 36px;
            margin-top: 10px;

            .plus {
                font-size: 20px;
                margin-right: 8px;
            }
        }

        label {
            color: $gray-100;
            margin-bottom: -25px;
            align-items: center;
            display: flex;
            flex-direction: column;

            span {
                background: linear-gradient(90deg, $yellow 0%, $magenta 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
            }
        }
        
        span {
            color: $gray-300;
        }
    }

    .download-template-button {
        height: 32px;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: $gray-200;
    }

    .download-template-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
    
        > p.info {
            font-size: 11px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.02em;
            color: $gray-400;
        }
    
        .download-template-button {
            white-space: nowrap;
            font-weight: bold;
        }
    }
}