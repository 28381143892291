@use "sass:color";
@import "./src/scss/old-deps";
@import "./src/scss/deps";

$backgroundColor: white;
$tabColor: var(--bodyTextColor);
$contentWidth: 432px;

.above-the-fold-layout {
	// --backgroundColor: #F5F7FF;
	// --containerColor: #FFFFFF;
	--above-the-fold-width: 432px;
	--above-the-fold-outline-color: 236 237 241;
	--above-the-fold-tabs-height: 60px;
	position: relative;
	min-width: var(--above-the-fold-width);
	max-width: var(--above-the-fold-width);
	width: var(--above-the-fold-width);
	background-color: var(--backgroundColor);
	transition:
		200ms min-width,
		200ms max-width,
		200ms width;

	.live-chat-header {
		display: none !important;
	}

	.active-bar {
		position: absolute;
		top: calc(var(--above-the-fold-tabs-height) + 1px);
		left: 0;
		width: 30px;
		height: 3px;
		background-color: var(--accentColor);
		border-radius: 10px;
		transition:
			left 200ms ease-in-out,
			width 200ms ease-in-out;
	}

	.tab-navigation {
		position: relative;
		display: flex;
		gap: 25px;
		width: 100%;
		margin: 0;
		padding: 0 20px 2px;
		list-style: none;
		opacity: 1;
		overflow-x: scroll;
		border-bottom: rgb(var(--above-the-fold-outline-color)) solid 1px;
		box-shadow: 1px 0 0 rgb(var(--above-the-fold-outline-color)) inset;
		transition: 150ms opacity;
		-webkit-user-select: none;
		/* Safari */
		-ms-user-select: none;
		/* IE 10 and IE 11 */
		user-select: none;
		/* Standard syntax */

		&::-webkit-scrollbar {
			display: none;
		}

		li {
			position: relative;
			display: flex;

			span {
				transition: 300ms color ease-in-out;
				animation-delay: 200ms;
			}
		}

		input {
			display: none;
		}

		label {
			display: flex;
			gap: 4px;
			justify-content: flex-start;
			align-items: center;
			height: var(--above-the-fold-tabs-height);
			font-size: 13px;
			line-height: var(--above-the-fold-tabs-height);
			font-weight: 600;
			white-space: nowrap;
			color: $tabColor;
			transition: color 150ms;
			cursor: pointer;

			svg {
				margin-right: 8px;

				path {
					fill: $tabColor;
					transition: fill 150ms;
				}

				&.noIcon {
					display: none;
				}
			}

			&:hover {
				color: var(--accentColor);

				svg {
					path {
						fill: var(--accentColor);
					}
				}
			}
		}
	}

	.page-container-body {
		width: var(--above-the-fold-width);
		height: calc(100% - var(--above-the-fold-tabs-height));
		opacity: 1;
		transition: opacity 150ms;
		gap: 8px;

		&.no-tabs {
			height: 100%;
		}

		&:has(.empty-module) {
			overflow-y: visible;
		}
	}

	.session-module,
	.editor-wrapper {
		padding: 0 !important;
		background-color: transparent;
	}

	.editor-wrapper {
		display: block;
	}

	&:not(.with-chat) {
		&>.page-container-body {
			border-left: $border-lightest-gray solid 1px;
			padding-top: 20px;
			overflow: auto;

			.page-container-body-inner {
				padding: 0 20px 20px 20px;
			}
		}
	}

	&.with-chat {
		.live-chat-container {
			border-left-color: $border-lightest-gray;
		}
	}

	&.chat-closed {
		min-width: 0;
		max-width: 0;
		width: 0;

		.tab-navigation {
			opacity: 0;
		}

		.page-container-body {
			opacity: 0;
		}
	}
}

.editor:not(.dark-mode),
.session:not(.dark-mode) {
	--modeBackgroundColor: white;
	--modeTabColor: #1a1a1f;
}

.dark-mode {
	$backgroundColor: $dark-gray;
	$tabColor: white;
	--modeTabColor: white;
	--modeBackgroundColor: #1a1a1f;

	.above-the-fold-layout,
	.breakout-session-above-the-fold {
		--above-the-fold-outline-color: #{color.red($border-gray) color.green($border-gray) color.blue($border-gray)};
		color: $white;
		background-color: $backgroundColor;

		.active-bar {
			background-color: var(--accentColor);
		}

		label {
			span {
				color: $tabColor;
			}

			svg {
				path {
					fill: $tabColor;
				}
			}

			:not(.solo-tab) {
				&:hover {
					span {
						color: var(--accentColor);
					}
				}
			}
		}

		li.solo-tab {
			input:checked+label>span {
				color: $tabColor;
			}
		}

		.tab-filter {
			button.evt-button.secondary {
				background-color: $gray;
				color: inherit;

				&:hover:not(.disabled):not(.selected) {
					background-color: $gray-hover;
				}

				&:focus:not(.disabled),
				&.selected {
					opacity: 0.8;
					color: inherit;
					background-color: rgba(var(--accentColor-rgb), 0.14);
					border-color: var(--accentColor);
				}
			}
		}

		.page-container-body {
			overflow: auto;
		}

		.page-container-body,
		.questions-container.session-module {
			background-color: $backgroundColor !important;
		}

		&:not(.with-chat) {
			&>.page-container-body {
				border-left: $border-gray solid 1px;
			}
		}

		&.with-chat {
			.live-chat-container {
				border-left-color: $border-gray;
			}
		}
	}
}

.editor {
	.above-the-fold-layout:not(.with-chat) {
		&>.page-container-body {
			padding-top: 20px;
			margin-top: 2px; // To account for the border so the top contend doesn't overflow (outline doesn't work here)
			overflow: scroll; // To make the bottom not of the border from getting clipped

			.page-container-body-inner {
				padding: 0 20px 20px 20px;
			}

			&:hover {
				.editor-wrapper {
					&::before {
						content: "";
						position: fixed;
						top: var(--above-the-fold-tabs-height);
						right: 0;
						bottom: 0;
						width: calc(var(--above-the-fold-width) - 4px);
						border: 2px solid $cyan;
						pointer-events: none;
					}
				}

				.session-module-controls {
					.button-container {
						opacity: 1;
					}
				}

				&:hover:has(.container:hover) {
					.session-module-controls {
						opacity: 0;
					}
				}

				&:hover:has(.surveys:hover) {
					.session-module-controls {
						opacity: 1;
					}
				}
			}

			.editor-wrapper {
				&.editing {
					&::before {
						content: "";
						position: fixed;
						top: var(--above-the-fold-tabs-height);
						right: 0;
						bottom: 0;
						width: calc(var(--above-the-fold-width) - 4px);
						border: 2px solid $cyan;
						pointer-events: none;
					}

					&::after {
						@include get-typography(caption-2);
						content: attr(data-edit-text);
						position: fixed;
						top: var(--above-the-fold-tabs-height);
						right: var(--above-the-fold-width);
						padding: 4px 8px;
						color: $off-black;
						background-color: $cyan;
						border-bottom-right-radius: 2px;
						transform: translateX(100%);
					}
				}
			}
		}
	}
}
