@import "../../../deps.scss";

button {
	@include flex-center(inline-flex);

	&.round,
	&.no-style {
		svg {
			margin: 0;
		}
	}

	svg:not(:last-child) {
		margin-right: 8px;
	}

	&.full-width {
		width: 100%;
	}
}

.evt-button {
	@include flex-center(inline-flex);
	padding: 14px 24px;
	font-size: 15px;
	line-height: 25px;
	font-weight: 700;
	text-decoration: none;
	border: 1px solid transparent;
	border-radius: var(--customButtonBorderRadius, 100px);

	&.primary {
		color: var(--buttonTextColor, var(--headingTextColor));

		path {
			fill: var(--containerColor);
		}

		&.negative {
			color: var(--accentColor);
			background-color: var(--containerColor);
			border-color: var(--accentColor);

			path {
				fill: var(--accentColor);
			}

			&:active:not(.disabled),
			&:hover:not(.disabled) {
				color: rgba(var(--accentColor-rgb), 0.7);
				background-color: rgba(var(--containerColor-rgb), 0.7);
			}
		}

		&:not(.negative) {
			background-color: var(--accentColor);
		}

		&:active:not(.disabled),
		&:hover:not(.disabled) {
			background-color: rgba(var(--accentColor-rgb), 0.7);
		}

		&:focus:not(.disabled) {
			background-color: rgba(var(--accentColor-rgb), 0.8);
		}

		&.negative:focus {
			background-color: var(--containerColor);
		}
	}

	&.danger {
		color: var(--buttonTextColor, var(--headingTextColor));
		background-color: $primary-red;

		path {
			fill: currentColor;
		}

		&:active:not(.disabled),
		&:hover:not(.disabled) {
			background-color: rgba($primary-red, 0.7);
		}

		&:focus:not(.disabled) {
			background-color: rgba($primary-red, 0.8);
		}
	}

	&.secondary {
		color: var(--accentColor);
		background: none;

		path {
			fill: var(--accentColor);
		}

		&:active:not(.disabled),
		&:hover:not(.disabled) {
			opacity: 0.7;
		}

		&:focus:not(.disabled) {
			opacity: 0.8;
		}

		&.no-border {
			border-color: transparent;
		}
	}

	&:focus {
		box-shadow: none;
	}

	&.full-width {
		width: 100%;
	}

	&.disabled {
		opacity: 0.4;
		cursor: not-allowed;
	}

	&.tertiary {
		padding: 15px;
		color: var(--accentColor);
		background: none;
		border: none;

		&:active:not(.disabled),
		&:hover:not(.disabled) {
			opacity: 0.7;
		}

		&:focus:not(.disabled) {
			opacity: 0.8;
		}
	}

	&.outlined {
		color: $classic-gray-text;
		background: none;
		border-color: #d5d8e1;

		path {
			fill: currentColor;
		}

		&.accent {
			color: var(--accentColor);
			border-color: var(--accentColor);
		}
	}

	&.round {
		width: 55px;
		height: 55px;
		padding: 0;

		&.btn-sm {
			width: 40px;
			padding: 0;
		}
	}

	&.btn-sm {
		height: 40px;
		padding: 0 15px;
		font-size: 13px;
	}

	&.btn-xs {
		height: 30px;
		padding: 0 10px;
		font-size: 13px;
	}

	&.btn-font-tiny {
		font-size: 11px;
	}
}
