@import './src/scss/old-deps';
@import './src/scss/abstracts/colors';

.button-plain {
	display: flex;
	align-items: center;

	.button-plain-svg-container {
		display: flex;
		align-items: center;
		flex-direction: column;
		margin-right: 8px;
	}

	.right-icon {
		margin-right: 0px;
		margin-left: 8px;
	}
}