@import './src/scss/base/_colors';

@keyframes rainingStars {
	from {
		background-position: 0 0;
	}

	to {
		background-position: 0 100vh;
	}
}

.no-access-admin {
	position: fixed;
	z-index: 0;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	.admin-top-nav {
		padding-bottom: 12px;
	}

	.no-access-container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 100svh;
		background-size: 100vw 100vh;
		animation: rainingStars 10s linear infinite forwards;

		.primary-text {
			font-weight: 700;
			font-size: 30px;
			background: $bl-lemonade;
			width: fit-content;
			-webkit-background-clip: text;
			background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}
}