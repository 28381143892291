@import "../../../scss/deps";
@import "../../../scss/base/colors.scss";
@layer navDesktop, navTablet, navMobile;

@mixin navTop($inEditor) {
	position: relative;
	display: flex;
	flex-direction: column-reverse;

	.navbar__content {
		left: 0;
		right: 0;
		z-index: 1;
		box-shadow: 0 10px 40px rgba(126, 130, 134, 0.2);
		flex-direction: row;
		width: 100vw;
		width: 100svw;
		height: 80px;

		@if $inEditor {
			width: 100%;
		}
	}

	&.navbar__admin {
		.navbar__content {
			width: 100%;
		}
	}

	.navbar__upper {
		padding-top: 0;
		padding-left: 24px;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		display: flex;
		flex-direction: row;
	}

	.navbar__logo {
		position: relative;
		left: 24px;
		max-width: 140px;
		max-height: 100%;
		display: inherit;
		align-items: inherit;
		flex-direction: inherit;
	}

	.navbar__menu-middle {
		padding-top: 0;
		justify-content: center;
		align-items: center;
	}

	.navbar__profile {
		position: absolute;
		right: 22px;
		top: 0;
		bottom: 0;
	}

	.navbar__profile-details-dropdown-menu {
		top: 70px;
		bottom: auto;
		left: auto;
		right: 13px;
	}
}

.navbar__left {

	&:not(.navbar__session),
	&.navbar__open {
		&:not(.navbar__show_mobile) {
			.banner-announcement {
				width: calc(100% - var(--navbar-width, 95px));
				min-width: calc(100% - var(--navbar-width, 95px));

				.banner-announcement-text {
					max-width: calc(100% - 95px);
				}
			}
		}
	}
}

@mixin navbarDesktopOnly($inEditor) {
	.navbar__left:not(.navbar__hideable) {


		+div,
		+a {
			margin-left: var(--banner-width, 95px);
			margin-top: var(--banner-height, 0px);
		}
	}

	nav.navbar.navbar__sticky.navbar__admin {
		width: 0;
		min-height: 100%;
	}

	.navbar__hideable {
		.banner-announcement {
			left: 0;
			transition: left 0.2s;
			transition-timing-function: cubic-bezier(0, 0.2, 0.11, 1);
		}

		&.navbar__open:not(.navbar__top) {
			.banner-announcement {
				width: calc(100% - var(--navbar-width, 95px));
				min-width: calc(100% - var(--navbar-width, 95px));
				left: var(--navbar-width, 95px);
			}
		}
	}

	@layer navDesktop {
		.navbar__hideable {
			.navbar__content {
				transition: transform 0.2s;
				transition-timing-function: cubic-bezier(0, 0.2, 0.11, 1);
				position: absolute;
				top: 0;
				left: 0;

				@if not ($inEditor) {
					height: 100svh;
				}
			}

			&.navbar__top {
				.navbar__content {
					top: var(--banner-height, 0px);
				}
			}

			.banner-announcement {
				left: 0;
				transition: left 0.2s;
				transition-timing-function: cubic-bezier(0, 0.2, 0.11, 1);
			}

			&:not(.navbar__top) {
				.navbar__content {
					bottom: 0;
					width: var(--navbar-width);
					transform: translateX(calc(var(--navbar-width) * -1));
				}
			}

			&.navbar__top {
				.navbar__content {
					right: 0;
					height: 80px;
					transform: translateY(-80px);
				}
			}

			&.navbar__open {
				.navbar__content {
					transform: translateX(0);
				}

				.banner-announcement {
					left: var(--navbar-width, 95px);
				}
			}
		}
	}

	.navbar__menu__mobile_only {
		display: none;
	}
}

@mixin navbarTablet($inEditor) {
	.navbar {
		@layer navDesktop {
			@include navTop($inEditor);
		}
	}

	@layer navTablet {
		.navbar:not(.navbar__session) {

			+div,
			+a {
				margin-top: 80px;
			}

			&.navbar__admin,
			&.navbar__left {

				+div,
				+a {
					margin-top: calc(var(--banner-height, 0px) + 80px);
				}
			}
		}

		.navbar__content {
			position: absolute;
			padding: 13px 75px;
			height: 80px;
			width: 100vw;
			width: 100svw;
			top: var(--banner-height, 0px);

			@if $inEditor {
				width: 100%;
			}
		}

		.navbar__upper {
			&::before {
				content: "";
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				height: 100vh;
				width: 100vw;
				backdrop-filter: blur(10px);
				opacity: 0;
				pointer-events: none;
				transition: 150ms opacity;
			}
		}

		.banner-announcement {
			margin-left: 0;
		}

		.navbar__logo {
			left: initial;
			width: initial;
			position: initial;
			height: 60px;
		}

		.navbar__hideable {

			&.navbar__open,
			&.navbar__top {
				transform: none;
			}

			&.navbar__top {
				// top: -80px;

				.navbar__menu-middle {
					top: 70px;
				}
			}
		}

		.navbar__upper {
			padding-left: 80px;
		}

		.navbar__actions {
			height: 100%;

			display: flex;
			align-items: center;
			justify-content: center;
		}

		.navbar__profile {
			padding-right: 50px;
		}

		.navbar__menu-middle {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: fixed;
			top: 80px;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			background-color: var(--background);
			pointer-events: none;
			transition: 150ms transform, 150ms opacity;
			transform: translateY(80px);
			box-shadow: 0 -10px 40px rgba(126, 130, 134, 0.2);
		}

		.navbar__menu__mobile_only {
			display: inherit;
			flex-direction: inherit;
			align-items: inherit;
			column-gap: inherit;
		}

		.navbar__profile {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.navbar__profile-details-dropdown-placeholder {
			height: 36px;
			width: 36px;
		}

		.navbar__open {
			.navbar__content {
				z-index: 999;

			}

			.navbar__upper {
				&::before {
					opacity: 1;
				}
			}

			.navbar__menu-middle-close {
				display: block;
				position: absolute;
				top: -66px;
				left: 65px;

				svg {
					path {
						fill: var(--color);
					}
				}
			}

			.navbar__menu-middle {
				opacity: 1;
				pointer-events: all;
				transform: translateY(0);
			}

			&.navbar__session {
				.navbar__menu-middle-close {
					top: -59px;
					left: 13px;
				}
			}
		}
	}

	.navbar__session {
		.navbar__content {
			display: none;
		}
	}

	.navbar__mobile {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100vh;
		height: 100svh;
		width: 100vw;
		width: 100svw;
		background-color: var(--background);
		color: var(--color);
		display: flex;
		flex-direction: column;
		z-index: 999;
		padding: 0 95px;
		pointer-events: none;
		opacity: 0;
		transform: translateY(100%);
		transition: transform 250ms, opacity 150ms;

		@if $inEditor {
			width: 100%;
		}

		svg {
			path {
				fill: var(--icon-color);
			}
		}
	}

	.navbar__open {
		.navbar__mobile {
			pointer-events: all;
			opacity: 1;
			transform: translateY(0);
			transition: transform 150ms, opacity 250ms;
		}
	}

	.navbar__mobile-header {
		height: 80px;
		display: flex;
		align-items: center;
		gap: 8px;
	}

	.navbar__mobile-header-profile-card {
		color: var(--color);
		background: var(--container);
		border-radius: 12px;
		padding: 16px;
	}

	.navbar__mobile-header-profile-card-upper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 16px;


		&:hover {
			color: var(--accentColor);
			cursor: pointer;

			svg path {
				fill: var(--accentColor);
			}
		}
	}

	.navbar__mobile-header-profile-card-upper-name {
		pointer-events: none;
		user-select: none;

		label {
			font-size: 16px;
			line-height: 24px;
			font-weight: 700;
		}

		aside {
			font-size: 12px;
		}
	}

	.navbar__mobile-header-profile-card-lower {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 16px;
		border-top: 1px solid var(--border-color);
		margin-top: 16px;

		label {
			font-size: 12px;
		}
	}

	.navbar__mobile-header-menu {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 24px 16px 0;
		// full height minus header and profile card
		max-height: calc(100vh - 45px - 125px);
		max-height: calc(100svh - 45px - 125px);
		overflow-y: auto;

		.side-nav-link .navigation-link {
			display: flex;
			flex-direction: inherit;
			align-items: center;
			justify-content: center;
			font: 600 14px/24px var(--headingFont);
			color: var(--color);
			text-decoration: none;
			transition: color 150ms;
			gap: 16px;

			img {
				max-height: 24px;
				max-width: 24px;
			}

			svg {
				path {
					fill: var(--color);
					transition: fill 150ms;
				}
			}

			&:hover,
			&.active {
				color: var(--accentColor);

				svg {
					path {
						fill: var(--accentColor);
					}
				}
			}
		}

		.side-nav-link {
			margin-top: 16px;
			margin-bottom: 16px;

			.navigation-link {
				display: flex;
				flex-direction: inherit;
				align-items: center;
				justify-content: space-between;
				font: 600 14px/24px var(--headingFont);
				color: var(--color);
				text-decoration: none;
				transition: color 150ms;
				width: 100%;

				.navigation-link-body-left,
				.navigation-link-body-right {
					gap: 16px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			svg {
				path {
					fill: var(--color);
					transition: fill 150ms;
				}
			}

			&:hover,
			&.active {
				color: var(--accentColor);

				svg {
					path {
						fill: var(--accentColor);
					}
				}
			}

			&.full-width {
				width: 100%;
				display: flex;
				justify-content: space-between;
			}
		}

		.navbar__menu__mobile_only {
			border-top: 1px solid var(--border-color);
			flex: 1;
			width: 100%;
		}
	}

	.navbar__session {
		.navbar__mobile {
			padding: 6px 38px;
		}
	}

	.navbar__mobile-header-logo {
		img {
			max-height: 50px;
		}
	}

	.navbar__language-selector {
		width: 100%;
		margin-top: 16px;
		margin-bottom: 16px;
		cursor: pointer;

		span {
			margin-left: 16px;
			font-weight: bold;
			font-size: 14px;
			display: block;
		}
	}

	.navbar__language-selector-current {
		display: flex;
		justify-content: space-between;
		align-items: center;

		svg {
			transition: transform 100ms;

			path {
				fill: var(--color);
			}
		}
	}

	.navbar__language-selector-current-label {
		display: flex;
		align-items: center;
		flex: 1;
	}

	.navbar__language-selector-dropdown {
		opacity: 0;
		max-height: 0;
		position: relative;
		transition: 250ms opacity, 250ms max-height;

		span {
			margin-left: 16px + 24px;
		}
	}

	.navbar__language-selector-dropdown-inner {
		width: 100%;
		max-height: 500px;
		overflow-y: auto;
	}

	.navbar__language-selector-dropdown-open {
		transition: 150ms opacity, 250ms max-height;

		#icon-angle-right {
			transform: rotate(90deg);
		}

		.navbar__language-selector-dropdown {
			opacity: 1;
			max-height: 500px;
		}
	}

	.navbar__language-selector-dropdown-item {
		margin-top: 32px;
		margin-bottom: 32px;
	}
}

@mixin navbarMobile($inEditor) {
	@layer navMobile {
		.navbar:not(.navbar__session) {
			@if not $inEditor {

				+div,
				+a {
					margin-top: 60px;
				}

				&.navbar__left {

					+div,
					+a {
						margin-top: calc(var(--banner-height, 0px) + 60px);
					}
				}
			}

			@if $inEditor {
				&:not(.navbar__top) {

					+div,
					+a {
						margin-top: 60px;
					}

					&.navbar__left {

						+div,
						+a {
							margin-top: calc(var(--banner-height, 0px) + 60px);
						}
					}
				}

				&.navbar__top {
					&.navbar__admin {

						+div,
						+a {
							margin-top: calc(var(--banner-height, 0px) + 60px);
						}
					}
				}
			}
		}

		.navbar__content {
			height: 60px;
			padding: 0;
			position: absolute;
			top: var(--banner-height, 0px);
		}

		.navbar__upper {
			left: 32px;
			padding: 0;

			.navbar__logo {
				max-height: 50px;
				max-width: 50px;
			}
		}

		.navbar__menu-middle {
			transform: translateY(60px);
			opacity: 0;
			top: 60px;
			transition: 150ms transform, 150ms opacity;
		}

		.navbar__open {
			.navbar__menu-middle-close {
				top: -55px;
				left: 13px;
			}

			.navbar__menu-middle {
				transform: translateY(0);
				opacity: 1;
			}
		}

		.navbar__profile {
			display: none;
		}
	}

	.navbar__mobile {
		padding: 0 42px;
	}

	.navbar__mobile-header {
		height: 60px;
	}
}

nav.navbar {
	--navbar-width: 95px;
	--background: var(--backgroundColor);
	--color: var(--bodyTextColor);
	--container: var(--user-mode-alt-background);
	--icon-color: rgba(var(--user-mode-color-rgb-partial), 0.5);
	--border-color: rgba(var(--user-mode-color-rgb-partial), 0.25);
}

body[data-theme-pack-type="auto"] {
	nav.navbar {
		--color: var(--backgroundColorContrast, var(--bodyTextColor));
	}
}

nav.navbar__dark {
	background-color: var(--dark-mode-container-color);
	color: var(--dark-mode-color);
	--container: var(--dark-mode-background);
	--icon-color: rgba(var(--dark-mode-color-rgb-partial), 0.5);
	--border-color: rgba(var(--dark-mode-color-rgb-partial), 0.25);
}

.navbar__profile-nav-language-container {
	>* {
		color: var(--color);
	}
}

nav.navbar .navbar__profile-details-dropdown-avatar {
	max-height: 48px;
	max-width: 48px;
	object-fit: cover;
	height: 48px;
	width: 48px;
	border-radius: 100%;
	aspect-ratio: 1 / 1;
}

@layer navDesktop {
	.navbar__overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100vw;
		height: 100vh;
		background-color: transparent;
		z-index: 102;
	}

	nav.navbar {
		position: relative;

		&.navbar__session:not(.navbar__top) {
			margin-bottom: var(--banner-height, 0);
		}

		&.navbar__sticky {
			position: sticky;
			top: 0;
			display: flex;
			flex-direction: column-reverse;

			.navbar__content {
				top: var(--banner-height, 0px);
			}

			.banner-announcement {
				position: absolute;
				top: 0;
			}

			&.navbar__admin {
				.navbar__content {
					position: absolute;

				}
			}
		}

		&:not(.navbar__top) {
			.navbar__content {
				position: fixed;
				left: 0;
				top: 0;
				bottom: 0;
				width: var(--navbar-width);
				flex-direction: column;
			}

			&:not(.navbar__session) {
				.banner-announcement {
					margin-left: var(--navbar-width);
				}
			}
		}

		&.navbar__admin.navbar__session.navbar__top {
			.navbar__content {
				top: var(--banner-height, 0px);
			}
		}

		z-index: 999;
	}

	.banner-announcement {
		z-index: 2;
		position: relative;
	}

	.navbar__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		position: relative;
		background-color: var(--background);


		z-index: 1;
	}

	.navbar__top {
		@include navTop(false);
	}

	.navbar__upper {
		padding-top: 20px;
		display: flex;
		flex-direction: inherit;
		align-items: center;
	}

	.navbar__actions {
		display: none;

		&.show {
			display: block;
		}
	}

	.navbar__actions {
		button {
			height: 40px;
			width: 40px;
		}

		svg {
			path {
				fill: var(--color);
			}
		}
	}

	.navbar__logo {
		max-width: 80px;
		max-height: 80px;
	}

	.navbar__menu-middle {
		display: flex;
		flex-direction: inherit;
		align-items: center;
		flex: 1;
		padding-top: 30px;
		column-gap: 40px;

		.side-nav-link {
			height: 80px;
			display: flex;
			flex-direction: inherit;
			align-items: center;
			justify-content: center;
		}

		.navdrop-list {
			background-color: var(--background);
			color: var(--color);
			white-space: nowrap;

			svg {
				path {
					fill: var(--color);
				}
			}
		}

		.side-nav-link .navigation-link {
			display: flex;
			flex-direction: inherit;
			align-items: center;
			justify-content: center;
			font: 600 11px/20px var(--headingFont);
			text-transform: uppercase;
			color: var(--color);
			text-decoration: none;
			transition: color 150ms;
			text-align: center;

			.navigation-link-body-left,
			.navigation-link-right {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}

			img {
				max-height: 24px !important;
				max-width: 24px !important;
			}

			svg {
				path {
					fill: var(--color);
					transition: fill 150ms;
				}
			}

			&:hover,
			&.active {
				color: var(--accentColor);

				svg {
					path {
						fill: var(--accentColor);
					}
				}
			}
		}
	}

	.navbar__menu-middle__close {
		display: none;
	}

	.navbar__profile-details-dropdown-trigger {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		left: 8px;
	}

	.profile-dropdown-arrow {
		margin-left: 4px;
		display: inline-flex; // Needed to center the arrow

		&:not(.top-nav) {
			transform: rotate(-90deg);
		}

		svg path {
			fill: var(--color);
		}
	}

	.navbar__profile-details-dropdown-placeholder {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 48px;
		width: 48px;
		border-radius: 100%;
		background-color: var(--accentColor);
		color: var(--buttonTextColor, var(--headingTextColor));
	}

	.navbar__profile-details-dropdown-menu {
		position: absolute;
		bottom: 15px;
		left: 100%;
		background-color: var(--background);
		box-shadow: 0 10px 40px rgba(126, 130, 134, 0.2);
		min-width: 220px;
		border-radius: 16px;
		overflow: hidden;
		max-height: 500px;
		overflow-y: auto;

		a {
			text-decoration: none;
		}

		button {
			border-radius: 0;
			width: 100%;
			white-space: nowrap;
			color: var(--color);
		}

		.icon-action-wrapper {
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			color: var(--color);
		}

		.left-action {
			display: inherit;
			align-items: inherit;
		}

		.see-profile-wrapper {
			text-align: left;
		}

		.see-profile-label {
			font-weight: 400;
			font-size: 12px;
		}

		p {
			margin: 0;
			color: var(--color);
		}


	}

	.navbar__menu-middle-close {
		display: none;
	}
}

.navbar__menu-middle {
	.navigation-dropdown {

		button {
			color: var(--color);
			display: flex;
			flex-direction: column;

			.navdrop-title {
				font: 600 11px / 20px var(--headingFont);
				text-transform: uppercase;
			}
		}

		.navdrop-list {
			background-color: var(--background);
			color: var(--color);
			white-space: nowrap;

			&:hover,
			&>*:hover {
				background-color: var(--container);
				color: var(--color);
			}

			&.right {
				right: calc(var(--navbar-width) * -1);
				bottom: 0%;
				top: unset;
			}

			svg {
				path {
					fill: var(--color);
				}
			}
		}
	}
}

.navbar__top {
	.navbar__menu-middle {
		.navigation-dropdown {
			button {
				color: var(--color);
				display: flex;
				flex-direction: row;
			}

			.navdrop-list {
				&.right {
					right: 0;
					bottom: unset;
					top: 100%;

					.side-nav-link {
						height: unset;
					}
				}
			}
		}
	}
}

.editor {
	nav.navbar:not(.navbar__no_outline) .navbar__content:hover {
		outline: 2px solid $cyan;
		outline-offset: -2px;

		.navbar__edit {
			button {
				background-color: $border-gray;
			}
		}
	}

	.navbar__edit {
		position: absolute;
		top: 0;
		right: 0;
	}
}

@media screen and (min-width: 1025px) {
	.live-event-container:not(.editor) {
		@include navbarDesktopOnly(false);
	}
}

@media screen and (max-width: 1024px) {
	.live-event-container:not(.editor) {
		@include navbarTablet(false);
	}
}

@media screen and (max-width: 768px) {
	.live-event-container:not(.editor) {
		@include navbarMobile(false);
	}
}

@media screen and (max-width: 640px) {
	.live-event-container:not(.editor) {
		.navbar__session {
			.banner-announcement {
				position: fixed;
				top: 0;
			}
		}
	}
}

.editor.desktop {
	.banner-announcement {
		width: 1440px;
	}

	&:not(.session) {
		.navbar__left {
			.banner-announcement {
				width: calc(1440px - var(--navbar-width, 95px));
			}
		}
	}

	.navbar__hideable {
		&.navbar__open:not(.navbar__top) {
			div.banner-announcement {
				width: calc(1440px - var(--navbar-width, 95px));
				min-width: calc(1440px - var(--navbar-width, 95px));
			}
		}
	}

	@include navbarDesktopOnly(true);
}

.editor.tablet {
	.banner-announcement {
		width: 768px;
		position: fixed;
		top: 0;
	}

	@include navbarTablet(true);
}

.editor.mobile {
	.banner-announcement {
		width: 375px;
		position: fixed;
		top: 0;
	}

	@include navbarTablet(true);
	@include navbarMobile(true);
}

.navbar__forcehidden {
	display: none;
}

.session-editor-absolute-container {
	position: absolute;
	top: var(--banner-height, 0px);
	left: 0;
	right: 0;
	bottom: 0;
}
