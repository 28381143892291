@import './src/scss/deps';

$select-error-color: #ff3434;
$select-success-color: #18d158;
$select-border-color: #838aa1;
$select-placeholder-color: #9fa0aa;

.evt-select {
	height: 55px;
	font-family: var(--bodyFont);
	color: var(--bodyTextColor);
	padding: 12px 45px 12px 15px;
	font-size: 15px;
	background: url('data:image/svg+xml,%3Csvg width=\'10\' height=\'5\' viewBox=\'0 0 10 5\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M0.893951 0.41276L4.7811 4.90359C4.89237 5.03214 5.10661 5.03214 5.21906 4.90359L9.10621 0.41276C9.25062 0.245297 9.12042 0 8.88723 0L1.11293 0C0.879747 0 0.749544 0.245297 0.893951 0.41276Z\' fill=\'%23081543\'/%3E%3C/svg%3E%0A')  no-repeat calc(100% - 15px) center;
	border-radius: 0;
	border: 1px solid $border-light-gray;
	-webkit-appearance: none;
	cursor: pointer;

	& + .evt-select {
		margin-left: 10px;
	}

	// this doesn't always work but it's the best option I've found
	[disabled] {
		color: $select-placeholder-color;
	}
}

@include dark-mode-theme {
	.evt-select {
		background: url('data:image/svg+xml,%3Csvg width=\'10\' height=\'5\' viewBox=\'0 0 10 5\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M0.893951 0.41276L4.7811 4.90359C4.89237 5.03214 5.10661 5.03214 5.21906 4.90359L9.10621 0.41276C9.25062 0.245297 9.12042 0 8.88723 0L1.11293 0C0.879747 0 0.749544 0.245297 0.893951 0.41276Z\' fill=\'%23ffffff\'/%3E%3C/svg%3E%0A') no-repeat calc(100% - 15px) center;
	}
}

@include light-mode-theme {
	.evt-select {
		background: url('data:image/svg+xml,%3Csvg width=\'10\' height=\'5\' viewBox=\'0 0 10 5\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M0.893951 0.41276L4.7811 4.90359C4.89237 5.03214 5.10661 5.03214 5.21906 4.90359L9.10621 0.41276C9.25062 0.245297 9.12042 0 8.88723 0L1.11293 0C0.879747 0 0.749544 0.245297 0.893951 0.41276Z\' fill=\'%23081543\'/%3E%3C/svg%3E%0A') no-repeat calc(100% - 15px) center;
	}
}

.evt-field-wrapper {
	font-family: var(--bodyFont);

	& + .evt-field-wrapper {
		margin-top: 25px;
	}

	.evt-select {
		width: 100%;
		padding: 15px 20px;
		font-size: 15px;
		line-height: 25px;
		font-weight: 500;
		border-color: $select-border-color;

		option {
			background: white;
			color: black;
		}
	}

	&.error {

		.evt-select {
			border-color: $select-error-color !important;
		}
	}

	&.success,
	&.ok {

		.evt-select {
			border-color: $select-success-color !important;
		}
	}

	.field-error,
	.field-success {
		margin-top: 5px;
		font-size: 10px;
		line-height: 15px;
		font-weight: 600;
	}

	.field-error {
		color: $select-error-color;
	}

	.field-success {
		color: $select-success-color;
	}
}

.evt-modal-filters {
	$filter-border-item-color: rgba(14, 43, 137, 0.1);
	$filter-border-item-color-fun: #ffcc00;

	.modal {
		max-width: 100%;
		width: 100%;
		max-height: 100%;
		height: 100%;
		padding: 0;
		background-color: var(--containerColor);
		border-radius: 0;

		&.normal {
			width: 100% !important;
		}

		.modal-header {
			height: 60px;
		}

		.modal-body {
			max-height: calc(100vh - 60px);
			min-height: calc(100vh - 60px);
			margin-bottom: 0;
			padding: 5px 25px 40px;
		}

		.close {

			svg path,
			&:hover svg path {
				fill: var(--bodyTextColor);
				transition: 150ms fill;
			}
		}
	}

	.evt-modal-filters-inner {

		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}

	.evt-modal-filters-title {
		margin-bottom: 5px;
	}

	.evt-modal-filters-list {
		max-height: 500px;
		margin: 0;
		padding: 0;
		list-style: none;
		overflow-y: auto;
	}

	.evt-modal-filters-list-item {
		display: flex;
		justify-content: flex-start;
		width: 100%;
		padding: 12px 0;
		font-family: inherit;
		font-size: 16px;
		line-height: 25px;
		font-weight: inherit;
		color: inherit;
		cursor: pointer;

		&:not(:last-child) {
			border-bottom: 1px solid $filter-border-item-color;
		}
	}
}
