@import "./src/scss/base/mixins";
@import "./src/scss/base/colors";
@import "./src/scss/deps";

$light-mode-text-color: rgba(0, 0, 0, 0.8);

.session-header {
	background-color: transparent;
	position: absolute;
	left: 95px;
	top: -11px;
	z-index: 6;
	width: var(--session-header-width, auto);
	transition: width 90ms;

	&.new-navigation:not(.static-header) {
		--session-header-width: 100%;
		top: 0;
		left: 0;
		background: $session-header-background;
	}

	&.fireside-session:not(.has-navigation) {
		left: 0;
	}

	&.has-banner-announcement.is-editor {
		// margin-top: 60px;

		&.is-editor-desktop {
			// margin-top: 48px;
		}
	}

	header {
		height: 80px;
		width: 100%;
		padding: 16px 0;
		color: var(--headingTextColor);
		justify-content: flex-start;

		.session-titlebox {
			display: inline-flex;
			align-items: center;
			text-decoration: none;
			color: inherit;
			height: 55px;
			position: absolute;
			background-color: transparent;
			left: 0;
			width: 100%;

			button {
				max-width: 100%;
				width: fit-content;
				justify-content: left;
				padding-left: 30px;
			}

			&.new-navigation {
				top: 16px;
				left: 65px;
				right: 0;
				width: auto;
				height: 48px;

				button {
					justify-content: center;
					flex-shrink: 0;
					max-width: none;
					width: 40px;
					height: 40px;
					padding: 0;

					.button-plain-svg-container {
						margin-right: 0;
					}
				}

				.back-button {
					svg {
						width: 20px;
						height: 20px;
						filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.7));
					}

					path {
						fill: $white !important;
					}
				}
			}

			h5.dark-mode {
				color: #ffffff;
			}

			&:visited {
				color: inherit;
			}

			img {
				height: 48px;
				width: 48px;
				object-fit: cover;
				@include radius-4;
				margin-left: 16px;
			}

			.session-title-text {
				display: flex;
				flex-direction: column;
				justify-content: center;
				-webkit-font-smoothing: subpixel-antialiased;
				width: calc(100% - 30px);

				p {
					font-size: 10px;
					line-height: 20px;
					font-weight: 500;
					margin: 0;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				h5 {
					color: var(--headingTextColor);
					font-size: 16px;
					line-height: 20px;
					font-weight: 700;
					margin: 0;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				h5.dark-mode {
					color: #ffffff;
				}

				h5:not(.dark-mode) {
					color: $light-mode-text-color;
				}

				.session-title-text-content {
					align-items: center;
					display: flex;
					flex-direction: row;
					width: fit-content;
					max-width: calc(var(--session-header-width, 100%) - 95px);

					span {
						font-size: 12px;
						margin-left: 12px;
						white-space: nowrap;
					}

					.title-and-tag {
						background-color: transparent;
						display: flex;
						flex-direction: column;
						flex-wrap: nowrap;
						align-items: flex-start;
						justify-content: center;
						margin-top: 18px;

						.tag-container {
							height: 20px;
							width: max-content;
						}
					}
				}
			}

			.back-button svg path {
				fill: var(--headingTextColor);
			}

			button.back-button.dark-mode svg path {
				fill: #ffffff;
			}

			button.back-button:not(.dark-mode) svg path {
				fill: $light-mode-text-color;
			}
		}

		.new-dropdown-value {
			visibility: hidden;
		}

		.video-controls-container {
			position: absolute;
			right: 0;
			top: 0;
			padding: 16px 24px 16px 0;
			display: flex;
			justify-content: flex-end;
		}

		&.is-mobile {
			color: var(--bodyTextColor);
			background-color: var(--backgroundColor);

			.session-titlebox {
				.back-button {
					svg path {
						fill: var(--bodyTextColor);
					}
				}
			}
		}
	}

	&.static-header {
		position: static;

		header {
			color: var(--bodyTextColor);
		}
	}

	&.single-session {
		header {
			.session-titlebox {
				padding-left: inherit;
				padding-right: inherit;
			}
		}
	}

	&.is-landscape-iphone {
		&:not(.breakout-session) {
			position: relative;
			top: unset;
		}
	}

	// if breakout session, make header backgroudn transparent according to figma
	&.breakout-session {
		background-color: transparent;
	}

	&.fireside-session {
		&.pre-live {
			position: absolute;
			top: 0;
			z-index: 1;
			background: transparent;

			&.has-navigation {
				left: 95px; // this is the width of the left panel (undo on tablet/mobile)
			}
		}

		&:not(.is-mobile) {
			background: linear-gradient(rgba(26, 26, 31, 0.9), rgba(26, 26, 31, 0.6), rgba(26, 26, 31, 0));

			transition: width 150ms;
		}
	}

	&.is-editor.is-desktop.is-editor-desktop {
		left: 60px;
		top: -3px;

		header {
			.session-titlebox {
				left: 0;
			}
		}
	}
}

@mixin newNavButtons {
	.new-nav-buttons {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;

		.navigation-toggle {
			top: 18px;
		}

		.control-button-container {
			top: 18px;
			left: 68px;
		}
	}
}

.breakout-editor-wrapper {
	@include newNavButtons;
}

.session.breakout-session {
	.session-main-content {
		position: relative;

		@include newNavButtons;
	}
}

@mixin sessionHeaderResponsive($size) {
	@if $size =="mobile" {
		.session-header {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			height: 60px;
			z-index: 7;

			&.new-navigation {
				z-index: 10;
			}

			&.fireside-session {
				&.pre-live {
					width: 100%;
					left: 0;
				}
			}

			header {
				height: 65px;
				padding: 6px 25px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				position: fixed;
				top: var(--banner-height, 0px);
				left: 0;
				right: 0;
				z-index: 10;

				.session-titlebox {
					left: 16px;

					img {
						height: 44px;
						width: 44px;
						min-height: 44px;
						min-width: 44px;
						margin-left: 6px;
					}

					h5 {
						&.dark-mode {
							color: var(--bodyTextColor);
						}

						font-size: 14px;
						line-height: 22px;
					}

					button.back-button {
						&.dark-mode {
							svg path {
								fill: var(--bodyTextColor);
							}
						}
					}
				}

				.new-dropdown-value {
					visibility: visible;
					margin: 0;
					height: 100%;
				}
			}

			&.new-navigation.new-navigation {
				&>header {
					.session-titlebox.new-navigation {
						left: 16px;
					}
				}
			}

			&.#{$ev-template-bold} {
				p {
					line-height: 15px;
				}
			}

			&.#{$ev-template-fun} {
				p {
					line-height: 15px;
				}

				h5 {
					font-weight: 500px;
				}
			}

			&.#{$ev-template-classic} {
				h5 {
					font-size: 16px;
					line-height: 25px;
					font-weight: 400;
				}
			}
		}
	}

	@if $size =="tablet" {
		.session-header {
			left: 0;

			&.fireside-session {
				&.pre-live {
					width: 100%;
					left: 0;
				}
			}

			header {
				padding: 16px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.new-dropdown-value {
					visibility: visible;
					margin: 0;
					height: 100%;
				}

				.mobile-header-actions-container {
					display: flex;
					align-items: center;
				}

				.session-titlebox {
					left: 100px;
				}

				.new-navigation {
					.session-titlebox {
						top: auto;
						height: 55px;
					}
				}
			}
		}
	}

	@if $size =="small-desktop" {
		.session-header {
			&.fireside-session {
				&.pre-live {
					width: 100%;
					left: 0;
				}
			}

			header {
				padding: 16px 75px;

				.session-titlebox {
					.session-title-text {
						.session-title-text-content {
							max-width: calc(var(--session-header-width, 100%) - 195px);
						}
					}
				}

				.new-dropdown-value {
					visibility: visible;
					margin: 0;
					height: 100%;
				}
			}
		}
	}
}

@include media-query($med-down) {
	:not(.editor) {
		@include sessionHeaderResponsive("small-desktop");
	}
}

@include media-query($sm-down) {
	:not(.editor) {
		@include sessionHeaderResponsive("tablet");
	}
}

@include media-query($xs-down) {
	:not(.editor) {
		@include sessionHeaderResponsive("mobile");
	}
}

.editor.mobile {
	@include sessionHeaderResponsive("tablet");
	@include sessionHeaderResponsive("mobile");
}

.editor.tablet {
	@include sessionHeaderResponsive("tablet");
}