@import "./src/scss/deps";

.create-speaker-wrapper {
	display: flex;
	flex-direction: column;
	overflow-y: scroll;

	.working-speaker {
		flex: 1;
		overflow-y: scroll;
		margin: 8px 24px;
		padding-bottom: 16px;

		.field-group {
			margin-bottom: 16px;

			&.session-select {
				margin-bottom: -8px;
				font-family: $font-family-poppins;

				.tag-select {
					border: 1px solid $gray-400;

					.placeholder {
						font-size: 12px;
					}
				}
			}

			&.textarea {
				margin-bottom: 24px;

				textarea {
					border: 1px solid $gray-400;
					font-size: 12px;
				}

				textarea::placeholder {
					color: $gray-400;
				}
			}

			.tag-select {
				cursor: pointer;
			}

			&.text.normal input,
			.tag-select,
			input {
				min-height: 48px;
			}
		}

		.speaker-panel-image-and-name {
			display: flex;

			.field-group.large-button {
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				width: 100px;
				height: 100px;
				margin-right: 16px;
				border: 1px dashed $gray-400;

				&.error {
					border-color: $rose;
				}

				label.inner {
					padding: 0;
					font-size: 13px;
					font-weight: 600;
					@include flex-center;

					span {
						margin: 0;
						color: $cyan;
					}
				}
			}

			.speaker-name {
				flex: 1;
			}
		}

		.switch-and-label {
			display: flex;
			align-items: center;
			margin-bottom: 16px;
			justify-content: space-between;

			.switch {
				margin-right: 12px;
			}
		}

		.speaker-attributes-list {
			display: flex;
			margin-bottom: 8px;

			.field-group {
				margin-right: 4px;
				margin-bottom: 0;

				&.select {
					width: 68px;
				}

				&.text {
					width: 128px;
				}

				.select-container {
					position: relative;

					.select-dropdown {
						position: absolute;
						top: 15px;
						left: 0;
					}
				}
			}

			.add-attribute.small {
				width: 76px;
			}
		}

		.link-social {
			margin-top: 24px;
		}

		.session-select {
			.tag-select {
				.placeholder {
					margin-left: 4px;
				}
			}
		}
	}

	.submit-actions-wrapper {
		position: sticky;
		bottom: 0;
		display: flex;
		justify-content: center;

		button {
			width: 172px;

			&:first-child {
				margin-right: 4px;
			}

			&:last-child {
				margin-left: 4px;
			}
		}
	}

	.social-media-list {
		@include flex-center;
		justify-content: space-between;
		margin-bottom: 8px;

		.field-group {
			margin-bottom: 0;
			width: 180px;
		}

		.navigation-dropdown {
			border-radius: 24px;
			width: 140px;

			button {
				height: 48px;
				padding-right: 0;

				span.navdrop-title {
					display: flex;
					align-items: center;
					white-space: nowrap;

					svg {
						margin-left: 8px;
					}
				}
			}
		}
	}

	.tag-select-container {
		.tag-select-dropdown.session-select {
			z-index: 2;

			.session-group-item {
				.field-group.checkbox {
					margin-bottom: 0;
				}
			}
		}
	}
}
