@import "./src/scss/old-deps";
@import "./src/scss/base/colors";
@import "./src/scss/abstracts/colors";

$background-color: $med-gray;

.reactions-description {
	color: $gray-300;
	font-size: 14px;
	margin: 10px 0 20px;
}

// adding specificity with this selector to prevent collision with class name on video player
#layout-reactions{
	.field-group {
		.reactions-container {
			display: flex;
			flex-wrap: wrap;
			margin: 15px 0 -10px 0;

			.reaction-container {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 40px;
				width: 40px;
				border-radius: 32px;
				margin: 0 15px 15px 0;
				right: unset;
				bottom: unset;
				position: unset;

				&.background {
					.reaction {
						height: 28px;
						width: 28px;
					}
				}

				.reaction {
					height: 40px;
					width: 40px;
				}
			}
		}
	}
}

.add-reaction {
	background-color: $gray-700;
	color: $light-gray;
	cursor: pointer;
	height: 40px;
	width: 40px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	user-select: none;
	margin-bottom: 15px;
}
