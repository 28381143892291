@import '../../scss/deps';

.page-not-found {
	height: 100vh;
	width: 100vw;
	background-color: $dark-gray;
	padding: 20px;
	font-family: "Roboto", sans-serif;
	color: $white;

	h4 {
		font-weight: 500;
	}

	.admin-404 {
		height: 100%;
		max-width: 664px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-left: 174px;

		h1 {
			font-size: 40px;
			font-weight: 600;
			margin-bottom: 24px;
		}

		.buttons {
			margin-top: 56px;
		}

		button {
			width: 320px;
		}

		button:not(:last-child) {
			margin-right: 24px;
		}
	}

	.admin-telly {
		position: absolute;
		right: 0;
		bottom: 0;
		pointer-events: none;
	}

	.event-404 {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.event-404-content {
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: flex-end;
			margin-bottom: 20px;
		}
		
		.event-404-text {
			max-width: 312px;
		}

		h1 {
			font-size: 154px;
			line-height: 156px;
			font-weight: 600;
			font-family: "Roboto", sans-serif;
			color: $white;
		}

		h4 {
			margin-bottom: 36px;
		}

		button {
			padding: 16px 40px;
		}
	}

	.event-telly {
		height: 220px;
		width: auto;
		position: absolute;
		right: 10px;
		bottom: 46px;
	}

	@media screen and (max-width: 1260px) {
		.admin-404 {
			margin-left: 0;
			max-width: 100%;
			align-items: center;
			text-align: center;

			.buttons {
				display: flex;
				flex-direction: column;
			}

			button {
				margin: 0;
			}

			button:not(:last-child) {
				margin-right: 0;
				margin-bottom: 8px;
			}
		}

		.admin-telly {
			height: 180px;
			right: calc(50% - 94px);
			bottom: calc(50% + 140px);
		}
	}

	@include media-query($sm-down) {
		.event-404 {
			.event-404-content {
				flex-direction: column-reverse;
				align-items: center;
				text-align: center;

				h1 {
					font-size: 72px;
					line-height: 144px;
				}

				h3 {
					font-size: 17px;
					line-height: 22px;
					font-weight: 600;
					margin-bottom: 18px;
				}

				h4 {
					font-size: 17px;
					line-height: 22px;
					font-weight: 400px;
				}

			}

			.event-telly {
				height: 155px;
				right: calc(50% - 75px);
				bottom: unset;
				top: -62px;
			}
		}
	}
}

