@import './src/scss/deps';
@import './responsive-sizes-mixin.scss';

.registration-v2 {
	.project-overview-content {
		color: var(--headingTextColor);

		display: flex;
		flex-direction: column;
		gap: 24px;
		position: relative;
		z-index: 1;

		* {
			margin-bottom: 0;
			margin-top: 0;
		}

		.session-description {
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
		}

		.registration-header {
			display: flex;
			flex-direction: column;
			gap: 12px;

			.registration-title {
				font-family: var(--headingFont);
				white-space: pre-wrap;
			}

			.streamed-date-text {
				p {
					color: $icon-gray;
				}

				.video-control-popup {
					color: $white;
				}
			}
		}

		.session-live-card-description {
			p {
				color: var(--headingTextColor);
			}
		}

		.registration-description {
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			font-family: var(--bodyFont);
		}
	}

	&.registration-above-the-fold-r,
	&.registration-above-the-fold-l {
		.project-overview-content {
			margin: 80px 100px;
			max-width: 66%;
		}
	}

	&.reg-image-overlay {
		&.registration-hero-r,
		&.registration-hero-l {
			.project-overview-content {
				max-width: 482px;

				&:not(.mobile) {
					@include media-query($sm-up) {
						min-width: 482px;
					}
				}

				color: var(--bodyTextColor);

				.registration-title {
					color: var(--bodyTextColor);
				}

				.registration-description {
					color: var(--bodyTextColor);
				}

				.session-live-card-description {
					p {
						color: var(--bodyTextColor);
					}
				}
			}
		}
	}

	&.registration-landing-r,
	&.registration-landing-l {

		.project-overview-content {
			min-height: 600px;
			padding: 80px 100px;
		}
	}


	@include media-query($xlrg-down) {
		@include responsive-sizes("small-desktop");
	}

	@include media-query($med-down) {
		@include responsive-sizes("tablet");
	}

	@include media-query($sm-down) {
		@include responsive-sizes("mobile");
	}

	@include media-query($xs-down) {
		@include responsive-sizes("mobile-xs");
	}

	// EDITOR SIZES
	&.editor.desktop {}

	&.editor.tablet {
		@include responsive-sizes("tablet");
	}

	&.editor.mobile {
		@include responsive-sizes("mobile-xs");
	}
}

// we need to set the text color for registration v2 without uploaded images to button text color
// because we auto adjust the default image based on the primary color, and button text color
// is a generated contrast of primary.
.registration-v2 {
	.project-overview-content {
		.registration-header {
			.streamed-date-text {
				p {
					color: var(--secondaryAccentColor);
				}
			}
		}
	}

	&.reg-image-overlay {
		.project-overview-content {
			color: var(--buttonTextColor);

			.registration-title {
				color: var(--buttonTextColor);
			}

			.registration-header {
				.streamed-date-text {
					p {
						color: color-mix(in srgb, var(--buttonTextColor), #000000 20%);
					}
				}
			}
		}
	}
}
