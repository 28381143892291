
@import './src/scss/old-deps';
@import "./src/scss/deps";

.description-actions {
	display: flex;
	flex-shrink: 0;
	padding-top: 20px;
	column-gap: 10px;
	gap: 10px;
	align-items: center;
	justify-content: flex-start;
}