@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes tabAnimate {
	from {
		transform: scaleX(0);
		opacity: 0;
	}
	to {
		transform: scaleX(1);
		opacity: 1;
	}
}
