
@import './src/scss/old-deps';

.paging-container {
	display: flex;
	align-items: center;

	button {
		width: 32px;
		height: 32px;
		margin-right: 8px;
		padding: 0;
		font-size: 12px;
		font-weight: 700;
	}

	.active {
		background-color: $gray;
	}

	.ellipsis {
		position: relative;
		bottom: 3.5px;
	}
}
