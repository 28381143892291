@import "../../../../../../../scss/deps";

.panel-container {
	.settings-panel-container {
		&.large-panel {
			width: 400px;
		}
		.panel-header {
			height: initial;
			padding: 32px 24px 16px;
			border-bottom: transparent;
			.title {
				margin: 0;
				padding: 0;
			}
			.event-availability {
				margin-left: 8px;
			}
		}
		.panel-body {
			height: calc(100vh - 84px - 72px); // 72px is main header, 84px is panel header (should be moved out to global variables)
			padding: 0 24px;

			.settings-card {
				width: 100%;
				min-height: 64px;
				border-radius: 12px;
				background-color: $gray-800;
				display: flex;
				align-items: center;
				justify-content: space-between;
				text-align: left;
				padding: 16px;
				margin-top: 8px;
				.switch {
					height: 100%;
				}
				label {
					font-size: 14px;
					display: flex;
					align-items: center;
					button.no-style {
						padding: 0;
						margin-right: 6px;
					}
					.sign-on-logo {
						margin-right: 8px;
					}
					.smart-tooltip {
						margin: 0 8px;
						top: 2px;
						.smart-tooltip-content {
							position: fixed;
							bottom: inherit;
							top: initial !important;
							left: inherit;
							width: 200px;
							margin: 20px 0 0 -11px;

							.smart-tooltip-text {
								white-space: normal;
								&::before {
									position: fixed;
									bottom: inherit;
									margin-top: -12px;
									transform: rotateX(180deg);
								}
							}
						}
					}

					&.pre-line {
						white-space: pre-line;
					}
				}
				p {
					font-size: 12px;
					color: $gray-300;
					margin: 4px 0 0;
				}
				button.no-style {
					svg {
						transition: 0.2s;
					}
					&.show {
						svg {
							transform: rotateZ(90deg);
						}
					}
				}
				&.sub-list {
					margin-left: 8%;
					width: 92%;
				}
				&.detailed-card {
					display: block;
					label {
						width: 100%;
						display: flex;
						justify-content: space-between;
					}
					.tag-list {
						list-style: none;
						padding: 0;
						margin: 8px 0 0;
						display: flex;
						flex-wrap: wrap;
						justify-content: flex-start;
					}
					.tag {
						padding: 8px 16px;
						margin-bottom: 4px;
						background-color: $gray-1000;
						border: 2px solid $gray-700;
						font-size: 12px;
						line-height: 18px;
						color: $gray-200;
						border-radius: 100px;
						&.capitalize {
							text-transform: uppercase;
						}
						&.clear {
							margin-top: 8px;
							background-color: transparent;
							border: 1px solid $gray-400;
							font-size: 14px;
						}
						&.tag-audience-list:hover {
							background-color: $gray-active;
							transition: background-color $hover-transition-duration;
						}
					}
					.options-button {
						padding: 1px;
						margin-right: 0;
						position: relative;

						.popover-wrapper {
							border-radius: 12px;
							position: absolute;
							right: 0;
							left: inherit;
							top: calc(100% + 20px);
							width: 150px;
							padding: 1px;

							button {
								padding: 8px 16px;
								font-size: 14px;
								font-weight: normal;
								border-radius: 0;
								background-color: transparent;
								&:first-child {
									border-top-left-radius: 12px;
									border-top-right-radius: 12px;
								}
								&:last-child {
									border-bottom-left-radius: 12px;
									border-bottom-right-radius: 12px;
									width: 100%;
								}
								&:hover {
									background-color: $border-gray;
								}
							}
						}
					}
					.field-group.text {
						margin: 8px 0 0;
						input {
							height: 32px;
						}
					}
				}
			}
			.blocked-emails-button {
				padding: 0;
				label {
					cursor: inherit;
				}
			}
			.gate-errors {
				border-radius: 12px;
				margin-top: 8px;
				margin-right: 0;
				padding: 14px 16px;
				font-size: 14px;
				line-height: 20px;
				font-weight: 500;
				background-color: rgba($red, 0.1);
				border: 1px dashed $red;
			}
			.field-group.select {
				margin-top: 16px;
			}
			.field-group.large-button {
				margin-top: 8px;
			}
			.left-margin {
				@extend .settings-card, .sub-list;
			}
		}
	}

	.advanced-settings-text {
		line-height: 20px;
		font-size: 14px;
		color: $gray-300;
		margin: 16px 0 12px;
		padding-top: 12px;
	}
}

.gating-requirements-modal {
	padding-bottom: 40px;
}

.page-gating-modal,
.gating-requirements-modal {
	.field-error,
	.field-group.large-button label.error-message {
		left: 12px;
		color: #ff375f;
		font-size: 12px;
		font-weight: 700;
	}
	.field-error {
		position: fixed;
		left: 52px;
		margin-top: -20px;
	}
}

.gating-dropdown-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px;
	align-items: center;
	margin-bottom: 24px;
	.field-group.select {
		margin: 0;
		.select-dropdown {
			position: absolute;
			width: 321px;
		}
	}
	.info {
		margin: 0;
	}
}
.tag-select-dropdown {
	width: 662px;
	margin: 0 auto;
}
.field-group.tag-select-container {
	.tag-select {
		max-width: none;
	}
	label.error-message {
		color: $rose;
		position: absolute;
	}
}

.email-colors {
	.basic-card {
		display: flex;
		align-items: center;
		.color {
			width: 100%;
			padding-right: 16px;
			.palette-row {
				border-radius: 16px;
				display: flex;
				flex-direction: row;
				height: 16px;
				margin-top: 12px;
				overflow: hidden;

				div {
					flex: 1;
				}
			}
		}
		.colors-container,
		.fonts-container {
			padding-left: 16px;
		}
	}
}

.email-fonts {
	.basic-card {
		display: flex;
		align-items: center;

		.font {
			flex: 1;

			h6 {
				margin: 0;
			}
		}
	}

	.fonts-container {
		.font-container {
			padding: 15px;
			display: flex;
			align-items: center;

			h6 {
				margin: 0 0 0 16px;
			}
		}
	}
}
