@import "./src/scss/old-deps";
@import "./src/scss/deps";

.survey-results-question-card {
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 16px;
	border-radius: 16px;
	background-color: $gray-800;
	margin-bottom: 8px;
	content-visibility: auto;

	.survey-results-question-card-options {
		.survey-results-question-card-option {
			display: flex;
			padding: 8px 12px;
			align-items: center;
			gap: 4px;
			flex: 1 0 0;
			border-radius: 12px;
			background-color: $gray-900;
			position: relative;
			overflow: hidden;

			&:not(:last-child) {
				margin-bottom: 8px;
			}

			.survey-results-question-card-option-bar {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				transition: 500ms width;
				background: $gray-700;
				z-index: 1;

				&.card-type-quiz {
					&.correct {
						background: rgba(50, 215, 75, 0.1);
					}

					&.incorrect {
						background: rgba(255, 68, 58, 0.1);
					}
				}
			}

			.survey-results-question-card-option-text {
				display: flex;
				flex-direction: column;
				gap: 4px;
				flex: 1;
				display: relative;
				z-index: 2;

				label {
					font-size: 13px;
					font-weight: 600;
					line-height: 16px;
				}

				span {
					display: block;
					color: $gray-300;
					font-size: 11px;
					font-weight: 600;
					line-height: 16px; /* 145.455% */
					letter-spacing: 0.22px;
				}
			}

			.survey-results-question-card-option-correct {
				@include flex-center;
			}
		}

		.survey-results-question-card-replies {
			.survey-results-question-card-empty {
				color: $gray-400;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px; /* 133.333% */
				letter-spacing: 0.24px;
				margin: 0 auto;
				text-align: center;
				width: 100%;
				display: block;
			}

			.survey-results-question-card-replies-list {
				display: flex;
				flex-direction: column;
				overflow-y: auto;
				overflow-x: hidden;
				max-height: 50svh;

				.stagger-child {
					content-visibility: auto;
					&:not(:last-child) {
						margin-bottom: 8px;
					}
				}
			}

			footer {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 12px;

				label {
					flex: 1;
					color: $gray-300;
					text-align: right;
					font-size: 12px;
					font-weight: 600;
					line-height: 20px; /* 166.667% */
					letter-spacing: 0.24px;
				}
			}
		}
		.survey-results-question-card-reply {
			display: flex;
			padding: 8px 12px;
			align-items: center;
			gap: 8px;
			flex: 1 0 0;
			border-radius: 12px;
			background: $gray-900;

			.survey-results-question-card-reply-text {
				display: flex;
				justify-content: center;
				gap: 4px;
				flex-direction: column;

				label {
					color: $gray-300;
					font-size: 12px;
					font-style: normal;
					font-weight: 600;
					line-height: 20px; /* 166.667% */
					letter-spacing: 0.24px;
				}

				p {
					color: $white;
					font-size: 12px;
					font-style: normal;
					font-weight: 500;
					line-height: 16px; /* 133.333% */
					letter-spacing: 0.24px;
					margin: 0;
				}
			}
		}
	}
}
