@import './src/scss/old-deps';
@import "./src/scss/base/colors";
@import './src/scss/deps';

.editor .live-session-banner,
.live-event-container .live-session-banner,
.live-session-banner {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 460px;
	background-position: 50%;
	background-size: cover;
	background-color: var(--secondaryBackgroundColor);

	&::before {
		@include absolute-fill;
		content: '';
	}

	.live-session-banner-header {
		position: relative;
		display: flex;
		align-items: center;
		padding: $ev-gap-x6 $ev-gap-x4 $ev-gap-x6 $ev-gap-x6;
		background: $session-header-background;

		img {
			@include size(48px);
			margin-right: $ev-gap-x4;
			object-fit: cover;
		}

		time {
			display: block;
			line-height: 24px;
			font-weight: 400;
		}

		p {
			margin-bottom: 0;
			line-height: 24px;
		}
	}

	.module-container {
		position: relative;
		display: flex;
		align-items: flex-start;
		margin: 0;
		margin-bottom: 56px;
		padding-top: 0;
		padding-bottom: 50px;
	}

	.live-session-banner-tags {
		display: flex;
		gap: $ev-gap-x2;
		flex-wrap: wrap;
		margin-top: 16px;
		margin-bottom: 10px;

	}

	.live-session-banner-title {
		margin: 10px 0 $ev-gap-x8;
	}
}
