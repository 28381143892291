@import './src/scss/old-deps';
@import './src/scss/base/colors';
@import './src/scss/abstracts/colors';

.chip-container {
	display: inline-block;
	padding: 6px 8px;
	border-radius: 24px;
	font-size: 14px;
	
	&.chip-primary {
		background-color: $gray-600;
	}

	&.chip-warning {
		background-color: $yellow;
	}
	
	&.chip-danger {
		background-color: $danger;
	}
	
	&.chip-admin {
		background-color: $dark-gray;
		border: 2px solid $border-gray;
		padding: 8px 16px;
	}
}
