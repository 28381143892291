@import './src/scss/old-deps';
@import './src/scss/deps';

#modal-global-container {
	.session-details-modal {

		&.session-details-edit-modal {
			z-index: 3;
		}

		// This modal always uses hard coded Classic/Limelight colors for both light/dark mode, regardless of theme pack
		.modal {
			.modal-body {
				.session-details-modal-speakers {
					li button {
						color: var(--classicBodyTextColor);
					}
				}

				.session-details-modal-image {
					.session-socials {
						a.streams-action-button,
						button.streams-action-button {
							border-color: var(--classicBodyTextColor);

						}
					}
					svg {
						path {
							fill: var(--classicBodyTextColor);
						}
					}
				}

				label.video-control-popup {
					color: var(--gray-100) !important;
				}

				.session-details-modal-content {
					color: var(--classicBodyTextColor);

					p.session-details-modal-tracks {
						color: color-mix(in srgb, var(--classicBodyTextColor), #ffffff 10%);
					}
				}

			}
		}


		.modal {
			max-width: 920px;

			.modal-body {
				overflow-y: auto;
			}

			.modal-footer {

				button {
					margin-right: 0;
				}
			}
		}

		.modal-body {
			display: flex;
			align-items: flex-start;
			column-gap: 40px;
		}

		.session-details-modal-image {
			flex-shrink: 0;
			width: 304px;
			margin-bottom: 20px;

			.edit-social-custom-url-container {
				margin: 20px 0 -100px;
				z-index: 10;
				position: relative;

				.buttons-container {
					display: flex;
					justify-content: flex-end;

					button {
						margin-left: 12px;
					}
				}
			}

			img {
				display: block;
				width: 100%;
				aspect-ratio: 16 / 9;
				object-fit: cover;
				border-radius: var(--customImageBorderRadius, 12px);
			}

			.relative {

				&::before {
					content: '';
					position: absolute;
					inset: 0;
					border-radius: var(--customImageBorderRadius, 12px);
					transition: background-color 0.15s;
				}

				.evt-button {
					position: absolute;
					left: 50%;
					bottom: 16px;
					height: 32px;
					padding: 0 16px;
					text-transform: none;
					opacity: 0;
					visibility: hidden;
					column-gap: 6px;
					transform: translateX(-50%);
					transition: visibility 0.15s, opacity 0.15s;
				}

				&:hover {

					&::before {
						background-color: rgb(0 0 0 / 0.4);
					}

					.evt-button {
						opacity: 1;
						visibility: visible;
					}
				}
			}

			.uploading {
				position: absolute;
				inset: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: var(--customImageBorderRadius, 12px);
				background: rgba(0, 0, 0, 0.5);
			}
		}

		.session-details-modal-content {
			display: flex;
			flex-direction: column;
			max-width: 496px;
			width: 100%;

			h5 {
				color: var(--classicBodyTextColor) !important;
			}

			.tag-select-dropdown {
				position: absolute;
				width: 100%;
				background: var(--grayscale-800, $image-gray);

				.tag-option span {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			.quill-wrap .ql-toolbar.ql-snow.active {
				left: calc(-25%);
				transform: scale(0.75);
			}

			.tag-select-container {
				.tag-select {
					input {
						color: var(--classicBodyTextColor, '#fff');
					}
				}
			}
		}

		.session-socials {
			margin-top: 20px;
		}

		.tab-title-form {
			margin-bottom: 10px;
			font-size: 23px;
			font-family: var(--headingFont) !important;
		}

		.session-details-modal-time {
			display: flex;
			align-items: center;
			margin-bottom: 5px;
			position: relative;
			gap: 4px;
			color: var(--icon-gray);

			p {
				margin: 0;
			}
		}

		.session-details-modal-description {
			min-height: 0;
			margin-bottom: 20px;
			color: var(--classicBodyTextColor);

			&>p {
				overflow: scroll;
				max-height: 100%;
			}

			&.evt-typography-subtitle-b3 {
				overflow-y: auto;
			}
		}


		.agenda-session-card-tags {
			display: flex;
			gap: 5px;
			flex-wrap: wrap;
			margin-top: 20px;
		}

		.session-tag {
			@include get-typography(caption-l2);
			padding: 4px 10px;
			border-radius: 100px;
		}
	}

	@mixin responsive($size) {
		@if $size =='tablet' {

			.modal {
				max-width: 510px;

				.modal-body {
					overflow-y: auto;
				}
			}

			.modal-body {
				flex-direction: column;
			}

			.session-details-modal-image {
				width: 100%;
			}

			.session-details-modal-time,
			.session-details-modal-description {
				margin-bottom: 15px;
			}

			.session-details-modal-socials,
			.agenda-session-card-tags {
				margin-top: 15px;
			}

			.session-details-modal-title {
				@include get-typography(h5, (font-weight));
			}

			.session-details-modal-time {
				font-size: get-typography-prop-value(subtitle-4, font-size);
			}
		}

		@if $size =='mobile' {

			.modal {
				max-width: calc(100vw - 30px);
			}
		}
	}

	@include media-query($med-down) {

		.session-details-modal {
			@include responsive('tablet');
		}
	}

	@include media-query($sm-down) {

		.session-details-modal {
			@include responsive('mobile');
		}
	}
}


.menu-portal {
	&.tag-select-dropdown {
		position: absolute;
		width: 100%;
		background: var(--grayscale-800, $image-gray);
		border-radius: 24px;
		box-shadow: 0 2px 4px 2px rgba(0, 0, 0, .25), 0 8px 24px rgba(0, 0, 0, .25);
		outline: none;
		margin: 0 auto;
		box-sizing: border-box;
		max-height: 155px;
		overflow-y: auto;

		.tag-option span {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			align-items: center;
			cursor: pointer;
			display: flex;
			flex: 1 1;
			font-size: 14px;
			justify-content: space-between;
			padding: 16px;
			outline: none;


		}

		.dropdown-action-button {
			align-items: center;
			background: linear-gradient(90deg, #fffd62, #ff6ae6);
			background: var(--gradient-sunset, linear-gradient(90deg, #fffd62 0, #ff6ae6 100%));
			background-clip: text;
			cursor: pointer;
			display: flex;
			flex: 1 1;
			font-size: 14px;
			font-weight: 500;
			height: 53px;
			justify-content: center;
			overflow: hidden;
			padding: 16px;
			text-overflow: ellipsis;
			white-space: nowrap;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;

			svg {
				margin-right: 8px;
			}

			span {
				padding: 16px 16px 16px 0px
			}
		}
	}
}
