@import "../../../scss/deps";

$mousePopupTranslateYstart: -50%;
$mousePopupTranslateYIn: -175%;
$mousePopupTranslateYOut: -225%;
$absoluteCenterX: -50%;

@keyframes mousePopupIn {
	from {
		opacity: 0;
		transform: translateX($absoluteCenterX) translateY($mousePopupTranslateYstart) scale(0.85);
	}

	to {
		opacity: 1;
		transform: translateX($absoluteCenterX) translateY($mousePopupTranslateYIn) scale(1);
	}
}

.mouse-popup {
	position: fixed;
	transform: translateX($absoluteCenterX) translateY($mousePopupTranslateYstart);
	padding: 7px;
	background-color: $gray-1000;
	border-radius: 4px;
	opacity: 0;
	transition:
		100ms opacity,
		150ms transform;
	white-space: nowrap;
	pointer-events: none;
	color: var(--grayscale-100-white, #fff);
	text-align: center;
	font-size: 10px;
	font-style: normal;
	font-weight: 500;
	line-height: 12px; /* 133.333% */
	letter-spacing: 0.24px;
	user-select: none;
	z-index: 9999;

	// this is the little caret below the alert
	&::before {
		content: "";
		height: 8px;
		width: 8px;
		transform: rotate(55deg) translateX($absoluteCenterX) skewY(-20deg);
		position: absolute;
		left: 45%;
		bottom: -7px;
		background-color: var(--mouse-popup-background-color, $gray-1000); // CSS var set when popup is triggered
	}

	&.mouse-popup--transitioning {
		opacity: 0;
		transform: translateX($absoluteCenterX) translateY($mousePopupTranslateYOut) scale(1);
		transition:
			200ms opacity,
			200ms transform;
	}

	&.mouse-popup--visible {
		opacity: 1;
		transform: translateX($absoluteCenterX) translateY($mousePopupTranslateYIn) scale(1);
		animation: mousePopupIn 64ms ease-out;
	}
}
