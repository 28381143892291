@import './src/scss/old-deps';
@import './src/scss/deps';

.breakout-session-banner {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	min-height: 460px;
	height: 100%;
	background-position: 50%;
	background-size: cover;

	&::before {
		@include absolute-fill;
		content: '';
	}

	.session-header {
		z-index: 1;
		background-color: transparent;
	}

	.breakout-session-banner-header {
		position: relative;


		img {
			@include size(48px);
			margin-right: $ev-gap-x4;
			object-fit: cover;
		}

		time {
			display: block;
			line-height: 24px;
			font-weight: 400;
		}

		p {
			margin-bottom: 0;
			line-height: 24px;
		}
	}

	.breakout-session-banner-container {
		position: relative;
		display: block;
		max-width: 1440px;
		width: 100%;
		margin: 0 auto;
		padding: 0 130px 75px;

		&.hiddenHeaderMargin {
			margin: auto 0 0 0;
		}
	}

	.breakout-session-banner-header-container {
		display: flex;
		align-items: center;
		padding: $ev-gap-x4 10px $ev-gap-x4;
	}

	.breakout-session-banner-tags {
		display: flex;
		gap: $ev-gap-x2;
		flex-wrap: wrap;
	}

	.breakout-session-banner-title {
		margin: 10px 0 25px;

		&.light-mode {
			color: var(--containerColor);
		}
	}

	.breakout-session-banner-buttons {
		display: flex;
		gap: 20px;
	}

	.breakouts-room-action {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: var(--customButtonBorderRadius, 100px);
		width: fit-content;
		text-transform: uppercase;
		background-color: var(--accentColor);
		margin-top: 10px;
		font-weight: bold;
		line-height: 25px;

		&.margin-right {
			margin-right: 10px;
		}

		span {
			font-style: normal;
			font-weight: bold;
			font-size: 13px;
			line-height: 25px;
			color: var(--buttonTextColor, var(--containerColor));
			font-family: var(--bodyFont);
		}
	}

	.countdown-container {

		.countdown-number {
			text-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
			color: var(--containerColor);
		}

		.countdown-label {
			opacity: 0.6;
			color: var(--containerColor);
		}
	}

	.breakout-session-add-calendar {
		display: inline-flex;
		justify-content: space-between;
		align-items: center;
		padding: 15px 15px 15px 20px;
		color: var(--bodyTextColor);
		background-color: var(--containerColor);
		border-radius: var(--customContainerBorderRadius, 0);
	}

	.breakout-session-add-calendar-content {
		margin-right: $ev-gap-x8;
	}

	.breakout-session-add-calendar-title {
		margin-bottom: 0;
	}

	.breakout-session-add-calendar-subtitle {
		font-size: 15px;
		line-height: 20px;
		font-weight: 400;
		color: var(--bodyTextSecondaryColor);
	}

	&.no-image {
		background: var(--secondaryBackgroundColor);
	}
}

.breakout-session-above-the-fold {
	display: flex;
	height: 100vh;
	min-height: 550px;

	.breakout-session-banner {
		width: 100%;
		height: unset;
	}
}
