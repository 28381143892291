@import '../../../../../../scss/deps';

.reaction-configuration-modal > .modal {
  &.normal {
    @media screen and (min-width: 640px) {
      width: fit-content;
    }
  }

  > .modal-footer {
    justify-content: space-between;
  }
}

.configure-reaction-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  > div {
    margin: 0 5px;
  }

  .drag-handle {
    cursor: grab;
  }

	&.new-reaction {
		.drag-handle {
			cursor: none;
			visibility: hidden;
		}
	}

  .reaction-select-container {
    border: 1px solid $gray-500;
    border-radius: 100px;
    padding: 8px 16px;
    height: 48px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.reaction-background-color {
      min-width: 176px;

      .reaction-background-display {
        height: 32px;
        width: 32px;
        border-radius: 100px;
        margin: 0 5px 0 -10px;
      }
    }

    &.reaction-icon {
      display: flex;
      justify-content: space-between;
      min-width: 86px;

      > img {
        height: 30px;
        width: 30px;
        margin: 0 12px 0 -8px;
      }
    }

    &.reaction-name {
      min-width: 176px;
      max-width: 176px;
      background-color: transparent;
      color: white;
      font-size: 16px;
      cursor: text;
      margin-left: 5px;
    }

    > .faded {
      color: $gray-placeholder
    }
  }

  .delete-reaction {
    cursor: pointer;

		&.disabled {
			opacity: 0.5;
		}
  }

  .color-editor-container {
    margin-top: -18px;
  }
}

.add-custom-reaction {
  color: $primary;
  font-size: 12px;
  font-weight: bold;
  margin-left: 8px;
  cursor: pointer;
  width: fit-content;
}

.reaction-description-toggle-container {
  display: flex;
  align-items: center;

  > span {
    margin-left: 10px;
  }
}

.color-picker-container {
  > .color-picker-wrapper {
    margin-top: 0;
    margin-bottom: 20px;
    position: relative;
    transform: translateY(0) translateX(0);

    &.display-left {
      margin-left: -36%;
    }

    &.open {
      transform: none;
    }
  }
}

.upload-reaction-name {
  background-color: transparent;
  color: white;
  font-size: 16px;
  cursor: text;
  margin-left: 5px;
  border: 1px solid $gray-500;
  border-radius: 100px;
  padding: 8px 16px;
  height: 48px;
  display: flex;
  align-items: center;
	margin-top: 2px;
}

.reaction-select {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: $gray-800;
  width: 240px;
  max-height: 400px;
  overflow-y: scroll;
  padding: 5px 2px;
  position: absolute;
  transform: translateX(55px);
  z-index: 1;

  .reaction-select-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    margin: 5px 0;

    > img {
      margin: 0 10px;
      height: 30px;
      width: 30px;
    }
  }
}
