@import "./src/scss/deps";
@import "../session-panel-mixins";

.sub-panel-header-options {
	position: absolute;
	top: 0;
	right: 0;
	height: 82px;
	padding-right: 24px;
	display: flex;
	align-items: center;

	.navigation-dropdown.session-panel-dropdown {
		.session-panel-right-header-button {
			@include meatballMenuButton;
		}

		.navdrop-list {
			>:nth-child(1) {
				border-top-left-radius: 15px;
				border-top-right-radius: 15px;
			}

			>:last-child {
				border-bottom-left-radius: 15px;
				border-bottom-right-radius: 15px;
			}
		}
	}
}