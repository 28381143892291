@import "./src/scss/deps";
@import "./src/scss/old-deps";

.editor-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	background-color: var(--backgroundColor);
	background-repeat: no-repeat;
	background-size: cover;
	border: 1px solid transparent;
	transition: 150ms border;

	.module-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 100px 150px;
	}

	&.session {
		margin-bottom: 36px;
	}

	&:hover {
		border: 1px solid $cyan;
	}
}

.landing-page-v2 {
	.editor-wrapper {
		position: relative;

		.module-container,
		.footer-container {
			padding: 75px;
		}
	}
}

.editor-main-container.tablet {
	.module-container {
		padding: 75px;
	}
}

.editor-main-container.mobile {
	.module-container {
		padding: 50px 25px;
	}
}

.sessions-container,
.structure-container {
	// margin: 10px;

	.structure-list {
		&.read-only {
			>* {
				pointer-events: none;
			}
		}

		.sortable-module {
			position: relative;
			display: flex;
			align-items: center;

			.drag-handle {
				opacity: 0;
				position: relative;
				left: -20px;
			}

			&:hover {
				.drag-handle {
					opacity: 1;
					cursor: grab;
				}

				.session-option-right button.options {
					display: inline-flex;
				}
			}
		}

		.session-option-container {
			display: flex;
			align-items: center;
			height: 64px;
			margin-bottom: 8px;
			padding: 0 16px;
			background-color: $image-gray;
			border: 1px solid $med-gray;
			user-select: none;
			@include radius-12;
			transition: background-color $hover-transition-duration;
			justify-content: space-between;
			position: relative;

			.title {
				display: flex;
				align-items: center;
				margin-left: -12px;
				font-size: 14px;
				line-height: 16px;
				font-weight: 700;
			}

			.session-option-right {
				display: flex;
				align-items: center;

				button.options {
					display: none;
				}
			}

			&:hover {
				cursor: pointer;
				background-color: $gray-active;
				transition: background-color $hover-transition-duration;

				button.options {
					display: inline-flex;
				}
			}

			&.no-toggle {
				display: flex;
				justify-content: space-between;
				align-items: center;

				label {
					font-size: 14px;
					line-height: 16px;
					font-weight: 700;
				}

				.options {
					display: none;
					margin: 0;
					padding: 0;
					margin-right: 16px;
				}

				&:hover {
					.options {
						display: block;
					}
				}

				.switch-right .options {
					margin-right: 0;
				}

				.nav-switch .switch-container .switch-right .options {
					margin-right: 0;
				}
			}

			&.no-drag {
				margin-left: 0;

				label {
					margin-left: 0;
				}
			}
		}

		.event-main-navigation-container .editable-nav-name {
			display: flex;
			align-items: center;
			font-size: 14px;
			line-height: 16px;
			font-weight: 700;
		}

		.add-custom-section {
			color: $cyan;
			margin-left: 0;
			width: 100%;
			justify-content: center;

			svg {
				margin-right: 8px;
			}
		}

		.section-delete {
			color: $red;
			margin-left: 0;
			width: 100%;
			justify-content: center;
		}
	}


	.custom-section-modal {
		display: grid;
		grid-template-columns: 1fr 3fr;

		.custom-section-select {
			ul {
				margin: 0;
				padding: 0;

				li {
					display: flex;
					align-items: center;
					height: 40px;
					padding-left: 16px;
					font-weight: 500;
					list-style-type: none;
					color: $border-light-gray;
					background-color: transparent;
					transition: 150ms background-color, 150ms color;
					cursor: pointer;
					@include radius-full;

					span {
						display: inline-block;
					}

					button {
						font-size: inherit;
						color: inherit;
					}

					&:hover,
					&.active {
						background-color: $med-gray;
					}

					&.active {
						color: $white;
					}
				}
			}
		}
	}

	.modal {
		.subtitle {
			display: inline-block;
			margin-bottom: 24px;
			font-weight: 500;
			color: $border-light-gray;
		}

	}

	.session-layout-options {
		padding: 16px;

		.session-layout-type {
			margin-bottom: 16px;

			.session-layout-label {
				display: inline-block;
				margin-bottom: 8px;
				font-size: 14px;
				font-weight: 500;
			}

			.session-layout-row {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 16px;

				.session-layout-option {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 100%;
					height: 111px;
					padding: 11px 15px;
					border: 1px solid $border-light-gray;
					transition: 150ms filter, 150ms border;
					cursor: pointer;
					@include radius-8;

					img {
						width: 100%;
					}

					label {
						margin-top: 15px;
						font-size: 14px;
						font-weight: 500;
					}

					&:hover {
						filter: contrast(1.15);
						border: 1px solid darken($border-light-gray, 10%);
					}

					&.active {
						border: 1px solid $cyan;
					}
				}
			}
		}
	}
}

.reference-title-tooltip {
	width: 200px;
	white-space: pre-line;
}

.editor-main-border {
	width: 100%;
	padding: 25px; //if you change this value, please change it in admin/document-head.tsx
	background-color: $off-black;
	overflow: hidden;
	position: relative;

	&.force-rtl {

		[class*="evt-"],
		[class$="-description"],
		[class$="-title"],
		.quill-wrap,
		.banner-title-container,
		.about-us-text,
		.product-item-content,
		.video-title-container,
		.sponsors-text-wrapper,
		.call-out-inner,
		.nav-item-container,
		.footer-nav,
		.editor.registration .title-container,
		.editor.registration .form-card .editable-text,
		.sidebar-container .side-nav-link,
		.preview-session-list-header,
		.preview-session-list-content .agenda-sessions-header .agenda-filters>*,
		.preview-session-list-content .agenda-sessions-list,
		.editor.session .speakers-title,
		.editor.session .speaker-data,
		.editor.session .agenda-session-card .agenda-session-card-content,
		.editor.session .tab-navigation button,
		.directory-main-content .profile-details,
		.modal-in-editor .profile-modal-content,
		.modal-in-editor .directory-buttons {
			text-align: start;
			direction: rtl;
		}
	}
}

.editor {
	width: $editor-desktop-width; //if you change this value, please change it in admin/document-head.tsx  -> widthMap
	height: 100%;
	overflow-y: visible;
	transition: $panel-transition-time;
	transform-origin: 50% 0;

	// keep desktop left aligned with translation header
	&.desktop {
		transform-origin: top left;
		margin: 0 auto 0 20px !important; // !important is needed to overwrite the document-head margin left settings
	}

	font-family: var(--bodyFont);
	color: var(--bodyTextColor);
	background-color: var(--containerColor);

	&.tablet {
		width: $editor-tablet-width; //if you change this value, please change it in admin/document-head.tsx  -> widthMap

		.container {
			width: 100%;
		}
	}

	&.mobile {
		width: $editor-mobile-width; //if you change this value, please change it in admin/document-head.tsx  -> widthMap

		.container {
			width: 100%;
		}
	}

	&.loading {
		filter: blur(2px) brightness(0.85);
	}

	.large-button {
		border-radius: 0;

		label {
			color: $dark-gray;
		}

		svg {
			path {
				fill: $dark-gray;
			}
		}
	}
}

@mixin sessionAndLandingHeight($percentage) {
	height: calc(#{$percentage} * 0.91); // need to account for language selector

	&.session {
		// We need to account for the difference made by the language menu in session
		height: calc(#{$percentage} * 0.935);
	}
}

//panel width is 360, left nav is ~100px and padding is 50px so 1950px means the editor will be 1440
@media only screen and (min-width: 2008px) {
	.editor:not(.no-scale) {
		transform: scale(1);
		@include sessionAndLandingHeight(100%);
	}
}

@media only screen and (max-width: 2007px) {
	.editor:not(.no-scale) {
		transform: scale(1);
		@include sessionAndLandingHeight(100%);

		&.panel-open.desktop {
			transform: scale(0.9);
			@include sessionAndLandingHeight(111%);
		}
	}
}

@media only screen and (max-width: 1864px) {
	.editor.desktop:not(.no-scale) {
		&.panel-open {
			transform: scale(0.8);
			@include sessionAndLandingHeight(125%);
		}
	}
}

@media only screen and (max-width: 1720px) {
	.editor.desktop:not(.no-scale) {
		transform: scale(0.9);
		@include sessionAndLandingHeight(111%);

		&.panel-open {
			transform: scale(0.7);
			@include sessionAndLandingHeight(135.5%);
		}
	}
}

@media only screen and (max-width: 1575px) {
	.editor.desktop:not(.no-scale) {
		transform: scale(0.8);
		@include sessionAndLandingHeight(125%);

		&.panel-open {
			transform: scale(0.6);
			@include sessionAndLandingHeight(158%);
		}
	}
}

@media only screen and (max-width: 1430px) {
	.editor.desktop:not(.no-scale) {
		transform: scale(0.7);
		@include sessionAndLandingHeight(142.5%);

		&.panel-open {
			transform: scale(0.5);
			@include sessionAndLandingHeight(200%);
		}
	}
}

@media only screen and (max-width: 1260px) {
	.editor.tablet:not(.no-scale) {
		&.panel-open {
			transform: scale(0.9);
			@include sessionAndLandingHeight(111%);
		}
	}
}

@media only screen and (max-width: 1286px) {
	.editor.desktop:not(.no-scale) {
		transform: scale(0.6);
		@include sessionAndLandingHeight(166.5%);

		&.panel-open {
			transform: scale(0.4);
			@include sessionAndLandingHeight(250%);
		}
	}
}

@media only screen and (max-width: 1315px) {
	.editor.tablet:not(.no-scale) {
		&.panel-open {
			width: 100%;
			transform: scale(0.8);
			@include sessionAndLandingHeight(125%);
		}
	}
}

@media only screen and (max-width: 1100px) {
	.editor.tablet:not(.no-scale) {
		&.panel-open {
			transform: scale(0.7);
			@include sessionAndLandingHeight(142.5%);
		}
	}
}

@media only screen and (max-width: 1143px) {
	.editor.desktop:not(.no-scale) {
		transform: scale(0.5);
		@include sessionAndLandingHeight(200%);

		&.panel-open {
			transform: scale(0.3);
			@include sessionAndLandingHeight(333.3%);
		}
	}

	.editor.tablet:not(.no-scale) {
		&.panel-open {
			transform: scale(0.6);
			@include sessionAndLandingHeight(166.5%);
		}
	}
}

@media only screen and (max-width: 950px) {
	.editor.tablet:not(.no-scale) {
		&.panel-open {
			transform: scale(0.5);
			@include sessionAndLandingHeight(200%);
		}
	}
}

@media only screen and (max-width: 1000px) {
	.editor.desktop:not(.no-scale) {
		transform: scale(0.4);
		@include sessionAndLandingHeight(250%);

		&.panel-open {
			transform: scale(0.2);
			@include sessionAndLandingHeight(500%);
		}
	}

	.editor.tablet:not(.no-scale) {
		transform: scale(0.8);
		@include sessionAndLandingHeight(125%);

		&.panel-open {
			transform: scale(0.4);
			@include sessionAndLandingHeight(250%);
		}
	}

	.editor.mobile:not(.no-scale) {
		&.panel-open {
			transform: scale(0.8);
			@include sessionAndLandingHeight(125%);
		}
	}
}

@media only screen and (max-width: 840px) {
	.editor.tablet:not(.no-scale) {
		width: 100%;
		transform: scale(0.7);
		@include sessionAndLandingHeight(125%);

		&.panel-open {
			transform: scale(0.4);
			@include sessionAndLandingHeight(250%);
		}
	}

	.editor.mobile:not(.no-scale) {
		&.panel-open {
			transform: scale(0.6);
			@include sessionAndLandingHeight(125%);
		}
	}
}
