
.content-editing-options {
	display: flex;

	.editing-button {
		display: flex;
		align-items: center;
		height: 32px;
		margin-left: 8px;
		padding: 0 16px;
		font-size: 12px;
	}

	.modal-wrapper {
		z-index: 2;

		.modal-body {

			p {
				text-align: center;
			}
		}

		.delete-modal-buttons {

			.delete-button {
				background-color: $pdf-red;
			}
		}
	}
}
