@import "./src/scss/old-deps";
@import "./src/scss/base/colors";
@import "./src/scss/abstracts/colors";

.custom-section-button {
	width: inherit;
	border-radius: 12px;
	padding: 24px;
	font-size: 12px;
	background-color: inherit;
	color: $cyan;

	svg {
		margin-right: 5px;
	}
}

.panel-container-v2 {

	// Sanity check to prevent spillover
	.session-module-grouping-list-container {
		display: flex;
		flex-direction: column;
		gap: 8px;

		.session-module-broadcast-item {
			height: 64px;
			margin-bottom: 24px;
			font-size: 14px;
			font-weight: 500;
			color: #e5e4ec;
			cursor: default;

			.session-display-card {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 16px;

				button {
					opacity: 0;
					padding: 0;
				}

				&:hover button {
					opacity: 1;
				}
			}
		}

		.top-level-card {
			padding: 24px 16px;
			display: flex;
			gap: 16px;
			cursor: pointer;

			button {
				padding: 0;
				margin-right: 8px;
			}

			.top-level-card-content {
				display: flex;
				flex: 1;
				justify-content: space-between;
				position: relative;

				.title {
					font-size: 14px;
					display: flex;
					align-items: center;
					margin-left: -12px;
					font-weight: 600;
					width: 100%;

					.drag-handle {
						position: relative;
						left: -23px;
						opacity: 0;

						&.non-sortable {
							visibility: hidden;
						}
					}

					.editable-text {
						min-height: 23px;
						min-width: 50px;

						&.slim-container {

							.max-character-count {
								top: -27px;
								right: -37px;
							}
						}
					}
				}

				.content-actions {
					display: flex;
					justify-content: center;
					align-items: center;

					button {
						margin: 0;
						background: none;

						&.top-level-card-delete-button {
							margin-right: 12px;
						}
					}
				}
			}

			&:hover {
				.top-level-card-content .title .drag-handle {
					opacity: 1;
					cursor: grab;
				}
			}
		}

		.new-module-group-container {
			height: 69px;
			border-radius: 12px;
			background-color: $gray-800;
			display: flex;
			align-items: center;
			padding: 16px;

			input {
				background-color: transparent;
				border: none;
				font-family: inherit;
				font-weight: 600;
				color: $white;
				width: 100%;

				&:focus {
					outline: none;
					border: none;
				}
			}
		}

		.add-tab-container {
			.add-tab-button {
				width: 100%;
				border-radius: 12px;
				color: $cyan;
				height: 69px;
				background-color: rgba(49, 47, 56, 0.5);
				transition: 150ms background-color;

				span {
					background: linear-gradient(90deg, $pink-lemonade, $lemonade);
					-webkit-background-clip: text;
					background-clip: text;
					-webkit-text-fill-color: transparent;
				}

				&:hover {
					background-color: $gray-800;
				}
			}
		}
	}
}