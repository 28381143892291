@import './src/scss/old-deps';
@import './src/scss/deps';

.typography-item-error {
	color: $rose;
}

.evt-heading-1 {
	@include eventHeading1();
}

.evt-heading-2 {
	@include eventHeading2();
}

.evt-heading-3 {
	@include eventHeading3();
}

.evt-heading-4 {
	@include eventHeading4();
}

.evt-heading-5 {
	@include eventHeading5();
}

.evt-body-text-1 {
	@include eventBodyText1();
}

.evt-body-text-2 {
	@include eventBodyText2();
}

.evt-caption-1 {
	@include eventCaption1();
}

.evt-caption-2 {
	@include eventCaption2();
}

.evt-caption-3 {
	@include eventCaption3();
}

@include media-query($sm-down) {

	.evt-heading-1 {

		&:not(.stable) {
			font-size: 40px;
			line-height: 50px;
		}
	}
	
	.evt-heading-2 {
		&:not(.stable) {
			font-size: 25px;
			line-height: 30px;
		}
	}

	.evt-body-text-1 {

		&:not(.stable) {
			line-height: 25px;
		}
	}

	.evt-caption-1 {
		&:not(.stable) {
			font-size: 12px;
			font-weight: 600;
		}
	}
}
