@import './src/scss/old-deps';
@import './src/scss/deps';

.blank-section {
	position: relative;

	&:hover {

		.section-controls-wrapper,
		.session-module-controls {
			.button-container {
				opacity: 1;
			}
		}
	}

	.section-controls-wrapper {
		.button-container .add-content {
			height: 32px;
			width: 32px;
			padding: 0;

			svg {
				margin: 0;
			}
		}
	}

	h1 {
		color: var(--bodyTextColor);
	}
}

.live-event-container .blank-section {
	.module-container {
		h1 {
			color: var(--bodyTextColor);
		}
	}
}

.blank-module-container {
	width: 100%;
}