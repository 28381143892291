/*
<!-- the base64 encoded SVG mask -->
<!-- the height of the element should be the same as the height of the text element you are trying to cover -->
<svg width="100" height="40" viewBox="0 0 100 40" version="1.1" id="gradient-mask" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <linearGradient id="Gradient1">
      <stop class="stop1" offset="0%" />
			<!-- begin the gradient halfway across the line -->
      <stop class="stop2" offset="50%" />
      <stop class="stop3" offset="80%" />
    </linearGradient>
  </defs>
  <style>
    #rect1 {
      fill: url(#Gradient1);
    }
    .stop1 {
      stop-color: rgba(0,0,0,1);
    }
    .stop2 {
      stop-color: rgba(0,0,0,1);
    }
    .stop3 {
      stop-color: rgba(0,0,0,0);
    }
  </style>
	<!-- black rectangle should allow all text in the upper portion to show -->
  <rect id="rect0" x="0" y="0" width="100" height="20" />
	
	<!-- gradient rectangle should stretch across the bottom of the image and fade to the right and be the height of one line -->
  <rect id="rect1" x="0" y="20" width="100" height="20" />
</svg>
<!-- to make changes, update the SVG above, the base64 encode it (in JS: btoa(svg)) and paste it into the mask-image property below -->
*/
@import "../../../../../../scss/deps";

.revealable-text {
	$descriptionLineHeight: 25px;
	$mask-image-2: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjQwIiB2aWV3Qm94PSIwIDAgMTAwIDQwIiB2ZXJzaW9uPSIxLjEiIGlkPSJncmFkaWVudC1tYXNrIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxkZWZzPgogICAgPGxpbmVhckdyYWRpZW50IGlkPSJHcmFkaWVudDEiPgogICAgICA8c3RvcCBjbGFzcz0ic3RvcDEiIG9mZnNldD0iMCUiIC8+CgoJCQk8IS0tIGJlZ2luIHRoZSBncmFkaWVudCBoYWxmd2F5IGFjcm9zcyB0aGUgbGluZSAtLT4KICAgICAgPHN0b3AgY2xhc3M9InN0b3AyIiBvZmZzZXQ9IjUwJSIgLz4KICAgICAgPHN0b3AgY2xhc3M9InN0b3AzIiBvZmZzZXQ9IjgwJSIgLz4KICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgPC9kZWZzPgogIDxzdHlsZT4KICAgICNyZWN0MSB7CiAgICAgIGZpbGw6IHVybCgjR3JhZGllbnQxKTsKICAgIH0KICAgIC5zdG9wMSB7CiAgICAgIHN0b3AtY29sb3I6IHJnYmEoMCwwLDAsMSk7CiAgICB9CiAgICAuc3RvcDIgewogICAgICBzdG9wLWNvbG9yOiByZ2JhKDAsMCwwLDEpOwogICAgfQogICAgLnN0b3AzIHsKICAgICAgc3RvcC1jb2xvcjogcmdiYSgwLDAsMCwwKTsKICAgIH0KICA8L3N0eWxlPgoJPCEtLSBibGFjayByZWN0YW5nbGUgc2hvdWxkIGFsbG93IGFsbCB0ZXh0IGluIHRoZSB1cHBlciBwb3J0aW9uIHRvIHNob3cgLS0+CiAgPHJlY3QgaWQ9InJlY3QwIiB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjIwIiAvPgoJCgk8IS0tIGdyYWRpZW50IHJlY3RhbmdsZSBzaG91bGQgc3RyZXRjaCBhY3Jvc3MgdGhlIGJvdHRvbSBvZiB0aGUgaW1hZ2UgYW5kIGZhZGUgdG8gdGhlIHJpZ2h0IGFuZCBiZSB0aGUgaGVpZ2h0IG9mIG9uZSBsaW5lIC0tPgogIDxyZWN0IGlkPSJyZWN0MSIgeD0iMCIgeT0iMjAiIHdpZHRoPSIxMDAiIGhlaWdodD0iMjAiIC8+Cjwvc3ZnPg==);
	$mask-image-5: url(data:image/svg+xml;base64,CgkJPHN2ZyB3aWR0aD0iODYwIiBoZWlnaHQ9IjEyNSIgdmlld0JveD0iMCAwIDg2MCAxMjUiIHZlcnNpb249IjEuMSIgaWQ9ImdyYWRpZW50LW1hc2siIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CgkJCTxkZWZzPgoJCQkJPGxpbmVhckdyYWRpZW50IGlkPSJHcmFkaWVudDEiPgoJCQkJCTxzdG9wIGNsYXNzPSJzdG9wMSIgb2Zmc2V0PSIwJSIgLz4KCgkJCQkJPCEtLSBiZWdpbiB0aGUgZ3JhZGllbnQgaGFsZndheSBhY3Jvc3MgdGhlIGxpbmUgLS0+CgkJCQkJPHN0b3AgY2xhc3M9InN0b3AyIiBvZmZzZXQ9IjUwJSIgLz4KCQkJCQk8c3RvcCBjbGFzcz0ic3RvcDMiIG9mZnNldD0iODAlIiAvPgoJCQkJPC9saW5lYXJHcmFkaWVudD4KCQkJPC9kZWZzPgoJCQk8c3R5bGU+CgkJCQkjcmVjdDEgewoJCQkJCWZpbGw6IHVybCgjR3JhZGllbnQxKTsKCQkJCX0KCQkJCS5zdG9wMSB7CgkJCQkJc3RvcC1jb2xvcjogcmdiYSgwLDAsMCwxKTsKCQkJCX0KCQkJCS5zdG9wMiB7CgkJCQkJc3RvcC1jb2xvcjogcmdiYSgwLDAsMCwxKTsKCQkJCX0KCQkJCS5zdG9wMyB7CgkJCQkJc3RvcC1jb2xvcjogcmdiYSgwLDAsMCwwKTsKCQkJCX0KCQkJPC9zdHlsZT4KCQkJPCEtLSBibGFjayByZWN0YW5nbGUgc2hvdWxkIGFsbG93IGFsbCB0ZXh0IGluIHRoZSB1cHBlciBwb3J0aW9uIHRvIHNob3cgLS0+CgkJCTxyZWN0IGlkPSJyZWN0MCIgeD0iMCIgeT0iMCIgd2lkdGg9Ijg2MCIgaGVpZ2h0PSIxMDAiIC8+CgoJCQk8IS0tIGdyYWRpZW50IHJlY3RhbmdsZSBzaG91bGQgc3RyZXRjaCBhY3Jvc3MgdGhlIGJvdHRvbSBvZiB0aGUgaW1hZ2UgYW5kIGZhZGUgdG8gdGhlIHJpZ2h0IGFuZCBiZSB0aGUgaGVpZ2h0IG9mIG9uZSBsaW5lIC0tPgoJCQk8cmVjdCBpZD0icmVjdDEiIHg9IjAiIHk9IjEwMCIgd2lkdGg9Ijg2MCIgaGVpZ2h0PSIxMjUiIC8+CgkJPC9zdmc+IA==);
	max-height: unset;
	transition:
		300ms max-height,
		150ms opacity;
	opacity: 0;
	user-select: none;
	pointer-events: none;

	section {
		margin-bottom: 15px;
		position: relative;
		max-height: unset;
	}

	.expand-description {
		padding: 0;
		background-color: transparent;
		height: $descriptionLineHeight;
		font-weight: bold;
		pointer-events: auto;
		display: inline;
		position: absolute;
		right: 15px;
		bottom: 0;
		font-family: var(--bodyFont);
		text-shadow:
			0px 0px 20px rgba(19, 19, 19, 0.75),
			0px 0px 15px rgba(19, 19, 19, 0.75),
			0px 0px 10px rgba(19, 19, 19, 0.75),
			0px 0px 5px rgba(19, 19, 19, 0.75);
	}

	p {
		// display: inline;
		// max-width: 300px;
		// overflow: hidden;
		position: relative;
		line-height: $descriptionLineHeight;
		max-height: unset;

		mask-position: right;
		-webkit-mask-position: right;
	}

	p,
	a,
	ul,
	ol {
		margin-bottom: 0;
		font-size: 14px;
		line-height: $descriptionLineHeight;
	}

	a {
		text-decoration: none;
		color: var(--accentColor);
		font-size: inherit;
	}

	p:empty {
		display: none;
	}

	&.maxlines-5 {

		max-height: calc($descriptionLineHeight * 5 + 15px);

		section {
			max-height: calc($descriptionLineHeight * 5);
		}


		p {
			max-height: calc($descriptionLineHeight * 5);
			mask-size: auto 100%;
			-webkit-mask-size: auto 100%;
		}

		&.show-fade {
			p {
				mask-image: $mask-image-5;
				-webkit-mask-image: $mask-image-5;
			}
		}
	}

	&.maxlines-2 {
		max-height: calc($descriptionLineHeight * 2 + 15px);

		section {
			max-height: calc($descriptionLineHeight * 2);
		}

		p {
			max-height: calc($descriptionLineHeight * 2);
			mask-size: 100% auto;
			-webkit-mask-size: 100%auto;
		}

		&.show-fade {
			p {
				mask-image: $mask-image-2;
				-webkit-mask-image: $mask-image-2;
			}
		}

	}

	&.revealed {
		opacity: 1;
	}
}


@include media-query($med-down) {
	.revealable-text {
		.expand-description {
			text-shadow: none;
		}
	}
}