@import './src/scss/old-deps';
@import './src/scss/deps';

.session-v2-modal {

	// session modal colors should ALWAYS use hard coded Classic/Limelight colors for both light/dark mode, regardless of theme pack
	// this was a product decision because modals should be generic and predicatble

	&.ev-modal-wrapper.modal-wrapper {

		.close {
			color: var(--gray-400);
			padding: 24px;
			transform: translateY(-24px);
			svg {
				width: 20px;
				height: 20px;

				path {
					fill: currentColor;
				}
			}
		}

		.modal {
			border-radius: 12px;

			p {
				color: inherit;
			}

			.session-v2-modal-placeholder {
				color: rgba(var(--bodyTextColor-rgb), 0.5);
			}
		}

		.modal-body {
			margin-bottom: 40px;
		}
	}

	.modal {
		background: var(--classicContainerColor);
		box-shadow: 0 13px 30px rgb(0 0 0 / 0.25);
	}

	&.dark-mode {

		&.ev-modal-wrapper.modal-wrapper {

			.close {
				color: var(--gray-300);
			}

			.modal {

				.session-v2-modal-placeholder {
					color: var(--gray-300);
				}
			}
		}
	}
}

@mixin responsive($size) {
	@if $size =='tablet' {

		&.ev-modal-wrapper.modal-wrapper {

			.close {
				top: 0;
				right: -8px;
				transform: none;

				svg {
					width: 16px;
					height: 16px;
				}
			}

			.modal-body {
				padding-right: 40px;
				padding-left: 40px;
			}
		}
	}

	@if $size =='mobile' {

		&.ev-modal-wrapper.modal-wrapper {

			.modal-body {
				margin-bottom: 25px;
				padding-right: 25px;
				padding-left: 25px;
			}
		}
	}
}

@include media-query($med-down) {

	.session-v2-modal {
		@include responsive('tablet');
	}
}

@include media-query($sm-down) {

	.session-v2-modal {
		@include responsive('mobile');
	}
}
