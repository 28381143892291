@import "./src/scss/old-deps";
@import "./src/scss/deps";

.tab-title-form {
	width: 100%;

	.tab-title-input {
		all: unset;
		border: 1px solid transparent;
		transition: all 0.2s ease-in-out;
		min-width: 50px;
		width: calc(100% - 20px);
		overflow: hidden;
		text-overflow: ellipsis;

		&:active,
		&:focus,
		&:hover {
			border: 1px solid $cyan;
			background-color: rgba(90, 228, 255, 0.05);
		}
	}
}