@import './src/scss/deps';
@import "./src/scss/old-deps";
@import './responsive-sizes-mixin.scss';

$error-color: rgba(255, 52, 52, 1);
$success-color: rgba(24, 209, 88, 1);

@keyframes errorShake {
	0% {
		opacity: 0;
		max-height: 0;
	}

	100% {
		opacity: 1;
		max-height: 50px;
	}
}

@keyframes signInMessage {
	0% {
		transform: translateX(100px);
		opacity: 0;
	}

	25% {
		opacity: 1;
	}

	100% {
		transform: translateX(0);
	}
}

.registration-v2 {
	display: flex;
	background-color: var(--backgroundColor);

	.logo-container {
		position: absolute;
		display: flex;
		top: 0;
		left: 0;
		width: 100%;
		height: 72px;
		padding: 16px 25px;
		justify-content: flex-start;

	}

	&.registration-above-the-fold-r,
	&.registration-above-the-fold-l {
		height: 100vh;

		&.left-type-layout {
			flex-direction: row-reverse;
		}

		.logo-container {
			display: none;
		}

		&.editor {
			height: 100%;

			.landing-page-v2 {
				height: 100%;
				padding-bottom: 230px;
			}

			.registration-slider {
				height: 100%;
				max-height: 100%;
			}

			.long-description-mobile-cta {
				bottom: 60px;
			}
		}
	}

	&.registration-hero-r,
	&.registration-hero-l {
		flex-direction: column;
		overflow: scroll;
		overflow-x: hidden;

		&.admin-theme-panel {
			width: 100%;
		}

		.registration-heading-container {
			height: 150px;
			min-height: 150px;

			.logo-container {
				display: none;
			}
		}

		.registration-form-container {
			width: 100%;
			padding: 100px 40px;
			flex-direction: row-reverse;
			gap: 50px;
			justify-content: center;
			height: auto;
		}


		&.left-type-layout {
			.registration-form-container {
				flex-direction: row;

				@media (max-width: $br-lrg) {
					flex-direction: column-reverse;
				}
			}
		}

		&.big-hero-layout {
			.registration-heading-container {
				height: 340px;
				min-height: 340px;
			}
		}
	}

	&.registration-modal {

		&:not(.editor) {
			height: 100vh;
		}

		.registration-heading-container {
			width: 100%;
		}

		&.admin-theme-panel {
			height: 100%;
			width: inherit;
		}
	}

	&.registration-landing-l {
		.landing-page-v2 {
			scrollbar-width: none; // there's a scrollbar on the right side of the page with a visible gap. This removes it.

			&::-webkit-scrollbar {
				width: 0px; // remove scrollbar but in safari
			}
		}
	}

	&.registration-landing-r,
	&.registration-landing-l {
		height: 100vh;

		&.left-type-layout {
			flex-direction: row-reverse;
			justify-content: center;
		}

		.landing-page-v2 {
			height: 100%;
			width: 100%;
			overflow: scroll;
			overflow-x: hidden;

			.marketing-page-footer {
				position: sticky;
			}
		}

		&.editor {
			height: 100%;

			.landing-page-v2 {
				height: 100%;
			}
		}

		.logo-container {
			width: inherit;
		}

		&>.logo-container {
			display: none;
		}

		.registration-heading-container {
			overflow: hidden;
		}

		.registration-form-container {
			height: 100%;
		}

		.agenda-session-card-buttons {
			.primary {
				display: none;
			}
		}
	}

	.registration-heading-container {
		position: relative;
		background-color: var(--backgroundColor);
		background-position: 50% 50%;
		background-size: cover;
		width: 100%;

		.registration-background-video {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: transparent;
			z-index: 0;
			object-fit: cover;
			pointer-events: none;
			user-select: none;
		}
	}

	&.reg-image-overlay.has-no-image {
		.registration-heading-container .registration-background-video {
			filter: grayscale(1);
		}

		.registration-heading-container::after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(var(--accentColor-rgb), 0.8);
			mix-blend-mode: lighten;
			pointer-events: none;
		}
	}

	@include media-query($lrg-up) {
		@include responsive-sizes("desktop");
	}

	@include media-query($xlrg-down) {
		@include responsive-sizes("small-desktop");
	}

	@include media-query($med-down) {
		@include responsive-sizes("tablet");
	}

	@include media-query($sm-down) {
		@include responsive-sizes("mobile");
	}

	@include media-query($xs-down) {
		@include responsive-sizes("mobile-xs");
	}

	// EDITOR SIZES
	&.editor.desktop {
		@include responsive-sizes("desktop");
	}

	&.editor.tablet {
		@include responsive-sizes("tablet");
	}

	&.editor.mobile {
		@include responsive-sizes("mobile-xs");
	}
}

.modal {
	.modal-body {
		.registration-form-container {
			width: 100%;

			.registration-panel-popout {
				width: 100%;
			}

			.registration-slider {
				max-width: unset;
				padding: 0;
			}
		}

		.layout-modal-body-v2 {
			display: flex;
			align-items: flex-start;
			justify-content: center;
			flex-wrap: wrap;
			gap: 4px;

			input[type="radio"] {
				display: none;

				&+label {
					border-radius: 14px;
					padding: 2px;
					position: relative;
					border: 1px solid transparent;

					img {
						width: 272px;
						height: 100%;
						position: relative;
						top: 2px;
						border-radius: 14px;
					}
				}

				&:checked+label {
					border: 1px solid $cyan;
				}
			}
		}
	}
}