@import './src/scss/base/mixins';
@import './src/scss/deps';

.new-dropdown {
	position: relative;
	height: 100%;

	&.open {

		.new-dropdown-icon {
			border-top-width: 0;
			border-bottom-width: 5px;
		}
	}

	.new-dropdown-value-container {
		display: flex;
		align-items: center;
		height: 24px;
		padding: 0;
		font-family: inherit;
		font-size: inherit;
		font-weight: inherit;
		color: inherit;
		background: none;
		border-radius: 0;
		position: relative;
		z-index: 2;
	}

	.new-dropdown-value {
		margin-right: $ev-gap-x3;
	}

	.new-dropdown-icon {
		border: 5px solid transparent;
		border-bottom-width: 0;
	}

	.new-dropdown-panel {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 999;
		padding: 50px;
		box-shadow: 0 10px 40px rgba(126, 130, 134, 0.2);
		transform: translateY(8px);

		&.limit-height {
			height: fit-content;
			overflow: auto;
		}

		&.menu-top-placement {
			bottom: 0;
			top: auto;
		}

		&.right {
			left: auto;
			right: 0;
		}
	}

	&.open {
		.new-dropdown-icon {
			border-bottom-color: var(--bodyTextColor);

			&.arrow-outline {
				transform: rotate(-135deg);
			}
		}
	}

	.new-dropdown-icon {
		border-top-color: var(--bodyTextColor);

		&.arrow-outline {
			border: solid rgba(var(--bodyTextColor-rgb), 0.5);
			border-width: 0 2px 2px 0;
			display: inline-block;
			padding: 3px;
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			transition: transform 250ms ease-in;
		}
	}

	.new-dropdown-panel {
		background-color: var(--containerColor);
	}
}

@include media-query($lrg-down) {
	.new-dropdown-panel-background {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(var(--backgroundColor-rgb), 0.5);
		backdrop-filter: blur(5px);
		z-index: 1;
	}
}

@include media-query($sm-down) {

	.new-dropdown {

		.new-dropdown-panel {
			padding: $ev-gap-x5 25px;
		}
	}
}
