@import './src/scss/old-deps';
@import './src/scss/deps';
@import './src/scss/base/_colors';
@import './src/scss/base/animations';

.admin-top-nav {
	display: flex;
	border-bottom: 1px solid #3c3a42;
	padding: 0 40px 0 20px;
	margin-bottom: 36px;
	width: 100%;
	padding-right: 126px;
	margin-left: auto;
	position: absolute;
	top: 0;
	left: 96px;

	.left {
		display: flex;
		width: 120%;

		a {
			color: $white;
			text-decoration: none;
			font-weight: 600;
			cursor: pointer;
		}

		.admin-top-nav-item {
			display: flex;
			position: relative;
			align-items: center;
			justify-content: center;
			padding: 30px 20px;
			cursor: pointer;

			&.active,
			&:hover {
				a {
					color: $white;
				}
			}

			&.active {
				>.gradient-nav-item {
					display: block;
				}
			}

			.gradient-nav-item {
				display: none;
				position: absolute;
				background: linear-gradient(45deg, $magenta, $yellow);
				bottom: -2px;
				height: 3px;
				border-radius: 20px;
				transition: width 1s linear;
				animation: tabAnimate 150ms;
			}

			&:hover {
				a {
					color: $gray-100;
				}
			}
		}
	}

	.create-event {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 24px;
		padding: 8px 16px;
		font-size: 12px;
		font-weight: 700;
		text-decoration: none;
		color: $dark-gray;
		background: $cyan;
		border: none;
		border-radius: 100px;

		&:visited {
			color: $dark-gray;
		}
	}

	.right {
		position: relative;
		display: flex;
		align-items: center;

		.brandlive-notification-button {
			display: flex;
			align-items: center;
			justify-content: center;
			height: fit-content;
			margin-right: 20px;
			margin-top: 16px;
			position: relative;

			svg {
				cursor: pointer;
			}

			.notification-count {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 10px;
				height: 16px;
				width: 16px;
				border-radius: 100px;
				background-color: $primary-red;
				position: absolute;
				top: -4px;
				right: -4px;
				cursor: pointer;
			}

			.notification-dropdown {
				position: absolute;
				top: 100px;
				height: 100px;
				width: 100px;
				background-color: $gray-900;
				z-index: 999;
				top: 30px;
				right: -7px;
				width: 364px;
				height: fit-content;
				padding: 16px 24px 24px 24px;
				border-radius: 16px;
				transition: 150ms opacity, 150ms transform;
				opacity: 0;
				transform: translateY(-8px);
				pointer-events: none;

				&.show {
					pointer-events: auto;
					opacity: 1;
					transform: translateY(0);
				}

				.header {
					color: $gray-300;
					font-size: 16px;
					font-weight: 600;
					line-height: 20px;
					letter-spacing: 0px;
					margin-bottom: 10px;
				}

				.notification-item {
					margin: 4px 0;
					color: $gray-200;
					font-size: 13px;
					font-weight: 600;
					line-height: 18px;
					letter-spacing: 0px;
					display: flex;
					align-items: center;
					min-height: 75px;

					a {
						color: $white;
					}

					img {
						height: 120px;
						width: 120px;
						margin: -50px -18px -50px -35px;
					}

					h1,
					h2,
					h3,
					h4,
					h5,
					h6 {
						font-size: 16px;
					}

					.notification-item-content {
						flex: 1;
					}
				}
			}
		}

		.profile-details-wrapper {
			left: -110px;
			top: 72px;
			height: fit-content;
			margin-left: -20px;
			margin-top: 5px;
			z-index: 999;
		}

		.profile-button,
		.notifications-button {
			width: 100%;
			margin-top: 16px;
			padding: 0;

			.profile-pic,
			.notifications {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 48px;
				height: 48px;
				overflow: hidden;
				background-color: $gray;
				border-radius: 100px;

				.user-initials {
					text-transform: uppercase;
				}

				img {
					width: 48px;
					height: 48px;
					object-fit: contain;
				}
			}

			.profile-pic-arrow {
				height: 48px;
				padding-left: 6px;
				@include flex-center;
			}
		}

		.notifications-button {
			padding: 0;
		}
	}
}