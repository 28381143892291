@import "./src/scss/old-deps";
@import "./src/scss/deps";

.marketing-page-navigation {
	position: sticky;
	top: 0;
	z-index: 105;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 95px;
	height: 100vh;
	height: 100svh;
	padding-top: 20px;
	background-color: var(--containerColor);

	// if preview mode, make sure header matches modal height to prevent scroll overflow
	&.preview-mode {
		max-height: calc(100vh - 180px); // to match the modal container max height
		nav {
			aside {
				bottom: 5% !important; // this only ever happens in preview mode and will not need to be overwritten, so it's all good to use !important
			}
		}
	}

	&.shorten-for-banner-announcement {
		height: calc(100vh - 48px);
	}

	.navigation-logo,
	.channel-avatar {
		display: flex;
		flex-direction: column;
		padding: 5px;
		overflow: hidden;
		border-radius: 8px;
		max-width: 80px;
	}

	.navigation {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding-top: 30px;

		.mobile-header-actions-container {
			display: flex;
			align-items: center;

			.mobile-header-language-selector {
				.new-dropdown-icon {
					display: none;
				}
				.language-dropdown {
					.new-dropdown-panel {
						text-align: center;
						background-color: var(--containerColor);
					}
				}
			}

			.mobile-header-language-selector {
				&.left-align {
					.language-dropdown {
						.new-dropdown-panel {
							text-align: left;
						}
					}
				}
			}
		}

		.navigation-link {
			@include flex-center;
			position: relative;
			flex-direction: column;
			width: 95px;
			min-height: 80px;
			text-decoration: none;
			text-align: center;
		}

		.header-nav-dropdown {
			.navigation-link {
				.navdrop-title {
					font: inherit;
				}
			}

			.navdrop-list {
				min-width: 124px;
				background-color: var(--containerColor);
				box-shadow: 0 10px 25px rgba(0, 0, 0, 0.4);

				.side-nav-link {
					&:hover {
						background: none;
					}
				}

				.navigation-link {
					flex-direction: row;
					justify-content: flex-start;
					width: 100%;
					min-height: 0;
					height: 40px;
					padding: 0 16px;
					white-space: nowrap;
					column-gap: 8px;

					svg {
						flex-shrink: 0;
						width: 16px;
						height: 16px;
					}
				}
			}

			.side-nav-link {
				padding: 0;
			}
		}

		// typical ::after class doesn't work here because the background-color needs to change with the dynamic color of the parent
		.side-nav-link {
			position: relative;
			.active-border {
				position: absolute;
				top: 0;
				right: -2px;
				width: 4px;
				background-color: transparent;
				height: 100%;
				border-radius: 2px;
				z-index: 5;
			}
			.active + .active-border {
				background-color: var(--bodyTextColor);
			}
		}

		.menu-tablet {
			padding-left: $ev-gap-x14;

			&.has-language-selector {
				padding-left: 8px;
			}

			.new-dropdown-value {
				@include flex-center;
				@include size(24px);
				margin: 0;
			}
		}

		.menu-tablet-popup {
			position: fixed;
			top: 72px;
			width: 100vw;
			height: 100%;
			text-align: center;
			background-color: var(--backgroundColor);
			border-radius: 0;
		}

		.tablet-links {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.back-to-session {
			width: 100%;
			text-align: center;
		}
	}

	&.new-navigation {
		.navigation-logo {
			flex-shrink: 0;
			max-width: 80px;
			padding: 0;
			border-radius: 0;
		}

		.navigation {
			padding-top: 16px;
		}

		&:not(.new-top-navigation) {
			.header-nav-dropdown {
				.navdrop-title {
					order: 99;
				}

				.navdrop-list {
					top: 0;
					left: 100%;
					right: auto;
					margin-top: 0;
				}
			}
		}

		&.new-top-navigation {
			.header-nav-dropdown {
				.navigation-link {
					flex-direction: row;
				}

				.navdrop-list {
					margin-top: -24px;

					.navigation-link {
						height: 48px;
					}
				}
			}

			.navigation {
				column-gap: 40px;

				.navigation-link {
					width: auto;
				}
			}
		}
	}
}

@include media-query($med-down) {
	.marketing-registered-page {
		flex-direction: column;
	}

	.marketing-page-navigation {
		display: flex;
		flex-direction: row;
		max-width: 1440px;
		width: 100%;
		height: 80px;
		padding: 13px 75px;

		.navigation {
			flex-grow: initial;
			margin-left: auto;
			padding: 0;

			.tablet-links {
				margin-bottom: 10px;

				.navigation-link {
					height: auto;
					min-height: fit-content;
					margin: 8px 0;

					&::after {
						display: none;
					}
				}
			}

			.header-navigation {
				padding: 0;
			}
		}
	}
}

@include media-query($sm-down) {
	.marketing-page-navigation {
		height: 60px;
		padding: 13px 25px;

		.navigation-logo,
		.channel-avatar {
			height: 34px;
		}

		.menu-tablet {
			padding-left: $ev-gap-x6;

			&.has-language-selector {
				padding-left: 8px;
			}

			.menu-tablet-popup {
				top: 60px;
				height: calc(100% - 60px);
				overflow: auto;

				.tablet-links {
					margin: 48px 0 68px;

					.navigation-link {
						margin: 12px 0;
					}
				}
			}
		}
	}
}
