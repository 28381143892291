@import './src/scss/old-deps';

.hr {
	width: 100%;
	height: 1px;
	margin: 0;
	background-color: $border-gray;
	border: none;
}

.center-content-view-port {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
}

.center-text {
	text-align: center;
}

.admin-gray-text {
	color: $gray-300;
	font-size: 16px;
}

.medium-text {
	font-size: 14px;
}

.small-text {
	font-size: 12px;
}