@import "./src/scss/deps";

.editor-empty-panel.panel-card.speaker-card.speaker-list-add-more {
	position: static;
	width: 100%;
	margin: 16px 0 0 0;
	background-color: $gray-800;
	padding: 0;
	height: 64px;

	.navigation-dropdown {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		margin: 0;
		width: 100%;
		height: 100%;
		border-radius: 12px;

		.navdrop-list {
			margin-top: 65px;
			width: 100%;
		}

		button {
			width: 100%;
			height: 100%;
			border-radius: inherit;
			background-color: $gray-800;
		}
	}

	.custom-add-btn {
		color: $cyan;
		@include flex-center;
	}
}
