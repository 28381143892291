@import "./src/scss/old-deps";
@import "./src/scss/deps";

.survey-results {
	aside {
		color: $gray-300;
		/* S1 */
		font-family: $font-family-poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
		padding-left: 24px;
		margin-top: -26px;
		position: fixed; // z-index breakout
		z-index: 2;
	}

	.survey-results-questions-list {
		margin-top: 16px;
		max-height: calc(100svh - 168px);
		overflow-y: auto;
		padding-bottom: 24px;
	}
}