@import './src/scss/deps';
@import './src/scss/old-deps';

.multi-select-container {
	.multi-select-component {

		.multi_select__option {

			&:hover {
				color: var(--accentColor);
				background: var(--containerColor);
			}
		}

		.multi_select__control {
			border-radius: var(--customInputBorderRadius, 2px);
			border: 1px solid rgba(var(--bodyTextColor-rgb), .5);
		}

		.multi_select__placeholder {
			font-size: 15px;
			font-family: var(--bodyFont);
			font-weight: 500;
		}

		.multi_select__menu,
		.multi_select__menu-list {
			background: var(--backgroundColor);
			height: calc(52px * 3);
		}

		.multi_select__multi-value {
			background: $darker-gray;
			padding: 0px 5px;
			color: $gray-400;
			border: 2px solid $gray-700;
		}
	}

}

.registration-v2 {
	.multi-select-container {
		.multi-select-component {

			.multi_select__option {

				&:hover {
					color: var(--accentColor);
					background: var(--containerColor);
				}
			}

			.multi_select__multi-value__label {
				color: #908CA0;
			}

			.multi_select__control {
				border-radius: var(--customInputBorderRadius, 2px);
				border: 1px solid rgba(var(--bodyTextColor-rgb, (131, 138, 161)), .5)
			}

			.multi_select__placeholder {
				font-size: 15px;
				font-family: var(--bodyFont);
				font-weight: 500;
			}

			.multi_select__menu,
			.multi_select__menu-list {
				background: var(--containerColor, $med-gray);
				height: calc(52px * 3);
				z-index: 2;
			}

			.multi_select__multi-value {
				background: $darker-gray;
				padding: 0px 5px;
				color: $gray-400;
				border: 2px solid $gray-700;
			}
		}

	}
}