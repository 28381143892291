@font-face {
	font-family: 'ev-icons';
	font-weight: normal;
	font-style: normal;
	src:
		url('/fonts/iconic/ev-icons.woff?66304072') format('woff'),
		url('/fonts/iconic/ev-icons.ttf?66304072') format('truetype'),
		url('/fonts/iconic/ev-icons.svg?66304072#ev-icons') format('svg');
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */

/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */

/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'ev-icons')
    src: url('../font/ev-icons.svg?66304072#ev-icons') format('svg');
  }
}
*/

[class^='ev-icon-'],
[class*='ev-icon-'] {
	display: inline-block;

	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'ev-icons' !important;
	line-height: 1;
	font-weight: normal;
	font-style: normal;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;
	speak: none;
}

@function unicode($str)
{
  @return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote("\"");
}

[class^='ev-icon-']::before,
[class*=' ev-icon-']::before {
	line-height: 1;
}

/* ICONS */
.ev-icon-add::before {
	content: unicode('e800');
}

.ev-icon-add-category::before {
	content: unicode('e801');
}

.ev-icon-add-invite::before {
	content: unicode('e802');
}

.ev-icon-add-ivent::before {
	content: unicode('e803');
}

.ev-icon-add-product::before {
	content: unicode('e804');
}

.ev-icon-align-center::before {
	content: unicode('e805');
}

.ev-icon-align-left::before {
	content: unicode('e806');
}

.ev-icon-align-right::before {
	content: unicode('e807');
}

.ev-icon-applause::before {
	content: unicode('e808');
}

.ev-icon-arrow-left::before {
	content: unicode('e809');
}

.ev-icon-arrow-right::before {
	content: unicode('e80a');
}

.ev-icon-arrow-right-bottom-filled::before {
	content: unicode('e80b');
}

.ev-icon-arrow-right-top-filled::before {
	content: unicode('e80c');
}

.ev-icon-arrow-up::before {
	content: unicode('e80d');
}

.ev-icon-attachment::before {
	content: unicode('e80e');
}

.ev-icon-bold::before {
	content: unicode('e80f');
}

.ev-icon-calendar::before {
	content: unicode('e810');
}

.ev-icon-camera::before {
	content: unicode('e904');
}

.ev-icon-camera-off::before {
	content: unicode('e811');
}

.ev-icon-camera-on::before {
	content: unicode('e812');
}

.ev-icon-camera-outline::before {
	content: unicode('e813');
}

.ev-icon-chat-comment::before {
	content: unicode('e814');
}

.ev-icon-chat-tab::before {
	content: unicode('e815');
}

.ev-icon-check::before {
	content: unicode('e816');
}

.ev-icon-checkbox::before {
	content: unicode('e817');
}

.ev-icon-checkbox-checked::before {
	content: unicode('e818');
}

.ev-icon-check-circle::before {
	content: unicode('e819');
}

.ev-icon-checked-partial::before {
	content: unicode('e81a');
}

.ev-icon-clock::before {
	content: unicode('e81b');
}

.ev-icon-close::before {
	content: unicode('e81c');
}

.ev-icon-close-add-circle::before {
	content: unicode('e81d');
}

.ev-icon-comment::before {
	content: unicode('e81e');
}

.ev-icon-company::before {
	content: unicode('e902');
}

.ev-icon-computer::before {
	content: unicode('e81f');
}

.ev-icon-copy::before {
	content: unicode('e820');
}

.ev-icon-cyan-checkmark::before {
	content: unicode('e821');
}

.ev-icon-desktop-slim::before {
	content: unicode('e822');
}

.ev-icon-dissolve::before {
	content: unicode('e823');
}

.ev-icon-document::before {
	content: unicode('e824');
}

.ev-icon-done-filled::before {
	content: unicode('e825');
}

.ev-icon-don-not-link::before {
	content: unicode('e826');
}

.ev-icon-download-speed::before {
	content: unicode('e827');
}

.ev-icon-drag-handle::before {
	content: unicode('e828');
}

.ev-icon-edit::before {
	content: unicode('e829');
}

.ev-icon-email::before {
	content: unicode('e82a');
}

.ev-icon-email-filled::before {
	content: unicode('e900');
}

.ev-icon-emoji::before {
	content: unicode('e82b');
}

.ev-icon-event::before {
	content: unicode('e906');
}

.ev-icon-facebook::before {
	content: unicode('e82c');
}

.ev-icon-feeds-tab::before {
	content: unicode('e82d');
}

.ev-icon-font::before {
	content: unicode('e82e');
}

.ev-icon-fonts::before {
	content: unicode('e82f');
}

.ev-icon-fullscreen::before {
	content: unicode('e830');
}

.ev-icon-hide::before {
	content: unicode('e831');
}

.ev-icon-home::before {
	content: unicode('e905');
}

.ev-icon-image::before {
	content: unicode('e832');
}

.ev-icon-image-inverse::before {
	content: unicode('e833');
}

.ev-icon-info-circle::before {
	content: unicode('e834');
}

.ev-icon-info-outline::before {
	content: unicode('e835');
}

.ev-icon-instagram::before {
	content: unicode('e836');
}

.ev-icon-internet-speed::before {
	content: unicode('e837');
}

.ev-icon-invite::before {
	content: unicode('e838');
}

.ev-icon-italic::before {
	content: unicode('e839');
}

.ev-icon-keyboard-arrow-down::before {
	content: unicode('e83a');
}

.ev-icon-keyboard-arrow-left::before {
	content: unicode('e83b');
}

.ev-icon-keyboard-arrow-right::before {
	content: unicode('e83c');
}

.ev-icon-keyboard-arrow-up::before {
	content: unicode('e83d');
}

.ev-icon-layout-grid::before {
	content: unicode('e83e');
}

.ev-icon-layout-list::before {
	content: unicode('e83f');
}

.ev-icon-link::before {
	content: unicode('e840');
}

.ev-icon-linkedin::before {
	content: unicode('e841');
}

.ev-icon-little-arrow-down::before {
	content: unicode('e842');
}

.ev-icon-little-arrow-up::before {
	content: unicode('e843');
}

.ev-icon-live::before {
	content: unicode('e844');
}

.ev-icon-log-out::before {
	content: unicode('e845');
}

.ev-icon-media-tab::before {
	content: unicode('e846');
}

.ev-icon-menu::before {
	content: unicode('e903');
}

.ev-icon-message::before {
	content: unicode('e847');
}

.ev-icon-messages::before {
	content: unicode('e848');
}

.ev-icon-mic-off::before {
	content: unicode('e849');
}

.ev-icon-mic-off-outline::before {
	content: unicode('e84a');
}

.ev-icon-mic-on::before {
	content: unicode('e84b');
}

.ev-icon-mic-on-outline::before {
	content: unicode('e84c');
}

.ev-icon-moderation-crossed-filled::before {
	content: unicode('e84d');
}

.ev-icon-moderation-filled::before {
	content: unicode('e84e');
}

.ev-icon-more-horiz::before {
	content: unicode('e84f');
}

.ev-icon-move::before {
	content: unicode('e850');
}

.ev-icon-new-window::before {
	content: unicode('e851');
}

.ev-icon-notes-tab::before {
	content: unicode('e852');
}

.ev-icon-ol::before {
	content: unicode('e853');
}

.ev-icon-palettes::before {
	content: unicode('e854');
}

.ev-icon-participants::before {
	content: unicode('e907');
}

.ev-icon-pause::before {
	content: unicode('e855');
}

.ev-icon-people::before {
	content: unicode('e856');
}

.ev-icon-phone::before {
	content: unicode('e857');
}

.ev-icon-phone-slim::before {
	content: unicode('e858');
}

.ev-icon-photo::before {
	content: unicode('e859');
}

.ev-icon-play::before {
	content: unicode('e85a');
}

.ev-icon-pointer-up::before {
	content: unicode('e85b');
}

.ev-icon-poll::before {
	content: unicode('e85c');
}

.ev-icon-product::before {
	content: unicode('e85d');
}

.ev-icon-question::before {
	content: unicode('e85e');
}

.ev-icon-question-filled::before {
	content: unicode('e85f');
}

.ev-icon-quiz::before {
	content: unicode('e860');
}

.ev-icon-radio-on::before {
	content: unicode('e861');
}

.ev-icon-redo::before {
	content: unicode('e862');
}

.ev-icon-refresh::before {
	content: unicode('e863');
}

.ev-icon-refresh-circle::before {
	content: unicode('e864');
}

.ev-icon-refresh-transparent::before {
	content: unicode('e865');
}

.ev-icon-reply-filled::before {
	content: unicode('e866');
}

.ev-icon-screen::before {
	content: unicode('e867');
}

.ev-icon-search::before {
	content: unicode('e868');
}

.ev-icon-send::before {
	content: unicode('e869');
}

.ev-icon-send-filled::before {
	content: unicode('e901');
}

.ev-icon-settings::before {
	content: unicode('e86a');
}

.ev-icon-settings-sliders::before {
	content: unicode('e86b');
}

.ev-icon-settings-transparent::before {
	content: unicode('e86c');
}

.ev-icon-share::before {
	content: unicode('e86d');
}

.ev-icon-share-screen::before {
	content: unicode('e86e');
}

.ev-icon-shield::before {
	content: unicode('e86f');
}

.ev-icon-slides::before {
	content: unicode('e870');
}

.ev-icon-smiley-face::before {
	content: unicode('e871');
}

.ev-icon-sound::before {
	content: unicode('e872');
}

.ev-icon-square-checkbox-all::before {
	content: unicode('e873');
}

.ev-icon-square-checkbox-selected::before {
	content: unicode('e874');
}

.ev-icon-square-checkbox-unselected::before {
	content: unicode('e875');
}

.ev-icon-stop-live::before {
	content: unicode('e876');
}

.ev-icon-tablet-slim::before {
	content: unicode('e877');
}

.ev-icon-three-lines-triangle::before {
	content: unicode('e878');
}

.ev-icon-titles-off::before {
	content: unicode('e879');
}

.ev-icon-titles-on::before {
	content: unicode('e87a');
}

.ev-icon-toggle-off::before {
	content: unicode('e87b');
}

.ev-icon-toggle-on::before {
	content: unicode('e87c');
}

.ev-icon-trash::before {
	content: unicode('e87d');
}

.ev-icon-trash-outline::before {
	content: unicode('e87e');
}

.ev-icon-twitter::before {
	content: unicode('e87f');
}

.ev-icon-ul::before {
	content: unicode('e880');
}

.ev-icon-undelete-filled::before {
	content: unicode('e881');
}

.ev-icon-underline::before {
	content: unicode('e882');
}

.ev-icon-undo::before {
	content: unicode('e883');
}

.ev-icon-upload::before {
	content: unicode('e884');
}

.ev-icon-upload-speed::before {
	content: unicode('e885');
}

.ev-icon-view::before {
	content: unicode('e886');
}

.ev-icon-virtual-bg::before {
	content: unicode('e887');
}

.ev-icon-virtual-bg-off::before {
	content: unicode('e888');
}

.ev-icon-wallpaper::before {
	content: unicode('e889');
}

.ev-icon-web::before {
	content: unicode('e88a');
}

.ev-icon-window::before {
	content: unicode('e88b');
}

.ev-icon-youtube::before {
	content: unicode('e88c');
}

.ev-icon-zoom-in::before {
	content: unicode('e88d');
}

.ev-icon-zoom-out::before {
	content: unicode('e88e');
}

.ev-icon-breakout-rooms::before {
	content: unicode('e88e');
}