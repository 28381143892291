@import './src/scss/old-deps';

.customized-color-picker {
	>div:nth-of-type(3) {
		margin-top: 15px !important;
	}

	[id^=rc-editable-input] {
		background-color: transparent;
		appearance: none;
		outline: none;
		color: var(--gray-200);
		border-radius: 12px;
		box-shadow: var(--gray-500) 0px 0px 0px 1px inset !important;
		transition: box-shadow 0.2s ease-in-out;
		text-align: center;
		width: 100% !important;

		// get sibling label
		&+label {
			color: var(--gray-200) !important;
		}

		&:focus {
			box-shadow: var(--gray-300) 0px 0px 0px 1px inset !important;
		}
	}

	.hue-horizontal-parent {
		padding: 4px 0 !important;
	}

	.hue-horizontal {
		padding: 0 !important;
		border-radius: 4px !important;

		div {
			div {
				height: 14px !important;
				width: 14px !important;
				border-radius: 100% !important;
				margin-top: -1px !important;
				transform: translateX(-7px) !important
			}
		}
	}

	.alpha-wrapper {
		div {
			border-radius: 4px !important;

			// target second div
			div~div {
				div {
					div {
						height: 14px !important;
						width: 14px !important;
						border-radius: 100% !important;
						margin-top: -1px !important;
						transform: translateX(-4px) !important
					}
				}
			}
		}
	}
}

.color-picker-eye-dropper-container {
	position: absolute;
	margin-top: -103px;
	width: 32px;
	height: 32px;
}

.color-picker {
	.hue-picker,
	.alpha-picker {
		width: 100% !important;
	}
}

.color-picker {
	&.card-container {
		padding: 24px;
		border-radius: var(--card-radius);
		background: var(--gray-700);
		height: 457px;
		width: 353px;
		margin: 0 auto;
	}
}

.transparent-nav-modal-picker {
	position: relative;
	padding-bottom: 64px;
}

.color-picker-recent-colors-container {
	border-top: 1px solid var(--gray-600);

	.color-picker-recent-colors-title {
		margin-top: 12px;
		color: var(--gray-300);
		margin-bottom: 8px;
	}

	.color-picker-recent-colors-list-container {
		display: flex;
		flex-flow: wrap;
		gap: 4px;

		.color-picker-recent-colors-list-item {
			width: 32px;
			height: 32px;
			border-radius: 4px;
		}
	}
}
