@import './src/scss/old-deps';
@import './src/scss/deps';

.session-details-modal-speakers {
	@include list-reset;
	display: flex;
	gap: 5px 15px;
	flex-wrap: wrap;

	li {
		display: flex;
		justify-content: center;
		gap: 8px;
	}

	.speaker-image {
		width: 30px;
		height: 30px;
		border-radius: 8px;

	}

	button {
		line-height: 20px;
		color: inherit;
	}
}

.registration-v2 {
	.session-details-modal-speakers {
		li {
			button {
				cursor: unset;
			}
		}
	}
}