.admin-header {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 96px;
	height: 100vh;
	background: $med-gray;
	border-right: 1px solid $border-gray;

	&:not(.in-editor) {
		z-index: 4; // keeps z-index above "event-details-header-container" (z-index: 2) when on events details page
	}

	img {
		height: 48px;
	}

	.left {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 96px;
		height: 100%;

		.channel-avatar {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 48px;
			height: 48px;
			border-radius: 12px;
			pointer-events: none;

			img {
				width: 36px;
				height: 36px;
				text-indent: -9999px;
				object-fit: contain;

				&[alt]::after {
					display: none;
				}
			}
		}

		.pathing {
			display: flex;
			align-items: center;
			margin-left: 24px;

			a {
				text-decoration: none;
				color: $med-light-gray;
			}

			&>a {
				font-size: 16px;
				font-weight: 700;
				color: $cyan;
			}

			&>span {
				margin: 0 8px;
				font-size: 16px;
				font-weight: 700;
			}

			.navigation-dropdown {
				right: 0;

				.navdrop-title {
					font-size: 16px;
					font-weight: 700;
					color: $border-light-gray;
				}

				svg {
					width: 10px;

					path {
						fill: $light-gray;
					}
				}

				.navdrop-list {
					right: calc(50% - 80px);
					min-width: 160px;
					max-height: calc(100vh - 100px);
					overflow: auto;

					a {
						&:hover {
							color: $white;
						}
					}
				}
			}
		}
	}

	.right {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-bottom: 16px;

		//Admin view
		.create-event {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 24px;
			padding: 8px 16px;
			font-size: 12px;
			font-weight: 700;
			text-decoration: none;
			color: $dark-gray;
			background: $cyan;
			border: none;
			border-radius: 100px;

			&:visited {
				color: $dark-gray;
			}
		}

		.profile-button,
		.notifications-button {
			width: 100%;
			margin-top: 16px;
			padding: 0;

			.profile-pic,
			.notifications {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 48px;
				height: 48px;
				overflow: hidden;
				background-color: $gray;
				border-radius: 100px;

				img {
					width: 48px;
					height: 48px;
					object-fit: contain;
				}
			}

			.profile-pic-arrow {
				height: 48px;
				padding-left: 6px;
				@include flex-center;
			}
		}

		.notifications-button {
			padding: 0;
		}
	}
}
