@import './src/scss/deps';

$error-color: rgba(255, 52, 52, 1);
$success-color: rgba(24, 209, 88, 1);

.evt-field-wrapper {
	position: relative;

	&+.evt-field-wrapper {
		margin-top: 25px;
	}

	.field-group {
		margin-bottom: 0;
		font-family: var(--bodyFont);

		input[type='text'],
		input[type='number'],
		input[type='email'],
		input[type='password'],
		textarea {
			height: 55px;
			padding: 15px 20px;
			font-family: var(--bodyFont);
			font-size: 15px;
			line-height: 25px;
			font-weight: 500;
			color: var(--bodyTextColor);
		}

		textarea {
			height: 200px;
			padding-left: var(--textAreaLeftPadding, 20px);
		}

		&.error,
		&.hit-max-chars.entered {
			input[type='text'],
			input[type='number'],
			input[type='email'],
			input[type='password'],
			textarea {
				border-color: $error-color !important;
			}
		}

		&.success,
		&.ok {
			input[type='text'],
			input[type='number'],
			input[type='email'],
			input[type='password'],
			textarea {
				border-color: $success-color !important;
			}
		}

		&.is-admin {
			textarea {
				color: white;
				font-family: var(--font-family-poppins);
			}
		}

		&.show-password-icon {
			margin-top: 25px;

			>input {
				padding-right: 50px;
			}
		}

		.toggle-password-visibility-icon {
			cursor: pointer;
			padding: 10px; // To make the click area larger
			position: absolute;
			top: 35px;
			right: 10px;

			>svg>path {
				fill: var(--bodyTextColor);
			}
		}


		.label-wrapper {
			display: flex;
		}

		.warn-max-chars {
			font-size: 10px;
			flex: 1 1 auto;
			text-align: end;
			color: $error-color;
			visibility: hidden;
			opacity: 0;
			transition: visibility 0s 1s, opacity 1s linear;
		}

		&.hit-max-chars {
			.warn-max-chars {
				opacity: 1;
				visibility: visible;
				transition: opacity 0s linear;
			}

			.evt-field-input {
				color: $error-color !important;
			}
		}
	}

	.field-error,
	.field-success {
		position: absolute;
		top: 100%;
		margin-top: 5px;
		font-size: 10px;
		line-height: 15px;
		font-weight: 600;
	}

	.field-error {
		color: $error-color;
	}

	.field-success {
		color: $success-color;
	}

	.custom-checkbox {
		position: relative;
		padding-left: 25px;
		font-family: var(--bodyFont);

		label {
			color: inherit !important;
		}

		span {
			position: absolute;
			top: 3px;
			left: 0;
		}

		svg path {
			fill: var(--bodyTextColor);
		}
	}
}

@mixin eventInputMobile($size) {
	@if $size =='mobile' {
		.evt-field-wrapper {
			.field-group {
				input[type='text'],
				input[type='number'],
				input[type='email'],
				input[type='password'],
				textarea {
					font-size: 16px;
				}
			}
		}
	}
}

@include media-query($xs-down) {
	:not(.editor) {
		@include eventInputMobile('mobile');
	}
}

.editor.mobile {
	@include eventInputMobile('mobile');
}
