.call-to-action-settings {
	margin: 0 auto;

	.call-to-action-upper {
		display: grid;
		grid-template-columns: 1fr 2fr;
		gap: 16px;
		align-items: flex-end;
		margin-top: 32px;
		margin-bottom: 32px;

		.call-to-action-dropdown {
			max-height: unset;
		}
	}

	.field-group.text {
		margin: 0;
	}

	.field-group.select {
		margin: 0;

		.select-dropdown {
			top: initial !important;
			left: initial !important;
			margin-right: 40px;
		}
	}
}
