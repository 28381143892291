// Template names
$ev-template-classic: "Apollo";
$ev-template-bold: "Studio54";
$ev-template-fun: "O2";

// Global variables
$ev-scroll-width: 8px;

// Gap variables (base gap is 4px)
$ev-gap-x1: 4px;
$ev-gap-x2: $ev-gap-x1 * 2;
$ev-gap-x3: $ev-gap-x1 * 3;
$ev-gap-x4: $ev-gap-x1 * 4;
$ev-gap-x5: $ev-gap-x1 * 5;
$ev-gap-x6: $ev-gap-x1 * 6;
$ev-gap-x7: $ev-gap-x1 * 7;
$ev-gap-x8: $ev-gap-x1 * 8;
$ev-gap-x9: $ev-gap-x1 * 9;
$ev-gap-x10: $ev-gap-x1 * 10;
$ev-gap-x11: $ev-gap-x1 * 11;
$ev-gap-x12: $ev-gap-x1 * 12;
$ev-gap-x13: $ev-gap-x1 * 13;
$ev-gap-x14: $ev-gap-x1 * 14;
$ev-gap-x15: $ev-gap-x1 * 15;
$ev-gap-x16: $ev-gap-x1 * 16;
$ev-gap-x17: $ev-gap-x1 * 17;
$ev-gap-x18: $ev-gap-x1 * 18;
$ev-gap-x19: $ev-gap-x1 * 19;
$ev-gap-x20: $ev-gap-x1 * 20;
$ev-gap-x21: $ev-gap-x1 * 21;
$ev-gap-x22: $ev-gap-x1 * 22;
$ev-gap-x23: $ev-gap-x1 * 23;
$ev-gap-x24: $ev-gap-x1 * 24;
$ev-gap-x25: $ev-gap-x1 * 25;
$ev-gap-x26: $ev-gap-x1 * 26;
$ev-gap-x27: $ev-gap-x1 * 27;
$ev-gap-x28: $ev-gap-x1 * 28;
$ev-gap-x29: $ev-gap-x1 * 29;

$editor-mobile-width: 375px;
$editor-tablet-width: 768px;
$editor-desktop-width: 1440px;
