@import './src/scss/deps';

$error-color: rgba(255, 52, 52, 1);
$success-color: rgba(24, 209, 88, 1);

@keyframes errorShake {
	0% {
		opacity: 0;
		max-height: 0;
	}

	100% {
		opacity: 1;
		max-height: 50px;
	}
}

@keyframes signInMessage {
	0% {
		transform: translateX(100px);
		opacity: 0;
	}

	25% {
		opacity: 1;
	}

	100% {
		transform: translateX(0);
	}
}

.registration {
	position: relative;
	display: flex;
	height: 100vh;
	overflow-x: hidden; // this fixes a safari overflow scroll bug

	.registration-slider {
		position: absolute;
		top: 120px;
		left: 40px;
		bottom: 0px;
		width: 400%;
		overflow: hidden;

		.registration-panel {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: calc(25% - 80px);
			opacity: 0;
			transition: transform 150ms, opacity 150ms;
			overflow-y: auto;
			overflow-x: hidden;
			border: none;

			&:not(.active) {
				z-index: -1;
			}

			&.fixed {
				opacity: 1;
			}

			&.active {
				opacity: 1;
			}

			&:nth-child(1) {
				transform: translateX(0);
			}

			&:nth-child(2) {
				transform: translateX(100%);
			}

			&:nth-child(3) {
				transform: translateX(200%);
			}

			&:nth-child(4) {
				transform: translateX(300%);
			}
		}

		&.two {
			.registration-panel {
				&:nth-child(1) {
					transform: translateX(-100%);
				}

				&:nth-child(2) {
					transform: translateX(0%);
				}

				&:nth-child(3) {
					transform: translateX(100%);
				}

				&:nth-child(4) {
					transform: translateX(200%);
				}
			}
		}

		&.three {
			.registration-panel {
				&:nth-child(1) {
					transform: translateX(-200%);
				}

				&:nth-child(2) {
					transform: translateX(-100%);
				}

				&:nth-child(3) {
					transform: translateX(0%);
				}

				&:nth-child(4) {
					transform: translateX(100%);
				}
			}
		}

		&.four {
			.registration-panel {
				&:nth-child(1) {
					transform: translateX(-300%);
				}

				&:nth-child(2) {
					transform: translateX(-200%);
				}

				&:nth-child(3) {
					transform: translateX(-100%);
				}

				&:nth-child(4) {
					transform: translateX(0%);
				}
			}
		}
	}

	.registration-heading-container {
		flex-grow: 1;
		background-color: var(--backgroundColor);
		background-position: 50% 50%;
		background-size: cover;
	}

	.registration-start-time {
		display: flex;
		align-items: center;
		margin-bottom: 15px;

		&.hideStartTime {
			display: none;
		}
	}

	.registration-start-time-title {
		margin-bottom: 0;
	}

	.registration-start-time-date {
		@extend .reg-evt-heading-5;
		@include size(50px);
		margin-inline-end: 10px; // accounts for rtl languages
		padding-top: $ev-gap-x2;
		text-align: center;
		background-color: var(--containerColor);
		border-radius: 12px;

		.month.evt-caption-2,
		.month {
			line-height: 1;
			color: var(--secondaryAccentColor);
			text-align: center;
		}
	}

	.registration-title {
		margin-bottom: 15px;
		font-family: var(--headingFont);
		white-space: pre-wrap;
	}

	.registration-description {
		max-width: 524px;
		margin-bottom: 0;
		font-size: 18px;
		line-height: 30px;
	}

	.registration-form-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		flex-shrink: 0;
		max-width: 455px;
		width: 100%;
		position: relative;
	}

	.registration-logo {
		display: flex;
		align-items: center;

		img {
			max-height: 40px;
		}
	}

	.registration-form-inner {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		min-height: 0;
		margin-right: -40px;
		padding-right: 40px;
		padding-bottom: 10px;

		.evt-field-label {
			font-size: initial;
			color: var(--bodyTextColor);
		}

		.sign-in {
			margin: 0;
			padding: 0;
			text-align: left;
			align-items: flex-start;
			justify-content: flex-start;
			padding-bottom: 16px;
			color: var(--accentColor);
		}

		&.verification {
			padding-top: 60px;

			p {
				font-size: 20px;
				line-height: 32px;
				animation: 300ms signInMessage;
			}
		}

		.sso-sign-in-text {
			text-align: center;
			margin: 20px 0 15px 0;
		}
	}

	.registration-form-title {
		margin-bottom: 25px;
		font-family: var(--headingFont);
		word-break: break-word;
	}

	.registration-form {
		width: 100%;
		min-height: 0;
	}

	.registration-fields {
		flex-grow: 1;
	}

	.registration-actions {
		display: flex;
		flex-shrink: 0;
		width: 100%;
		padding-top: 25px;

		&.primary {
			flex-direction: column;

			.evt-button {
				font-family: var(--bodyFont);

				&+.evt-button,
				&+.button-icon-wrapper {
					margin-top: 25px;
				}
			}
		}

		&.secondary {

			.registration-button {
				font-family: var(--bodyFont);

				&+.registration-button,
				&+.button-icon-wrapper {
					margin-left: 15px;
				}
			}

			&>div {
				margin-left: auto;
			}
		}
	}

	.registration-button {
		height: 55px;
		padding: 15px 25px;
		line-height: 25px;
		font-size: 13px;
		font-weight: 700;
		color: var(--containerColor);
		background-color: var(--accentColor);
		border-radius: var(--customButtonBorderRadius, 100px);

		&.secondary {
			color: var(--accentColor);
			background-color: rgba(var(--accentColor-rgb), 0.1);
		}

		&.icon-only {
			width: 55px;
			padding-right: 0 !important;
			padding-left: 0 !important;
		}

		img {
			@include size(20px);
			margin-right: 10px;
		}
	}

	.button-icon-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 -10px;

		.evt-button {
			margin: 0 10px !important;

			img {
				width: 20px;
			}
		}
	}

	.registration-avatar {
		position: relative;
		margin-bottom: 90px;
		background-position: 50% 50%;
		background-size: cover;
		border-radius: var(--customImageBorderRadius, 12px);
		background-color: rgba(var(--accentColor-rgb), 0.1);

		&::before {
			content: '';
			display: block;
			padding-bottom: 100%;
		}

		button.avatar-upload {
			position: absolute;
			left: 50%;
			bottom: 20px;
			transform: translateX(-50%);
			@include size(55px);
			color: var(--containerColor);
			background-color: var(--accentColor);
		}

		.navdrop-list {
			top: 100%;
			margin-top: $ev-gap-x2;
			background-color: var(--containerColor);
			box-shadow: 0 4px 16px rgba(0, 0, 0, 0.16);
			border-radius: 0;

			button {
				position: relative;
				justify-content: flex-start;
				padding: 10px $ev-gap-x4;
				font-size: 13px;
				font-weight: 600;
				white-space: nowrap;
				color: var(--accentColor);
				background-color: transparent;
				border-radius: 0;

				&:hover {
					background-color: var(--backgroundColor);
				}

				input {
					@include size(100%);
					position: absolute;
					top: 0;
					left: 0;
					font-size: 0;
					opacity: 0;
					cursor: pointer;
				}
			}

			[class*='ev-icon-'] {
				margin-right: $ev-gap-x2;
			}
		}
	}

	.registration-avatar-name {
		@include position-center;
		font-size: 75px;
		line-height: 90px;
		text-transform: uppercase;
		color: var(--accentColor);
	}

	.registration-error {
		color: $white;
		text-align: center;
		background: $red;
		padding: 3px 6px;
		animation: 150ms errorShake;
		font-weight: 500;
		opacity: 0.9;
		margin-top: 6px;
		border-radius: 8px;

		&.custom-failed-passcode {
			margin-bottom: 10px;
			white-space: pre-line;

			p {
				margin: initial
			}
		}
	}

	&.form-right {

		.registration-heading-container {
			padding: 80px;
		}

		.registration-form-container {
			padding: $ev-gap-x10;
			padding-bottom: 0;
			background-color: var(--containerColor);
		}

		.registration-logo {
			margin-bottom: 35px;
		}
	}

	&.form-left {
		.registration-slider {
			width: 100%;
		}

		flex-direction: row-reverse;

		.registration-heading-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 60px 120px 60px 270px;
		}

		.registration-form-container {
			background-color: var(--containerColor);
			padding: 100px 0 0 150px;
		}

		.registration-logo {
			margin-bottom: 25px;
		}

		.registration-slider {
			position: absolute;
			top: 180px;
			left: 150px;
			overflow: visible
		}

		.registration-panel.active {
			width: 480px;
			overflow: visible;
		}

		.registration-panel {
			width: 480px;
		}

		.registration-form {
			padding: $ev-gap-x10;
			border-radius: var(--customContainerBorderRadius, 18px);
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
			// on form-left this has to get applied here to maintain the border radius on the bottom of the container
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			background-color: var(--containerColor);
		}
	}

	.currencies {
		margin-bottom: 15px;

		.currency-btn {
			height: fit-content;
			padding: 0 8px;
			margin-right: 4px;
		}
	}

	.ticketing {
		.title {
			font-weight: bold;
		}

		.ticket {
			padding: 15px;
			display: flex;
			justify-content: space-between;
			background-color: $lighter-gray;
			margin-bottom: 15px;
			border-radius: 12px;
			font-size: 14px;

			p {
				margin: 0;
			}

			.ticket-name {
				font-weight: bold;
				text-transform: uppercase;
				color: var(--accentColor);
			}

			.ticket-description {
				font-size: 13px;
				color: $alt-gray;
				font-weight: bold;
			}

			.ticket-price {
				color: $alt-gray;
				font-weight: bold;
			}

			.ticket-sold-out {
				align-self: center;
				width: 25%;
				text-transform: uppercase;
			}
		}

		.ticket-checkbox {
			width: 25%;
			display: flex;
			align-items: center;
			justify-content: center;

			.custom-checkbox {
				height: 26px;
				width: 26px;
			}
		}


		.credit-card {
			display: flex;
			flex-direction: column;

			.small-cols {
				display: flex;
				justify-content: space-between;

				.evt-field-wrapper:first-of-type {
					margin-top: 25px;
				}

				div {
					width: 48%;

					div {
						width: 100%;
					}
				}
			}

			.stripe-wrapper {
				height: 55px;
				padding: 15px 20px;
				font-family: var(--bodyFont);
				font-size: 15px;
				line-height: 25px;
				font-weight: 500;
				color: var(--bodyTextColor);
				border-width: 1px;
				border-style: solid;
				width: 100%;
				transition: 150ms border;
				padding-top: 18px;

				&.error {
					border-color: $error-color !important;
				}

				&.ok {
					border-color: $success-color !important;
				}
			}
		}
	}

	p.sold-out {
		padding-bottom: 25px;
	}

	.sold-out-container {
		position: relative;
		opacity: 0.4;
	}

	.google-signin {
		img {
			margin-right: 6px;
		}

		svg.waiting-indicator circle {
			// need !important here to override inline styling
			fill: rgba(var(--bodyTextColor-rgb), .5) !important;
		}
	}

	// general ui
	.reg-evt-heading-5 {
		@include eventHeading5();
	}

	.external-registration {
		margin-bottom: 24px;
	}
}

@include media-query($xlrg-down) {

	.registration {

		.registration-description {
			max-width: none;
		}

		&.form-right {

			.registration-heading-container {
				padding-right: $ev-gap-x10;
				padding-left: $ev-gap-x10;
			}
		}

		&.form-left {
			.registration-form-container {
				left: $ev-gap-x10;
			}
		}
	}
}

@include media-query($med-down) {

	.registration {

		.registration-heading-container {
			display: none;
		}

		.registration-logo {
			flex-shrink: 0;
			height: 80px;
			padding-right: 75px;
			padding-left: 75px;
		}

		.registration-form-container {
			width: 100%;
		}

		.registration-form {
			margin: 0 auto;
			align-self: center;
			width: 375px;
			padding: $ev-gap-x10 0;
		}

		.registration-form-inner {
			flex-grow: 0;
		}

		&.form-left {

			.registration-slider {
				width: 400%;
			}

			.registration-heading-container {
				display: none;
			}

			.registration-logo {
				margin-bottom: 0;
			}

			.registration-form-container {
				position: static;
				padding: 0;

				.registration-form {
					padding: $ev-gap-x10 25px 25px;
				}
			}

			.registration-slider {
				top: 120px;
				left: 40px;

				.registration-panel {
					width: calc(25% - 80px);
				}
			}

			.registration-form {
				padding: $ev-gap-x10 0;
				box-shadow: none;
			}
		}

		&.form-right {

			.registration-logo {
				margin-bottom: 0;
			}

			.registration-form-container {
				padding: 0;
			}
		}
	}
}

@include media-query($sm-down) {

	.registration {

		.registration-logo {
			height: 60px;
			padding-right: $ev-gap-x5;
			padding-left: $ev-gap-x5;
		}

		.registration-form {
			width: 100%;
			padding: $ev-gap-x10 25px 25px;
		}

		.registration-form-inner {
			margin-right: -25px;
			padding-right: 25px;
		}


		&.form-left {

			.registration-form {
				padding: $ev-gap-x10 25px 25px;
			}

			.registration-slider {
				overflow: hidden;
				top: 50px;
				left: 5px;

				.registration-panel {
					width: 25%;
				}
			}

			.registration-panel.active {
				overflow: auto;
			}
		}

		&.form-right {
			.registration-slider {
				top: 50px;
				left: 5px;

				.registration-panel {
					width: 25%;
				}
			}

			.registration-form-container {
				padding: 0;
			}
		}
	}
}