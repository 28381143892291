@import "./src/scss/base/mixins";
@import "./src/scss/deps";



@mixin tooltipLabel {
	padding: 8px;
	background-color: $gray-1000;
	border-radius: 4px;
	position: absolute;
	top: -40px;
	left: 50%;
	opacity: 0;
	transform: translateX(-50%) translateY(50%);
	transition:
		100ms opacity,
		150ms transform;
	white-space: nowrap;
	pointer-events: none;
	z-index: 2;
	color: #fff;
	text-align: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	/* 133.333% */
	letter-spacing: 0.24px;
	user-select: none;
	height: 32px;

	&.left-aligned {
		left: 0%;
		transform: translateX(0%);
	}
	&.right-aligned {
		left: 100%
	}
}


label.video-control-popup {
	@include tooltipLabel();
}

#modal-global-container .modal-wrapper .modal:not(.event-preview) {
	label.video-control-popup {
		color: white;
	}
}

.streams-action-button, .session-details-modal-time, .streamed-date-text-container {
	&:hover {
		label {
			transition-delay: 100ms;
			opacity: 1;
			transform: translateX(-50%);
			&.left-aligned {
				left: 0%;
				transform: translateX(0%);
			}
			&.right-aligned {
				left: 100%
			}
		}
	}
}

.video-meta-actions .social-link-icon-container:first-child label.video-control-popup {
	margin-left: 16px;
}
