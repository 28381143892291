@import './src/scss/old-deps';
@import './src/scss/base/colors';
@import './src/scss/abstracts/colors';

.breakout-session-modal {
	&.modal-wrapper {
		.modal {
			padding-right: 8px;
			max-height: calc(100vh - 80px);

			.modal-header {
				padding-bottom: 8px;
			}

			.modal-body {
				overflow-y: auto;
				max-height:none;
				overflow-x:visible;	
				padding-right: 32px;
				scroll-margin: 0px;

				&.scrollbar-visible{
					padding-right: 26px;
				}
			}

			.tag-select{
				border: 0px;
				margin-bottom: 0px;

				margin-bottom: 26px;
				overflow-x: visible;
				overflow-y: auto;
				max-height: 108px;
				border-radius: 22px;
			}
		}
	}
}

.session-modal,
.breakout-session-modal {



	::-webkit-scrollbar{
		width: 6px;
		margin-left:-6px
	}

	.scroll-outside-border{
		border: 1px solid #908CA0;
		border-radius: 22px;
		position: absolute;
		left: 0;
		right:32px;
		top:20px;
		bottom:0;
		pointer-events:none;
	}

	.header{
		//padding-right: 32px;

		.tag-select{
			overflow-y: auto;
			max-height: none;
			overflow-x: visible;
			padding-right: 32px;
			box-sizing: border-box;
		}

		.divider{
			background-color: $gray-700;
			height: 1px;
			margin: 0px;
			margin-right: 32px;
			width:auto;
		}
	}

	.footer{
		display: flex;
		flex: 1;
		justify-content: space-between;
		padding-right: 32px;
		flex-direction: row-reverse;

		.toggle{
			display: flex;
			justify-content: center;
			align-items: center;

			span{
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;

				margin-left: 10px;
			}
		}
	}

	

	&.modal-wrapper {

		.modal {

			.modal-title-container {
				margin-bottom: 24px;
				display: flex;
				justify-content: space-between;
				margin-right: 32px;
				align-items: center;

				.field-group {
					&.switch{
						label{
							margin: 0px;
							margin-right: 8px;
						}
					}
				}

				.tooltip {
					 margin-top: -4px; 
					 margin-left: 0px;
					 margin-right: 0px;
				}
			}
		}
	}

	.modal-header-select {

		.modal-header-select-title {
			display: flex;
			justify-content: space-between;
			margin-bottom: 8px;
			margin-left: 16px;
			font-size: 12px;
			line-height: 16px;
			font-weight: 700;
			letter-spacing: 0.4px;
			color: $gray-400;
		}
	}

	.header-line-break {
		height: 1px;
		width: auto;
		background-color: #3C3A42;
		margin-top: 16px;
		margin-left: -40px;
		margin-right: -40px;
		margin-bottom: -8px;
	}

	.breakout-rooms-options {

		.field-group.radio-group {
			margin-top: 0;
			margin-bottom: 24px;
		}
	}

	.breakout-rooms-header {
		display: flex;
		justify-content: space-between;
		margin-top: 16px;
		margin-right:32px;

		.breakout-rooms-header-title {
			margin-left: 16px;
			font-size: 12px;
			line-height: 16px;
			font-weight: 700;
			letter-spacing: 0.4px;
			color: $gray-400;
		}
	}

	.breakout-rooms-container {
		border-radius: 12px;
		overflow-y: scroll;
	
		height: 100%;
	}



	.breakouts-loading {
		height: 60px;
		width: 100%;
		@include flex-center;
	}
}

	.breakout-room-card {
		position: relative;
		padding: 16px 24px 0;
		margin-bottom: 8px;
		background: $gray-800;
		border-radius: 12px;

		.field-group input{
			height: 48px;
		}

		.header{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-right: 0px;
			margin-bottom: 16px;

			.top-right{
				display: flex;
				align-items: center;
			}
		}

		.breakout-room-card-title {
			font-size: 12px;
			line-height: 16px;
			font-weight: 500; 
			letter-spacing: 1px;
			text-transform: uppercase;
			color: $gray-400;
		}

		.breakout-room-card-body {
			display: flex;
			gap: 16px;

			.image{
				display: flex;
				flex-direction: column;
			
				.image-label {
					color: #908CA0;
					font-size: 12px;
					line-height: 16px;
					font-weight: 700;
					display:inline-block;
				}

				.image-container{
					width: 112px;
					height: 112px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 12px;
					position: relative;

					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
						border-radius: 12px;
					}

					button {
						position: absolute;
						top: 8px;
						right: 8px;
						filter: drop-shadow(0 1px 2px rgba(0,0,0,0.75))
					}
				}
			}
			

			.right-side{
				display: flex;
				flex-direction: column;
				flex: 1;

				.top-row{
					display: flex;
					gap: 16px;

					.room-name {
						flex: 1;
					}
				}

				.max-users {
					width: 102px;
				}

				.description {
					margin-bottom: 0px;
					padding-bottom: 0px;
				}
			}

			
		}

		

		.breakout-room-card-upload {
			padding-bottom: 24px;

			.large-button {
				margin-top: 0;
			}
		}

		.breakout-room-card-add-users {
			padding-bottom: 24px;

			.tag-select {
				margin-bottom: 0;
			}
		}

		.breakout-room-card-remove {
			padding: 0px;
			background-color: transparent;
			margin-left: 18px;
		}
	}
