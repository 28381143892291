@import '../../../../../../../scss/deps';

.card-container.marketing-email-opt-in {
	margin-top: 8px;
	display: flex;
	align-items: center;

	.toggle-card {
		width: 100%;
		min-height: 64px;
		border-radius: 12px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-align: left;
		padding: 16px 22px;
		transition: background-color 0.18s;
		background-color: $image-gray;

		label {
			font-size: 14px;
			display: flex;
			align-items: center;

			svg {
				margin-left: 4px;
			}
		}

		div {
			display: flex;
		}
	}
}