@import "./src/scss/deps";
@import "./src/scss/old-deps";

.create-ui {
	display: flex;
	align-items: center;
	padding-left: 16px;
	border-left: 1px solid $gray;

	.changes {
		margin: 0 24px;
		font-size: 12px;
		font-weight: 700;
		color: $alt-gray;
	}

	.icon-toggle {

		svg {
			width: 14px;
			height: 14px;

			&#icon-phone-slim {
				height: 12px;
			}
		}
	}

	.navigation-dropdown {
		.navdrop-title {
			svg {
				margin-right: 0;
				margin-top: 3px;
			}
		}

		.navdrop-list {
			min-width: 160px;
			background-color: $gray-700;

			>button {
				text-align: left;
				font-size: 13px;
			}

			span {
				width: 100%;
				display: flex;
				align-items: center;

				svg {
					margin-right: 8px;
				}
			}
		}
	}

	button.small {
		display: flex;
		align-items: center;
		margin-right: 8px;
		font-size: 12px;
		font-weight: 700;

		svg {
			margin-right: 8px;
		}

		&.primary {
			margin: 0;
			color: $off-black;

			&.publish-btn {
				height: 30px;
				width: auto;
			}
		}
	}
}
