@import "./src/scss/base/mixins";
@import "./src/scss/deps";
@import "./mixins/base.scss";


@keyframes tabAnimate {
	from {
		transform: scaleX(0);
		opacity: 0;
	}

	to {
		transform: scaleX(1);
		opacity: 1;
	}
}

.session,
.session-editor-wrapper {
	.page-container-body {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 100%;
		background-color: var(--backgroundColor);

		.session-module {
			width: 100%;
			padding: 25px 130px;
			background-color: transparent;

			&.single-joined-room {
				padding: 0;
			}

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-family: var(--headingFont);
			}
		}

		.container {
			width: 100%;
			max-width: 1140px;
			margin: 0 auto;
		}
	}
}

// BP-2610 This is a temporary fix for the Google Meet options dialog.
// The options are loaded in an iframe, which needs to be full screen and on top of everything.
// Google says they are working on a fix that will not require this css.
.dialog {
	border: none;
	height: 100vh;
	left: 0;
	overflow: auto;
	position: fixed;
	top: 0;
	width: 100vw;
	/* The quantum wiz dialog (which goes inside this iframe) has a z-index of * 5000, so we will have that index here too */
	z-index: 5000;
}

// .meet-room-above-the-fold {
// 	width: 100%;
// 	// height: 100%;
// }

.session {
	display: flex;

	&.new-top-navbar {
		display: block;
	}

	&.video-v2 {
		.session-main-content {
			.session-header-and-stream {
				position: relative;
				z-index: 103;

				.session-header {
					z-index: 6;
				}
			}
		}
	}

	&.above-the-fold {
		.session-main-content {
			.session-header-and-stream {
				padding-top: var(--banner-height, 0px);

				&.has-banner-announcement {
					@include media-query($sm-down) {
						padding-top: 0;
					}
				}
			}
		}
	}

	&.below-the-fold {
		.session-main-content {
			.session-header-and-stream {
				padding-top: var(--banner-height, 0px);

				&.has-banner-announcement {
					@include media-query($sm-down) {
						padding-top: 0;
					}
				}
			}
		}
	}

	.session-main-content {
		width: 100%;
		background: var(--backgroundColor);

		.session-header-and-stream {
			position: relative;

			&.new-navigation {
				display: flex;
			}

			&.single-session-nav {
				flex-direction: column;
			}
		}

		&.new-navigation {
			width: 100%;
		}

		// Placeholder to allow Meet container to move into a PiP and not resize the screen.
		#meet-room-placeholder {
			width: 100%;

			// Ensure we don't re-size the screen and cause a state
			// that just disables the PIP immediately.
			&.meet-room-pip {
				height: 100vh;

				&.dark-mode {
					background-color: $dark-gray;
				}
			}
		}

		#meet-room {
			width: 100%;
			background-color: $white;

			.meet-header-gradient {
				width: 100%;
				height: 64px;
				position: absolute;
				background: $session-header-background;

				&.dark-mode {
					background: $dark-gray;
				}
			}

			&.dark-mode {
				background-color: $dark-gray;
			}

			height: 100vh;
			min-height: 550px;

			iframe {
				padding: 64px 0 12px 0;
				width: 100%;

				height: 100vh;
				// Min-height is specificaly for landscape on phone to push the display
				// larger than the screen height so the bottom of the video is not cut off.
				// Need a 'full screen' solution for this.
				min-height: 550px;
				border: none;
			}

			// When in full screen mode, Meet should take up
			// the entire screen.  While this is for mobile, it should scale
			// for any screen size.
			&.full-screen {
				height: 100vh;
				min-height: 300px;

				iframe {
					padding: 0;
					height: 100vh;
					min-height: 300px;
				}

				div.meet-header-gradient {
					display: none;
				}
			}

			// Push meet into a Picture-In-Picture view.
			// Height to allow no content to be cut off.
			&.meet-room-pip {
				position: fixed;
				bottom: 20px;
				right: 10px;
				z-index: 1000;
				width: 380px;
				height: 550px;
				border-radius: 12px;
				box-shadow: 0px 13px 30px 0px rgba(0, 0, 0, 0.25);

				iframe {
					padding: 0;
					height: 550px;
				}

				div.meet-header-gradient {
					display: none;
				}
			}
		}

		&.fireside-session {
			display: flex;
			flex-direction: column;

			// attendee fireside session with display-language-toggle
			.modules-container.display-language-dropdown {
				.language-dropdown-container {
					top: 35px;
					// make room for fireside button
				}
			}

			// host fireside session with display-language-toggle
			.host-control-container~.modules-container.display-language-dropdown {
				.language-dropdown-container {
					margin-right: 325px;
				}

				&:not(.horizontal) {
					padding-top: 50px;

					.language-dropdown-container {
						top: -45px;
					}
				}
			}
		}

		&.single-session {
			width: 100%;
		}
	}

	.host-control-container {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 24px 130px 0;

		button {
			z-index: 1; // needed for button to be clickable when overlapping with tabs
		}
	}

	.host-control-container+.horizontal {
		margin-top: -80px;
	}

	.modules-container {
		background-color: var(--backgroundColor);
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		z-index: 4;

		.sticky-component-wrapper {
			padding: 50px 130px 0;
			width: 100%;
			max-width: 1440px;
		}

		.tab-navigation:not(.page-container-below-the-fold-tabs) {
			list-style: none;
			padding: 0;
			margin: 0 0 30px;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			gap: 10px 64px;
			width: 100%;

			input {
				display: none;
			}

			label {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				font-size: 13px;
				font-weight: 600;
				line-height: 30px;
				padding: 0px 0px 4px;
				color: var(--bodyTextColor);
				cursor: pointer;
				transition: color 150ms;

				svg {
					margin-right: 8px;

					path {
						fill: var(--bodyTextColor);
						transition: fill 150ms;
					}

					&.noIcon {
						display: none;
					}
				}

				&:hover {
					color: var(--accentColor);

					svg {
						path {
							fill: var(--accentColor);
						}
					}
				}
			}

			input:checked+label {
				color: var(--accentColor);
				position: relative;

				&::after {
					content: " ";
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					height: 2px;
					background-color: var(--accentColor);
					animation: tabAnimate 150ms;
				}

				svg {
					path {
						fill: var(--accentColor);
					}
				}
			}
		}

		&.vertical {
			flex-direction: row;
			align-items: stretch;

			.tab-navigation {
				max-width: 380px;
				padding: 25px 40px 0 130px;
				flex-direction: column;
				align-self: flex-start;

				label {
					width: 100%;
					padding: 16px 0px 16px 20px;

					svg {
						margin-right: 10px;
					}
				}

				input:checked+label {
					&::after {
						height: 0px;
					}
				}
			}

			.page-container-body {
				.session-module {
					padding-left: 30px;
				}
			}
		}

		&.display-language-dropdown {

			// only applied to language dropdown in body
			.language-dropdown-container {
				width: 100%;
				max-width: 1440px;
				display: flex;
				justify-content: flex-end;
				padding: 0 130px 0;

				position: absolute;
				top: 50px;

				.language-dropdown {
					.new-dropdown-panel {
						min-width: unset;
						padding: 10px;
						right: 0;
						left: unset;
					}
				}
			}

			&.horizontal .tab-navigation {
				max-width: calc(100% - 60px);
			}

			&.vertical,
			&.no-tabs {
				padding-top: 80px;
			}
		}
	}

	// language dropdown styling shared between header and body
	.modules-container,
	.session-header header {
		.language-dropdown {
			.language-dropdown-title {
				display: none;
			}

			.new-dropdown-value-container {
				border: 1px solid var(--bodyTextColor);
				border-radius: var(--customButtonBorderRadius, 8px);
				padding: 16px 8px;
			}

			.new-dropdown-value {
				font-weight: 600;
				font-size: 16px;
				line-height: 20px;
				font-family: var(--bodyFont);
				text-transform: lowercase;
			}

			.new-dropdown-icon {
				@include arrow($arrowDown);
				border-color: var(--bodyTextColor);
				margin-bottom: 5px;
			}

			&.open {
				.new-dropdown-icon {
					@include arrow($arrowUp);
					border-color: var(--bodyTextColor);
				}
			}
		}
	}
}

@keyframes menuDropDown {
	0% {
		max-height: 0;
	}

	100% {
		max-height: 100%;
	}
}

// Breaking on the large breakpoint up, makes it so it's vertical layout, but not scrollable of this css
// Break 1 px above the breakpoint fixes this
// This impacts iPads in general since they are often 1024px wide
@media (min-width: 1025px) {
	.live-event-container {
		.session.above-the-fold {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			overflow: hidden;
		}
	}
}

// break-lrg = 1024px
@include media-query($med-down) {
	.session {
		.session-main-content {
			width: 100%;
		}

		.session-header {
			header {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.mobile-header-actions-container {
				display: flex;
				align-items: center;
			}

			.session-title-text {
				max-width: 180px;
			}
		}

		.menu-tablet-popup {
			position: fixed;
			top: 52px; // conflicting - could be 72px?
			width: 100vw;
			height: 100%;
			text-align: center;
			background-color: var(--backgroundColor);
			border-radius: 0;
			z-index: 3;
			animation: menuDropDown 150ms;
		}

		&.video-v2 {
			.menu-tablet-popup {
				z-index: 10000;
			}
		}

		.navigation-link {
			@include flex-center;
			position: relative;
			flex-direction: column;
			width: 95px;
			margin: 6px 0;
			text-decoration: none;
		}

		.navigation-link,
		.new-dropdown-value {
			color: var(--bodyTextColor);

			&:hover {
				color: var(--accentColor);
			}

			&.active {
				color: var(--accentColor);

				&::after {
					background-color: var(--accentColor);
				}
			}
		}

		.tablet-links {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.marketing-page-navigation {
			display: none;
		}
	}
}

@include media-query($sm-down) {
	.session {
		.navigation-link {
			white-space: nowrap;
		}
	}
}

// break-xlrg = 1120px;
@include media-query($lrg-down) {
	:not(.editor) {
		@include baseSessionResponsive("small-desktop");
	}
}

// break-lrg = 1024px;
@include media-query($med-down) {
	:not(.editor) {
		@include baseSessionResponsive("med-desktop");
	}
}

// med = 768px
@include media-query($sm-down) {
	:not(.editor) {
		@include baseSessionResponsive("tablet");
	}
}

.editor.mobile {
	@include baseSessionResponsive("mobile");
}

.session-preview-wrapper {
	position: relative;

	&.mobile {
		.session {
			padding-top: 0 !important;
		}
	}
}

// xs = 480px
@include media-query($xs-down) {
	:not(.editor) {
		.modules-container {

			&.horizontal {
				padding-top: 0;
			}

			.sticky-component-wrapper {
				padding: 0 !important;
			}

			.tab-navigation {
				flex-wrap: nowrap;
				padding-top: 12px;
				padding-left: 25px;
				padding-right: 25px;

				li:last-child {
					padding-right: 25px;
				}
			}
		}

		@include baseSessionResponsive("mobile");
	}

	.session .session-main-content {
		.sticky-component-wrapper {
			padding: 0;
		}
	}
}
