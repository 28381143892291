
@import './src/scss/deps';

$label-error-color: #ff3434;
$label-success-color: #18d158;

.evt-field-wrapper {

	.evt-field-label {
		display: inline-block;
		margin: 0 0 5px;
		line-height: 20px;
		font-weight: 400;
	}

	.evt-field-label-required::after {
		content: '*';
		margin-left: 4px;
	}

	.field-error {
		color: $label-error-color;
	}

	.field-success {
		color: $label-success-color;
	}

	.evt-tooltip {
		position: relative;
		display: inline-block;
		margin: 0 5px 5px;
		cursor: pointer;

		.evt-tooltip-text {
			position: absolute;
			top: -5px;
			left: 20px;
			z-index: 1;
			width: 250px;
			padding: 10px 15px;
			font-size: 12px;
			line-height: 18px;
			font-weight: 400;
			visibility: hidden;
			color: #fff;
			background-color: $gray;
			border-radius: 6px;

			&::after {
				content: '';
				position: absolute;
				top: 13px;
				right: 100%;
				margin-top: -5px;
				border-width: 5px;
				border-style: solid;
				border-color: transparent $gray transparent transparent;
			}
		}

		&:hover {

			.evt-tooltip-text {
				visibility: visible;
			}
		}

		[class^='ev-icon-'],
		[class*='ev-icon-'] {
			display: block;
			line-height: 20px;
			color: var(--accentColor);
		}
	}
}
