@import "./src/scss/old-deps";
@import "./src/scss/deps";
@import "../../../panel/session-panel-mixins";

.question-prompt-response-card {
	padding: 16px;
	border-radius: 12px;
	background: var(--grayscale-800, #312f38);
	margin-bottom: 8px;

	&.anonymous {
		background-color: var(--grayscale-1000, #1a1a1f);
		background-image: url('data:image/svg+xml;utf9,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none"><path d="M36.6755 39.0682C37.1726 39.5692 37.961 39.6038 38.4581 39.0682C38.9895 38.5325 38.9552 37.7723 38.4581 37.2712L11.2217 9.83284C10.7246 9.33176 9.91897 9.33176 9.42189 9.83284C8.94196 10.3166 8.94196 11.146 9.42189 11.6298L36.6755 39.0682ZM38.201 34.2474C42.9318 31.0855 46 26.7485 46 24.8133C46 21.4959 37.0355 10.8868 24.0086 10.8868C21.2489 10.8868 18.6778 11.3534 16.3296 12.1482L20.6147 16.4678C21.6603 16.0186 22.8087 15.7767 24.0086 15.7767C28.9965 15.7767 33.0245 19.768 33.0245 24.8133C33.0245 26.0228 32.7674 27.1978 32.2875 28.2172L38.201 34.2474ZM24.0086 38.7399C27.0082 38.7399 29.7678 38.2042 32.2189 37.2885L27.8995 32.9343C26.7339 33.539 25.4141 33.8673 24.0086 33.8673C19.0035 33.8673 15.0097 29.7377 14.9926 24.8133C14.9926 23.3792 15.3183 22.0315 15.9011 20.8393L10.2618 15.1201C5.17102 18.3512 2 22.8609 2 24.8133C2 28.1136 11.136 38.7399 24.0086 38.7399ZM29.185 24.4678C29.185 21.6168 26.9053 19.3015 24.0429 19.3015C23.8372 19.3015 23.6315 19.3015 23.4601 19.336L29.1508 25.0725C29.1679 24.8825 29.185 24.6751 29.185 24.4678ZM18.8321 24.4851C18.8321 27.336 21.1804 29.6686 24.0086 29.6686C24.2143 29.6686 24.4199 29.6513 24.6428 29.6341L18.8664 23.8112C18.8492 24.0358 18.8321 24.2604 18.8321 24.4851Z" fill="%23312F38"/></svg>');
		background-size: 48px 48px;
		background-position: center 28px;
		background-repeat: no-repeat;
	}

	.question-prompt-response-card-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8px;
		gap: 8px;

		.chat-avatar {
			height: 24px;
			width: 24px;
			min-width: 24px;
			min-height: 24px;
			margin: 0;
			background-color: $gray-900;
		}

		.question-prompt-response-card-header-text {
			color: var(--grayscale-300-grey, #bfbbcf);
			font-feature-settings:
				"clig" off,
				"liga" off;
			flex: 1;
			display: flex;
			align-items: flex-center;
			gap: 4px;

			// username in card - clamp width for long names
			label {
				text-transform: capitalize;
				max-width: 160px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.question-prompt-response-card-header-actions {
			@include flex-center;
			gap: 8px;

			.answer-check {
				display: flex;
				cursor: default;
				opacity: 0.8;
			}
		}
	}

	.question-body {
		color: var(--grayscale-100-white, #fff);
		/* H6 */
		font-family: $font-family-poppins;
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;
		/* 142.857% */
		word-break: break-word;
	}

	.question-metadata {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: var(--grayscale-300-grey, #bfbbcf);
		text-align: right;
		font-feature-settings:
			"clig" off,
			"liga" off;
		margin-bottom: 12px;

		.question-likes {
			span {
				display: flex;
				align-items: center;
				gap: 4px;
			}
		}

		.question-replies-count {
			cursor: pointer;
			transition: color 150ms;

			&:hover {
				color: white;
			}
		}
	}

	.reply-input {
		position: relative;

		.field-group.text {
			input {
				padding: 8px 12px 8px 28px;
				height: 32px;

				font-feature-settings:
					"clig" off,
					"liga" off;

				/* P2 */
				font-family: $font-family-poppins;
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: 16px;
				/* 133.333% */
				letter-spacing: 0.24px;
				color: $white;

				&::placeholder {
					color: var(--grayscale-400-dark-grey, #908ca0);
				}
			}
		}

		&.sending {
			opacity: 0.75;
		}

		.send-question-reply {
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			padding: 12px;
		}
	}

	.question-prompt-response-replies {
		.question-prompt-response-replies-list {
			display: flex;
			flex-direction: column;
			gap: 8px;
			margin-top: 16px;
			max-height: 40svh;
			overflow-y: auto;
			overflow-x: hidden;

			.question-reply-row {
				display: flex;
				gap: 8px;
				padding-left: 16px;
				position: relative;
				cursor: pointer;

				.question-prompt-response-reply-actions {
					position: absolute;
					right: 0;
					top: 0;
					opacity: 0;
					transition: 150ms opacity;
					display: flex;
					gap: 8px;

					.smart-tooltip-text {
						font-size: 11px;
					}

					button {
						svg {
							path {
								transition: 150ms fill;
							}
						}

						&:hover {
							svg {
								path {
									fill: $rose;
								}
							}
						}
					}
				}

				&:hover {
					.question-prompt-response-reply-actions {
						opacity: 1;
					}
				}

				.chat-avatar {
					height: 32px;
					width: 32px;
					min-width: 32px;
					min-height: 32px;
					margin: 0;
					background-color: $gray-900;
				}

				.question-prompt-response-reply-text {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: center;

					.question-prompt-response-reply-text-header {
						color: var(--grayscale-300-grey, #bfbbcf);
						font-feature-settings:
							"clig" off,
							"liga" off;

						/* L2 */
						font-family: $font-family-poppins;
						font-size: 11px;
						font-style: normal;
						font-weight: 600;
						line-height: 16px;
						/* 145.455% */
						letter-spacing: 0.22px;
						display: flex;
						align-items: center;
						gap: 4px;
					}

					.question-reply-body {
						color: var(--grayscale-100-white, #fff);
						font-feature-settings:
							"clig" off,
							"liga" off;

						/* S3 */
						font-family: $font-family-poppins;
						font-size: 12px;
						font-style: normal;
						font-weight: 600;
						line-height: 20px;
						/* 166.667% */
						letter-spacing: 0.24px;
						position: relative;
						word-break: break-word;

						p {
							margin: 0;
						}
					}
				}
			}
		}

		.question-replies-loading {
			@include flex-center;
			height: 60px;
		}
	}

	.question-approval-actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 4px;
	}

	.navigation-dropdown.session-panel-dropdown {
		.question-card-options {
			@include meatballMenuButton;
		}
	}
}
