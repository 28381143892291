@mixin xxlargeBaseResponsive($size) {
	@if $size =="xxlarge" {
		.about-us {
			.module-container {
				padding-right: $ev-gap-x10;
				padding-left: $ev-gap-x10;
			}

			&.about-us-standard,
			&.about-us-standard_flip {
				.about-us-gallery-wrapper {
					width: 57%;
				}

				.about-us-gallery {
					height: 436px;
				}
			}

			&.about-us-standard,
			&.about-us-standard_full {
				.about-us-gallery-wrapper {
					margin-right: $ev-gap-x6;
				}
			}

			&.about-us-standard_flip,
			&.about-us-standard_full_flip {
				.about-us-gallery-wrapper {
					margin-left: $ev-gap-x6;
				}
			}

			&.about-us-standard_full,
			&.about-us-standard_full_flip {
				.about-us-gallery-wrapper {
					width: 59%;
				}
			}

			&.about-us-center_full {
				.about-us-gallery-4 {
					grid-template-columns: repeat(27, 1fr);
					height: 500px;

					img {
						&:first-child {
							grid-column: 1 / span 16;
						}

						&:nth-child(2) {
							grid-column: 17 / span 11;
						}

						&:nth-child(3) {
							grid-column: 1 / span 11;
						}

						&:last-child {
							grid-column: 12 / span 16;
						}
					}
				}
			}
		}

		.main-event-banner {
			max-height: calc(100vh - 86px);

			&.enable-dynamic-height {
				max-height: unset;
			}

			.module-container {
				padding: 100px 40px;
			}

			&.main-event-banner-standard {
				.banner-title-container {
					padding-right: 32px;
					padding-left: 40px;
				}
			}

			&.main-event-banner-right_full {
				.banner-title-container {
					padding-right: 40px;
					padding-left: 32px;
				}
			}

			&.main-event-banner-left_small {
				.banner-title-container {
					padding-right: 30px;
				}
			}

			&.main-event-banner-right_small {
				.banner-title-container {
					padding-left: 30px;
				}
			}
		}

		.speakers {
			.module-container {
				padding: 100px 40px;
			}

			&.speakers-standard {
				.speakers-list {
					margin: 50px -7px -50px;
				}

				.speakers-column {
					flex-basis: 33.33%;
					padding: 0 12px 50px;
				}

				.speaker-about {
					@include line-clamp(3);
				}
			}

			&.speakers-small_horizontal {
				.speaker {
					img {
						width: calc(50% - 16px);
					}
				}

				.speaker-name-container {
					margin-left: 16px;
				}
				.speaker-about {
					@include line-clamp(3);
				}
			}

			&.speakers-staggered {
				.module-container {
					padding: 100px 40px;
				}
			}

			&.speakers-solid {
				.speaker {
					height: auto;
					padding-bottom: 100%;

					img {
						@include absolute-fill;
					}
				}

				.speaker-name {
					padding: 0 20px;
				}
				.speaker-job-title {
					padding: 0 20px;
					margin-bottom: 20px;
				}
			}

			&.speakers-small_vertical {
				.speakers-column {
					flex-basis: 33.33%;
				}
			}

			&.speakers-long_card {
				.speakers-list {
					margin: 50px -8px -16px;
				}

				.speakers-column {
					padding: 0 8px 16px;
				}

				.speaker {
					height: 400px;
				}

				.speaker-name {
					padding: 0 20px;
				}
				.speaker-job-title {
					padding: 0 20px;
					margin-bottom: 25px;
				}
			}
		}

		.agenda {
			.module-container {
				padding: 100px 40px;
			}

			.agenda-description {
				padding-right: 193px;
				padding-left: 193px;
			}

			&.agenda-ltr_even_split {
				.agenda-sessions-wrapper {
					padding: 0;
				}
			}

			&.agenda-contained_card {
				.agenda-session-card {
					padding: 60px 25px 25px;
					padding-left: max(25px, calc(var(--customImageBorderRadius, 12px) / 2));
					padding-right: max(25px, calc(var(--customImageBorderRadius, 12px) / 2));

					.agenda-session-card-tags-wrapper {
						top: 25px;
						left: 25px;
					}
				}
			}

			&.agenda-vertical_card,
			&.agenda-vertical_card_with_featured {
				.agenda-sessions-list {
					grid-template-columns: repeat(2, 1fr);
				}
			}

			&.agenda-standard_no_image {
				.agenda-session-card-text-content {
					max-width: 100%;
				}

				.agenda-session-card-description {
					-webkit-line-clamp: 2;
				}
			}
		}

		.products {
			.module-container {
				padding: 100px 40px;
			}

			.dropdown-container {
				padding-left: 30px;
			}

			.product-item-label {
				padding: 6px;
			}

			&.products-standard {
				.product-list {
					grid-template-columns: repeat(2, 1fr);
					gap: $ev-gap-x5;
				}
			}

			&.products-grid,
			&.products-vertical_list {
				.product-list {
					grid-template-columns: 1fr;
					row-gap: 25px;

					.product {
						grid-template-columns: 41% 1fr;
						height: 285px;
						padding-right: 30px;
						column-gap: 32px;
						width: 100%;
					}
				}

				.module-container {
					padding: 100px 40px;
					.product-list {
						grid-template-columns: 1fr;
						row-gap: 25px;

						.product {
							grid-template-columns: 41% 1fr;
							height: 285px;
							padding-right: 30px;
							column-gap: 32px;
							width: 100%;
						}
					}
				}

				.product-item-content {
					align-self: center;
				}

				.product-item-description {
					@include line-clamp(2);
					&.increase-description {
						-webkit-line-clamp: 2;
					}
				}
			}
		}

		.videos {
			.video-slider {
				max-width: calc(100vw - 180px);
			}

			.module-container {
				padding-right: $ev-gap-x10;
				padding-left: $ev-gap-x10;
			}

			&.videos-standard {
				.module-container {
					padding-right: $ev-gap-x10;
					padding-left: $ev-gap-x10;
				}
			}

			&.videos-ltr {
				.video-title-container {
					max-width: 752px;
				}

				.slick-slider {
					.slick-list {
						margin-right: -10px;
						margin-left: -10px;
					}

					.slick-slide {
						width: 702px;
						height: 480px;
						margin-right: 10px;
						margin-left: 10px;
					}
				}
			}
		}

		.sponsors {
			&.sponsors-standard,
			&.sponsors-card_full,
			&.sponsors-card_short,
			&.sponsors-split {
				.module-container {
					padding: 100px 40px;
				}
			}

			&.sponsors-card_short,
			&.sponsors-split {
				.sponsor-card {
					padding: 20px 40px;
				}
			}

			&.sponsors-standard {
				.sponsor-cards-container {
					margin-top: 30px;
				}

				.sponsor-card-image {
					height: 105px;
				}
			}

			&.sponsors-card_full,
			&.sponsors-card_short {
				.sponsors-description {
					padding-right: 190px;
					padding-left: 190px;
				}
			}

			&.sponsors-card_full {
				.sponsor-cards-container {
					margin-top: 30px;
				}

				.sponsor-card {
					padding: 20px;
				}

				.sponsor-card-image {
					margin-bottom: 5px;
				}

				.sponsor-card-title {
					margin-bottom: 10px;
				}
			}

			&.sponsors-card_short {
				.sponsor-cards-container {
					gap: 20px 24px;
					margin-top: 30px;
				}
			}

			&.sponsors-split {
				.sponsor-card {
					width: calc(45% - 10px);
				}
				.sponsors-text-wrapper {
					margin-right: 25px;
				}

				.sponsor-cards-container {
					gap: 20px 24px;
				}
			}
		}

		.breakout-session-banner {
			.breakout-session-banner-header-container,
			.breakout-session-banner-container {
				padding: 0 130px;
			}

			.breakout-session-banner-container {
				padding-bottom: 75px;
			}

			.breakout-session-banner-title {
				margin-top: $ev-gap-x4;
			}
		}

		.marketing-page-footer {
			.footer-container {
				padding: 13px 40px 0;
			}

			.footer-left {
				flex-basis: 150px;
			}

			.footer-right {
				flex-basis: 150px;
			}
		}

		.call-out {
			&.call-out-standard {
				.module-container {
					padding: 100px 40px;
				}

				.call-out-inner {
					padding: 75px 30px;
				}
			}

			&.call-out-no_border,
			&.call-out-custom_background {
				.call-out-inner {
					padding: 0;
				}
			}

			&.call-out-ltr {
				.call-out-inner {
					padding-right: 24px;
					padding-left: 40px;
				}
			}
		}

		.people-container {
			.people-title {
				margin-bottom: 20px;
			}

			.people-content {
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}
}
