@import './src/scss/old-deps';
@import './src/scss/deps';

.ql-toolbar-portal,
.quill-wrap {
	position: relative;
	width: 100%;

	.ql-picker-options {
		background-color: var(--gray-800);
		color: var(--gray-100);
		max-height: 330px;
		overflow: auto;

		.ql-picker-item {
			font-size: 20px;
		}

		svg * {
			stroke: var(--gray-100);
		}

		:disabled,
		[data-value="Font size"],
		[data-value="Line height"],
		[data-value="Text indent"],
		[data-value="Heading"],
		[data-value="disabled"] {
			pointer-events: none;
			opacity: 0.5;
		}
	}

	.ql-toolbar.ql-snow {
		position: fixed;
		left: auto;
		right: auto;
		display: none;
		flex-flow: wrap;
		justify-content: center;
		align-items: center;
		max-width: 720px;
		opacity: 0;
		transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
		margin: 0 auto;
		transform: translateY(-80%) scale(0.8); // scale(0.8) keeps sizing consistent with v1 quill
		z-index: 1000;
		background-color: var(--gray-800);
		border-radius: 100px;
		padding: 8px 20px;
		border: none;
		box-shadow: 0 5px 40px rgba(0, 0, 0, 0.25);

		.ql-formats .ql-picker {
			width: calc(100% + 15px);
			height: inherit;

			.ql-picker-label {
				font-size: 18px;
			}

			&.ql-align {
				width: 34px;
			}
		}

		.ql-picker.ql-header {
			width: calc(100% + 15px);

			.ql-picker-label {
				font-size: 18px;
			}
		}

		.ql-formats {
			padding: 10px 10px 0 10px;
			margin: 0;

			&.ql-formats-text-align {
				padding-left: 0;
			}

			&.extra-space {
				margin-right: 10px;
			}

			button {
				height: 30px;
			}

			.ql-color-picker, .ql-snow .ql-icon-picker {
				width: 40px;
			}

			// give the "reset" color square options a red diagonal line
			.ql-color-picker {
				.ql-picker-options {
					.ql-picker-item:first-of-type,
					[data-value=""] {
						border: 1px solid var(--gray-100);
						background:
							linear-gradient(to top right,
								rgba(255, 0, 0, 0) 0%,
								rgba(255, 0, 0, 0) calc(50% - 0.8px),
								rgba(255, 0, 0, 1) 50%,
								rgba(255, 0, 0, 0) calc(50% + 0.8px),
								rgba(255, 0, 0, 0) 100%);
					}
				}
			}

			.ql-target {
				&:hover,
				&.ql-active {
					svg {
						path {
							fill: #06c;
						}
					}
				}
			}

			&.links-container {

				&:not(.disable-anchor-tag) {
					border: 1px solid var(--gray-100);
					padding: 0 4px 0 0;
					display: inline-block;
					align-items: center;
					height: 80%;
					margin: 10px 0 0 3px;
				}

				&.disable-anchor-tag {
					.ql-target {
						display: none;
					}
				}
			}
		}

		.ql-picker.ql-expanded .ql-picker-options {
			border-color: transparent;
			border-radius: 0 0 10px 10px;
			box-shadow: 0 5px 40px rgba(0, 0, 0, 0.25);
		}

		.ql-picker-label:not(:hover, .ql-active) {
			color: var(--gray-100);
		}

		.ql-picker-label:not(.ql-active),
		button:not(.ql-active) {
			svg:not(:hover) {
				* {
					stroke: var(--gray-100);
				}
			}
		}
	}

	.ql-container.ql-snow {
		border: none;
		font-size: 16px;
		min-width: 100px;

		// these styles are important to make sure the editor displays the same as our live event will display it
		.ql-editor {
			padding: 0;
			transition: outline 200ms ease-in-out;
			font-family: var(--bodyFont);

			h1, h2, h3, h4, h5, h6 {
				font-family: var(--headingFont);
			}

			blockquote {
				border-left: none;
				margin-bottom: 0;
				margin-top: 0;
				padding: 0;
				margin-block-start: 1em;
				margin-block-end: 1em;
				margin-inline-start: 40px;
				margin-inline-end: 40px;
				background-color: inherit;
				color: inherit;
			}

			code {
				font-family: 'source-code-pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New', monospace;
				background-color: inherit;
				color: inherit;
				padding: 0;
			}

			pre {
				display: block;
				font-family: monospace;
				white-space: pre-wrap;
				margin: 1em 0px;
				background-color: inherit;
				color: inherit;
				padding: 0;
			}

			ol,
			ul {
				display: block;
				margin-block-start: 1em;
				margin-block-end: 1em;
				margin-inline-start: 0px;
				margin-inline-end: 0px;
				padding-inline-start: 40px;

				&:has([data-list="bullet"]) {
					padding-inline-start: 35px;
				}

				li {
					padding-left: 0;
				}

				// remove quills custom bullet/decimal styling
				li::before {
					content: '';
				}
			}

			// re-add standard bullet/decimal styling
			ul {
				li {
					list-style-type: disc;
				}
			}
			ol {
				li:not([data-list="bullet"]) {
					list-style-type: decimal;
				}
			}
		}
	}
}

.ql-text-indent,
.ql-line-height {

	// fixes custom select dropdown icon overlapping text
	.ql-picker-label {
		padding-right: 20px;

		svg {
			right: 5px !important;
		}
	}

	// required to show dropdown for custom parchment line height dropdown items
	.ql-picker-label:before,
	.ql-picker-item:before {
		content: attr(data-label);
	}
}


.quill-content {
	max-width: 100%;
	word-break: break-word;

	blockquote,
	pre {
		white-space: pre-wrap;
	}

	// preserve empty paragraph heights
	p:empty:before {
		content: ' ';
		white-space: pre;
	}
}

.quill-wrapper .ql-editor {
	transition: box-shadow 0.2s ease-in-out;
	padding: 0;
}

.quill-wrapper .ql-container.ql-snow {
	border: none;
}

.quill-wrapper:not(.quill-disabled):hover .ql-editor {
	box-shadow: 0 0 0 1px var(--cyan);
}

.quill-wrapper:not(.quill-disabled) .ql-editor.editor-focused {
	box-shadow: 0 0 0 1px var(--cyan);
	transform: translateY(0px);
}

.ql-toolbar.ql-snow.display-toolbar {
	display: flex;
}

.ql-toolbar.ql-snow.show-toolbar-opacity {
	opacity: 1;
	transform: translateY(-100%) scale(0.8);
}

.ql-snow .ql-tooltip {
	z-index: 10;
}

.ql-toolbar.ql-snow .ql-formats {
	margin-right: 4px;
}

.ql-snow .ql-picker.ql-text-indent .ql-picker-label::before,
.ql-snow .ql-picker.ql-text-indent .ql-picker-item::before,
.ql-snow .ql-picker.ql-line-height .ql-picker-label::before,
.ql-snow .ql-picker.ql-line-height .ql-picker-item::before,
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
	content: attr(data-value) !important;
}

.ql-snow .ql-picker.ql-text-align .ql-picker-label:not([data-value]),
.ql-snow .ql-picker.ql-text-align .ql-picker-options .ql-picker-item:not([data-value]) {
	&::before {
		content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 18 18' %3E%3Cline stroke='%23ffffff' x1='3' x2='15' y1='9' y2='9' %3E%3C/line%3E%3Cline stroke='%23ffffff' x1='3' x2='13' y1='14' y2='14' %3E%3C/line%3E%3Cline stroke='%23ffffff' x1='3' x2='9' y1='4' y2='4' %3E%3C/line%3E%3C/svg%3E") !important;
	}

	&:hover {
		&::before {
			content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 18 18' %3E%3Cline stroke='%2306c' x1='3' x2='15' y1='9' y2='9' %3E%3C/line%3E%3Cline stroke='%2306c' x1='3' x2='13' y1='14' y2='14' %3E%3C/line%3E%3Cline stroke='%2306c' x1='3' x2='9' y1='4' y2='4' %3E%3C/line%3E%3C/svg%3E") !important;
		}
	}
}
.ql-snow .ql-picker.ql-text-align .ql-picker-label[data-value="center"],
.ql-snow .ql-picker.ql-text-align .ql-picker-options .ql-picker-item[data-value="center"] {

	&::before {
		content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 18 18' %3E%3Cline stroke='%23ffffff' x1='15' x2='3' y1='9' y2='9' %3E%3C/line%3E%3Cline stroke='%23ffffff' x1='14' x2='4' y1='14' y2='14' %3E%3C/line%3E%3Cline stroke='%23ffffff' x1='12' x2='6' y1='4' y2='4' %3E%3C/line%3E%3C/svg%3E") !important;
	}

	&:hover {
		&::before {
			content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 18 18' %3E%3Cline stroke='%2306c' x1='15' x2='3' y1='9' y2='9' %3E%3C/line%3E%3Cline stroke='%2306c' x1='14' x2='4' y1='14' y2='14' %3E%3C/line%3E%3Cline stroke='%2306c' x1='12' x2='6' y1='4' y2='4' %3E%3C/line%3E%3C/svg%3E") !important;
		}
	}
}
.ql-snow .ql-picker.ql-text-align .ql-picker-label[data-value="right"],
.ql-snow .ql-picker.ql-text-align .ql-picker-options .ql-picker-item[data-value="right"] {

	&::before {
		content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 18 18' %3E%3Cline stroke='%23ffffff' x1='15' x2='3' y1='9' y2='9' %3E%3C/line%3E%3Cline stroke='%23ffffff' x1='15' x2='5' y1='14' y2='14' %3E%3C/line%3E%3Cline stroke='%23ffffff' x1='15' x2='9' y1='4' y2='4' %3E%3C/line%3E%3C/svg%3E") !important;
	}

	&:hover {
		&::before {
			content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 18 18' %3E%3Cline stroke='%2306c' x1='15' x2='3' y1='9' y2='9' %3E%3C/line%3E%3Cline stroke='%2306c' x1='15' x2='5' y1='14' y2='14' %3E%3C/line%3E%3Cline stroke='%2306c' x1='15' x2='9' y1='4' y2='4' %3E%3C/line%3E%3C/svg%3E") !important;
		}
	}
}
.ql-snow .ql-picker.ql-text-align .ql-picker-label[data-value="justify"],
.ql-snow .ql-picker.ql-text-align .ql-picker-options .ql-picker-item[data-value="justify"] {

	&::before {
		content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 18 18' %3E%3Cline stroke='%23ffffff' x1='15' x2='3' y1='9' y2='9' %3E%3C/line%3E%3Cline stroke='%23ffffff' x1='15' x2='3' y1='14' y2='14' %3E%3C/line%3E%3Cline stroke='%23ffffff' x1='15' x2='3' y1='4' y2='4' %3E%3C/line%3E%3C/svg%3E") !important;
	}

	&:hover {
		&::before {
			content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 18 18' %3E%3Cline stroke='%2306c' x1='15' x2='3' y1='9' y2='9' %3E%3C/line%3E%3Cline stroke='%2306c' x1='15' x2='3' y1='14' y2='14' %3E%3C/line%3E%3Cline stroke='%2306c' x1='15' x2='3' y1='4' y2='4' %3E%3C/line%3E%3C/svg%3E") !important;
		}
	}
}

.ql-snow .ql-picker.ql-text-indent,
.ql-snow .ql-picker.ql-line-height,
.ql-snow .ql-picker.ql-size {

	.ql-picker-label:not([data-value])::before,
	.ql-picker-item:not([data-value])::before {
		content: "Default" !important;
	}
}

.ql-target.ql-active svg g path {
	fill: #06c !important;
}

.ql-target:hover svg g path {
	fill: #06c !important;
}
