@import './src/scss/deps';

$tag-classic-background: #FF8164;
$tag-live-background: rgba(222, 53, 44, 1);

.tag-container {
	@include flex-center($display: inline-flex, $justify-content: null);
	padding: 6px 16px;
	font-size: 14px;
	line-height: 20px;
	font-weight: 700;
	text-transform: uppercase;
	vertical-align: middle;
	color: var(--containerColor);

	.tag-icon-container {
		display: flex;
		margin-right: 6px;
	}

	&.tag-container-live, &.live-player-tag {
		position: relative;
		padding: 6px;
		padding-left: 8px;
		padding-right: 8px;

		&::before {
			@include size(6px);
			content: '';
			display: inline-block;
			margin-right: 6px;
			background-color: var(--containerColor);
			border-radius: 50%;
			animation: blink 1.4s linear infinite;
		}

		@keyframes blink {

			from {
				opacity: 1;
			}

			50% {
				opacity: 0;
			}

			to {
				opacity: 1;
			}
		}
	}

	&.tag-container-live {
		// TODO: Add custom colors in tags
		background-color: $tag-live-background !important;
	}

	&.tag-container-views {
		padding: 6px;
		font-weight: 500;
	}

	&.tag-container-type {
		background-color: var(--containerColor);
	}

	&.tag-container-views {
		color: var(--containerColor);
		background-color: var(--secondaryBackgroundColor);
	}
}
