@import '../../../scss/deps';

.tag-select-container {
	.tag-select {
		.placeholder {
			padding-top: 4px;
			padding-left: 16px;
		}
	}
	&.session-select.error {
		.tag-select {
			border: 1px solid $rose;
		}
	}

	.tag-select-dropdown {
		&.session-select {
			width: 100%;
		}
	}
}
