@import "./src/scss/deps";

.cookie-banner-container {
	width: fit-content;
	max-width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--containerColor);
	padding: 20px 15px;
	border-radius: var(--customContainerBorderRadius, 12px);
	box-shadow: 0px 13px 30px rgba(0, 0, 0, 0.25);

	.cookie-banner-info {
		max-width: 571px;
		margin-right: 20px;

		.cookie-banner-title {
			font-weight: 700;
			font-size: 16px;
			line-height: 20px;
			margin-bottom: 5px;
		}

		.cookie-banner-subtitle {
			font-weight: 400;
			font-size: 13px;
			line-height: 20px;

			.learn-more-link {
				color: var(--accentColor);
				cursor: pointer;
				font-weight: bold;
			}
		}
	}

	.cookie-banner-actions {
		display: flex;
		align-items: center;

		@include media-query($med-down) {
			flex-flow: wrap;
		}

		button {
			font-weight: 700;
			font-size: 13px;
			line-height: 25px;
			padding: 15px 25px;
			white-space: nowrap;
			border-radius: var(--customButtonBorderRadius, 100px);
		}

		button.learn-more {
			background-color: rgba(var(--accentColor-rgb), 0.2);
			color: var(--accentColor);
			margin-right: 15px;
			text-transform: uppercase;
		}

		button.accept {
			width: fit-content;
			height: 55px;
			background-color: var(--accentColor);
			color: var(--headingTextColor);
			text-transform: uppercase;
		}

		svg {
			margin-left: 15px;
			cursor: pointer;
		}
	}
}

.cookie-banner-sticky-wrapper {
	position: sticky;
	bottom: 25px;
	justify-content: center;
	display: flex;
	left: 0;
	right: 0;
	height: fit-content;
	z-index: 9;
	width: fit-content;
	margin: 0 auto;
	max-width: 95%;
	// content-visibility: auto;

	&.fixed {
		position: fixed;
		bottom: 25px;
	}

	&.cookie-banner-registration-page {
		// for some reason registration page in editor breaks with position sticky, but works with position absolute
		position: absolute;
	}
}