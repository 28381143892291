
@import '../../../scss/deps';

.toggle-group-container {
	margin-bottom: 16px;

	.editable-text {
		margin-left: 14px;
	}
}

.toggle-group {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.left-group{
		display: flex;
		align-items: center;
		gap: 4px;
		label {
			margin: 0;
			color: var(--grayscale-200-light-grey, #E5E4EC);
			
			font-family: var(--headingFont);
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 16px; /* 133.333% */
			letter-spacing: 0.24px;
		}
	}
}