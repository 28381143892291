@import './src/scss/old-deps';
@import './src/scss/deps';

.marketing-page-header {
	display: flex;
	width: 100%;

	&.vertical {
		flex-direction: column;
	}

	&.is-sticky {
		position: sticky;
		top: 0;
		z-index: 4;
	}

	.header-container {
		position: relative;
		display: flex;
		align-items: center;
		max-width: 1440px;
		width: 100%;
		margin: 0 auto;
		padding: 10px 150px;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.header-col-left {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		max-width: 200px;
		height: 60px;

		.header-logo {
			display: block;
			max-height: 100%;
		}
	}

	.header-navigation {
		display: flex;
		flex-grow: 1;
		padding-right: 30px;
		padding-left: 30px;

		.navigation-link {
			margin-right: 20px;
			margin-left: 20px;
			text-decoration: none;
			cursor: pointer;
		}

		.menu-tablet {
			padding-left: $ev-gap-x14;

			.new-dropdown-value {
				@include flex-center;
				@include size(24px);
				margin: 0;
			}
		}

		.menu-tablet-popup {
			position: fixed;
			top: 72px;
			width: 100%;
			height: 100%;
			text-align: center;
			border-radius: 0;
		}

		.tablet-links {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
	.header-col-right {
		display: flex;
		align-items: center;
	}

	.language-dropdown {
		align-self: center;
		margin-left: auto;
	}

	&.header-fixed {
		position: sticky;
		top: 0;
		z-index: 100;
	}

	&.header-right {

		.header-navigation {
			display: flex;
			justify-content: flex-end;
			.language-dropdown {
				margin: 0;
				margin-left: 30px;
			}
		}
	}

	&.header-center {

		.header-col-right {
			display: flex;
			justify-content: flex-end;
			gap: 20px;
		}

		.header-navigation {
			position: relative;
			display: flex;
			justify-content: center;

			.language-dropdown {
				position: absolute;
				right: 30px;
				margin: 0;
				display: flex;
				align-items: center;

				&.adjust-for-sign-in-btn {
					right: 120px;
					top: 3px;
				}
			}

			ul {
				padding-left: 0;
			}
		}
	}
}

//This mixin is mostly for the editors sake, this is why there is only the tablet version and mobile version responsive styles here, to match the editor view types. Default being desktop
@mixin homePageHeaderResponsive($size) {
	@if $size =="tablet" {
		.marketing-page-header {
			&.header-fixed-mobile-only {
				position: sticky;
				top: 0;
				z-index: 100;
			}

			&.header-center {

				.header-navigation {
					justify-content: flex-end;

					.language-dropdown {
						position: static;
					}
				}
			}

			.header-col-left {
				height: 54px;
			}

			.header-container {
				padding: 13px 75px;

				&::before {
					left: 0px;
					right: 0px;
				}

				.tablet-links {
					margin-bottom: 10px;

					.navigation-link {
						margin: 8px 0;
					}
				}
			}

			.header-navigation {
				padding: 0;
			}

			.header-call-to-action {
				width: 245px;
			}

			&.#{$ev-template-fun} {

				&::before {
					left: 75px;
					right: 75px;
				}
			}
		}
	}


	@if $size =="mobile" {
		.marketing-page-header {

			&.header-fixed-mobile-only {
				position: sticky;
				top: 0;
				z-index: 100;
			}

			.header-col-left {
				height: 34px;
			}

			.header-container {
				padding: 13px 20px;

				&::before {
					left: 0px;
					right: 0px;
				}
			}

			.menu-tablet {
				padding-left: $ev-gap-x6;

				.menu-tablet-popup {
					top: 60px;
					height: calc(100% - 60px);
					overflow: auto;

					.tablet-links {
						margin: 48px 0 68px;

						.navigation-link {
							margin: 12px 0;
						}
					}
				}
			}

			.header-call-to-action {
				width: 100%;
			}

			&.#{$ev-template-fun} {

				&::before {
					left: 20px;
					right: 20px;
				}
			}
		}
	}
}

//small desktop
@include media-query($xlrg-down) {
	.marketing-page-header {

		.header-container {
			padding: 13px 40px;
		}

		&.#{$ev-template-fun} {

			&::before {
				left: 40px;
				right: 40px;
			}
		}
	}
}

@include media-query($med-down) {
	.live-event-container:not(.editor) {
		@include homePageHeaderResponsive("tablet")
	}
}
.editor.tablet {
	@include homePageHeaderResponsive("tablet")
}

@include media-query($sm-down) {
	.live-event-container:not(.editor) {
		@include homePageHeaderResponsive("mobile")
	}
}
.editor.mobile {
	@include homePageHeaderResponsive("tablet");
	@include homePageHeaderResponsive("mobile")
}
