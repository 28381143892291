@import "../deps";

.text-ellipsis {
	@include text-ellipsis;
}

.text-primary {
	color: $primary !important;
}

.text-danger {
	color: $danger !important;
}

.visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0 0 0 0);
	border: 0;
	outline: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.flex-grow-1 {
	flex-grow: 1;
}

.full-height {
	height: 100%;
}

.relative {
	position: relative;
}

.cursor-move {
	cursor: move;
}

.cursor-pointer {
	cursor: pointer;
}

.ev-color-light-hint {
	color: $gray-300 !important;
}

.ev-color-secondary {
	color: $gray-400 !important;
}

.radius-main {
	border-radius: 12px;
}

.disabled {
	opacity: 0.8;
	pointer-events: none;
}

.half-opacity {
	opacity: 0.5;
}

@include generate-displays((flex));
