@import './src/scss/old-deps';
@import './src/scss/base/colors';
@import './src/scss/abstracts/colors';

.session-type-select {

	.session-type-selector {
		position: absolute;
		z-index: 1;
		width: calc(100% - 80px);
		opacity: 0;
		overflow: hidden;
		background: $dark-gray;
		box-shadow: $shadow-alert;
		transform: translateY(0);
		transition: 150ms opacity, 150ms transform;
		cursor: pointer;
		pointer-events: none;
		@include radius-12;

		&.open {
			opacity: 1;
			transform: translateY(8px);
			pointer-events: auto;
		}

		.select-option {
			transition: background-color 150ms;

			&:hover {
				background-color: $gray;
			}
		}
	}

	.session-type-selected {
		position: relative;
		background: $gray;
		cursor: pointer;
		@include radius-12;

		.arrow {
			position: absolute;
			top: 0;
			right: 0;
			width: 48px;
			height: 100%;
			transform: scaleY(1);
			transition: 60ms transform;
			@include flex-center;
		}

		&.open {

			.arrow {
				transform: scaleY(-1);
			}
		}
	}

	.session-selected-type {
		display: flex;
		align-items: center;
		padding: 16px;

		.icon-gradient {
			width: 40px;
			min-width: 40px;
			height: 40px;
			margin-right: 8px;
			background: $gradient;
			@include flex-center;
			@include radius-8;
		}

		.session-selected-info {
			display: flex;
			flex-direction: column;

			label {
				margin-bottom: 6px;
				font-size: 14px;
				font-weight: 500;
				color: $white;
			}

			span {
				font-size: 14px;
				color: $light-gray;
				margin-right: 20px;
			}
		}
	}

	&.session-type-select-outline {

		.session-type-selected {
			background: transparent;
			border: 1px solid  $gray;
		}
	}
}