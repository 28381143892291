@import "./src/scss/old-deps";
@import "./src/scss/deps";
@import "./src/scss/base/_colors";

.alerts-container {
	position: fixed;
	z-index: $z-index-alert;
	display: flex;
	flex-direction: column;

	@include media-query($sm-down) {
		top: 0;
		left: 5px;
		right: 5px;
	}

	@include media-query($med-up) {
		top: 15px;
		right: 0;
	}
}

.alert {
	position: relative; //top: $header-size + 20;
	z-index: $z-index-alert;
	display: flex;
	width: 100%;
	max-height: 90vh;
	margin-bottom: 5px;
	padding: 12px 16px 6px;
	opacity: 0;
	overflow: auto;
	color: var(--accentColor);
	background-color: var(--containerColor);
	border: 1px solid $border-dark-gray;
	border-radius: 16px;
	box-shadow: $shadow-alert;
	transition: transform $alert-transition-duration, opacity $alert-transition-duration,
		max-height $alert-transition-duration, margin-bottom $alert-transition-duration, background 150ms;
	// content-visibility: auto;

	&.admin-alert {
		color: $darker-gray;
		background-color: $cyan;
	}

	@include media-query($sm-down) {
		// top: -100%;
		transform: translateY(-100%);

		&.show {
			transform: translateY(5px);
		}

		&.hide {
			transform: translateY(-100%);
		}
	}

	@include media-query($med-up) {
		max-width: 360px;
		margin-bottom: 15px;
		transform: translateX(100%);

		&.show {
			transform: translateX(-20px);
		}

		&.hide {
			transform: translateX(100%);
		}
	}

	@media all and (-ms-high-contrast: none),
	(-ms-high-contrast: active) {
		min-width: 360px;
	}

	&.show {
		opacity: 1;
	}

	&.hide {
		max-height: 0;
		margin-bottom: 0;
		opacity: 0;
	}

	.alert-icon {
		display: flex;
		justify-content: center;
		width: 42px;

		>i {
			position: relative;
			top: 15px;
			font-size: 20px;
		}
	}

	.alert-image {
		padding: 15px 0 0 15px;

		img {
			width: 32px;
			height: 32px;
		}
	}

	.clear-alert {
		position: absolute;
		top: 12px;
		right: 12px;
		display: block;
		line-height: 1;
		color: $med-light-gray;

		.icon-close-circle {
			font-size: 18px;
		}

		&:hover {
			color: $border-gray;
		}
	}

	.alert-content {
		display: flex;
		flex-direction: column;
		flex: 1;

		@media all and (-ms-high-contrast: none),
		(-ms-high-contrast: active) {
			flex-basis: 0;
		}

		// padding: 14px 14px 14px 0;
		line-height: normal;

		.alert-title {
			min-width: 115px;
			max-width: 240px;
			font-size: $_14px;
			font-weight: 900;
		}

		.alert-description-wrap {
			display: -webkit-box;
			align-items: center;
			margin-top: 6px;
			overflow: hidden;
			-webkit-box-orient: vertical;
			&:not(.no-clamp) {
				-webkit-line-clamp: 5;
			}

			.alert-spinner {
				margin-right: 14px;
			}

			.alert-description {
				max-width: 90%; //added because path of converted videos can be very long single words, so this should allow word wrapping in paths
				max-height: 300px;
				font-size: $_12px;
				word-wrap: break-word; //added because path of converted videos can be very long single words, so this should allow word wrapping in paths
				overflow-y: auto;
				color: $border-med-gray;
			}

			* {
				margin: 0;
				padding: 0;
				font-size: 14px;
			}

			a {
				color: $white;
			}
		}
	}

	&.warning {
		background: $yellow;
	}

	&.error {
		color: $white;
		background: darken($red, 20%);

		.alert-content {
			padding: 14px;
		}

		.alert-icon {
			color: $white;
			background: $rose;
		}
	}

	&.neutral .alert-icon {
		color: $med-light-gray;
	}

	&.positive .alert-icon {
		color: $green;
	}

	.alert-buttons {
		display: flex;
		margin-top: 14px;

		button {
			margin: 0 10px 0 0;
		}
	}

	&.has-children {
		padding: 0;
		background-color: transparent;
		border: none;

		.alert-title {
			display: none;
		}
	}

	&.clickable {
		cursor: pointer;

		&:hover {
			background: lighten($cyan, 5%);
		}
	}

	&.announce {
		flex-direction: column;
		position: relative;
		background-color: $white;
		border: transparent;
		max-width: 450px;
		padding: 20px 50px 20px 80px;

		&.no-avatar-announcement {
			padding: 20px 50px;
		}

		.clear-alert {
			top: 15px;
			right: 15px;
			color: $classic-gray-text;
		}

		.avatar {
			position: absolute;
			top: 15px;
			left: 15px;
			width: 55px;
			height: 55px;

			img {
				width: 55px;
				height: 55px;
				object-fit: cover;
			}
		}

		h6 {
			font-size: 14px;
			color: $classic-gray-text;
			margin-bottom: 3px;

			.unbold {
				font-weight: normal;
			}
		}

		p {
			font-size: 16px;
			line-height: 25px;
			margin-bottom: 10px;
			word-wrap: break-word;
			white-space: pre-wrap;
		}
	}

	&.copy-url-alert {
		background-color: var(--user-background-color, black);
		color: var(--accentColor, $cyan);

		svg {
			path {
				fill: var(--accentColor, $cyan);
			}
		}

		.admin-alert {
			border-color: $classic-gray-text;
		}

		.alert-description-wrap {
			* {
				font-size: 11px;
				color: $gray-800;
				letter-spacing: -.5px;
				line-height: 13px;
				color: var(--accentColor, $cyan);
			}
		}

		&.light-mode {
			color: var(--user-mode-color, black);
			background-color: var(--user-background-color, white);

			svg {
				path {
					fill: var(--user-mode-color, black);
				}
			}

			code {
				color: var(--user-mode-color, black);
			}
		}
	}
}

.dark-mode+.alerts-container {
	.alert {
		&.admin-alert {
			background-color: var(--user-background-color, black);
			color: var(--accentColor, $cyan);
		}

		&.copy-url-alert {
			.alert-description-wrap {
				* {
					color: var(--accentColor, $cyan);
				}
			}
		}
	}
}
