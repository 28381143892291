@import './src/scss/old-deps';

.popover-wrapper {
	position: absolute;
	left: 0;
	right: 0;
	z-index: 1;
	display: flex;
	flex-direction: column;
	max-height: 51px * 3;
	margin-top: -16px;
	opacity: 0;
	overflow-y: auto;
	background-color: $med-gray;
	border-radius: 24px;
	box-shadow: 0 8px 24px rgba(0, 0, 0, 0.25);
	transform: translateY(-8px);
	transition: 150ms opacity, 150ms transform;
	pointer-events: none;

	&.bottom {
		transform: translateY(8px);
	}

	&.open {
		opacity: 1;
		transform: translateY(0);
		pointer-events: auto;
	}
}