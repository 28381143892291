//*----------------------------------------------------------------------*\\
//*    DEFAULT THEME COLORS (SCSS)                                       *\\
//*----------------------------------------------------------------------*\\

$reg-black: #070117;

// Grayscale
$gray-100: #fff;
$gray-100-rgb: 255, 255, 255;
$gray-200: #e5e4ec;
$gray-200-rgb: 229, 228, 236;
$gray-300: #bfbbcf;
$gray-300-rgb: 191, 187, 207;
$gray-400: #908ca0;
$gray-400-rgb: 144, 140, 160;
$gray-500: #625e70;
$gray-500-rgb: 98, 94, 112;
$gray-600: #4f4c57;
$gray-600-rgb: 79, 76, 87;
$gray-700: #3c3a42;
$gray-700-rgb: 60, 58, 66;
$gray-800: #312f38;
$gray-800-rgb: 49, 47, 56;
$gray-900: #26242b;
$gray-900-rgb: 38, 36, 43;
$gray-1000: #1a1a1f;
$gray-1000-rgb: 26, 26, 31;
$gray-1100: #121114;
$gray-1100-rgb: 18, 17, 20;
$gray-1200: #09090a;
$gray-1200-rgb: 9, 9, 10;

$green-500: #37EEA1;
$greenroom-green: #A6FF79;

$yellow-500: #FFD60A;

// Primary/Brand
$primary: #5AE4FF;
$primary-rgb: 90, 228, 255;

// Other colors
$danger: #F46D66;
$dark-danger: #6B211D;
$danger-rgb: 244, 109, 102;

// purple scale
$purple-400: #7B6CF6;
$purple-400-rgb: 124, 108, 246;

// survey progress and select bar color
$survey-blue-select: #3456ff4d;