@import './src/scss/old-deps';
@import './src/scss/deps';
@import './src/scss/base/colors';
@import './src/scss/abstracts/colors';

$chat-avatar-width: 32px;
$chat-avatar-height: 32px;

.chat-avatar {
	height: $chat-avatar-height;
	width: $chat-avatar-width;
	min-height: $chat-avatar-height;
	min-width: $chat-avatar-width;
	font-size: 12px;
	@include flex-center;
	@include radius-full;
	// FUTURE DEV: is the chat styling bug throwing this off? If so, we may need to add this back in.
	// color: var(--headingTextColor);
	margin-right: 8px;
	background-size: cover;
	object-fit: cover;
	background-position: center;
	background-repeat: no-repeat;

	background-color: $gray-300;

	&.clickable:not(.disable-click) {
		cursor: pointer;
	}

	&.hidden {
		opacity: 0;
	}
}