@import './src/scss/base/mixins';
@import './src/scss/deps';

.google-auth-wrapper {
	@include flex-center;
	@include absolute-fill;
	background: white;
	flex-direction: column;
	color: $gray-700;

	img {
		height: 200px;
	}
}