.disable-native-registration-modal {
	.disable-native-reg-toggle {
		display: flex;
		gap: 8px;
		font-size: 12px;
		font-weight: 500;
		margin-bottom: 24px;
		margin-top: 30px;
		padding-left: 14px;
	}
}