@import "./src/scss/deps";

.registration-options-container-v2 {
	margin-bottom: 16px;
	padding: 0 24px 200px 24px; // Leaves room for dropdown menu
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	gap: 8px;

	&.layout {
		padding-bottom: 140px;
	}

	.edit-registration-fields-v2 {
		display: flex;
		flex-direction: column;
		gap: 12px;
		width: 100%;


		.registration-row {
			height: 40px;
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 8px;

			.pill-toggle-round {
				width: 164px;
			}
		}

		.edit-registration-question-placeholder {
			input {
				height: 32px;
			}
		}

		.question-footer {
			display: flex;
			justify-content: flex-end;
			gap: 8px;

			button {
				padding: 8px 16px;
			}

			.primary {
				background: $bl-lemonade;
			}
		}

		.edit-registration-question-options {
			padding-bottom: 16px;
			max-height: 416px;
			overflow-y: auto;
			display: flex;
			gap: 8px;
			flex-direction: column;

			.edit-registration-question-option {
				display: grid;
				grid-template-columns: 20px 1fr 32px;
				height: 32px;
				column-gap: 8px;
				outline: none;

				.drag-handle {
					position: relative;
					top: 8px;
					cursor: grab;

					&:active,
					&:focus {
						cursor: grabbing;
					}
				}

				input,
				.select,
				.text {
					height: 32px;
					margin: 0;
				}
			}

		}

		.registration-options-container {
			max-height: 416px;
			overflow-y: auto;

			.registration-option {
				position: relative;
				display: grid;
				grid-template-columns: 10px 48px 1fr 154px 154px 32px;
				justify-content: center;
				align-items: center;
				padding: 12px 0;
				column-gap: 8px;
				border-bottom: 1px solid $border-gray;
				transition: 150ms background-color;

				.custom-text-question-info-text {
					font-size: 14px;
					text-align: center;
				}

				.drag-handle {
					cursor: grab;
				}

				&.dragging {
					cursor: grabbing;

					.switch,
					.drag-handle,
					p,
					button {
						cursor: grabbing;
					}
				}

				p.registration-question-label {
					margin-bottom: 0;
					max-width: 200px;
					word-wrap: break-word;
					@include line-clamp(3);
				}

				.registration-question-type {
					@include radius-full;
					font-size: $_14px;
					line-height: 32px;
					font-weight: 500;
					color: $light-gray;
					text-align: center;
					padding: 0;
					background-color: $border-gray;
					border: none;
				}

				.switch {
					position: relative;
				}

				button.round {
					position: absolute;
					right: 0;
					opacity: 0;
					transform: scale(0.8);
					transition: 150ms opacity, 150ms transform;
				}

				&.on {
					p {
						color: $white !important;
					}
				}

				&:hover {
					button.round {
						opacity: 1;
						transform: scale(1);
					}
				}
			}
		}

	}

	.field-group.tag-select-container {
		.tag-select-dropdown {
			position: absolute;
		}
	}
}