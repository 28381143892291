.channel-avatar {
	border-radius: 8px;
	width: 32px;
	height: 32px;
	min-width: 32px;
	min-height: 32px;
	margin-right: 8px;
	display: flex;
	align-content: center;
	justify-content: center;

	img {
		width: 24px;
		height: 24px;
		margin: auto;
		object-fit: contain;
	}

	h5 {
		margin: 0;
		margin: auto;
	}
}
