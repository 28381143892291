@import "./src/scss/deps";
@import "./src/scss/old-deps";
@import "../../../scss/base/colors";

@mixin gradientText {
	background: linear-gradient(90deg, $pink-lemonade, $lemonade);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 500;
}

.authentication {
	--form-width: 400px;

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	padding-top: 48px;
	padding-bottom: 180px;
	background-color: $dark-gray;
	background-repeat: no-repeat, no-repeat, no-repeat;
	background-position:
		left 248px,
		left 647px,
		right 118px;
	background-size: auto, auto, auto;

	.authentication-form {
		position: absolute;
		top: 0px;
		left: 0px;
		width: var(--form-width);
		height: 100vh;
		padding-top: 30px;

		display: flex;
		flex-direction: column;
		justify-content: center;

		margin-bottom: auto;
		background-repeat: no-repeat;
		background-position: 60% center;
		background-size: 55%;
		background-color: $med-gray;
		z-index: 1;

		form {
			@include radius-32;
			width: var(--form-width);

			padding: 0 40px;

			&.new-password-form {
				// Keeps the password input field hidden for 1 second so greenroom redirects don't flash the password input field
				opacity: 0;
				animation: fadeIn .5s ease-in-out 1s forwards;
			}

			.auth-logo {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				margin-bottom: 30px;
				column-gap: 18px;

				img {
					height: 48px;
					width: 48px;
					object-fit: contain;
				}
			}

			svg.brandlive-icon {
				width: 68px;
				height: 24px;

				path {
					fill: rgba($white, 0.6);
				}
			}

			button.lemonade {
				width: 100%;
				margin-top: 12px;
				margin-bottom: 24px;
				height: 53px;
				background-image: linear-gradient(140deg, $pink-lemonade, $lemonade);
				font-weight: 600;
			}

			button.back {
				margin: 0;

				svg {
					margin: 0;
				}
			}

			.lemonade-text {
				background: linear-gradient(90deg, $pink-lemonade, $lemonade);
				-webkit-background-clip: text;
				background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			.forgot-password {
				margin-top: -8px;
				margin-left: 15px;
			}

			a:not(.auth-resource-link) {
				width: 100%;
				height: 40px;
				font-size: 14px;
				font-weight: bold;
				text-align: left;
				text-decoration: none;
				color: $border-light-gray;
				background: linear-gradient(145deg, $pink-lemonade, $lemonade);
				-webkit-background-clip: text;
				background-clip: text;
				-webkit-text-fill-color: transparent;
				transition: 0.3s;
			}

			h4 {
				font-weight: 600;
				line-height: 30px;
				font-size: 22px;
			}

			p {
				font-size: 13px;
				line-height: 20px;
				font-weight: 500;

				&.password-reset {
					margin-bottom: 20px;
				}

				&.error {
					text-align: left;
					color: $rose;
					margin-left: 15px;
				}
			}

			.hide-input-field {
				display: none;
			}

			.row {
				display: flex;
				gap: 10px;
				justify-content: space-between;
				align-items: center;

				.back {
					background-color: $gray;
					height: 53px;
					width: 68px;
					border-radius: 100px;
					margin-top: 12px;
					margin-bottom: 24px;
				}
			}

			.success {
				margin-top: 32px;

				label {
					font-size: 12px;
					color: $border-light-gray;
				}

				p {
					margin: 0;
					font-weight: 700;

					.email {
						@include gradientText();
						font-weight: 600;
						text-align: center;
						margin-top: 22px;
						width: 100%;
						display: inline;
					}
				}
			}

			.list-o-channels-wrapper {
				max-height: calc(100% - 116px);
				overflow: hidden;
			}

			.nav-dropdown-search {
				margin: 0 0 10px 0;
				padding: 0;
				height: 32px;

				.search-input {
					width: 100%;
					height: 100%;
				}
			}

			.list-o-channels {
				display: flex;
				flex-direction: column;
				gap: 10px;
				overflow-y: scroll;
				overflow-x: hidden;
				width: calc(100% + 15px);
				padding-bottom: 56px;
				height: calc(100vh - 280px);
				max-height: calc(100vh - 446px);

				.channel {
					display: flex;
					justify-content: flex-start;
					align-items: center;

					padding: 8px 16px 8px 8px;
					gap: 20px;

					width: 320px;
					min-height: 72px;
					height: fit-content;

					background: $gray-800;
					border-radius: 12px;
					cursor: pointer;

					.channel-name {
						font-size: 16px;
						font-weight: 500;
						line-height: 20px;

						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.channel-avatar {
						height: 48px;
						width: 48px;
						min-width: 48px;
						border-radius: 8px;
						display: flex;
						justify-content: center;
						align-items: center;

						h5 {
							color: $gray;
							font-size: 32px;
							margin: 0;
						}

						img {
							height: 36px;
							width: 36px;
							object-fit: contain;
						}
					}

					&:hover {
						background: $grayish-purple;
					}
				}
			}

			.list-o-channels-fade {
				position: relative;
				z-index: 1;
				pointer-events: none;
				background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba($med-gray, 1) 90%);
				width: 100%;
				height: 4em;
				margin-top: -4em;
			}
		}

		.redirect-form {
			flex-direction: column;

			.auth-logo {
				position: absolute;
				top: 150px;
				left: 50px;
			}

			.redirect-message {
				text-align: center;
				font-size: 14px;
				background: $bl-lemonade;
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}

		.incorrect-user {
			display: inline-block;
			cursor: pointer;
			font-size: 18px;
			position: absolute;
			bottom: 30px;
			left: 40px;
			z-index: 10;

			.lemonade-text {
				background: linear-gradient(90deg, $pink-lemonade, $lemonade);
				-webkit-background-clip: text;
				background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}

		.gradient-color {
			@include gradientText();
			font-weight: 600;
			text-align: center;
			margin-top: 22px;
			width: 100%;
			display: inline;
		}

		.auth-resources {
			padding-top: 46px;
			text-align: center;

			p {
				margin-bottom: 20px;
				font-size: 25px;
				line-height: 32px;
				font-weight: 500;
				letter-spacing: -0.2px;
			}

			div {
				display: inline-flex;
				flex-direction: column;
				row-gap: 6px;
				width: 100%;
			}

			a {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				min-width: 166px;
				height: 48px;
				padding: 0 24px;
				font-size: 15px;
				line-height: 24px;
				font-weight: 600;
				letter-spacing: -0.2px;
				text-decoration: none;
				color: inherit;
				border: 1px solid $white;
				border-radius: 24px;
			}
		}


	}

	.video-garnish {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: calc(100% - var(--form-width));
		height: 100%;
		object-fit: cover;
	}

	.auth-code {
		.background-dots {
			width: 100%;
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			position: absolute;
			left: 20px;
			right: 20px;
			bottom: 20px;

			span {
				height: 8px;
				width: 8px;
				border-radius: 100%;
				display: block;
				border: 1px solid $border-light-gray;
			}
		}

		.auth-code-input-container {
			border: 1px solid $border-gray;
			border-radius: 100px;
			height: 48px;
			width: 100%;
			transition: 150ms border-color;

			&.active {
				border-color: $border-light-gray;
			}

			&.ok {
				border-color: $bright-green;
			}

			&.error {
				border-color: $error;
			}
		}

		.auth-code-value {
			position: absolute;
			left: 20px;
			bottom: 13px;
			letter-spacing: 44px;
			overflow: hidden;
			z-index: 1;
			max-width: 286px;
			pointer-events: none;

			&::before {
				content: " ";
				background-color: $med-gray;
				position: absolute;
				display: block;
				top: 0;
				bottom: 0;
				left: -10px;
				width: 100%;
				z-index: -1;
			}
		}

		input[type="number"] {
			border: none;
			opacity: 0;

			&:focus {
				border: none;
			}
		}
	}

	span.error {
		color: $error;
		position: absolute;
		font-size: 12px;
		margin-top: -12px;
		margin-left: 16px;
		max-width: 298px;
	}
}

@include media-query($med-down) {
	.authentication {
		.authentication-form {
			width: 100%;
			align-items: center;

			form {
				width: clamp(400px, 90vw, 640px);

				&.auth-code-wrapper {
					width: 400px;
				}

				.list-o-channels-wrapper {
					.channel {
						width: calc(100% - 15px);
					}
				}
			}


			.video-garnish {
				display: none;
			}
		}
	}

	// create an animation that takes the opacity from 0 to 1
	@keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}
}

.loading-channels-info {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
}