// Bootstrap method for showing elements above define size

.show-xs,
.show-sm,
.show-med,
.show-lrg,
.show-xlrg,
.show-xs-height,
.show-sm-height,
.show-med-height,
.show-lrg-height,
.show-xlrg-height {
	display: none;
}

.show-xs {
	@include media-query($xs-up) {
		display: block;
	}
}

.show-sm {
	@include media-query($sm-up) {
		display: block;
	}
}

.show-med {
	@include media-query($med-up) {
		display: block;
	}
}

.show-lrg {
	@include media-query($lrg-up) {
		display: block;
	}
}

.show-xlrg {
	@include media-query($xlrg-up) {
		display: block;
	}
}

.show-xs-height {
	@include break-min-height($break-xs) {
		display: block;
	}
}

.show-sm-height {
	@include break-min-height($break-sm) {
		display: block;
	}
}

.show-med-height {
	@include break-min-height($break-med) {
		display: block;
	}
}

.show-lrg-height {
	@include break-min-height($break-lrg) {
		display: block;
	}
}

.show-xlrg-height {
	@include break-min-height($break-xlrg) {
		display: block;
	}
}

.max-width-100-percent {
	max-width: 100%;
}