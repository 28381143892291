@import "./src/scss/deps";

.file-card {
	label {
		font-family: $font-family-poppins;
		font-size: 12px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0.02em;
		text-align: left;
		color: $gray-400;
		margin-left: 18px;
	}

	.file-card-inner {
		padding: 16px;
		border-radius: 12px;
		background: $gray-800;
		display: grid;
		grid-template-columns: 75% 25%;
		position: relative;

		.smart-tooltip {
			position: absolute;
			left: 20px;
			top: 10px;
			cursor: help;

			// create a larger area where you can hover over this tooltip
			width: 50px;
			height: 50px;
			display: flex;
			justify-content: flex-end;
		}

		.file-card-data {
			display: flex;
			flex: 1;
			overflow: hidden;

			.file-card-image {
				height: 48px;
				position: relative;

				.file-thumbnail,
				.file-card-loading {
					height: 48px;
					width: 48px;
					border-radius: 7.8px;
					margin-right: 12px;
					max-width: 48px;
					object-fit: cover;
					background-color: $gray-900;
					@include flex-center;
					user-select: none;
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;

					span.file-card-waiting {
						transform: scale(0.6);
						@include flex-center;
						flex-direction: column;
						gap: 4px;
					}
				}
			}

			.file-card-metadata {
				display: flex;
				flex-direction: column;
				justify-content: center;
				overflow: hidden;

				p {
					color: #fff;

					/* S1 */
					font-family: $font-family-poppins;
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 20px; /* 142.857% */
					margin-bottom: 4px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 180px;
				}

				span {
					color: var(--grayscale-400-dark-grey, $gray-400);
					/* P2 */
					font-family: $font-family-poppins;
					font-size: 12px;
					font-style: normal;
					font-weight: 500;
					line-height: 16px; /* 133.333% */
					letter-spacing: 0.24px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 180px;
				}
			}
		}

		.file-card-actions {
			@include flex-center;
			gap: 8px;
		}
	}
}
