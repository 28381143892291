@import "./src/scss/deps";

.speaker-details-modal {
	&.ev-modal-wrapper {
		.modal {
			padding-bottom: 0;

			.modal-body {
				overflow: hidden;
			}
		}
	}

	.modal {
		max-width: 800px;
	}

	.person-details-modal-content {
		display: flex;
		gap: 0px 40px;
		align-items: flex-start;
	}

	.person-details-image {
		flex-shrink: 0;
		width: 304px;
		margin-bottom: 0;

		img {
			border-radius: var(--customImageBorderRadius, 12px);

			&.rounded-speaker-image {
				border-radius: 50%;
			}
		}
	}

	.person-details-content {
		padding-left: 0;

		> .person-details-description-show-more {
			display: block;

			> button {
				padding: 0;
				color: var(--accentColor) !important;
				margin-bottom: 0;
			}
		}
	}

	.person-details-name {
		display: block;
		padding-right: 0;
	}

	.social-links {
		gap: 20px;
		flex-wrap: wrap;
		margin: 15px 0;

		li {
			margin-right: 0;
		}

		svg {
			width: 20px;
			height: 20px;
		}
	}

	.person-details-description {
		margin: 8px 0 0;
		color: inherit !important;
		max-height: 190px;
		overflow: auto;

		&::-webkit-scrollbar-thumb {
			background: white;
		}
		&.light-mode::-webkit-scrollbar-thumb {
			background: var(--bodyTextColor, $gray-900);
		}

		&.long {
			mask-image: linear-gradient(to bottom, black calc(100% - 48px), transparent 100%);
			padding-bottom: 30px;
		}
	}
}

@mixin responsive($size) {
	@if $size =="tablet" {
		&.ev-modal-wrapper {
			.modal {
				height: auto;
			}
		}

		.person-details-modal-content {
			flex-direction: row;
		}

		.person-details-content {
			display: block;

			.person-details-name,
			.person-details-job {
				text-align: center;
				max-height: unset;
			}
		}

		.person-details-description {
			text-align: left;
			max-height: fit-content;

			&.long {
				mask-image: none;
			}
		}
	}

	@if $size =="mobile" {
		.person-details-image {
			width: 100%;
			margin-top: 16px;
		}

		.person-details-modal-content {
			flex-direction: column;

			.person-details-name,
			.person-details-job {
				text-align: center;
				max-height: 150px;
			}
		}

		.person-details-description {
			max-height: 350px;

			&.long {
				mask-image: linear-gradient(to bottom, black calc(100% - 48px), transparent 100%);
			}
		}
	}
}

@include media-query($med-down) {
	.speaker-details-modal {
		@include responsive("tablet");
	}
}

@include media-query($sm-down) {
	.speaker-details-modal {
		@include responsive("mobile");
	}
}
