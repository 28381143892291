@import './src/scss/old-deps';
@import './src/scss/deps';

.main-event-banner-live-carousel {
	$carouselCardHeight: calc(100vh - 80px);
	// -95px for the side nav
	max-width: calc(100vw - 95px);

	&.horizontal-navbar {
		max-width: 100vw;
	}

	.slick-prev, .slick-next {
		width: auto;
		z-index: 3;
		&::before {
			content: none;
		}
	}

	.slick-prev {
		left: 16px;
	}

	.slick-next {
		right: 16px;
	}

	.slick-dots {
		margin-bottom: 80px;
		width: 100%;
		height: 10px;
		border-radius: 100%;
		display: flex;
		justify-content: center;

		li {
			margin: 0 3px;
			button {
				display: flex;
				justify-content: center;
				align-items: flex-start;
				&::before {
					opacity: 1;
					width: 7px;
					height: 7px;
					left: unset;
					border-radius: 100px;
					background-color: $gray-hover;
					content: '';
					transition: width 250ms ease-in-out;
				}
			}
		}

		.slick-active {
			button {
				&::before {
					background-color: white;
					width: 20px;
					height: 7px;
					content: ''
				}
			}
		}

	}

	.session-banner-container {
		min-height: 640px;
		max-height: $carouselCardHeight;

		.live-session-banner,
		.breakout-session-banner,
		.banner-preview-container {
			max-height: 640px;
			min-height: 640px;
			height: 100vh;

			&.enable-dynamic-height {
				max-height: unset;
				height: auto;
			}
		}

		.breakout-session-banner .breakout-session-banner-container {
			margin: unset;
		}

		.breakout-session-banner {
			padding-bottom: 70px;
		}
	}

	.main-event-banner {
		.banner-preview-container {
			// clamp this value whilst in the carousel
			max-height: 640px;
		}

		&.enable-dynamic-height {
			.banner-preview-container {
				max-height: unset;
			}
		}
	}

}
