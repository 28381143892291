@import '../../../../../../../../scss/deps';

.panel-body .settings-card {
	width: 100%;
	min-height: 64px;
	border-radius: 12px;
	background-color: $gray-800;
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: left;
	padding: 16px;
	margin-top: 8px;
	.switch {
		height: 100%;
	}
	label {
		font-size: 14px;
		display: flex;
		align-items: center;
		button.no-style {
			padding: 0;
			margin-right: 6px;
		}
		.sign-on-logo {
			margin-right: 8px;
			height: 24px;
			width: auto;
		}
		.smart-tooltip {
			margin-left: 8px;
			top: 2px;

			.smart-tooltip-content {
				position: absolute;
				bottom: inherit;
				top: initial !important;
				left: inherit;
				width: 200px;  
				margin: 20px 0 0 -11px;
				z-index: 1;

				.smart-tooltip-text {
					white-space: normal;

					&::before {
						position: fixed;
						bottom: inherit;
						margin-top: -12px;
						transform: rotateX(180deg);
					}
				}
			}
		}
	}
	p {
		font-size: 12px;
		color: $gray-300;
		margin:  4px 0 0;
	}
	button.no-style {
		svg {
			transition: 0.2s;
		}
		&.show {
			svg {
				transform: rotateZ(90deg);
			}
		}
	}
	&.sub-list {
		margin-left: 8%;
		width: 92%;
	}
	&.detailed-card {
		display: block;
		label {
			width: 100%;
			display: flex;
			justify-content: space-between;
		}
		.tag-list {
			list-style: none;
			padding: 0;
			margin: 8px 0 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;

			.cursor-type-normal {
				cursor: default;
			}
		}
		.tag {
			padding: 8px 16px;
			margin-bottom: 4px;
			background-color: $gray-1000;
			border: 2px solid $gray-700;
			font-size: 12px;
			line-height: 18px;
			color: $gray-200;
			border-radius: 100px;
			&.capitalize {
				text-transform: uppercase;
			}
			&.clear {
				margin-top: 8px;
				background-color: transparent;
				border: 1px solid $gray-400;
				font-size: 14px;
			}
		}
		.options-button {
			padding: 1px;
			margin-right: 0;
			position: relative;

			.popover-wrapper {
				border-radius: 12px;
				position: absolute;
				right: 0;
				left: inherit;
				top: calc(100% + 20px);
				width: 150px;
				padding: 1px;

				button {
					padding: 8px 16px;
					font-size: 14px;
					font-weight: normal;
					border-radius: 0;
					background-color: transparent;
					width: 100%;
					&:first-child {
						border-top-left-radius: 12px;
						border-top-right-radius: 12px;
					}
					&:last-child {
						border-bottom-left-radius: 12px;
						border-bottom-right-radius: 12px;
					}
					&:hover {
						background-color: $border-gray;
					}
				}
			}

		}
		.field-group.text {
			margin: 8px 0 0;
			input {
				height: 32px;
			}
		}
	}
}