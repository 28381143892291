@import "../../../scss/deps";

.banner-announcement {
	min-height: 48px;
	width: 100%;
	min-width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0em;
	padding: 4px 40px;

	.banner-announcement-text {
		word-break: break-all;
	}

	.banner-announcement-button {
		cursor: pointer;
		display: flex;
		align-items: center;
		text-decoration: none;

		span {
			font-size: 13px;
			font-weight: 600;
			line-height: 16px;
			letter-spacing: 0em;
		}

		svg {
			margin-left: 8px;
		}
	}

	@media screen and (min-width: 770px) and (max-width: 1279px) {
		padding: 4px 40px;
		font-size: 12px;
		min-height: 60px;
	}

	@media screen and (max-width: 770px) {
		padding: 4px 16px;
		min-height: 32px;
		font-size: 12px;

		span {
			display: none;
		}

		.banner-announcement-button {
			margin-left: 8px;
			height: 100%;
		}
	}
}