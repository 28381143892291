@import './src/scss/old-deps';
@import './src/scss/base/colors';
@import './src/scss/abstracts/colors';

@keyframes uploadCard {
	from {
		transform: translateX(500px);
		opacity: 0;
	}

	to {
		transform: translateX(0);
		opacity: 1;
	}
}

.uploader-container {
	position: fixed;
	bottom: 40px;
	right: 40px;
	display: flex;
	flex-direction: column-reverse;
	z-index: 1000; //should render on top of everything
	// content-visibility: auto;

	.uploading-job {
		background: $gray;
		@include radius-8;
		padding: 8px 16px;
		margin-top: 8px;
		font-size: 14px;
		font-weight: 500;
		color: $light-gray;
		animation: uploadCard 150ms;
		//transition: transform 150ms, opacity 150ms, max-height 150ms, margin-bottom 150ms;

		.progress {
			color: $cyan;
			margin-left: 8px;
		}
	}
}