// --headingFont:
// --bodyFont:
// --accentColor:
// --headingTextColor:
// --backgroundColor:
// --bodyTextColor:
// --containerColor:
// --secondaryBackgroundColor:
// --secondaryAccentColor:
// --customColor0: this is the transparent accent color by default@import './src/scss/base/mixins';

@import './src/scss/base/mixins';
@import './src/scss/deps';

.description {
	padding-bottom: 50px;

	.custom-title {
		margin-bottom: 15px;
		font-size: 18px;
	}

	.description-content {
		border-radius: var(--customContainerBorderRadius, 0);
		padding: 30px;
		.title {
			margin-bottom: 15px;

			h1, h2, h3, h4, h5, h6 {
				line-height: normal;
			}
		}
	}
}

@include media-query($sm-down) {
	.session .description {
		padding-bottom: 40px;
	}
}
