.module-display-options {
	display: flex;
	align-items: center;
	margin: 0 auto 0 0;

	.module-display-options-label {
		margin-right: 8px;
	}

	.module-display-options-select-wrapper {
		width: 150px;

		.module-display-options-select {
			.select-dropdown.open {
				transform: translateY(calc(-100% - 58px));
			}
		}
	}
}
