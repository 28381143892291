@import "./src/scss/deps";
@import "./src/scss/base/colors";

.sub-tab-navigation {
	display: flex;
	gap: 4px;
	margin-bottom: 16px;
	flex-wrap: wrap;
	padding: 0 16px;


	p {
		@include get-admin-typography(label-2);
		height: 32px;
		padding: 0 14px;
		color: $white;
		background-color: $dark-gray;
		border: 2px solid $border-gray;
		outline: none;
		border-radius: 32px;
		font-family: inherit;
		width: auto;
		display: flex;
		align-items: center;
		border-color: $cyan;
		transition: 150ms border-color;

		span {
			outline: none;
			border: none;
			white-space: nowrap;
		}

		&.saving,
		&.exists {
			opacity: 0.8;
		}

		&.exists {
			border-color: $rose;
		}
	}

	.step-tab {
		@include get-admin-typography(label-2);
		$border-size: 4px;
		align-items: center;
		background-color: $dark-gray;
		border-radius: 16px;
		border: 2px solid $border-gray;
		color: $med-light-gray;
		display: flex;
		font-size: 11px;
		height: 32px;
		justify-content: center;
		margin-right: 0;
		min-width: 65px;
		padding: 0 14px;
		white-space: nowrap;

		&:hover {
			color: $white;
		}

		&.selected {
			color: $white;
			position: relative;
			z-index: 0;
			border-radius: 16px;
			border: 2px solid $gray-900;
			background-color: $gray-1000;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: 16px;
				background: $bl-lemonade;
				z-index: -2;
			}

			&::after {
				content: "";
				position: absolute;
				top: calc($border-size / 2);
				left: calc($border-size / 2);
				width: calc(100% - $border-size);
				height: calc(100% - $border-size);
				border-radius: 16px;
				background: black;
				z-index: -1;
			}
		}
	}
}