@import './src/scss/old-deps';
@import './src/scss/deps';
@import './src/scss/base/colors';
@import './src/scss/abstracts/colors';

$background-color: $med-gray;

.settings-block {
	@include radius-12;
	padding: 16px;
	background-color: $image-gray;
	margin-bottom: 16px;
	transition: background-color $hover-transition-duration;

	.settings-block-header {
		@include flex-between;

		h5 {
			font-weight: 600;
			font-size: 14px;
			margin-bottom: 0;
		}

		button {
			background: none;
			margin: 0;
			padding: 0;
		}

		&.tooltip {
			.smart-tooltip-content {
				z-index: 1;
				width: 160px;

				.smart-tooltip-text {
					white-space: pre-wrap;
				}
			}
		}

		.setting-block-header-text-with-tooltip {
			display: flex;
			gap: 5px;
		}
	}

	.settings-block-header-record {
		position: relative;

		h5 {
			margin-bottom: 0;
		}

		.settings-block-record-disable-text {
			font-size: 11px;
			margin: 0;
			display: none;
			position: absolute;
			background-color: $background-color;
			border-radius: 22px;
			padding: 16px;
			bottom: 150%;
		}

		.settings-block-record-disable-text::after {
			content: "";
			border-left: 8px solid $background-color;
			border-bottom: 8px solid $background-color;
			display: block;
			height: 8px;
			width: 8px;
			;
			position: absolute;
			transform: rotate(-45deg);
			bottom: -5px;
			left: calc(50% - 8px);
		}

		&:hover .settings-block-record-disable-text {
			display: initial;
		}
	}

	.rtmp-data {
		margin: 16px 0;
		font-style: normal;
		font-weight: 500;
		line-height: 16px;

		.rtmp-label {
			font-size: 12px;
			letter-spacing: 0.4px;
			color: $med-light-gray;
			margin-bottom: 8px;
		}

		.rtmp-value-row {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.rtmp-value {
				max-width: 246px;
				overflow-wrap: break-word;
				font-size: 14px;
			}
		}
	}

	.secondary-video-buttons button {
		margin-top: 8px;
	}

	.reactions-description {
		color: $gray-300;
		font-size: 14px;
		margin: 10px 0 20px;
	}

	.reactions-container {
		display: flex;
		flex-wrap: wrap;
		margin: 15px 0 -10px 0;
	}

	.reaction-container {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 40px;
		width: 40px;
		border-radius: 32px;
		margin: 0 15px 15px 0;

		&.background {
			.reaction {
				height: 28px;
				width: 28px;
			}
		}

		.reaction {
			height: 40px;
			width: 40px;
		}
	}

	.add-reaction {
		background-color: $gray-700;
		color: $primary;
		cursor: pointer;
		height: 40px;
		width: 40px;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 30px;
		user-select: none;
		margin-bottom: 15px;
	}

	.settings-section {
		margin-bottom: 16px;

		h6 {
			font-size: 12px;
			color: $med-light-gray;
			letter-spacing: 0.4px;
		}

		p {
			font-size: 14px;
			font-weight: 500;
		}

		.video-link {
			@include flex-between;
			align-items: flex-start;

			p {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				max-width: 80%;
				text-overflow: ellipsis;
				overflow: hidden;
				-webkit-line-clamp: 4;
			}

			button {
				margin: 0;
				margin-top: 8px;
				padding: 0;
				background-color: transparent;

				svg {
					path {
						transition: 150ms fill;
					}
				}

				&.hover {
					svg {
						path {
							fill: $white;
						}
					}
				}
			}
		}

		.firesides-hosts-settings-container {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		.tag-container {
			display: flex;
			padding: 0;
			max-width: 100%;
			flex-wrap: wrap;
			overflow-y: scroll;

			.tag {
				margin: 2px 2px;
				padding: 8px 16px;
				background-color: $dark-gray;
				border: 2px solid $border-gray;
				@include radius-full;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-size: 12px;
				font-weight: 500;
				color: $light-gray;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			.tag-container-show-more-btn {
				font-size: 12px;
				color: $cyan;
				padding: 0;
				margin: 2px 6px;
			}

			.tag-container-show-more-btn:hover {
				cursor: pointer;
			}

			&.breakout-room-name-tag-container {
				max-height: none;
				overflow: hidden;

				.breakout-room-name-tag {
					white-space: normal;
					word-wrap: break-word;
				}
			}
		}

		&:last-child {
			margin-bottom: 0;
		}

		.breakout-room-chip-container {
			display: flex;
			flex-wrap: wrap;
		}
	}

	.settings-section.categories {
		.tag {
			text-transform: none;
		}
	}

	.settings-type {
		background: $gray;
		@include radius-8;
		display: flex;
		align-items: center;
		padding: 8px;
		margin-bottom: 16px;

		.icon-gradient {
			background: $gradient;
			height: 24px;
			width: 24px;
			@include flex-center;
			@include radius-4;
			margin-right: 8px;
		}

		.icon-greenroom {
			background: $greenroom;
			height: 24px;
			width: 24px;
			@include flex-center;
			@include radius-4;
			margin-right: 8px;

			img {
				width: 16px;
			}
		}

		label {
			font-size: 14px;
			font-weight: 500;
		}
	}

	.open-gr-btn-wrapper {
		display: flex;
		padding: 14px 0 8px;

		.theme-btn {
			padding: 8px 16px;
			font-size: 13px;
		}
	}

	.video-display {
		display: flex;

		img {
			width: 28%;
			@include radius-8;
		}

		.video-data {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-left: 8px;

			label {
				@include line-clamp(2);
				font-size: 14px;
				font-weight: 500;
				word-break: break-word;
			}

			.duration {
				font-size: 14px;
				color: $border-light-gray;
				display: block;
				margin-top: 8px;
			}
		}
	}

	.field-group.switch {
		@include flex-between;
		margin-top: 16px;
		margin-left: 0;

		label {
			color: $gray-300;
			font-weight: bold;
			margin: 0;
		}

		&.session-replay {
			height: 32px;
			margin-top: 0;

			span {
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 18px;
			}
		}

		&.toggle-feedback-survey {
			.smart-tooltip {
				margin-left: 5px;

				.smart-tooltip-content {
					width: 160px;

					.smart-tooltip-text {
						font-weight: 300;
						white-space: pre-wrap;
					}
				}
			}
		}

		.dark-mode-toggle {
			display: flex;
			justify-content: flex-start;

			.smart-tooltip {
				margin-left: 5px;

				.smart-tooltip-content {
					height: 97px;
					width: 260px;

					.smart-tooltip-text {
						height: 91px;
						white-space: pre-wrap;
					}
				}
			}
		}
	}

	pre {
		font-size: 12px;
		color: $gray-300;
		font-weight: bold;
		overflow: hidden;
	}

	.session-language-select {
		align-items: stretch;
		color: $med-light-gray;
		display: flex;
		flex-direction: column;
		font-size: 12px;
		font-weight: 700;
		padding-bottom: 5px;

		.selected-option {
			color: white;
		}

		.session-language-label {
			display: flex;
			flex-direction: row;
			padding-bottom: 4px;
			padding-left: 16px;
		}

		.select-dropdown.open {
			margin-top: 2px;
		}
	}

	&.session-tags-block {
		.field-group {
			label {
				margin: 0;
			}

			margin-bottom: 16px;
		}

		.tag-chips-container {
			margin-top: 8px;

			.tag-chip {
				margin-right: 8px;
				margin-bottom: 5px;
			}
		}

	}

	&.feedback-survey-block {
		.editable-text {
			margin-top: 12px;
		}
	}
}

.session-tags-block-modal {
	.modal-body {
		.event-tag-add-tag {
			margin-top: 16px;
			padding: 8px 16px;
		}

		.sortable-session-tag-container {
			list-style: none;
			width: 100%;
			padding-left: 0;

			.sortable-session-tag {
				display: flex;
				justify-content: space-between;

				&:not(:first-child) {
					margin-top: 10px;
				}

				.container-left {
					display: flex;
					align-items: center;
					// - 20px to account for trash icon
					width: calc(100% - 20px);

					p {
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						margin-bottom: 0;
					}

					.drag {
						@include flex-center;
						margin-right: 12px;
						cursor: grab;

						svg path {
							transition: fill 150ms linear;
						}

						&:hover {
							svg path {
								fill: white;
							}
						}
					}

					.tag-color-preview {
						min-width: 40px;
						height: 20px;
						border-radius: 100px;
						margin-right: 12px;
					}
				}

				&.dragging {
					cursor: grabbing;

					.drag {
						svg path {
							fill: $white;
						}

						cursor: grabbing;
					}
				}

				.remove-tag {
					svg path {
						transition: fill 150ms linear;
					}

					&:hover {
						svg path {
							fill: $white;
						}
					}
				}
			}
		}
	}
}

.add-video-modal {
	.video-type-select {
		.video-type-selector {
			position: absolute;
			background: $dark-gray;
			width: calc(100% - 80px);
			border-radius: 0 0 12px 12px;
			opacity: 0;
			transform: translateY(0px);
			transition: 150ms opacity, 150ms transform;
			overflow: hidden;
			cursor: pointer;
			pointer-events: none;
			z-index: 5;
			box-shadow: $shadow-alert;

			&.open {
				opacity: 1;
				pointer-events: auto;
			}

			.select-option {
				transition: background-color 150ms;
			}
		}

		.video-type-selected {
			background: $gray;
			@include radius-12;
			cursor: pointer;
			position: relative;

			.arrow {
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				@include flex-center;
				width: 48px;
				transform: scaleY(1);
				transition: 60ms transform;
			}

			&.open {
				.video-selected-type {
					border-radius: 12px 12px 0 0;
				}

				.arrow {
					transform: scaleY(-1);
				}
			}
		}

		.select-streaming-type {
			background: none;
			height: 100%;
		}

		.video-selected-type {
			display: flex;
			align-items: center;
			padding: 16px;
			background-color: $gray-1000;
			border-radius: 12px;

			.icon-gradient {
				background: $gradient;
				height: 40px;
				width: 40px;
				@include flex-center;
				@include radius-8;
				margin-right: 8px;
			}

			.icon-greenroom {
				background: $greenroom;
				height: 40px;
				width: 40px;
				@include flex-center;
				@include radius-8;
				margin-right: 8px;
			}

			.video-selected-info {
				display: flex;
				flex-direction: column;

				label {
					font-size: 14px;
					color: $white;
					font-weight: 500;
					margin-bottom: 6px;
				}

				span {
					color: $light-gray;
					font-size: 14px;
				}
			}
		}
	}

	.recorded-header {
		display: flex;
		align-items: center;
		margin-top: 24px;

		span {
			display: inline-block;
			width: 40px;
			text-align: center;
			color: $light-gray;
			font-size: 14px;
		}

		.text {
			flex: 1;
			margin-bottom: 0;
		}

		.rtmp-data-container {
			flex: 1;
			width: 100%;

			.rtmp-data {
				display: flex;
				align-items: center;
				margin-bottom: 24px;

				.rtmp-data-text {
					flex: 1;

					h6 {
						font-weight: normal;
						margin: 0;
						line-height: 24px;
					}

					span {
						font-size: 12px;
						color: $med-light-gray;
						display: inline;
						font-weight: 400;
					}
				}

				button {
					margin: 0;
					padding: 0;
					background: none;
					color: $cyan;
					font-weight: 500;
				}
			}
		}

		.view-rtmps-container {
			@include flex-center;
			flex: 1;
		}
	}

	@keyframes overlayPulse {
		from {
			background-color: rgba(0, 0, 0, 0.75);
		}

		to {
			background-color: rgba(0, 0, 0, 0.7);
		}
	}

	.recorded-videos {
		display: grid;
		grid-template-columns: 50% 50%;
		gap: 16px;
		margin-top: 24px;
		max-width: calc(100% - 16px);

		.recorded-video {
			content-visibility: auto;

			.video-banner {
				width: 100%;
				padding-bottom: 56.25%;
				background-color: black;
				background-repeat: no-repeat;
				background-size: 110%;
				background-position: center;
				@include radius-8;
				border: 2px solid transparent;
				transition: 150ms border;
				position: relative;

				.processing-overlay {
					@include flex-center;
					position: absolute;
					top: -2px;
					left: -2px;
					bottom: -2px;
					right: -2px;
					@include radius-8;
					background-color: rgba(0, 0, 0, 0.7);
					animation: overlayPulse;
					animation-duration: 1s;
					animation-fill-mode: both;
					animation-iteration-count: infinite;
					animation-direction: alternate;
					padding: 15%;
					text-align: center;

					span {
						font-weight: bold;
					}
				}

				.video-options {
					padding: 10px;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.edit-thumbnail {
						opacity: 0;
						padding: 0;
					}

					&:hover .edit-thumbnail {
						opacity: 1;
					}
				}
			}

			&.selected {
				.video-banner {
					border: 2px solid $cyan;
				}
			}

			&:hover .video-options .edit-thumbnail {
				opacity: 1;
			}

			label {
				color: $white;
				font-weight: 500;
				margin-top: 8px;
				display: inline-block;
			}

			.duration {
				font-size: 14px;
				color: $border-light-gray;
				font-weight: bold;
				display: block;
				margin-top: 8px;
			}
		}
	}

	.video-embed-code {
		margin-top: 16px;

		label {
			font-size: 14px;
			font-weight: 500;
			margin-bottom: 8px;
			display: inline-block;
		}
	}
}

.edit-session-modal {

	&.breakout-session-modal {

		.modal-footer,
		.modal-header {
			padding-right: 26px;
		}
	}

	.edit-session-modal-footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		.error-message {
			color: $rose;
			opacity: 0;
			transition: opacity 250ms ease-in-out;

			&.toast {
				opacity: 1;
			}
		}
	}
}

.calendar-text-modal {
	.calendar-text-modal-body {
		max-height: 500px;

		.language-label {
			color: white;
			margin-bottom: 20px;
			display: flex;
		}
	}
}