@import "../../../../../../scss/deps";

@keyframes animateEmojiContainer {
	0% {
		transform: translateY(20px);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

.reaction-container {
	@include flex-center;
	gap: 4px;
	overflow: visible;
	position: absolute;
	right: 25px;
	bottom: 25px;
	transition: bottom 0.1s ease-in-out;

	.reaction-expander-btn {
		position: relative;
		margin-bottom: 4px;
	}

	.reaction {
		animation: 150ms animateEmojiContainer;
		border-radius: 100px;
		cursor: pointer;
		height: 40px;
		width: 40px;
		user-select: none;

		div {
			height: 40px;
			width: 40px;

			img {
				height: 40px;
				width: 40px;
				user-select: none;
			}
		}
	}

	.reaction.background-color-present {
		justify-content: flex-end;

		img {
			position: relative;
			top: 1px;
			height: 30px;
			width: 30px;
		}

		.smart-tooltip {
			width: 30px;
		}
	}

	.reaction.background-color-present.tooltip-present {
		justify-content: center;

		.smart-tooltip div img {
			position: relative;
			top: 0;
		}
	}

	.reaction.google-reactions,
	.reaction.brandlive-reactions {
		.smart-tooltip {
			display: flex;

			div img {
				display: flex;
			}

			.smart-tooltip-content .smart-tooltip-text {
				background-color: transparent;
				color: white;
			}

			.smart-tooltip-content .smart-tooltip-text:before {
				display: none;
			}
		}
	}
}

.video-controls-wrapper.hover+.reaction-container,
.video-controls-wrapper.paused:not(.hover)+.reaction-container {
	bottom: 90px;
	transition: bottom 0.1s ease-in-out;
}

.video-controls-wrapper.overlay-opened+.reaction-container {
	bottom: 25px;
}