@mixin tabletBaseResponsive($size) {
	@if $size =="tablet" {
		.about-us {
			.module-container {
				padding: 75px;
			}

			.about-us-text {
				width: 100%;
				margin-bottom: $ev-gap-x10;
			}
			.about-us-custom-heading,
			.about-us-custom-heading p {
				font-family: var(--headingFont);
				font-size: 14px;
				line-height: 20px;
			}

			&.about-us-standard,
			&.about-us-standard_flip,
			&.about-us-standard_full,
			&.about-us-standard_full_flip {
				.module-container {
					flex-direction: column;
				}

				.about-us-gallery-wrapper {
					width: 100%;
				}

				.about-us-gallery {
					height: 436px;
				}
			}

			&.about-us-standard,
			&.about-us-standard_full {
				.about-us-gallery-wrapper {
					margin-right: 0;
				}
			}

			&.about-us-standard_flip,
			&.about-us-standard_full_flip {
				.about-us-gallery-wrapper {
					margin-left: 0;
				}
			}

			&.about-us-standard_full,
			&.about-us-standard_full_flip,
			&.about-us-center_full {
				.module-container {
					padding: 75px 0;
				}

				.about-us-text {
					padding: 0 75px;
				}
			}

			&.about-us-center,
			&.about-us-center_full,
			&.about-us-center_split {
				.about-us-gallery {
					height: 300px;
				}
			}

			&.about-us-center,
			&.about-us-center_full {
				.about-us-text {
					max-width: 100%;
					margin-bottom: $ev-gap-x10;
				}
			}

			&.about-us-center,
			&.about-us-center_split {
				.about-us-gallery {
					gap: $ev-gap-x5;
				}
			}

			&.about-us-center {
				.about-us-gallery-4 {
					gap: $ev-gap-x2;
					max-width: none;
					height: 520px;
				}
			}

			&.about-us-center_full {
				.about-us-text {
					padding-right: 75px;
					padding-left: 75px;
				}

				.about-us-gallery-4 {
					grid-template-columns: repeat(24, 1fr);
					height: 512px;

					img {
						&:first-child {
							grid-column: 1 / span 14;
						}

						&:nth-child(2) {
							grid-column: 15 / span 10;
						}

						&:nth-child(3) {
							grid-column: 1 / span 10;
						}

						&:last-child {
							grid-column: 11 / span 14;
						}
					}
				}
			}

			&.about-us-center_split {
				.about-us-text {
					margin-bottom: $ev-gap-x10;
				}

				.about-us-text-inner {
					display: block;

					&>* {
						width: 100%;

						&:first-child {
							padding-right: 0;
						}

						&:last-child {
							padding-left: 0;
						}
					}
				}
			}
		}

		.main-event-banner {
			max-height: none;

			.module-container {
				flex-direction: column !important;
				padding: 75px;
			}

			.countdown-container {
				margin-bottom: 25px;
			}

			&.main-event-banner-standard,
			&.main-event-banner-right_full,
			&.main-event-banner-left_small,
			&.main-event-banner-right_small {
				.banner-preview-container {
					padding: 0;
					width: 100%;
					height: 350px;

					img {
						border-radius: 0;
					}
				}

				.banner-title-container {
					max-width: 100%;
				}
			}

			&.main-event-banner-standard,
			&.main-event-banner-right_full {
				.module-container {
					padding: 0;
				}
				.banner-title-container {
					padding: 75px;
				}
			}

			&.main-event-banner-left_small,
			&.main-event-banner-right_small {
				.banner-title-container {
					padding: 0 0 75px;
				}
			}

			&.main-event-banner-center,
			&.main-event-banner-center_no_bg {
				align-items: center;
			}

			&.main-event-banner-center_left_text {
				.module-container {
					.banner-title-container {
						align-self: flex-start;
					}
				}
			}

			&:not(.main-event-banner-center) {
				min-height: inherit;
			}

			&.enable-dynamic-height {
				min-height: auto;
			}
		}

		.speakers {
			.module-container {
				padding: 75px;
			}

			.speakers-title h1,
			.speakers-title h2 {
				font-size: 40px;
				text-align: center;
			}

			&.speakers-standard,
			&.speakers-small_vertical {
				.speakers-list {
					margin: 50px -9px -40px;
				}

				.speakers-column {
					flex-basis: 50%;
					padding: 0 12px 40px;
				}
			}

			&.speakers-small_horizontal,
			&.speakers-staggered {
				.speakers-list {
					margin: 40px 0 -40px;
				}

				.speakers-column {
					flex-basis: 100%;
					padding: 0 0 40px;
				}

				.speaker {
					justify-content: flex-start;
					img {
						width: calc(50% - 25px);
						height: unset;
					}
				}

				.speaker-name-container {
					margin-left: 25px;
				}

				.speaker-about {
					@include line-clamp(6);
				}
			}

			&.speakers-staggered {
				.module-container {
					padding: 75px;
				}

				.speakers-column {
					&:nth-child(even) {
						.speaker {
							flex-direction: row;

							.speaker-name-container {
								margin: 0 0 0 25px;
							}
						}
					}
				}

				.speaker-name-container {
					width: auto;
				}
			}

			&.speakers-solid {
				.speakers-list {
					grid-template-columns: 1fr 1fr;
				}

				.speaker {
					// always display this content cause tablets dont have hover
					.speaker-name-container {
						z-index: 2;
						opacity: 1;
					}
					&::after {
						@include absolute-fill;
						content: "";
						z-index: 1;
						background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
					}
				}
			}

			&.speakers-long_card {
				.speakers-list {
					margin: 50px -10px -20px;
				}

				.speakers-column {
					flex-basis: 50%;
					padding: 0 10px 20px;
				}
			}
		}

		.agenda {
			padding-top: 24px;

			.module-container {
				padding: 75px;
			}

			.agenda-description {
				padding: 0;
			}

			.agenda-featured-container,
			.agenda-sessions-container,
			.agenda-show-more-wrapper,
			.agenda-sessions-wrapper+.agenda-sessions-wrapper {
				margin-top: 40px;
			}

			.agenda-sessions-header {
				flex-direction: column;
				align-items: initial;
			}

			.agenda-sessions-header-title {
				margin-bottom: 10px;
			}

			.agenda-filters {
				justify-content: initial;

				.new-dropdown {
					flex-shrink: 0;
					min-width: 0;
				}
			}

			&.agenda-ltr,
			&.agenda-ltr_even_split {
				.agenda-session-card {
					&+.agenda-session-card {
						margin-top: 20px;
					}
				}

				.agenda-session-card-image {
					max-width: 46%;
				}

				.agenda-session-card-content {
					padding-left: 20px;
				}

				.agenda-session-card-timestamp {
					margin-bottom: 5px;
				}

				.agenda-session-card-title {
					@include line-clamp(2);
					margin-bottom: 10px;
					white-space: normal;
				}

				.agenda-session-card-description {
					display: none;
				}
			}

			&.agenda-ltr_even_split {
				.agenda-sessions-wrapper {
					padding: 0;
				}
			}

			&.agenda-contained_card {
				.agenda-sessions-list {
					grid-template-columns: 1fr;
				}

				.agenda-session-card {
					padding: 92px 40px 40px;

					.agenda-session-card-tags-wrapper {
						top: 40px;
						left: 40px;
					}
				}
			}

			&.agenda-vertical_card,
			&.agenda-vertical_card_with_featured {
				.agenda-sessions-list {
					grid-template-columns: repeat(2, 1fr);
				}
			}

			&.agenda-vertical_card {
				.agenda-session-card:not(.featured-card) {
					.agenda-session-card-description {
						-webkit-line-clamp: 4;
					}
				}
			}

			&.agenda-standard_no_image {
				.agenda-session-card {
					padding: 25px;

					&.live {
						.agenda-session-card-buttons {
							margin-top: 20px;
						}
					}
				}

				.agenda-session-card-content {
					display: block;
				}

				.agenda-session-card-text-content {
					max-width: 100%;
				}

				.agenda-session-card-description {
					-webkit-line-clamp: 2;
				}

				.agenda-session-card-buttons {
					margin-top: 20px;
					padding-left: 0;

					.primary {
						order: -1;
					}
				}
			}

			&.agenda-contained_card_no_image {
				.agenda-session-card {
					min-height: 0;
					padding: 25px;

					.agenda-session-card-tags-wrapper {
						margin-bottom: 20px;
					}
				}

				.agenda-session-card-description {
					-webkit-line-clamp: 5;
				}
			}

			&.new-event {
				.agenda-featured-container.agenda-featured-container {
					.agenda-session-card-image {
						max-width: 46%;
					}

					.agenda-session-card-content {
						padding-left: 20px;
					}

					.agenda-session-card-timestamp {
						margin-bottom: 5px;
					}

					.agenda-session-card-title {
						@include line-clamp(2);
						margin-bottom: 10px;
						white-space: normal;
					}

					.agenda-session-card-description {
						display: none;
					}
				}

				.agenda-session-card-description {
					-webkit-line-clamp: 5;
				}
			}

			&.agenda-calendar {
				.list-view {
					.agenda-sessions-section {
						h6 {
							line-height: 20px;
						}
					}

					.agenda-sessions-section-row {
						flex-direction: column;

						.agenda-sessions-section-row-time {
							flex-basis: 100%;
							padding: 0 0 5px;
						}
					}

					.agenda-session-card {
						height: 144px;

						.agenda-session-card-content {
							max-width: none;
							padding: 10px 10px 10px 15px;

							.agenda-session-card-description {
								-webkit-line-clamp: 2;
							}
						}

						.agenda-session-card-buttons {
							padding-right: 15px;

							.action-menu {
								position: static;
								display: flex;
								width: auto;
								padding: 0;
								background: none;
							}
						}
					}
				}
			}

			.agenda-calendar-container {
				max-width: calc(100% - 20px);

				.calendar-container {
					.block-container {
						.block-sessions-container {
							.session-card-container {
								.calendar-session-card-content {
									.calendar-session-card-time {
										font-weight: 500;
										font-size: 11px;
									}

									h4 {
										font-size: 14px;
									}
								}
							}
						}
					}
				}
			}
		}

		.registered-main-content {
			.agenda {
				.agenda-sessions-header {
					.agenda-sessions-header-title {
						margin-bottom: 30px;
					}

					.search-input {
						margin-right: 0;
						margin-bottom: 0;
					}

					.agenda-filters {
						gap: 10px;
						flex-wrap: wrap;
					}

					.evt-select {
						margin-left: 0;
					}
				}
			}
		}

		.products {
			.module-container {
				padding: 75px;
			}
			.products-top {
				flex-wrap: wrap;
				margin-bottom: 25px;
			}

			.products-description {
				margin-bottom: 40px;
			}

			.dropdown-container {
				padding-left: 0;
				text-align: left;
			}

			&.products-standard {
				.product-list {
					margin-bottom: 40px;
					grid-template-columns: repeat(2, 1fr);
					grid-gap: 20px;
				}

				.product-item-image-container {
					height: 240px;
				}
			}

			&.products-grid,
			&.products-vertical_list {
				.product-list {
					grid-template-columns: 1fr;
					grid-row-gap: 25px;

					.product {
						grid-template-columns: 47% 1fr;
						height: 265px;
						width: 100%;
						padding-right: 0;
						column-gap: 25px;

						.slick-slider img {
							width: 285px;
						}
					}
				}

				.module-container {
					.product-list {
						grid-template-columns: 1fr;
						grid-row-gap: 25px;

						.product {
							grid-template-columns: 47% 1fr;
							height: 265px;
							width: 100%;
							padding-right: 0;
							column-gap: 25px;

							.slick-slider img {
								width: 285px;
							}
						}
					}
				}
			}
		}

		.videos {
			.video-slider {
				max-width: initial;
			}

			.module-container {
				padding: 75px;
			}

			.video-title-container {
				margin-bottom: $ev-gap-x10;
			}

			&.videos-standard,
			&.videos-wide,
			&.videos-full {
				.video-description .public-DraftStyleDefault-ltr {
					text-align: center;
				}
				.slick-slider {
					.slick-slide {
						height: 420px;
					}
				}
			}

			&.videos-standard,
			&.videos-wide,
			&.videos-ltr {
				.video-title-container {
					max-width: none;
				}
			}

			&.videos-standard {
				.module-container {
					padding-right: 75px;
					padding-left: 75px;
				}
			}
			&.videos-full {
				.module-container {
					padding: 75px 0;
				}
			}
			&.videos-wide {
				.module-container {
					padding: 75px 0;
				}
				.video-title-container {
					padding-right: 75px;
					padding-left: 75px;
				}
			}

			&.videos-ltr {
				.video-description .public-DraftStyleDefault-ltr {
					text-align: center;
				}
				.video-title-container {
					text-align: center;
				}

				.slick-slider {
					.slick-slide {
						width: 512px;
						height: 380px;
					}
				}
			}
		}

		.sponsors {
			&.sponsors-standard,
			&.sponsors-card_full,
			&.sponsors-card_short,
			&.sponsors-split {
				.module-container {
					padding: 75px;
				}

				.sponsor-cards-container {
					gap: 20px;
					margin-top: 20px;
				}
			}

			&.sponsors-card_short,
			&.sponsors-split {
				.sponsor-card {
					height: 165px;
					padding: 30px 27px;
				}
			}

			&.sponsors-standard {
				.sponsor-card {
					width: calc(100% / 3 - 20px);
				}

				.sponsor-card-image {
					height: 104px;
				}
			}

			&.sponsors-card_full {
				.sponsor-card {
					padding: 30px;
				}

				.sponsor-card-image {
					margin-bottom: 20px;
				}
			}

			&.sponsors-card_full,
			&.sponsors-card_short {
				.sponsors-description {
					padding-right: 0;
					padding-left: 0;
				}

				.sponsor-cards-container {
					grid-template-columns: repeat(2, 1fr);
				}
			}

			&.sponsors-split {
				.module-container {
					flex-direction: column;
				}

				.sponsors-text-wrapper {
					width: 100%;
					margin-right: 0;
					text-align: center;
				}

				.sponsors-description {
					margin-bottom: 20px;
					.public-DraftStyleDefault-ltr {
						text-align: center;
					}
				}

				.sponsor-cards-container {
					width: 100%;
				}
			}
		}

		// when consolidating, this styling was under the "large" responsive mixin
		// but the break max was in line with "tablet"
		.breakout-session-banner {
			min-height: 435px;

			.breakout-session-banner-header-container,
			.breakout-session-banner-container {
				padding-right: 130px;
				padding-left: 130px;
			}

			.breakout-session-banner-container {
				padding-bottom: 75px;
			}
		}

		.live-session-banner {
			justify-content: flex-end;
			min-height: 435px;

			.live-session-banner-header {
				display: none;
			}

			.module-container {
				padding-bottom: $ev-gap-x10;
			}

			.live-session-banner-title {
				margin-top: $ev-gap-x4;
			}
		}

		.main-event-banner-live-carousel {
			max-width: 100vw;

			.main-event-banner,
			.live-session-banner,
			.breakout-session-banner {
				max-height: unset;
				height: $carouselCardHeight;

				&.iOS {
					height: 100vh;
				}

				&.enable-dynamic-height {
					height: auto;

					&.iOS {
						height: auto;
					}
				}

			}
		}

		.call-out {
			&.call-out-standard {
				.module-container {
					padding: 75px;
				}

				.call-out-inner {
					padding: 75px 30px;
				}
			}
			&.call-out-no_border,
			&.call-out-custom_background {
				.module-container {
					padding: 0 75px;
				}
			}

			&.call-out-ltr {
				.call-out-inner {
					padding-right: 20px;
					padding-left: 75px;
				}
			}
		}

		.accordion {
			.module-container {
				padding: 75px;
			}
		}

		.marketing-page-footer {
			.footer-container {
				padding: 23px 75px 33px;
			}

			.footer-left {
				flex-basis: 120px;
			}

			.footer-right {
				flex-basis: 120px;
			}

			.footer-nav {
				.footer-nav-item {
					margin: 0 12px;
				}
			}
		}

		.people-container {
			.people-content {
				grid-template-columns: repeat(2, 1fr);
				gap: 20px 30px;
			}
		}

		.hide-x-overflow-tablet {
			overflow-x: hidden;
		}

		.cookie-banner-container {
			display: block;
			width: 618px;

			.cookie-banner-actions {
				margin-top: 15px;
			}
		}

		.video-player-v2-wrapper {
			.video-controls-layer {
				.video-controls-wrapper {
					.lower {
						.video-metadata {
							transform: translateY(0);

							.video-meta-content {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
