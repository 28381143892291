@import "./src/scss/deps";

$linear-gradient-tag: linear-gradient(90deg, #97f998 0%, #6aeae5 108.06%);
$max-content-width: 808px;

.leaderboard-wrapper {
	background-color: var(--backgroundColor);

	.leaderboard-main-content {
		width: 100%;
		max-width: 1440px;
		padding: 40px 130px;
		margin: 0 auto auto auto;

		.leaderboard-header {
			display: flex;
			flex-direction: column;
			margin: 0 auto 30px auto;
			max-width: $max-content-width;

			h2 {
				font-size: 40px;
				font-weight: 700;
				line-height: 50px;
				margin-bottom: 25px;
			}

			.leaderboard-search-container {
				display: flex;
				align-items: center;
				gap: 8px;

				&.disabled {
					pointer-events: none;
					opacity: 0.6;
				}

				.search-input,
				.new-dropdown {
					border: 1px solid rgba(var(--bodyTextColor-rgb), 0.3);
					border-radius: var(--customInputBorderRadius, 8px);
				}

				.search-input {
					height: 40px;
					flex: 1;
					margin-right: 0;

					input {
						color: var(--bodyTextColor);

						&::placeholder {
							color: rgba(var(--bodyTextColor-rgb), 0.5);
						}
					}

					svg#icon-search {
						width: 20px;
						height: 20px;
					}
				}

				.leaderboard-filters {
					@include flex-center;
					height: 40px;
					gap: 8px;

					.filter-dropdown {
						.new-dropdown-value {
							color: rgba(var(--bodyTextColor-rgb), 0.5);
							font-weight: 600;
							@include line-clamp(1);
							text-align: left;
						}
					}
				}

				.leaderboard-filters-button {
					display: none;

					color: var(--accentColor);
					background: var(--containerColor);
					height: 40px;
					width: 40px;
					transition:
						background-color 150ms ease-in,
						color 150ms ease-in;
					border-radius: 8px;

					&.on {
						background-color: var(--accentColor);
						color: var(--containerColor);
					}
				}
			}
		}

		.no-results {
			max-width: $max-content-width;
			margin: 0 auto;
			text-align: center;
		}

		.entry-avatar-wrapper {
			background-color: var(--containerColor);
			border-radius: var(--customImageBorderRadius, 8px);

			.entry-avatar {
				border: 1px solid var(--containerColor);
				background-color: rgba(var(--accentColor-rgb), 0.05);
				border-radius: var(--customImageBorderRadius, 8px);
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				@include flex-center;
				font-size: 20px; // matches directory
				font-weight: 600;
			}
		}

		.leaderboard-list {
			width: 100%;
			max-width: $max-content-width;
			margin: 0 auto;

			.paging-container {
				justify-content: center;
				margin-top: 16px;

				button {
					color: rgba(var(--bodyTextColor-rgb), 0.3);

					svg {
						path {
							fill: var(--accentColor);
						}
					}

					&.active {
						background-color: var(--accentColor);
						color: var(--containerColor);
						border-radius: var(--customButtonBorderRadius, 2px);
					}
				}
			}

			.to-my-page {
				float: right;
				position: relative;
				top: -33px;
				font-weight: bold;
				background-color: var(--accentColor);
				color: var(--containerColor);
				border-radius: var(--customButtonBorderRadius, 2px);
			}
		}

		.me {
			background-color: rgba(var(--accentColor-rgb), 20%);
			border-radius: var(--customContainerBorderRadius, 4px);
			padding: 1px 5px;
			font-size: 10px;
			font-weight: 600;
			line-height: 14px;
			color: var(--accentColor);
		}

		.leaderboard-card {
			width: 100%;
			height: fit-content;
			background-color: var(--containerColor);
			border-radius: var(--customContainerBorderRadius, 12px);
			margin-bottom: 8px;
			display: flex;
			align-items: center;

			&.clickable {
				cursor: pointer;
			}

			&:hover {
				filter: drop-shadow(0px 13px 20px rgba(0, 0, 0, 0.15));
			}

			.entry-avatar {
				height: 64px;
				width: 64px;
				margin: 8px;
			}

			.card-content {
				flex-grow: 1;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.name {
					font-size: 14px;
					font-weight: 600;
					line-height: 24px;
					color: var(--bodyTextColor);
					margin: 0 0 2px;
				}

				.me {
					margin-left: 8px;
				}

				.title {
					font-size: 13px;
					font-weight: 500;
					line-height: 20px;
					color: rgba(var(--bodyTextColor-rgb), 0.3);
					margin: 0;
				}
			}

			.score {
				margin: 0 24px;
				height: 24px;
				width: fit-content;
				background: $linear-gradient-tag;
				border-radius: var(--customButtonBorderRadius, 100px);
				padding: 4px 8px;
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
				color: var(--bodyTextColor);
			}
		}

		.podium-container {
			width: 100%;
			max-width: $max-content-width;
			height: 184px;
			margin: 0 auto 8px;
			border-radius: var(--customContainerBorderRadius, 12px);
			background-color: var(--containerColor);

			.podium-entries-container {
				display: flex;
				align-items: flex-end;
				justify-content: center;
				height: 100%;

				.podium-entry {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 180px;
					position: relative;

					&.clickable {
						cursor: pointer;
					}

					.entry-avatar-wrapper {
						height: 58px;
						width: 58px;
						position: absolute;

						.entry-avatar {
							height: 100%;
							width: 100%;

							.badge {
								@include flex-center;
								position: absolute;
								top: -6px;
								right: -5px;
								width: 18px;
								height: 18px;
								border-radius: 50%;
								background-color: var(--accentColor);
								color: var(--containerColor);
								font-size: 10px;
								font-weight: 700;
								border: 1px solid var(--containerColor);
							}
						}
					}

					.podium-entry-details {
						margin: auto 0 0 0;
						padding: 18px 18px 0 18px;
						text-align: center;
						background-color: rgba(var(--accentColor-rgb), 0.1);
						width: 100%;

						div {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						.podium-entry-name {
							font-size: 14px;
							font-weight: 600;
							line-height: 20px;
							margin-bottom: 4px;
							margin-top: 4px;
							color: var(--bodyTextColor);

							.podium-list-rank {
								display: none;
							}

							.me {
								width: 26px;
								position: absolute;
								top: 52px;
								right: calc(50% - 13px);
							}
						}

						.podium-entry-job {
							font-size: 13px;
							font-weight: 500;
							line-height: 20px;
							margin-bottom: 4px;
							color: rgba(var(--bodyTextColor-rgb), 0.55);
						}
					}

					&.silver {
						height: 158px;

						.podium-entry-details {
							height: 114px;
							border-radius: 12px 0 0 0;
						}
					}

					&.gold {
						height: 178px;

						.podium-entry-details {
							height: 134px;
							border-radius: 12px 12px 0 0;
							box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
						}
					}

					&.bronze {
						height: 148px;

						.podium-entry-details {
							height: 104px;
							border-radius: 0 12px 0 0;
						}
					}
				}
			}

			.podium-entry-score {
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
				color: var(--bodyTextColor);
				background: $linear-gradient-tag;
				border-radius: 100px;
				width: fit-content;
				margin: 0 auto;
				padding: 4px 8px;

				.points-text {
					display: none;
				}
			}
		}
	}
}

@mixin leaderboardResponsive($size) {
	.marketing-registered-page .leaderboard-wrapper {
		@if $size =="small-desktop" {}

		@if $size =="tablet" {
			.leaderboard-main-content {
				padding: 30px 75px;
			}
		}

		@if $size =="mobile" {
			.leaderboard-main-content {
				padding: 30px 8px;

				.leaderboard-header {
					.leaderboard-search-container {
						flex-wrap: wrap;
						gap: 10px;

						.leaderboard-filters {
							width: 100%;

							.filter-dropdown {
								flex: 1;
							}
						}

						.leaderboard-filters-button {
							display: block;
						}
					}
				}

				.leaderboard-list {
					padding: 0 12px;

					.to-my-page-container {
						display: flex;
						justify-content: center;
						margin-top: 16px;

						.to-my-page {
							top: 0;
						}
					}
				}

				.me {
					background-color: $pink;
					border-radius: var(--customButtonBorderRadius, 100px);
					color: var(--containerColor);
				}

				.leaderboard-card {
					.avatar {
						margin: 12px 8px 12px 12px;
					}

					.card-content {
						flex-direction: column;
						justify-content: center;
						align-items: flex-start;

						.name {
							line-height: 20px;
						}

						.title {
							font-size: 12px;
							line-height: 16px;
							margin: 0 0 4px;
						}
					}

					.score {
						margin: 0;
					}
				}

				.podium-container {
					&.mobile-podium {
						height: auto;
						background-color: rgba(var(--accentColor-rgb), 0.1);
						padding: 12px;
						margin: 0 0 8px;
						width: 100%;
						border-radius: 12px;
						box-shadow: 0px 8.721px 18.169px 0px rgba(0, 0, 0, 0.05);

						.podium-entries-container {
							display: block;

							.podium-entry {
								width: 100%;
								height: fit-content;
								justify-content: flex-start;
								display: flex;
								flex-direction: revert;
								background-color: var(--containerColor);
								border-radius: var(--customContainerBorderRadius, 12px);
								padding: 12px;

								&:not(:last-of-type) {
									margin-bottom: 8px;
								}

								.entry-avatar-wrapper {
									position: relative;
									width: 64px;
									height: 64px;

									.entry-avatar {
										width: 64px;
										height: 64px;

										.badge {
											background-color: $pink;
											color: var(--bodyTextColor);
										}
									}
								}

								.podium-entry-details {
									display: flex;
									flex-direction: column;
									justify-content: center;
									width: 100%;
									height: 100%;
									text-align: left;
									padding: 0;
									background-color: unset;
									margin-left: 8px;

									.podium-entry-name {
										.me {
											position: inherit;
											top: unset;
											right: unset;
											margin-left: 8px;
										}
									}

									.podium-entry-job {
										font-size: 12px;
										line-height: 16px;
									}

									.podium-list-rank {
										display: inline-block;
									}

									.podium-entry-score {
										margin: 0;

										.points-text {
											display: inline-block;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@include media-query($xlrg-down) {
	.live-event-container {
		@include leaderboardResponsive("small-desktop");
	}
}

@include media-query($med-down) {
	.live-event-container {
		@include leaderboardResponsive("tablet");
	}
}

@include media-query($sm-down) {
	.live-event-container {
		@include leaderboardResponsive("mobile");
	}
}

.editor.desktop {
	@include leaderboardResponsive("small-desktop");
}

.editor.tablet {
	@include leaderboardResponsive("tablet");
}

.editor.mobile {
	@include leaderboardResponsive("mobile");
}