@import './src/scss/old-deps';
@import './src/scss/deps';



.agenda-session-card-tags {
	display: flex;
	gap: 5px;
	flex-wrap: wrap;
	margin-top: 20px;

	.session-tag {
		@include get-typography(caption-l2);
		padding: 4px 10px;
		border-radius: 100px;
	}
}