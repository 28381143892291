@import './src/scss/old-deps';

.basic-card {
	display: inline-block;
	background-color: var(--gray-800);
	border-radius: 12px;
	width: 100%;
	transition: background-color $hover-transition-duration;
	padding: var(--basic-card-padding, 5px);

	&:hover {
		background-color: var(--gray-700);
		transition: background-color $hover-transition-duration;
	}
}
