@import "./src/scss/deps";
@import "./src/scss/old-deps";

.panel-color-picker {
	display: flex;
	justify-content: space-between;
	margin-top: 16px;

	.color-editor {
		label {
			margin-left: 0;
			font-size: 10px;
			color: $gray-300;
		}

		.color-input {
			width: 156px;
			font-size: 11px;
			height: 27px;

			.color-input-left {
				.color-bubble {
					width: 22px;
					height: 22px;
					min-width: 22px;

				}

				.hex-readout {
					width: 80px;
					margin-left: 4px;

					input {
						font-size: 11px;
						width: 100%;
					}
				}

				.color-picker-wrapper.open, .image-picker-wrapper.open {
					transform: translateY(-100%) translateX(0px);
				}
			}

			.color-input-right {
				justify-content: flex-start;
				width: 46px;
				font-size: 11px;
				height: 27px;

				input {
					font-size: 11px;
				}
			}
		}
	}

	.panel-color-picker-label-wrapper {
		display: flex;
		align-items: flex-end;

		span {
			font-size: 12px;

			u {
				cursor: pointer;
			}
		}
	}

	&:not(.disabled-picker) {
		.color-editor {
			.color-input {
				.color-input-left {
					.color-bubble {
						cursor: pointer;
					}
				}
			}
		}
	}

	&.disabled-picker {
		.color-editor {
			.color-input {
				opacity: 0.5;
				pointer-events: none;
			}
		}
	}
}

.panel-block#layout-theme {
	.panel-color-picker {
		.panel-color-picker-label-wrapper {
			.smart-tooltip {
				margin-left: 2px;

				svg {
					width: 16px;
					height: 16px;
				}
			}
		}
	}
}