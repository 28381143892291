@import "./src/scss/deps";

.advanced-settings {
	margin: 0 auto 0 0;
	height: 1.5rem;

	&.open {
		height: auto;
	}

	.content-container {
		width: fit-content;

		.rounded-speaker-images-container {
			margin-top: 8px;

			.smart-tooltip {
				display: inline;
				position: relative;
				top: -5px;
				left: 3px;
			}
		}
	}

	.text-container {
		width: auto;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 16px;

		.title {
			font-size: 14px;
			line-height: 20px;
			font-weight: 500;
			margin: 0;
		}
		button {
			padding: 0 8px;
		}
		.arrow-down {
			transform: rotate(180deg);
			// transition: 150ms transform;
		}
	}

	.settings-row {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		p {
			font-size: 14px;
			font-weight: 500;
			margin: 0 6px;
		}
		// switch overruns its boundaries a little bit so gets some extra padding
		.switch {
			padding: 0 2px;
		}

		.smart-tooltip-content {
			width: 420px;
			white-space: normal;
		}
	}

	.agenda-advanced-settings {
		.settings-row {
			justify-content: space-between; /* make sure toggles are aligned */
		}

		.toggle-label {
			margin-right: 16px;
		}

		.agenda-tooltip {
			display: inline-block;
		}
	}
}
