$accent-purple: #cdd4f3;
$alt-gray: #908ca0;
$autocomplete: #093a6c;
$above-the-fold-outline-color: #ecedf1;
$above-the-fold-dark-mode-outline-color: 60 58 66;
$black: #1b1a1d;
$blue: #3456ff;
$border-dark-gray: #2a2a2a;
$border-gray: #3c3a42;
$border-lightest-gray: #ecedf1;
$border-light-gray: #908ca0;
$border-med-gray: #625e70;
$bright-green: #32d74b;
$chat-black: #00051a;
$classic-gray-text: #838aa1;
$cyan: #5ae4ff;
$cyan-active: #1fdaff;
$cyan-focus: #5ae3ff;
$cyan-hover-rgba: rgba($cyan, 0.05);
$cyan-hover: #78e9ff;
$dark-blue: #081543;
$dark-gray: #1a1a1f;
$darker-gray: #1b1a1d;
$error: #ff6961;
$excel-green: #479a5f;
$focus-border: rgba(255, 255, 255, 0.4);
$gradient: linear-gradient(133.12deg, #fff85c 16.71%, #ff6ae6 100%);
$gradient-alt: linear-gradient(90deg, #fffd62 0%, #ff6ae6 100%);
$gray-active: #3c3a42;
$gray-hover: #3c3a42;
$gray-placeholder: rgba(120, 120, 120, 1);
$gray: #4f4c57;
$grayish-blue: #ebeeff;
$grayish-purple: #46444d;
$green: #2aae3e;
$greenroom: #a6ff79;
$icon-gray: #878793;
$image-gray: #312f38;
$lemonade: #d5fc68;
$icon-gray: #a1a1a1;
$light-blue: #5ae3ff;
$light-gray: #e5e4ec;
$lighter-gray: rgb(247, 247, 249);
$lime: rgb(192, 238, 145);
$magenta: #ff6ae6;
$med-gray-inactive: rgba(38, 36, 43, 0.5);
$med-gray: #26242b;
$med-light-gray: #bfbbcf;
$modal-background: #09090a;
$off-black: #09090a;
$off-white: #fcfcfc;
$option-border-gray: #180439;
$option-gray: #f5f7ff;
$option-green: rgba(43, 172, 75, 0.1);
$option-red: rgba(255, 68, 58, 0.1);
$pdf-red: #d61e00;
$pink-lemonade: #f881cf;
$pink: #ed75e0;
$primary-red: #de352c;
$powerpoint-orange: #db6905;
$purple-star: #6663f9;
$purple: #7c69f6;
$red: #ec0c00;
$rose: #ff375f;
$shadow-alert: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
$white: #fff;
$word-blue: #5185ec;
$yellow-star: #ffde3b;
$yellow: #fff85c;
$green-check: #5bdf6f;
$tab-gray: #e1e7ff;
$session-header-background: linear-gradient(180deg, rgba(26, 26, 26, 0.6) 0%, rgba(26, 26, 26, 0) 100%);
$bl-lemonade: linear-gradient(104.42deg, $magenta -4.67%, $yellow 69.07%, $greenroom 120.95%);
$bl-lemonade-hover: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
	linear-gradient(108.66deg, $magenta -4.67%, $yellow 69.07%, $greenroom 120.95%);
$bl-razz: linear-gradient(90deg, $magenta 0%, #55d6f0 100%);
$bodyTextSecondaryColor: rgba(131,
		138,
		161,
		1); // bodyTextSecondaryColor is only available for elements under the live-event-container class, which is most places but not all.

$accent-color-text: #ec5e2b;
$accent-color-background: #ec5e2b;

// Create an array of colors
$colors: (
	accent-purple: $accent-purple,
	alt-gray: $alt-gray,
	autocomplete: $autocomplete,
	black: $black,
	blue: $blue,
	border-dark-gray: $border-dark-gray,
	border-gray: $border-gray,
	border-light-gray: $border-light-gray,
	border-med-gray: $border-med-gray,
	bright-green: $bright-green,
	chat-black: $chat-black,
	classic-gray-text: $classic-gray-text,
	cyan: $cyan,
	cyan-active: $cyan-active,
	cyan-focus: $cyan-focus,
	cyan-hover-rgba: $cyan-hover-rgba,
	cyan-hover: $cyan-hover,
	dark-blue: $dark-blue,
	dark-gray: $dark-gray,
	darker-gray: $darker-gray,
	error: $error,
	excel-green: $excel-green,
	focus-border: $focus-border,
	gradient: $gradient,
	gray-active: $gray-active,
	gray-hover: $gray-hover,
	gray-placeholder: $gray-placeholder,
	gray: $gray,
	grayish-blue: $grayish-blue,
	grayish-purple: $grayish-purple,
	green: $green,
	greenroom: $greenroom,
	icon-gray: $icon-gray,
	image-gray: $image-gray,
	lemonade: $lemonade,
	light-blue: $light-blue,
	light-gray: $light-gray,
	lighter-gray: $lighter-gray,
	lime: $lime,
	magenta: $magenta,
	med-gray-inactive: $med-gray-inactive,
	med-gray: $med-gray,
	med-light-gray: $med-light-gray,
	modal-background: $modal-background,
	off-black: $off-black,
	off-white: $off-white,
	option-border-gray: $option-border-gray,
	option-gray: $option-gray,
	option-green: $option-green,
	option-red: $option-red,
	pdf-red: $pdf-red,
	pink-lemonade: $pink-lemonade,
	pink: $pink,
	powerpoint-orange: $powerpoint-orange,
	purple-star: $purple-star,
	purple: $purple,
	red: $red,
	rose: $rose,
	shadow-alert: $shadow-alert,
	tab-gray: $tab-gray,
	white: $white,
	word-blue: $word-blue,
	yellow-star: $yellow-star,
	yellow: $yellow,
	green-check: $green-check
);

svg {
	@each $color, $value in $colors {
		&.#{"#{$color}"} {
 path {
			fill: #{$value};
		}
	}
}

&#icon-close-add-circle {
	transform: rotate(45deg);
}

&.lemonade-gradient {
	path {
		fill: url(#svg_lemonade_gradient);
	}
}
}

/* GENERATES THE FOLLOWING SCSS:
svg {
	&.dark-blue {
		path {
			fill: $dark-blue;
		}
	}

	&.blue {
		path {
			fill: $blue;
		}
	}

	&.cyan {
		path {
			fill: $cyan;
		}
	}

	...
}
*/