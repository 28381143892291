@keyframes loadBounce {
	0% {
		fill: rgba(var(--buffering-fill), 0);
		transform: scale(1);
	}

	10% {
		fill: rgba(var(--buffering-fill), 0.8);
	}

	50% {
		transform: scale(1);
	}

	100% {
		fill: rgba(var(--buffering-fill), 0);
		transform: scale(0.9);
	}
}

.buffering-indicator {
	path {
		transform-origin: center center;
		animation: 2s loadBounce infinite linear;

		&:nth-child(8) {
			animation-delay: 0s;
		}

		&:nth-child(7) {
			animation-delay: 0.25s;
		}

		&:nth-child(6) {
			animation-delay: 0.5s;
		}

		&:nth-child(5) {
			animation-delay: 0.75s;
		}

		&:nth-child(4) {
			animation-delay: 1s;
		}

		&:nth-child(3) {
			animation-delay: 1.25s;
		}

		&:nth-child(2) {
			animation-delay: 1.5s;
		}

		&:nth-child(1) {
			animation-delay: 1.75s;
		}
	}
}
