@import "../../../scss/deps";

$rem8px: 8px;
$rem100px: 100px;
$tabColor: var(--bodyTextColor);

.sliding-menu-wrapper {
	padding-left: $rem8px;
	display: flex;
	position: relative;

	.sliding-menu-options {
		display: flex;
		overflow-x: scroll;
		scrollbar-width: none;
		gap: 25px;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&.animate {
		.sliding-menu-underline {
			transition:
				left 150ms ease-in-out,
				width 150ms ease-in-out;
		}
	}

	.sliding-menu-option {
		cursor: pointer;
		position: relative;
		color: $med-gray;

		&.selected {
			color: #ffffff;
		}

		.text {
			pointer-events: none;
		}
	}
	.sliding-menu-underline {
		border-radius: $rem100px;
		height: 3px;
		background: var(--accentColor);
		left: 0;
		bottom: 0;
		z-index: 2;
		position: absolute;
	}

	.position-relative {
		position: relative;
	}

	&.above-the-fold-tabs {
		height: var(--above-the-fold-tabs-height);
		padding-left: 0;
		border-left: rgb(var(--above-the-fold-outline-color)) solid 1px;

		.sliding-menu-options {
			padding-right: 20px;
			padding-left: 20px;
		}

		.sliding-menu-option {
			height: var(--above-the-fold-tabs-height);
			line-height: var(--above-the-fold-tabs-height);
			font-size: 13px;
			font-weight: 600;
			white-space: nowrap;
			color: var(--modeTabColor);
			font-family: var(--bodyFont);

			svg {
				margin-right: 8px;

				path {
					fill: $tabColor;
					transition: fill 150ms;
				}

				&.noIcon {
					display: none;
				}
			}

			&:hover {
				color: var(--accentColor);

				svg {
					path {
						fill: var(--accentColor);
					}
				}
			}
		}
	}
}
.sliding-menu-divider {
	position: absolute;
	left: 0;
	bottom: 1px;
	width: 100%;
	border-bottom: 1px solid $border-lightest-gray;
}
.dark-mode{
	.sliding-menu-divider {
		border-color: $border-gray;
	}
}
