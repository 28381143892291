@import '../../../../../../../../scss/deps';

.panel-body {
	.paid-ticketing-header {
		@include flex-between;
		margin-bottom: 12px;
		margin-top: 12px;

		label {
			color: $gray-300;
			font-weight: 500;
			font-size: 14px;
		}

		button {
			background: $bl-lemonade;
			-webkit-background-clip: text;
			background-clip: text;
			-webkit-text-fill-color: transparent;
			font-size: 14px;
			font-weight: bold;
			margin: 0;
			padding: 0;

			svg {
				margin-right: 6px;
			}
		}
	}

	.paid-ticketing-card {
		padding: 16px;
		@include radius-12;
		background-color: $gray-800;
		@include flex-between;
		margin-bottom: 16px;

		.ticket-data {
			display: flex;
			flex-direction: column;
			.ticket-time {
				font-size: 14px;
				font-weight: 500;
				margin-bottom: 8px;
			}

			label {
				font-size: 14px;
				font-weight: bold;
				margin-bottom: 6px;
			}

			span {
				color: $gray-300;
				font-weight: 500;
				font-size: 12px;
			}

			.ticket-currency-list {
				display: flex;
				margin-top: 5px;

				.ticket-currency-bubble {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 24px;
					min-width: 24px;
					font-size: 10px;
					text-transform: uppercase;
					color: #bfbbcf;
					border: 1px solid $gray-500;
					background-color: $gray-900;
					border-radius: 24px;
					margin-right: 4px;
				}
			}
		}

		.ticket-popover {
			align-self: flex-start;
			position: relative;

			button.open-popover {
				margin: 0;
				position: relative;
				top: -6px;
				right: -6px;
				padding: 6px;
				transition: background-color 150ms;
				
				&:hover {
					background-color: $gray-600;
				}
				
				svg {
					transform: rotate(0deg) !important;
				}
			}

			 .popover-wrapper {
				border-radius: 12px;
				position: absolute;
				left: inherit;
				top: 40px;
				right: -16px;
				bottom: inherit;
				width: 150px;
				padding: 1px;

				button {
					padding: 8px 16px;
					font-size: 14px;
					font-weight: normal;
					border-radius: 0;
					background-color: transparent;
					&:first-child {
						border-top-left-radius: 12px;
						border-top-right-radius: 12px;
					}
					&:last-child {
						border-bottom-left-radius: 12px;
						border-bottom-right-radius: 12px;
					}
					&:hover {
						background-color: $border-gray;
					}
				}
			}
		}
	}

	.stripe-status {
		padding: 16px;
		@include radius-12;
		background-color: $gray-800;
		margin-bottom: 16px;
		margin-top: 16px;

		p {
			font-size: 14px;
			text-align: center;
			font-weight: bold;
			margin-bottom: 16px;
			line-height: 18px;;
		}

		.button.primary {
			background-color: $cyan;
			color: #000;
			text-align: center;
			width: 100%;
			display: block;
			@include radius-full;
			font-size: 12px;
			font-weight: 500;
			padding: 8px;
		}
	}
}

.paid-ticketing-modal {
	.paid-ticketing-settings {
		display: grid;
		grid-template-areas: 
			"name name name quantity quantity quantity"
			"description description description description description description"
			"currency-container currency-container currency-container currency-container currency-container currency-container"
			"session session session session session session ";
		gap: 18px;
		border-bottom: 1px solid $gray-700;
		padding-bottom: 28px;

		.text {
			margin-bottom: 0;
		}

		.broadcast-date {
			margin-top: 0;

			label {
				display: inline-block;
				margin-bottom: 5px;
				margin-left: 16px;
				font-size: 12px;
				line-height: 16px;
				font-weight: 700;
			}
		}

		.currency-container {
			width: 100%;
			grid-area: currency-container;

			.currency-title-container {
				display: flex;
				label {
					display: inline-block;
					margin-bottom: 5px;
					margin-left: 16px;
					font-size: 12px;
					line-height: 16px;
					font-weight: 700;
					color: $alt-gray;
					margin-right: 5px;
				}

				.smart-tooltip-content {
					font-size: 12px;
					border-radius: 12px;
					max-width: 260px;
					min-width: 260px;
					white-space: normal;
					background-color: $gray-600;
					padding-bottom: 0;

					p {
						margin-bottom: 0;
					}
				}
			}
			
			ul {
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				margin: 0;
				max-height: 230px;
				overflow-y: auto;
				border: 1px solid $gray-hover;
				border-radius: 12px;
				padding: 10px 5px;
				overflow-x: hidden;
			}

			.currency-row {
				position: relative;
				list-style-type: none;
				width: calc(100% - 24px);
				display: grid;
				grid-template-columns: 1fr 1fr;
				margin-bottom: 5px;
				.field-group {
					width: 100%;
				}

				.currency-dropdown {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;

					label {
						display: inline-block;
						margin-bottom: 5px;
						margin-left: 34px;
						font-size: 12px;
						line-height: 16px;
						font-weight: 700;
					}
				}

				.remove-currency, .smart-tooltip:not(.field-group > label > .smart-tooltip) {
					align-self: flex-end;
					margin-bottom: 18px;
					position: absolute;
					right: -20px;
					bottom: 0;
					
					
					&.smart-tooltip {
						// puts it in line with the trashcan
						padding-right: 3px;
						.smart-tooltip-content {
							font-size: 12px;
							z-index: 1;
							p {
								margin-bottom: 0;
							}
						}
					}
				}
				
				.smart-tooltip.left {
					.smart-tooltip-content {
						border-radius: 12px;
						max-width: 260px;
						min-width: 260px;
						white-space: normal;
						background-color: $gray-600;
						padding-right: 0;
					}
				}
			}
			
			.currency-list-footer {
				width: 100%;
				margin-top: 10px;
				display: flex;

				.add-currency, .recalculate {
					background: $bl-lemonade;
					-webkit-background-clip: text;
					background-clip: text;
					-webkit-text-fill-color: transparent;
					@include flex-center;
				}

				.add-currency {
					margin: 0 24px 0 auto;
				}

				.recalculate {
					margin-left: 24px;
				}
			}
		}
	}

	

	.paid-ticketing-settings-advanced {
		margin-top: 18px;

		.paid-ticketing-settings-advanced-options {
			margin-top: 18px;
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 18px;
		}
	}
}