@import "./src/scss/old-deps";
@import "./src/scss/deps";

.modal {
	width: 960px;

	.modal-body {
		.tab-header-container {
			height: 40px;
			line-height: 22px;
			margin-bottom: 30px;
		}

		.edit-content {
			.search {
				display: grid;
				grid-template-columns: 7fr 1fr 1.5fr;
				align-items: center;
				justify-items: center;
				margin-bottom: 25px;
				padding-top: 2px;

				.field-group {
					width: 100%;
					margin: 0;
				}

				button {
					font-weight: 700;
				}
			}

			.edit-agenda-cards {
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;

				.edit-session-card {
					width: 100%;
					display: grid;
					grid-template-columns: 1fr 12fr 1fr;
					align-items: center;
					background-color: $image-gray;
					border-radius: 12px;
					padding: 16px;
					margin: 4px 0;
					cursor: pointer;

					.inner-container {
						.date {
							color: $med-light-gray;
							size: 12px;
							font-weight: 500;
							margin: 0;
						}
						h6 {
							font-weight: 500;
							size: 14px;
						}

						.lower-container {
							margin-top: 12px;
							display: flex;
							justify-content: flex-start;

							.session-type {
								background-color: $gray;
								border-radius: 50px;
								display: flex;
								justify-content: center;
								align-items: center;
								width: fit-content;
								padding: 2px 8px;
								margin-right: 4px;
								svg {
									margin-right: 8px;
								}
							}

							.language-list {
								display: flex;
								justify-content: space-between;
								align-items: center;
								margin-left: 4px;

								.language-bubble {
									border-radius: 50px;
									background-color: $med-gray;
									color: $med-light-gray;
									border: 1px solid $border-med-gray;
									padding: 6px 6px;
									margin: 0 2px;
									size: 10px;
								}
							}
						}
					}

					&.selected {
						outline: 1px solid $cyan;
					}
				}

				.edit-session-buttons {
					display: flex;
					width: 100%;
					justify-content: space-between;
					align-items: center;
				}
			}
		}
	}

	.edit-layout-modal-content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		overflow-x: hidden;

		.module-list {
			max-height: calc(100vh - 280px);
			margin: 0;
			padding: 2px;
			list-style-type: none;
			overflow: scroll;

			@include media-query($med-down) {
				display: flex;
				max-width: 100%;
				overflow: scroll;

				.module-list-button {
					white-space: nowrap;
				}
			}

			.module-list-button {
				width: 100%;
				margin-bottom: 1px;
				text-align: left;
			}
		}

		.module-layout-items {
			height: auto;
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr;

			.module-layout-item {
				margin: 5px;
				height: auto;
				width: calc(100% - 10px);
				border: 2px solid transparent;
				border-radius: 12px;
				display: flex;
				justify-content: center;
				align-items: center;

				&.saving-module-layout {
					background-color: rgba($dark-gray, 0.05);
				}

				button {
					border-radius: 12px;
					padding: 0;
					width: 100%;
					height: auto;

					.module-layout-selector-content {
						position: relative;
						height: auto;
						width: 100%;
						display: flex;
						justify-content: center;
						align-items: center;

						.layout-thumbnail {
							width: 100%;
							height: 200px;
							border-radius: 12px;
							overflow: hidden;
							img {
								width: 100%;
							}

							&.main_event_banner {
								height: 180px;
							}
							&.products {
								img {
									height: auto;
								}
							}
							&.callout {
								height: 120px;
							}
							&.about {
								height: 150px;
							}
							&.sponsors {
								height: 115px;
							}
						}

						.module-layout-selector-content-waiting-indicator {
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							display: flex;
							justify-content: center;
							align-items: center;
							width: 100%;
							height: 100%;
						}
					}

					&.selected-module-layout-button {
						cursor: default;
					}
				}

				@include media-query($xs-down) {
					width: 100%;
				}
			}

			.selected-module-layout .layout-thumbnail {
				transition: 150ms outline;
				outline: 3px solid $cyan;
				outline-offset: -3px;
			}
		}
	}
}

.edit-header-modal-container {
	.module-layout-display-items {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;

		.layout-selector {
			padding: 0;
			padding-top: 8px;
			margin-top: 12px;
			height: 42px;
			width: 100%;
			overflow: hidden;
			border-radius: 12px;

			.layout-thumbnail {
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					border-radius: 8px;
					width: 100%;
				}
			}

			&.isSelected {
				outline: 3px solid $cyan;
				outline-offset: -3px;
				transition: 150ms outline;
			}
		}
	}
}
