@import './src/scss/deps';

@include light-mode-theme {
	#modal-global-container {
		.breakout-end-rooms-modal {
			.modal {
				.modal-header .modal-title, .modal-body {
					color: var(--gray-1200);
				}
			}
		}
	}
}

@include dark-mode-theme {
	#modal-global-container {
		.breakout-end-rooms-modal {
			.modal {
				.modal-header .modal-title, .modal-body {
					color: var(--gray-100);
				}
			}
		}
	}
}
