* {
	box-sizing: border-box;
}

html {
	background-color: $dark-gray;
}

body {
	width: 100%;
	overflow-x: hidden;

	&.modal-open {
		overflow: hidden;
	}
}

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	background-color: $dark-gray;

	&.products-container {
		margin-top: 80px;
	}

	&.content-main {
		margin-top: 80px;
	}
}

.container {
	display: flex;
	flex-direction: column;
	width: 1088px;

	&.full {
		width: 100%;
	}

	&.center {
		margin: auto;
	}
}

.row {
	display: flex;
	flex: 1;
}

// Small devices (landscape phones, 576px and up)

/* stylelint-disable block-no-empty */
@media (max-width: 576px) {
	.container {
		width: 100%;
	}
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
	.container {
		width: 100%;
	}
}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
	.container {
		width: 100%;
	}
}

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {}

/* stylelint-enable block-no-empty */

.grid {
	display: grid;

	&.col-2 {
		grid-template-columns: 1fr 1fr;
	}

	&.gap-16 {
		gap: 16px;
	}
}

.flex {
	display: flex;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;

	&.column {
		flex-direction: column;
	}
}

.flex-space-between {
	display: flex;
	justify-content: space-between;
}

.flex-space-evenly {
	display: flex;
	justify-content: space-evenly;

	&.grow-children {
		&>* {
			flex: 1;
		}
	}
}

.flex-start {
	display: flex;
	justify-content: flex-start;
}

.flex-end {
	display: flex;
	justify-content: flex-end;
}

iframe {
	border-width: 0;
}
