:root {
	--font-family-poppins: 'Poppins', sans-serif;
}

.C1 {
	font-family: var(--font-family-poppins);
	font-size: 11px;
	font-style: normal;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0.02em;
}

.C2 {
	font-family: var(--font-family-poppins);
	font-size: 11px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0.22px;
}

.H2 {
	font-family: var(--font-family-poppins);
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: 40px;
}

.H3 {
	font-family: var(--font-family-poppins);
	font-size: 23px;
	font-style: normal;
	font-weight: 700;
	line-height: 32px;
}

.H4 {
	font-family: var(--font-family-poppins);
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px;
	letter-spacing: -0.2px;
}

.H5 {
	font-family: var(--font-family-poppins);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
}

.H6 {
	font-family: var(--font-family-poppins);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
}

.L1 {
	font-family: var(--font-family-poppins);
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: 16px;
}

.L2 {
	font-family: var(--font-family-poppins);
	font-size: 11px;
	font-style: normal;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0.22px;
}

.L3 {
	font-family: var(--font-family-poppins);
	font-size: 11px;
	font-style: normal;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 1.3px;
	text-transform: uppercase;
}

.P1 {
	font-family: var(--font-family-poppins);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
}

.P2 {
	font-family: var(--font-family-poppins);
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0.24px;
}

.S1 {
	font-family: var(--font-family-poppins);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
}

.S2 {
	font-family: var(--font-family-poppins);
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.24px;
}

.S3 {
	font-family: var(--font-family-poppins);
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.24px;
}
