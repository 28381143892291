/*
	FIGMA DESIGN SYSTEM V2:
	https://www.figma.com/file/ET5Nc3if8EeBm2a5TsTcN8/Base-File---Brandlive-DS-v2?type=design&node-id=1-23&mode=design&t=Lj9TlHBn9nB7CEWJ-0

	500 are the theme default colors
	*/

:root {
	--card-radius: 12px;

	--true-white: #ffffff;
	--true-white-rgb: 255, 255, 255;
	--true-black: #000000;
	--true-black-rgb: 0, 0, 0;
	--light-mode-background: #f5f7ff;
	--light-mode-background-rgb: 245, 247, 255;

	--cyan: #5ae4ff;

	/*
		***********************
		BEGIN THEME COLOR SCALES
		***********************
	*/
	// Primary/Brand
	--primary-100: #def9ff;
	--primary-200: #bdf4ff;
	--primary-300: #9ceeff;
	--primary-400: #7be9ff;
	--primary-500: #5ae3ff;
	--primary-600: #4ab7ce;
	--primary-700: #3a8c9d;
	--primary-800: #29606c;
	--primary-900: #19353b;
	--primary-100-rgb: 222, 249, 255;
	--primary-200-rgb: 189, 244, 255;
	--primary-300-rgb: 156, 238, 255;
	--primary-400-rgb: 123, 233, 255;
	--primary-500-rgb: 90, 227, 255;
	--primary-600-rgb: 74, 183, 206;
	--primary-700-rgb: 58, 140, 157;
	--primary-800-rgb: 41, 96, 108;
	--primary-900-rgb: 25, 53, 59;
	--primary-100-font-color: var(--true-black);
	--primary-200-font-color: var(--true-black);
	--primary-300-font-color: var(--true-black);
	--primary-400-font-color: var(--true-black);
	--primary-500-font-color: var(--true-black);
	--primary-600-font-color: var(--true-black);
	--primary-700-font-color: var(--true-black);
	--primary-800-font-color: var(--true-white);
	--primary-900-font-color: var(--true-white);


	// Secondary/Accent
	--secondary-orange-100: #ffecce;
	--secondary-orange-200: #ffd99d;
	--secondary-orange-300: #ffc56c;
	--secondary-orange-400: #ffb23b;
	--secondary-orange-500: #ff9f0a;
	--secondary-orange-600: #ce810a;
	--secondary-orange-700: #9d630a;
	--secondary-orange-800: #6b450a;
	--secondary-orange-900: #3a270a;
	--secondary-orange-100-rgb: 255, 236, 206;
	--secondary-orange-200-rgb: 255, 217, 157;
	--secondary-orange-300-rgb: 255, 197, 108;
	--secondary-orange-400-rgb: 255, 178, 59;
	--secondary-orange-500-rgb: 255, 159, 10;
	--secondary-orange-600-rgb: 206, 129, 10;
	--secondary-orange-700-rgb: 157, 99, 10;
	--secondary-orange-800-rgb: 107, 69, 10;
	--secondary-orange-900-rgb: 58, 39, 10;
	--secondary-orange-100-font-color: var(--true-black);
	--secondary-orange-200-font-color: var(--true-black);
	--secondary-orange-300-font-color: var(--true-black);
	--secondary-orange-400-font-color: var(--true-black);
	--secondary-orange-500-font-color: var(--true-black);
	--secondary-orange-600-font-color: var(--true-black);
	--secondary-orange-700-font-color: var(--true-white);
	--secondary-orange-800-font-color: var(--true-white);
	--secondary-orange-900-font-color: var(--true-white);

	--secondary-yellow-100: #fff7ce;
	--secondary-yellow-200: #ffef9d;
	--secondary-yellow-300: #ffe66c;
	--secondary-yellow-400: #ffde3b;
	--secondary-yellow-500: #ffd60a;
	--secondary-yellow-600: #cead0a;
	--secondary-yellow-700: #9d840a;
	--secondary-yellow-800: #6b5b0a;
	--secondary-yellow-900: #3a320a;
	--secondary-yellow-100-rgb: 255, 246, 204;
	--secondary-yellow-200-rgb: 255, 239, 157;
	--secondary-yellow-300-rgb: 255, 230, 108;
	--secondary-yellow-400-rgb: 255, 222, 59;
	--secondary-yellow-500-rgb: 255, 214, 10;
	--secondary-yellow-600-rgb: 206, 173, 10;
	--secondary-yellow-700-rgb: 157, 132, 10;
	--secondary-yellow-800-rgb: 107, 91, 10;
	--secondary-yellow-900-rgb: 58, 50, 10;
	--secondary-yellow-100-font-color: var(--true-black);
	--secondary-yellow-200-font-color: var(--true-black);
	--secondary-yellow-300-font-color: var(--true-black);
	--secondary-yellow-400-font-color: var(--true-black);
	--secondary-yellow-500-font-color: var(--true-black);
	--secondary-yellow-600-font-color: var(--true-black);
	--secondary-yellow-700-font-color: var(--true-black);
	--secondary-yellow-800-font-color: var(--true-white);
	--secondary-yellow-900-font-color: var(--true-white);

	--secondary-green-100: #d7fcec;
	--secondary-green-200: #aff8d9;
	--secondary-green-300: #87f5c7;
	--secondary-green-400: #5ff1b4;
	--secondary-green-500: #37eea1;
	--secondary-green-600: #2ec083;
	--secondary-green-700: #259265;
	--secondary-green-800: #1b6447;
	--secondary-green-900: #123628;
	--secondary-green-100-rgb: 215, 252, 236;
	--secondary-green-200-rgb: 175, 248, 217;
	--secondary-green-300-rgb: 135, 245, 199;
	--secondary-green-400-rgb: 95, 241, 180;
	--secondary-green-500-rgb: 55, 238, 161;
	--secondary-green-600-rgb: 46, 192, 131;
	--secondary-green-700-rgb: 37, 146, 101;
	--secondary-green-800-rgb: 27, 100, 71;
	--secondary-green-900-rgb: 18, 54, 40;
	--secondary-green-100-font-color: var(--true-black);
	--secondary-green-200-font-color: var(--true-black);
	--secondary-green-300-font-color: var(--true-black);
	--secondary-green-400-font-color: var(--true-black);
	--secondary-green-500-font-color: var(--true-black);
	--secondary-green-600-font-color: var(--true-black);
	--secondary-green-700-font-color: var(--true-black);
	--secondary-green-800-font-color: var(--true-white);
	--secondary-green-900-font-color: var(--true-white);

	--secondary-lightblue-100: #e0f6ff;
	--secondary-lightblue-200: #c1edff;
	--secondary-lightblue-300: #a2e4ff;
	--secondary-lightblue-400: #83dbff;
	--secondary-lightblue-500: #64d2ff;
	--secondary-lightblue-600: #52aace;
	--secondary-lightblue-700: #40829d;
	--secondary-lightblue-800: #2d596c;
	--secondary-lightblue-900: #1b313b;
	--secondary-lightblue-100-rgb: 224, 246, 255;
	--secondary-lightblue-200-rgb: 193, 237, 255;
	--secondary-lightblue-300-rgb: 162, 228, 255;
	--secondary-lightblue-400-rgb: 131, 219, 255;
	--secondary-lightblue-500-rgb: 100, 210, 255;
	--secondary-lightblue-600-rgb: 82, 170, 206;
	--secondary-lightblue-700-rgb: 64, 130, 157;
	--secondary-lightblue-800-rgb: 45, 89, 108;
	--secondary-lightblue-900-rgb: 27, 49, 59;
	--secondary-lightblue-100-font-color: var(--true-black);
	--secondary-lightblue-200-font-color: var(--true-black);
	--secondary-lightblue-300-font-color: var(--true-black);
	--secondary-lightblue-400-font-color: var(--true-black);
	--secondary-lightblue-500-font-color: var(--true-black);
	--secondary-lightblue-600-font-color: var(--true-black);
	--secondary-lightblue-700-font-color: var(--true-black);
	--secondary-lightblue-800-font-color: var(--true-white);
	--secondary-lightblue-900-font-color: var(--true-white);

	--secondary-blue-100: #cee6ff;
	--secondary-blue-200: #9dceff;
	--secondary-blue-300: #6cb5ff;
	--secondary-blue-400: #3b9dff;
	--secondary-blue-500: #0a84ff;
	--secondary-blue-600: #0a6bce;
	--secondary-blue-700: #0a539d;
	--secondary-blue-800: #093a6c;
	--secondary-blue-900: #09223b;
	--secondary-blue-100-rgb: 206, 230, 255;
	--secondary-blue-200-rgb: 157, 206, 255;
	--secondary-blue-300-rgb: 108, 181, 255;
	--secondary-blue-400-rgb: 59, 157, 255;
	--secondary-blue-500-rgb: 10, 132, 255;
	--secondary-blue-600-rgb: 10, 107, 206;
	--secondary-blue-700-rgb: 10, 83, 157;
	--secondary-blue-800-rgb: 9, 58, 108;
	--secondary-blue-900-rgb: 9, 34, 59;
	--secondary-blue-100-font-color: var(--true-black);
	--secondary-blue-200-font-color: var(--true-black);
	--secondary-blue-300-font-color: var(--true-black);
	--secondary-blue-400-font-color: var(--true-black);
	--secondary-blue-500-font-color: var(--true-black);
	--secondary-blue-600-font-color: var(--true-white);
	--secondary-blue-700-font-color: var(--true-white);
	--secondary-blue-800-font-color: var(--true-white);
	--secondary-blue-900-font-color: var(--true-white);

	--secondary-violet-100: #e0e0fe;
	--secondary-violet-200: #c2c1fd;
	--secondary-violet-300: #a3a1fb;
	--secondary-violet-400: #8582fa;
	--secondary-violet-500: #6663f9;
	--secondary-violet-600: #5351c9;
	--secondary-violet-700: #413f99;
	--secondary-violet-800: #2e2d6a;
	--secondary-violet-900: #1c1b3a;
	--secondary-violet-100-rgb: 224, 224, 254;
	--secondary-violet-200-rgb: 194, 193, 253;
	--secondary-violet-300-rgb: 163, 161, 251;
	--secondary-violet-400-rgb: 133, 130, 250;
	--secondary-violet-500-rgb: 102, 99, 250;
	--secondary-violet-600-rgb: 83, 81, 201;
	--secondary-violet-700-rgb: 65, 63, 153;
	--secondary-violet-800-rgb: 46, 45, 106;
	--secondary-violet-900-rgb: 28, 27, 58;
	--secondary-violet-100-font-color: var(--true-black);
	--secondary-violet-200-font-color: var(--true-black);
	--secondary-violet-300-font-color: var(--true-black);
	--secondary-violet-400-font-color: var(--true-black);
	--secondary-violet-500-font-color: var(--true-white);
	--secondary-violet-600-font-color: var(--true-white);
	--secondary-violet-700-font-color: var(--true-white);
	--secondary-violet-800-font-color: var(--true-white);
	--secondary-violet-900-font-color: var(--true-white);

	--secondary-pink-100: #f2defc;
	--secondary-pink-200: #e5bdfa;
	--secondary-pink-300: #d99cf7;
	--secondary-pink-400: #cc7bf5;
	--secondary-pink-500: #bf5af2;
	--secondary-pink-600: #9b4ac4;
	--secondary-pink-700: #763a95;
	--secondary-pink-800: #522967;
	--secondary-pink-900: #2d1938;
	--secondary-pink-100-rgb: 242, 222, 252;
	--secondary-pink-200-rgb: 229, 189, 250;
	--secondary-pink-300-rgb: 217, 156, 247;
	--secondary-pink-400-rgb: 204, 123, 245;
	--secondary-pink-500-rgb: 191, 90, 242;
	--secondary-pink-600-rgb: 155, 74, 196;
	--secondary-pink-700-rgb: 118, 58, 149;
	--secondary-pink-800-rgb: 82, 41, 103;
	--secondary-pink-900-rgb: 45, 25, 56;
	--secondary-pink-100-font-color: var(--true-black);
	--secondary-pink-200-font-color: var(--true-black);
	--secondary-pink-300-font-color: var(--true-black);
	--secondary-pink-400-font-color: var(--true-black);
	--secondary-pink-500-font-color: var(--true-black);
	--secondary-pink-600-font-color: var(--true-white);
	--secondary-pink-700-font-color: var(--true-white);
	--secondary-pink-800-font-color: var(--true-white);
	--secondary-pink-900-font-color: var(--true-white);

	// Gray scale	
	--gray-100: #ffffff;
	--gray-200: #e5e4ec;
	--gray-300: #bfbbcf;
	--gray-400: #908ca0;
	--gray-500: #625e70;
	--gray-600: #4f4c57;
	--gray-700: #3c3a42;
	--gray-800: #312f38;
	--gray-900: #26242b;
	--gray-1000: #1a1a1f;
	--gray-1100: #121114;
	--gray-1200: #09090a;
	--gray-100-rgb: 255, 255, 255;
	--gray-200-rgb: 229, 228, 236;
	--gray-300-rgb: 191, 187, 207;
	--gray-400-rgb: 144, 140, 160;
	--gray-500-rgb: 98, 94, 112;
	--gray-600-rgb: 79, 76, 87;
	--gray-700-rgb: 60, 58, 66;
	--gray-800-rgb: 49, 47, 56;
	--gray-900-rgb: 38, 36, 43;
	--gray-1000-rgb: 26, 26, 31;
	--gray-1100-rgb: 18, 17, 20;
	--gray-1200-rgb: 9, 9, 10;
	--semantic-gray-100-font-color: var(--true-black);
	--semantic-gray-200-font-color: var(--true-black);
	--semantic-gray-300-font-color: var(--true-black);
	--semantic-gray-400-font-color: var(--true-black);
	--semantic-gray-500-font-color: var(--true-white);
	--semantic-gray-600-font-color: var(--true-white);
	--semantic-gray-700-font-color: var(--true-white);
	--semantic-gray-800-font-color: var(--true-white);
	--semantic-gray-900-font-color: var(--true-white);
	--semantic-gray-1000-font-color: var(--true-white);
	--semantic-gray-1100-font-color: var(--true-white);
	--semantic-gray-1200-font-color: var(--true-white);

	// SPECIAL COLORS
	--semantic-red-100: #ffd7df;
	--semantic-red-200: #ffafbf;
	--semantic-red-300: #ff879f;
	--semantic-red-400: #ff5f7f;
	--semantic-red-500: #ff375f; // this is our $rose color
	--semantic-red-600: #ce2e4e;
	--semantic-red-700: #9d253d;
	--semantic-red-800: #6b1b2c;
	--semantic-red-900: #3a121b;
	--semantic-red-100-rgb: 255, 215, 223;
	--semantic-red-200-rgb: 255, 175, 191;
	--semantic-red-300-rgb: 255, 135, 159;
	--semantic-red-400-rgb: 255, 95, 127;
	--semantic-red-500-rgb: 255, 55, 95; // this is our $rose color
	--semantic-red-600-rgb: 206, 46, 78;
	--semantic-red-700-rgb: 157, 37, 61;
	--semantic-red-800-rgb: 107, 27, 44;
	--semantic-red-900-rgb: 58, 18, 27;
	--semantic-red-100-font-color: var(--true-black);
	--semantic-red-200-font-color: var(--true-black);
	--semantic-red-300-font-color: var(--true-black);
	--semantic-red-400-font-color: var(--true-black);
	--semantic-red-500-font-color: var(--true-black);
	--semantic-red-600-font-color: var(--true-white);
	--semantic-red-700-font-color: var(--true-white);
	--semantic-red-800-font-color: var(--true-white);
	--semantic-red-900-font-color: var(--true-white);


	--semantic-green-100: #d6f7db;
	--semantic-green-200: #adefb7;
	--semantic-green-300: #84e793;
	--semantic-green-400: #5bdf6f;
	--semantic-green-500: #32d74b;
	--semantic-green-600: #2aae3e;
	--semantic-green-700: #228531;
	--semantic-green-800: #195b24;
	--semantic-green-900: #113217;
	--semantic-green-100-rgb: 214, 247, 219;
	--semantic-green-200-rgb: 173, 239, 183;
	--semantic-green-300-rgb: 132, 231, 147;
	--semantic-green-400-rgb: 91, 223, 111;
	--semantic-green-500-rgb: 50, 215, 75;
	--semantic-green-600-rgb: 42, 174, 62;
	--semantic-green-700-rgb: 34, 133, 49;
	--semantic-green-800-rgb: 25, 91, 36;
	--semantic-green-900-rgb: 17, 50, 23;
	--semantic-green-100-font-color: var(--true-black);
	--semantic-green-200-font-color: var(--true-black);
	--semantic-green-300-font-color: var(--true-black);
	--semantic-green-400-font-color: var(--true-black);
	--semantic-green-500-font-color: var(--true-black);
	--semantic-green-600-font-color: var(--true-white);
	--semantic-green-700-font-color: var(--true-white);
	--semantic-green-800-font-color: var(--true-white);
	--semantic-green-900-font-color: var(--true-white);


	--link-100: #e3e8fa;
	--link-200: #c7d0f5;
	--link-300: #abb9ef;
	--link-400: #8fa1ea;
	--link-500: #738ae5;
	--link-600: #5e70b9;
	--link-700: #49568d;
	--link-800: #333d62;
	--link-900: #1e2336;
	--link-100-rgb: 227, 232, 250;
	--link-200-rgb: 199, 208, 245;
	--link-300-rgb: 171, 185, 239;
	--link-400-rgb: 143, 161, 234;
	--link-500-rgb: 115, 138, 229;
	--link-600-rgb: 94, 112, 185;
	--link-700-rgb: 73, 86, 141;
	--link-800-rgb: 51, 61, 98;
	--link-900-rgb: 30, 35, 54;
	--link-100-font-color: var(--true-black);
	--link-200-font-color: var(--true-black);
	--link-300-font-color: var(--true-black);
	--link-400-font-color: var(--true-black);
	--link-500-font-color: var(--true-black);
	--link-600-font-color: var(--true-white);
	--link-700-font-color: var(--true-white);
	--link-800-font-color: var(--true-white);
	--link-900-font-color: var(--true-white);

	// Gradients
	--gradient-sherbet: linear-gradient(89.99deg, #5ae4ff -0.01%, #a6ff79 32.8%, #fff85c 65.23%, #ff6ae6 99.99%);
	--gradient-sherbet-2: linear-gradient(90deg, #a7ff7b 0%, #fffd62 52.04%, #ff6ae6 100%);
	--gradient-sherbet-3: linear-gradient(108.66deg, #ff6ae6 -4.67%, #fff85c 69.07%, #a6ff79 120.95%);
	--gradient-seabreeze: linear-gradient(90deg, #5ae4ff 0%, #a7ff7b 100%);
	--gradient-pina-colada: linear-gradient(90deg, #5ae4ff 0%, #fffd62 100%);
	--gradient-sunset: linear-gradient(90deg, #fffd62 0%, #ff6ae6 100%);
	--gradient-sunset-light: linear-gradient(90deg, #fffd62 0%, #ff6ae6 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
	--gradient-watermelon: linear-gradient(270deg, #a7ff7b 0%, #ff6ae6 100%);
	--gradient-blue-razz: linear-gradient(90deg, #ff6ae6 0%, #55d6f0 100%);
	--gradient-blue-razz-light: linear-gradient(90deg, #ff6ae6 0%, #55d6f0 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
	// "sunrise" veribiage is technically not part of the design system, but because design are very inconsistent and sometimes
	// they use yellow to pink left to right and sometimes pink to yellow left to right,
	// so I'm adding this "sunrise" gradient as a reversal of "sunset"
	--gradient-sunrise: linear-gradient(90deg, #ff6ae6 0%, #fffd62 100%);


	// Effects
	--box-shadow-bl-shine: 6px -2px 20px 0px #ff6ae659, -6px 2px 20px 0px #fffd6259;
	--box-shadow-bl-shine-top: 4px -1.9235398769378662px 2.4387738704681396px 0px #ea57a515,
		4px -4.864776611328125px 6.167841911315918px 0px #ea57a51d,
		4px -9.923687934875488px 12.581818580627441px 0px #ea57a525,
		4px -20.440921783447266px 25.916168212890625px 0px #ea57a52e,
		-4px -1.9235398769378662px 2.4387738704681396px 0px #fff85c15,
		-4px -4.864776611328125px 6.167841911315918px 0px #fff85c1d,
		-4px -9.923687934875488px 12.581818580627441px 0px #fff85c25,
		0px -20.440921783447266px 25.916168212890625px 0px #fff85c2e;
	--box-shadow-ev-shine: 0px 0px 20px 0px #5ae3ff59;
	--box-shadow-ev-shine-top: 0px -1.9235398769378662px 2.4387738704681396px 0px #5ae3ff15,
		0px -4.864776611328125px 6.167841911315918px 0px #5ae3ff1d,
		0px -9.923687934875488px 12.581818580627441px 0px #5ae3ff25,
		0px -20.440921783447266px 25.916168212890625px 0px #5ae3ff2e,
		0px -56px 71px 0px #5ae3ff42;

	/*
		***********************
		END THEME COLOR SCALES
		***********************
	*/
}
