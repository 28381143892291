@import './src/scss/old-deps';
@import './src/scss/deps';

.marketing-page-footer {
	border-top: 1px solid rgba(var(--bodyTextColor-rgb), 0.1);
	position: sticky;
	top: 100%;

	.footer-container {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		max-width: 1440px;
		margin: 0 auto;
		padding: 23px 150px 33px;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.footer-left {
		flex: 0 1 200px;
		height: 60px;

		.footer-logo {
			border-radius: var(--customImageBorderRadius, 0);
		}
	}

	.footer-right {
		flex: 0 1 200px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		text-align: right;

		.footer-author {
			.bl-logo {
				width: 70px;
				height: auto;
			}
		}
	}

	.footer-nav {
		display: flex;
		justify-content: center;
		flex: 1 0 auto;

		.footer-nav-item {
			margin: 0 20px;
			line-height: 30px;
			text-decoration: none;
			cursor: pointer;

			&:hover {
				color: var(--accentColor);
			}
		}
	}

	.footer-copyright {
		flex: 0 0 100%;
		margin-top: 13px;
		padding: 10px 0;
		font-size: 10px;
		line-height: 1;
		text-align: center;
	}
}

.single-session-no-homepage {
	.marketing-page-footer {
		position: unset;
	}
}