@import './src/scss/old-deps';
@import './src/scss/deps';


.view-answer-count {
	align-items: flex-start;
	gap: 10px;
	display: inline-flex;

	.row {
		display: flex;
		gap: 4px;
		justify-content: center;
		align-items: center;
	}
}