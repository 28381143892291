@import "./src/scss/base/mixins";
@import "./src/scss/deps";
@import "./src/scss/old-deps";
@import "./src/scss/live-event/base/mixins/theme-packs";

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.session .surveys,
.surveys-content-home {
	background-size: cover;
	background-repeat: no-repeat;

	header,
	.surveys-custom-heading {
		font-weight: 600;
		font-size: 14px;
		line-height: 30px;
		color: var(--bodyTextColor);
	}

	.surveys-custom-heading-event {
		text-align: center;
		font-size: 14px;
		color: var(--secondaryAccentColor);
	}

	.survey-text-actions-container {
		display: flex;
		flex-direction: column;
		width: 100%;

		.field-group.text {
			width: 100%;
		}

		button {
			margin-top: 8px;
			width: 100px;
		}
	}

	.survey-option-wrapper {
		margin-bottom: 10px;
		min-height: 60px;
		background-color: var(--containerColor, #ffffff);

		.survey-option-container {
			width: 100%;
			height: 100%;
			min-height: inherit;
			border-radius: 1px;
			padding: 5px;
			display: flex;
			justify-content: flex-start;

			.survey-option {
				overflow: hidden;
				width: calc(100% + 10px);
				height: 100%;
				border-radius: 0;
				justify-content: flex-start;
				position: relative;
				padding: 0 15px;
				margin: -5px;
				display: flex;
				align-items: center;
				min-height: inherit;

				.survey-option-title {
					color: var(--bodyTextColor, #000000);
					font-family: var(--bodyFont);
					font-size: 18px;
					line-height: 30px;
				}

				.survey-option-progress-bar-container {
					position: absolute;
					width: 100%;
					top: 0;
					left: 0;
					bottom: 0;
					display: flex;
					flex: 1;

					.survey-option-progress-bar {
						border-radius: 1px;
						background-color: rgba(var(--accentColor-rgb), 0.4); // set blue color - not in theme
						animation: fadeIn 0.75s;
					}
				}

				.survey-option-selected-answer-container {
					position: absolute;
					width: 100%;
					top: 0;
					left: 0;
					bottom: 0;
					display: flex;
					flex: 1;
					background-color: rgba(var(--accentColor-rgb), 0.4); // set blue color - not in theme
					color: var(--containerColorContrast);

					.survey-option-check-quiz {
						margin-right: 10px !important;
					}
				}

				.survey-option-content {
					display: flex;
					align-items: center;
					width: 100%;
					z-index: 1;
					justify-content: space-between;

					.survey-quiz-pill-container {
						display: flex;
					}

					.survey-option-pill {
						font-weight: 600;
						font-size: 14px;
						border-radius: 5px;
						background-color: var(--accentColor);
						padding: 0.25rem 0.5rem;
						color: var(--buttonTextColor);
						margin-right: 1em;
					}

					.survey-option-check-quiz {
						display: flex;
						justify-content: space-evenly;
						align-items: center;
						margin: 0 0 0 auto;
					}

					.survey-option-check {
						display: flex;
						justify-content: space-evenly;
						align-items: center;
						margin: 0 0 0 auto;

						svg path {
							fill: var(--containerColor);
						}

						&.disabled {
							svg {
								opacity: 0.5;
							}
						}
					}

					.survey-option-percentage-text {
						width: 41px;
						color: var(--bodyTextColor);
						font-weight: 600;
						font-size: 18px;
						line-height: 30px;
						margin: 0 1em;
						animation: fadeIn 0.75s;

						&:empty {
							width: 10px;
							margin: 0;
						}
					}
				}
			}
		}
	}

	.surveys-custom-header-title {
		margin-bottom: 50px;
	}

	.survey-container {
		width: 100%;
		font-family: var(--bodyFont);
		background-color: var(--backgroundColor);

		.survey-title,
		.question-title {
			font-weight: bold;
			background-color: transparent;
			border-color: transparent;
		}

		.survey-title {
			padding: 10px 0 0 30px;
		}

		.question-wrapper {
			background-color: var(--backgroundColor);
		}

		.question-container {
			.survey-text-actions-container {
				textarea {
					color: var(--bodyTextColor);
					font-family: var(--bodyFont);
					border-radius: var(--customInputBorderRadius, 12px);
				}

				.submit-action {
					margin-top: 0;
				}
			}

			.field-group.textarea {
				textarea {
					color: var(--bodyTextColor);
				}
			}
		}

		.survey-pagination,
		.submit-action {
			width: 100%;
			justify-content: space-between;
			align-items: center;
			margin-top: 40px;
			margin-bottom: 60px;
			display: flex;
			gap: 16px;

			.hide-button {
				visibility: hidden;
			}

			button {
				background-color: var(--accentColor);
				color: var(--buttonTextColor);
				height: 55px;
				width: 120px;
				border-radius: var(--customButtonBorderRadius, 100px);
				padding: 15px 25px;
				text-transform: uppercase;

				&.next {
					background-color: var(--accentColor);
				}
			}

			span {
				color: $classic-gray-text;
			}
		}

		.submit-action {
			justify-content: flex-end;
		}

		.question-list .view-all {
			width: 100%;
			height: 30px;
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 16px;

			.retake-button {
				margin-right: auto;
			}

			button {
				color: var(--bodyTextColor);
			}
		}

		.question-list {
			background-color: var(--backgroundColor);
			padding: 30px;
		}
	}

	.quiz-completion {
		display: flex;
		justify-content: flex-start;
		gap: 20px;
		padding: 50px 50px;
		background: white;

		.quiz-completion-status {
			display: flex;
			gap: 20px;
		}

		&.mobile {
			display: none;
		}

		.party-icon {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100px;
			width: 100px;
			border-radius: 10px;
			background-color: $option-gray;
			font-size: 4rem;
		}

		.completion-status-container {
			height: 100px;
			display: flex;
			gap: 10px;
			flex-direction: column;
			justify-content: center;

			.completion-status {
				font-size: 2rem;
				font-weight: 700;
			}

			.completion-status-subtext {
				font-size: 1.25rem;
				margin-right: 10px;
			}
		}

		.retake-container {
			display: flex;
			flex: 1;
			justify-content: flex-end;
			align-items: center;

			.retake-button {
				display: flex;
				background: var(--backgroundColor);
				border: none;
				font-weight: 700;
				font-size: 13px;
				line-height: 25px;
				gap: 2px;
				border-radius: var(--customButtonBorderRadius, 100px);
				color: $blue;
				text-transform: uppercase;

				svg path {
					fill: $blue;
				}

				&:hover {
					background: rgba(52, 86, 255, 0.08);
				}
			}
		}
	}
}

//tablet
@include media-query($med-down) {
	.session .surveys {
		.survey-container .question-container {
			margin: 0;
		}
	}
}

//mobile
@include media-query($sm-down) {

	.session .surveys .quiz-completion,
	.surveys-content-home .quiz-completion {
		&.desktop {
			display: none;
		}
	}

	.survey-container {
		.quiz-completion {
			&.mobile {
				display: block;
				padding: 50px 20px;

				.quiz-completion-status {
					display: block;

					.quiz-mobile-status-title-container {
						display: flex;
						align-items: flex-start;
						flex-flow: wrap-reverse;

						.completion-status {
							font-size: 1.5rem;
							font-weight: 700;
							margin-right: 8px;
						}

						.party-icon {
							height: 30px;
							width: 30px;
							font-size: 1.5rem;
						}
					}

					.completion-status-container {
						height: fit-content;
						margin-top: 10px;

						.completion-status-subtext {
							font-size: 1rem;
							margin-right: 0px;
						}
					}
				}

				.retake-container {
					justify-content: flex-start;
					margin-top: 20px;
				}
			}
		}
	}
}

@include dark-mode-theme {
	.surveys-content-home {
		.survey-option-wrapper {
			background-color: color-mix(in srgb, var(--backgroundColor), #ffffff 4%);
		}

		.survey-option-container {
			.survey-option {
				.survey-option-title {
					color: #ffffff;
				}
			}
		}
	}
}

@include light-mode-theme {
	.surveys-content-home {
		.survey-option-wrapper {
			background-color: color-mix(in srgb, var(--backgroundColor), #000000 4%);
		}

		.survey-option-container {
			.survey-option {
				.survey-option-title {
					color: #000000;
				}
			}
		}
	}
}