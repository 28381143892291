
@import './src/scss/old-deps';

.image-editor {
	position: relative;
	width: 100%;
	height: 496px;
	user-select: none;

	* > {
		user-select: none;
	}

	.aspect-selector {
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		top: 0;
		height: 56px;
		display: flex;
		justify-content: center;
		align-items: center;
		button {
			height: 32px;
			font-size: 12px;
			line-height: 16px;
			color: $med-light-gray;
			background-color: $dark-gray;
			border: 2px solid $border-gray;
			padding: 8px 12px;
			margin-right: 8px;
			&.active {
				border: 2px solid $cyan;
			}
			&:last-of-type { 
				margin-right: 0;
			}
			.ratio-icon {
				background-color: $alt-gray;
				border: none;
				border-radius: 2px;
				margin-right: 4.5px;
			}
		}
	}

	.image-container {
		position: absolute;
		top: 56px;
		left: 0;
		right: 0;
		bottom: 40px;
		overflow: hidden;
		cursor: grab;
		&:active {
			cursor: grabbing;
		}
		img {
			position: absolute;
			pointer-events: none;
		}
		.image-window-indicator {
			position: absolute;
			border: 2px solid $cyan;
			box-shadow: 0 0 0 500px rgba($dark-gray, 0.5);
			display: flex;
			justify-content: center;

			.drag-icon-container {
				background-color: $border-gray;
				height: 32px;
				width: 159px;
				margin-top: 59px;
				border-radius: 100px;
				display: flex;
				justify-content: center;
				align-items: center;
				opacity: 1;
				transition: 150ms opacity;
				p {
					padding: 0;
					margin: 0 0 0 8px;
					font-size: 12px;
					font-weight: bold;
					letter-spacing: 0.4px;
				}
				
				&.dragging {
					opacity: 0;
				}
			}
		}
	}

	.zoom-slider {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		input[type='range'] {
			flex: 1;
			height: 3px;
			margin: 16px;
			background: transparent;
			border-radius: 3px;
			outline: none;
			-webkit-appearance: none;

			&::-webkit-slider-runnable-track {
				height: 3px;
				color: $red;
				background: $gray;
				border-radius: 3px;
				-webkit-appearance: none;
			}

			&::-webkit-slider-thumb {
				position: relative;
				top: -5px;
				width: 13px;
				height: 13px;
				background: $white;
				border-radius: 100%;
				-webkit-appearance: none; /* Override default look */
				appearance: none;
				cursor: pointer;
			}

			&::-moz-range-thumb {
				width: 13px;
				height: 13px;
				color: $red;
				background: $white;
				border-radius: 100%;
				cursor: pointer;
			}
		}
	}
}

.image-modal-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	.left-button {
		padding: 0;
		color: $cyan;
		svg {
			margin-right: 8px;
		}
	}
}
