@use "sass:math";

@mixin flex-center($display: flex, $align-items: center, $justify-content: center) {
	display: $display;
	@if $align-items != null {
		align-items: $align-items;
	}
	@if $justify-content != null {
		justify-content: $justify-content;
	}
}

@mixin size($w, $h: null, $force: false) {
	width: $w;
	@if $h {
		height: $h;
	} @else {
		height: $w;
	}

	// prevent size from adjusting with flex
	@if $force {
		@if $h {
			min-height: $h;
			max-height: $h;
		} @else {
			min-height: $w;
			max-height: $w;
		}
		min-width: $w;
		max-width: $w;
	}
}

@mixin circle($r, $center-content: true, $rest...) {
	@include size($r, $r, true);
	@if $center-content {
		@include flex-center($rest...);
	}
	border-radius: 50%;
}

@mixin generate-displays($list: ()) {
	@each $display in $list {
		.d-#{$display} {
			display: $display;
		}
	}
}

@mixin line-clamp($lines: 2) {
	display: -webkit-box;
	overflow: hidden;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: $lines;
}

@mixin text-ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

@mixin list-reset {
	margin: 0;
	padding: 0;
	list-style: none;
}

@mixin position-center($position: absolute) {
	position: $position;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin scrollbar {
	::-webkit-scrollbar {
		width: $ev-scroll-width;
		height: $ev-scroll-width;
	}

	::-webkit-scrollbar-thumb {
		visibility: hidden;
		background-color: rgba($gray-500, 0.7);
		border-radius: math.div($ev-scroll-width, 2);
	}

	:hover::-webkit-scrollbar-thumb,
	::-webkit-scrollbar-thumb:hover {
		visibility: visible;
	}

	::-webkit-scrollbar-corner {
		display: none;
	}
}

$arrowRight: -45deg;
$arrowLeft: 135deg;
$arrowUp: -135deg;
$arrowDown: 45deg;
@mixin arrow($direction, $size: 3px, $thickness: 2px) {
	border: solid rgba(var(--bodyTextColor-rgb), 0.5);
	border-width: 0 $thickness $thickness 0;
	display: inline-block;
	padding: $size;
	transform: rotate($direction);
	-webkit-transform: rotate($direction);
	transition: transform 250ms ease-in;
}
