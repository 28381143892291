@import "./src/scss/deps";
@import "../session-panel-mixins";

$pdf-color: #f05a5a;
$pptx-color: #db6905;
$doc-color: #4d88fd;
$xls-color: #479a5f;

@mixin smallGrayFont {
	font-family: $font-family-poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	/* 133.333% */
	letter-spacing: 0.24px;
}

.session-panel {
	display: flex;
	flex-direction: column;
	flex: 1;
	min-height: 0px;

	hr {
		border-color: $border-gray;
		border-style: solid;
		border-width: 1px;
		margin-bottom: 24px;
		margin-top: 8px;
	}

	.session-panel-section-label {
		color: $gray-300;
		font-size: 12px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0.24px;
		margin-bottom: 8px;
		display: block;
		padding: 0px 24px;
	}

	&.speakers-list {
		overflow: hidden;
		height: 100%;
	}

	&.upload-resource {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.session-panel-content {
			display: flex;
			flex-direction: column;
		}

		.file-card {
			margin-bottom: 16px;
		}

		.session-panel-footer {
			display: flex;
			gap: 8px;

			button {
				flex: 1;
			}
		}

		.field-group {
			margin-bottom: 16px;
		}
	}

	&.upload-product {

		.field-group.text,
		.field-group.textarea,
		.field-group.switch,
		.field-group.enter-select-container,
		.tag-select {
			margin-bottom: 16px;
			border-color: $gray-400;
		}

		.field-group.enter-select-container {
			.enter-select {
				border-color: $gray-400;
			}

			.enter-select-dropdown.open {
				z-index: 2;
			}
		}

		.session-panel-content {
			overflow-x: hidden;
		}

		.field-group.text {
			input {
				padding: 16px;
				height: 48px;
			}
		}

		.field-group.switch {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 20px;
			margin: 0 6px 16px 0;

			label {
				color: var(--grayscale-200-grey, $gray-200);
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px;
				/* 166.667% */
				letter-spacing: 0.24px;
				padding: 0;
				margin: 0;
			}
		}

		.product-image-label {
			font-size: 11px;
			line-height: 16px;
			font-weight: 600;
			letter-spacing: 0.22px;
			font-family: $font-family-poppins;
			color: $gray-400;
			margin-left: 17px;
		}

		.product-images {
			&.error {
				>.upload-container {
					border: 1px dashed $rose;
				}
			}

			.product-image {
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				@include flex-center;
				background-color: darken($gray-900, 2);
				position: relative;
				border-radius: 8px;

				.product-image-actions {
					opacity: 0;
					transition:
						opacity 150ms,
						background-color 150ms,
						backdrop-filter 150ms;
					background-color: rgba($gray-1000, 0.5);
					backdrop-filter: brightness(0.9) contrast(0.9);
					border-radius: inherit;

					@include absolute-fill;
					@include flex-center;

					.no-style {
						@include flex-center;
						top: 0;
						right: 0;
						padding: 0;
						margin: 0;
						position: absolute;
						height: 40px;
						width: 40px;
						transform: scale(0.5);
						transition: transform 150ms;
						filter: drop-shadow(0 2px 3px black);
					}
				}

				&:hover {
					.product-image-actions {
						opacity: 1;
						backdrop-filter: none;

						.no-style {
							transform: none;

							&:hover {
								transform: scale(1.2);
							}
						}
					}
				}
			}

			div {
				min-height: 104px;
				border-radius: 8px;
			}

			&.uploading.zero,
			&.one,
			&.two {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				gap: 8px;

				div {
					aspect-ratio: 1;
				}
			}

			&.three,
			&.four {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-template-rows: 1fr 1fr;
				gap: 8px;

				div {
					aspect-ratio: 1;
				}
			}
		}

		.upload-container {
			margin-top: 8px;
			margin-bottom: 24px;
			border: 1px dashed $gray-400;
			border-radius: 16px;
			@include flex-center;
			flex-direction: column;
			gap: 8px;
			padding: 32px 60px;
			transition:
				150ms border-color,
				150ms background-color;
			cursor: pointer;

			&:hover {
				border-color: $cyan;
				background-color: rgba(255, 255, 255, 0.025);
			}

			.add-image {
				color: $cyan;
				font-size: 13px;
				font-style: normal;
				font-weight: 600;
				line-height: 16px;
				/* 123.077% */

				span {
					margin-left: 8px;
				}

				svg {
					path {
						fill: $cyan;
					}
				}
			}

			p {
				color: var(--grayscale-300-grey, $gray-300);
				text-align: center;
				font-size: 11px;
				font-style: normal;
				font-weight: 600;
				line-height: 16px;
				/* 145.455% */
				letter-spacing: 0.22px;
			}

			&:not(.upload-container-empty) {
				padding: 0;
				margin: 0;

				p {
					display: none;
				}
			}
		}

		.attribute-row {
			border-bottom: 1px solid $gray-700;
			margin-top: 24px;
			padding-bottom: 24px;

			&:last-of-type {
				border-color: transparent;
				padding-bottom: 8px;
			}
		}
	}

	&.embed-section {
		.session-panel-embed-card {
			background: $gray-800;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 20px 16px;
			border-radius: 12px;

			label {
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px;
				/* 142.857% */
			}

			button {
				height: 100%;
			}
		}
	}

	&.create-survey {
		.correct-threshold {
			margin-top: 16px;
		}

		#allow-retakes {
			margin-bottom: 0;
		}
	}

	.session-panel-header-options {
		display: grid;
		grid-template-columns: 3fr 1fr;
		gap: 8px;
		position: relative;
		margin-bottom: 16px;

		&.search-only {
			grid-template-columns: 1fr;
		}

		&.openable {
			grid-template-columns: 1fr;
			gap: 0;

			.search-row {
				grid-template-columns: 1fr min-content;
				gap: 8px;
			}

			.col-2 {
				padding-top: 8px;
				column-gap: 8px;
			}

			.small-select-container {
				width: 100%;

				button {
					width: 100%;
				}
			}

			button.round {
				height: 32px;
				width: 32px;
				@include flex-center;
				padding: 0;
				margin: 0;
				background-color: $gray-700;

				&:hover {
					background-color: $gray-600;
				}
			}

			&.open {
				button.round {
					background-color: $gray-600;
				}
			}
		}

		&.with-filter {
			grid-template-columns: 1fr min-content;
		}

		.navigation-dropdown {
			button.round {
				height: 32px;
				width: 32px;
				@include flex-center;
				padding: 0;
				margin: 0;

				svg {
					position: relative;
					top: 2px;
				}
			}
		}

		.small-select-container {
			height: 32px;
			border-color: $gray-400;
			width: 120px;

			button {
				white-space: nowrap;

				.navdrop-title {
					@include smallGrayFont();
				}
			}

			.navdrop-list {
				p {
					@include smallGrayFont();
				}
			}

			svg {
				height: 12px;
				width: 12px;
				margin-left: 4px;

				path {
					fill: #fff;
				}
			}

			.navdrop-list {
				top: 36px;
			}
		}
	}

	.session-panel-content {
		flex: 1;
		max-height: 100%;

		.panel-card {
			display: flex;
			gap: 10px;
			margin-bottom: 4px;
			border-radius: 16px;
			padding: 8px;
			transition:
				150ms background-color,
				350ms outline;
			cursor: pointer;
			background-color: $gray-800;
			user-select: none;

			* {
				cursor: pointer;
			}

			.added-card-dropdown {
				.navdrop-title {
					display: flex;
				}
			}

			&.selected {
				outline: 2px solid #fff;
				outline-offset: -2px;
				transition:
					150ms background-color,
					75ms outline;
			}

			&:hover,
			&.selected {
				background-color: $gray-700;

				button {
					background-color: $gray-600;
				}
			}
		}

		.add-button {
			border-radius: 12px;
			background-color: rgba(49, 47, 56, 0.5);
			width: 100%;
			display: flex;
			padding: 24px 16px;
			justify-content: center;
			align-items: center;
			gap: 8px;
			align-self: stretch;

			span {
				background: linear-gradient(145deg, $pink-lemonade, $lemonade);
				-webkit-background-clip: text;
				background-clip: text;
				-webkit-text-fill-color: transparent;
				transition: 0.3s;
			}

			&:hover {
				transition: background-color 150ms;
				background-color: rgba(49, 47, 56, 0.75);
			}
		}

		&.added-items {
			.panel-card {
				position: relative;

				&.product-card,
				&.speaker-card {
					padding: 0;
					background-color: transparent;
					margin-bottom: 16px;
					height: auto;
				}

				.added-card-dropdown {
					position: absolute;
					right: 10px;
					top: 0;
					opacity: 0;
					transition: 150ms opacity;
				}

				&.document-card {
					.added-card-dropdown {
						margin-top: -14px;
					}
				}

				&:hover {
					.added-card-dropdown {
						opacity: 1;
					}
				}

				.navigation-dropdown.added-card-dropdown {
					.question-card-options {
						@include meatballMenuButton;
					}

					.navdrop-list {

						button,
						a {
							background-color: transparent;
							padding: 0 16px 0 16px;
							border-radius: 0;
							height: 36px;
							outline: none;
							text-align: left;
							color: var(--grayscale-100-white, #fff);
							font-size: 12px;
							font-weight: 500;
							line-height: 16px;
							/* 133.333% */
							letter-spacing: 0.24px;
							display: flex;
							align-items: center;
							justify-content: flex-start;
							transition: 150ms background-color;

							&:hover {
								background-color: $gray-700;
							}

							&:active,
							&:focus {
								outline: none;
								box-shadow: none;
							}
						}
					}
				}
			}
		}
	}

	&.speakers-list {
		.session-panel-content {
			display: flex;
			flex-direction: column;
			gap: 4px;
		}

		.panel-card {
			background-color: transparent;
			padding: 6px;

			&.selected {
				outline: 2px solid var(--primary-ev-500-key, #5ae3ff);
			}
		}
	}

	&.products-list,
	&.engagement-list {
		overflow: hidden;
		height: 100%;

		input {
			&::placeholder {
				@include smallGrayFont();
			}
		}

		.session-panel-content {
			opacity: 1;
			flex: 1;
			max-height: 100%;

			.product-card {
				.product-image {
					height: 125px;
					width: 125px;
					min-height: 125px;
					min-width: 125px;
					border-radius: 10px;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					background-color: $gray-1000;
					overflow: hidden;

					.slick-slider {
						height: 100%;
						width: 100%;

						.slick-prev,
						.slick-next {
							width: auto;
							z-index: 3;
							filter: drop-shadow(0 2px 2px $gray-900);

							svg {
								transition: 150ms transform;
							}

							&:hover {
								svg {
									transform: scale(1.15);
								}
							}

							&:active {
								svg {
									transition: 32ms transform;
									transform: scale(0.95);
								}
							}

							&::before {
								content: none;
							}
						}

						.slick-prev {
							left: 4px;
						}

						.slick-next {
							right: 4px;
						}

						.slick-list,
						.slick-track,
						.slide,
						div:not(.slick-arrow) {
							width: 100%;
							height: 100%;
						}

						img {
							height: 100%;
							width: 100%;
							object-fit: cover;
						}
					}
				}

				.product-data {
					display: flex;
					flex-direction: column;
					justify-content: center;
					gap: 8px;
					overflow: hidden;
					font-size: 12px;
					color: $gray-300;
					font-weight: 500;
					line-height: 16px;
					/* 133.333% */
					letter-spacing: 0.24px;

					h4 {
						font-size: 16px;
						font-weight: 600;
						line-height: 20px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						margin: 0;
						color: #fff;
					}

					.product-price {
						font-weight: 600;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						display: flex;
						max-width: 100%;
						gap: 2px;

						span {
							max-width: 100%;
							overflow: hidden;
						}
					}

					.product-description {
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}

					button {
						color: $gray-200;
						font-family: $font-family-poppins;
						font-size: 11px;
						font-style: normal;
						font-weight: 600;
						line-height: 16px;
						/* 145.455% */
						letter-spacing: 0.22px;
						white-space: nowrap;
						max-width: 100%;
					}
				}
			}

			.e-engage-card {
				margin-bottom: 8px;
			}
		}
	}

	&.resources-list {
		overflow: hidden;
		height: 100%;

		.document-card {
			justify-content: space-between;
			width: 100%;

			.document-image {
				height: 48px;
				width: 84px;
				min-width: 84px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				border-radius: 8px;
				background-color: $gray-1000;
				mask-image: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0.5));
				padding: 4px;

				.type-bubble {
					padding: 2px 8px;
					background-color: $gray-1000;
					color: $white;
					text-align: center;
					font-family: $font-family-poppins;
					font-size: 11px;
					font-style: normal;
					font-weight: 600;
					line-height: 16px;
					letter-spacing: 0.22px;
					border-radius: 50px;
					text-transform: uppercase;
					width: fit-content;
					@include flex-center;

					&.pptx,
					&.ppt {
						background-color: $pptx-color;
					}

					&.pdf {
						background-color: $pdf-color;
					}

					&.docx,
					&.doc {
						background-color: $doc-color;
					}

					&.xlsx,
					&.xls {
						background-color: $xls-color;
					}
				}
			}

			.document-data {
				display: flex;
				flex-direction: column;
				justify-content: center;
				gap: 8px;
				overflow: hidden;
				font-size: 12px;
				color: $gray-300;
				font-weight: 500;
				line-height: 16px;
				/* 133.333% */
				letter-spacing: 0.24px;
				flex: 1;

				label {
					font-size: 12px;
					font-weight: 600;
					line-height: 20px;
					margin: 0;
					color: #fff;
					word-break: break-word;
					@include line-clamp(2);
				}
			}

			.popover-wrapper {
				width: 100px;
				min-width: 100px;
				margin-left: -64px;
				margin-top: 5px;
				border-radius: 12px;

				ul {
					margin: 0;
					padding: 0;

					li {
						list-style-type: none;
						height: 40px;
						font-size: 14px;
						font-weight: 500;
						line-height: 24px;
						letter-spacing: 0em;
						text-align: left;
						display: flex;
						align-items: center;
						padding: 0 16px;
						background-color: transparent;
						transition: 150ms background-color;

						&:hover {
							background-color: $gray-700;
						}
					}
				}
			}

			.document-actions {
				align-self: center;
				position: relative;
			}
		}
	}

	.session-panel-footer {
		background: var(--grayscale-900, #26242b);
		z-index: 1000;
		height: 0;
		display: flex;
		justify-content: stretch;
		gap: 8px;
		transform: translateY(100%);
		opacity: 0;
		transition:
			300ms transform,
			300ms opacity;

		&.visible {
			padding: 0 24px 24px 24px;
			transform: translateY(0);
			height: unset;
			opacity: 1;
			transition:
				75ms transform,
				75ms opacity;
		}

		button {
			flex: 1;
			font-family: $font-family-poppins;
			font-size: 13px;
			font-style: normal;
			font-weight: 600;
			line-height: 16px;
			/* 123.077% */
		}

		&::before {
			content: " ";
			position: absolute;
			top: -24px;
			left: 0;
			right: 0;
			background: linear-gradient(180deg, rgba(38, 36, 43, 0) 0%, #26242b 100%);
			display: block;
			height: 24px;
		}
	}

	&.suggested-list {
		.session-panel-content {
			.panel-card {
				margin-bottom: 8px;
				padding: 15px;
				/* Text Shadow */
				text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.7);
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px;
				/* 125% */
			}
		}
	}

	&.has-selections {
		.session-panel-footer {
			padding: 0 24px 24px 24px;
			transform: translateY(0);
			height: unset;
			transform: translateY(0);
			opacity: 1;
			transition:
				75ms transform,
				75ms opacity;
		}
	}

	.session-panel-no-results {
		min-height: calc(100vh - 300px);
		@include flex-center;
		color: $gray-300;
		font-weight: 600;
		flex-direction: column;
		gap: 20px;

		.session-panel-dropdown {
			width: 100%;
		}
	}

	span.error {
		font-size: 12px;
		color: $rose;
		font-weight: 600;
		position: relative;
		top: -15px;
		left: 16px;
		z-index: 2;
	}
}
