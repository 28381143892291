$panel-transition-time: 0.7s ease-in-out;

.panel-container {
	position: relative;
	display: flex;
	flex-direction: column;
	height: calc(100vh - 72px);
	background-color: $med-gray;
	width: 0px;
	transition: width $panel-transition-time;

	.panel-slider {
		position: relative;
		width: 360px;

		left: -360px;
		transition: left $panel-transition-time;
	}

	.panel-header {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 32px 24px;

		&.no-border {
			border-bottom: none;
		}

		.switch {
			display: inline-flex;
			margin-right: 18px;
		}

		.tabs-container {
			height: 42px;
			margin: 12px -24px 0 -24px;
			padding: 0 24px;
			border-bottom: 1px solid $border-gray;
			font-size: 16px;
			line-height: 22px;
			font-weight: 500;
		}

		.title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			overflow: visible;

			button {
				padding: 0;
			}

			.title-left {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				button {
					margin-right: 8px;
				}
			}

			h6 {
				margin: 0;
				align-items: center;
				word-break: break-word;
				display: -webkit-box;
				max-width: 100%;
				overflow: hidden;
				-webkit-line-clamp: 1;

				a {
					display: flex;
					align-items: center;
				}
			}

			.buttons {
				display: flex;

				.add-session {
					font-size: 12px;
					margin-right: 16px;
					background: linear-gradient(90deg, $pink-lemonade, $lemonade);
					-webkit-background-clip: text;
					background-clip: text;
					-webkit-text-fill-color: transparent;
					color: none;
				}

				button {
					margin-left: 16px;

					&.custom-page-btn {
						margin-left: 0px;
						padding: 5px 20px;
					}
				}

				.navigation-dropdown {
					button {
						flex-direction: row-reverse;
						margin-right: 16px;

						&:hover {
							background-color: unset;
						}

						svg {
							margin-right: 8px;
						}
					}

					.navdrop-title {
						color: cyan;
						font-size: 12px;
						font-weight: 500;
					}
				}
			}

			&.truncate-panel-header-title {
				.title-left {
					width: 100%;
				}
				span {
					display: block;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					max-width: 100%;
				}
			}
		}

		&.session {
			height: 76px;
			border-bottom: none;
			align-items: center;
		}

		.panel-session-title {
			display: flex;
			align-items: center;
			width: 100%;
			height: 100%;
			justify-content: space-between;

			.left {
				display: flex;
				align-items: center;
			}

			.right {
				display: flex;
				align-items: center;
				width: 48px;
				justify-content: space-between;
			}

			button {
				margin: 0;
				padding: 0;
			}

			h6 {
				margin: 0 0 0 8px;
			}
		}
	}

	.panel-session-type {
		display: flex;
		align-items: center;
		width: 312px;
		height: 64px;
		padding: 0 16px;
		background-color: $image-gray;
		border: 1px solid $med-gray;
		user-select: none;
		@include radius-12;
		margin: 0 24px;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
	}

	.panel-body {
		width: 100%;
		max-height: 100%;
		height: calc(100vh - 138px - 72px); // 72px is main header, 138px is panel header (should be moved out to global variables)
		// height: 768px;
		padding: 0 16px 16px 24px;
		overflow: scroll;

		&.read-only {
			>* {
				pointer-events: none;
			}

			.read-only-exception {
				pointer-events: initial;

				.switch,
				button {
					pointer-events: none;
				}

				.override-read-only-button {
					pointer-events: auto;
				}
			}
		}

		&.no-padding {
			padding: 0;
		}

		.panel-tabs-header {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;

			button {
				padding: 0;
			}
		}

		.panel-structure-list {
			.panel-structure-item {
				display: flex;
				align-items: center;
				width: 312px;
				height: 64px;
				margin: 8px 0;
				padding: 0 16px;
				background-color: $image-gray;
				border: 1px solid $med-gray;
				user-select: none;
				@include radius-12;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
			}

			.panel-structure-footer {
				display: flex;
				align-items: center;

				button {
					padding-left: 0;

					span {
						font-weight: bold;
						font-size: 12px;
						line-height: 16px;
						letter-spacing: 0.4px;
						color: $cyan;
					}
				}
			}
		}

		.temp-card {
			position: relative;
			display: grid;
			grid-template-columns: 40px 1fr;
			gap: 12px;
			border-radius: 12px;
			background-color: $gray-600;
			padding: 16px;
			font-size: 14px;
			font-weight: 400;

			.key-gradient {
				width: 40px;
				height: 40px;
				border-radius: 20px;
				background: $gradient;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.add-button {
				padding: 0;
				margin: 0;
				margin-right: 24px;
				color: $cyan;
			}
		}
	}

	h3 {
		padding: 16px 24px 0;
		text-transform: capitalize;
	}

	a {
		text-decoration: none;
		color: $white;

		&:visited {
			text-decoration: inherit;
			color: inherit;
		}
	}

	.panel-top {
		flex: 1;
		height: calc(100vh - 72px - 65px);
		padding: 16px 24px;
		overflow-y: auto;

		.tooltip {
			svg {
				margin-bottom: 3px;
			}

			.tooltip-text {
				white-space: inherit;
			}
		}
	}

	.react-datepicker-popper {
		// needs to be here to effect both the datepickers in both the add and edit session modals
		// needs the !importants to override ReactDatepickers inline positioning
		position: fixed !important;
		top: inherit !important;
		margin-top: -38px;
	}

	.sessions-lists {
		.sessions-list {
			margin-top: 16px;

			.session-item {
				margin-bottom: 0;
				padding: 16px 0 16px 24px;

				.collapsing-list-header>span {
					width: calc(100% - 32px);
				}

				.session-languages {
					padding-top: 8px;

					a {
						display: block;
						padding: 8px 24px;
						font-size: 14px;
						font-weight: 500;
						text-decoration: none;
						color: $white;

						&:visited {
							color: $white;
						}
					}
				}

				.edit-sessions-container {
					.navigation-dropdown.edit-sessions {
						opacity: 0;
						transition: 0.3s;

						.navdrop-title {
							display: flex;
							justify-content: center;

							svg {
								top: 0;
								margin: 0;
							}
						}

						.navdrop-list {
							position: fixed;
							top: inherit;
							left: inherit;
							width: 185px;
							margin-top: 4px;

							&>button {
								padding: 5px 16px;
								color: $med-light-gray;
								border-radius: 0;

								&:hover {
									color: $white;
								}
							}
						}
					}

					.delete-message {
						margin: 24px 0 32px;
						line-height: 24px;
					}

					.modal-body {
						overflow-x: hidden;

						span button svg {
							position: inherit;
							width: inherit;
							height: inherit;
							margin: inherit;

							path {
								fill: $border-light-gray;
							}
						}
					}
				}

				&:hover {
					.navigation-dropdown.edit-sessions {
						opacity: 1;
					}
				}
			}
		}

		.no-sessions {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 24px;

			h5 {
				opacity: 0.4;
			}
		}

		.add-session {
			position: relative;
			top: -4px;
			margin: 0;
			padding: 0;
			font-size: 12px;
			font-weight: 700;
			white-space: nowrap;
			color: $cyan;

			svg {
				position: relative;
				top: 2px;
				margin-right: 6px;

				path {
					fill: $cyan;
				}
			}
		}
	}

	.event-registration {
		@include flex-between;
		display: relative;

		h5 {
			opacity: 1;
			transition: 150ms opacity;
			cursor: pointer;

			&.disabled {
				opacity: 0.4;
				pointer-events: none;
			}

			button.no-style {
				position: relative;
				top: -1px;
				right: -1px;
				margin: 0;
				padding: 0;
			}
		}
	}

	.settings-container {
		min-height: 65px;
		border-top: 1px solid $border-gray;

		.settings-label {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 65px;
			padding: 0 24px;

			h3 {
				margin: 0;
				padding: 0;
			}

			button {
				margin: 0;
				padding: 0;
				background: transparent;
			}
		}

		ul {
			padding: 0 24px;

			li {
				height: 40px;
				list-style-type: none;

				a {
					font-size: 18px;
					font-weight: 700;
				}
			}
		}
	}

	&.open {
		width: 360px;
		left: 0px;

		.panel-slider {
			left: 0px;
		}
	}

	&.panel-container-v2 {
		.panel-slider {
			position: relative;
			width: 400px;

			left: -400px;
		}

		&.open {
			width: 400px;

			.panel-slider {
				left: 0px;
			}
		}
	}
}
