.people-wrapper {
	display: flex;
	width: 100vw;
	padding: 48px 0;
	overflow-x: hidden;

	.people-container {

		.people-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 56px;
			margin-bottom: 16px;
			border-bottom: 1px solid $gray;

			.people-header-right {
				display: flex;
				justify-content: center;
				align-items: center;

				.field-group.text {
					margin-bottom: 0;
				}

				.sort-container {
					width: 120px;

					.select-container {
						height: 32px;

						.selected-option {
							&.placeholder {
								color: $white;
							}
						}

						.select-dropdown {
							margin: 0;
						}
					}
				}

				.header-buttons {

					button {
						margin: 0 5px;

						svg {

							path {
								fill: $dark-gray;
							}
						}
					}
				}
			}
		}

		.pagination {
			display: flex;
			justify-content: space-around;
			align-items: center;
			width: 300px;
			margin: 20px 20px 20px 140px;

			button {
				background-color: transparent;
			}
		}

		.filter-container {

			.filter-row {
				display: flex;
				align-items: center;

				.filter-option {
					width: 30%;
					margin: 10px;
				}

				.filter-value {
					width: 60%;
					margin: 10px;
				}
			}
		}

		.list-container {
			display: grid;

			ul {
				width: 100%;
				margin-top: 0;

				.list-item {
					display: grid;
					grid-template-rows: 64px;
					grid-template-columns: 64px 272px 416px 336px;
					align-items: center;
					font-weight: 500;
					border-bottom: 1px solid #3C3A42;

					.header {
						display: flex;
						align-items: center;
						font-size: 12px;
						line-height: 16px;
						letter-spacing: 1px;
						color: #BFBBCF;
					}

					.header-arrows {
						display: flex;
						flex-direction: column;
						margin-left: 8px;
					}

					.inactive {
						color: #908CA0;
					}
				}

				.list-pagination {
					display: grid;
					grid-template-rows: 64px;
					grid-template-columns: 352px;
					align-items: center;
					font-weight: 500;
				}
			}
		}

		.list-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			.header-right {
				display: flex;
				justify-content: center;
				align-items: center;

				.list-settings {
					@include flex-center;
					width: 32px;
					height: 32px;
					padding: 10px;
					border-radius: 100px;
				}

				button {
					margin: 0 6px;
				}

				button.add-passcode {

					svg {
						position: relative;
						margin-right: 6px;
					}
				}
			}

			.header-left {
				display: flex;

				button {
					position: absolute;
					margin-left: -56px;

					&:hover {

						svg {

							path {
								fill: $white;
							}
						}
					}
				}
			}
		}

		.passcode-content {
			width: 80%;
			margin: 20px auto;
			border-radius: 22px;
		}

		.modal-body {
			overflow-x: hidden;
		}

		.upload-toggle {
			display: flex;
			justify-content: space-between;
			width: 560px;
			margin: 24px 0;
			font-size: 14px;
			line-height: 16px;
			font-weight: 500;
			font-style: normal;

			.gutter {
				margin-right: 20px;
			}
		}

		.upload-button {
			margin-bottom: 24px;
		}

		.divider {
			width: 560px;
			height: 1px;
			margin: 24px auto;
			background-color: $border-gray;
		}

		.empty-list-button {
			@include flex-center;
			flex-direction: column;
			width: 1088px;
			height: 248px;
			margin-top: 32px;
			font-size: 14px;
			line-height: 16px;
			font-weight: 500;
			font-style: normal;
			background-color: $off-black;
			border: 1px dashed #3C3A42;
			border-radius: 16px;

			&.div {
				display: flex;
				align-items: center;
				width: 192px;
			}
		}

		.passcode-settings {
			margin: 16px 0 24px;
			font-size: 14px;
			line-height: 16px;
			font-weight: 500;
			font-style: normal;
			overflow-x: hidden;

			label {
				margin: 8px 0;
			}
		}

		.passcodelist-container {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 16px;

			.list-header {
				display: flex;
				justify-content: center;
				align-items: center;

				button {
					background-color: transparent;
				}
			}

			.passcode-tile {
				@include radius-12;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				width: 100%;
				padding: 24px;
				font-size: 14px;
				background-color: $med-gray;

				p {
					margin: 0;
				}

				.passcode-header {
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 100%;

					.passcode-edit {
						margin: 0;
						padding: 0;
						background-color: transparent;
					}
				}

				.passcode-members {
					margin-top: 8px;
					color: $light-gray;
				}

				.passcode-type {
					display: flex;
					justify-content: space-between;
					margin-top: 8px;
					font-size: 14px;
					color: $med-light-gray;
				}

				.add-passcode-to-list,
				.remove-passcode-from-list {
					width: 100%;
					height: 40px;
					margin: 20px 0 5px 0;

					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 100px;
					transition: .3s;
					cursor: pointer;

					&:hover {
						background-color: $gray-400;
						transition: .3s;
					}
				}

				.add-passcode-to-list {
					background-color: $gray-700;

					&.disabled {
						opacity: 0.35;
						pointer-events: none;
					}

					&.loading {
						opacity: 0.8;
						cursor: default;
					}
				}

				.remove-passcode-from-list {
					background: $bl-lemonade;
					color: black;

					&.loading {
						opacity: 0.8;
						cursor: default;
					}
				}

			}
		}

		.passcodelist-pagination {
			margin-top: 12px;
		}
	}
}