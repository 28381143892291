@import "./src/scss/base/_colors";
@import "./src/scss/deps";

.user-survey-modal.modal-wrapper {
	.modal {
		background-color: var(--containerColor);

		.modal-header {
			padding: 40px;

			.modal-title {
				color: var(--bodyTextColor);
				text-align: center;
				font-weight: 700;
				font-size: 25px;
			}
		}

		.modal-body {
			padding: 0 104px;

			p,
			h3 {
				color: var(--bodyTextColor);
			}

			.user-survey-body {
				margin-bottom: 16px;

				p {
					text-align: center;
				}

				.error {
					color: #ff3434;
					font-size: 14px;
					font-weight: 700;
				}

				.textarea {
					margin: 36px 0 0;

					textarea {
						color: var(--bodyTextColor);
					}
				}
			}

			.user-survey-submitted {
				padding-top: 46px;
				text-align: center;

				p {
					margin-bottom: 0;
				}

				svg {
					margin-bottom: 22px;
				}
			}
		}

		.modal-footer {
			button {
				margin-right: 0;
			}
		}
	}
}

.user-survey-alert-container {
	padding: 0 130px;
	width: 100%;
	max-width: 1440px;

	&.Vertical {
		padding: 0;
	}

	&.No,
	&.Tabs {
		margin-bottom: 40px;
	}

	&.hidden {
		display: none;
	}

	.user-survey-alert {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 15px;
		font-weight: 600;
		background-color: $white;
		position: relative;
		overscroll-behavior: contain;
		border-radius: 8px;
		width: 100%;
		padding: 10px 25px;
		margin-bottom: 40px;

		&.is-editor {
			margin-bottom: 0;
		}

		>.right-actions {
			margin-left: auto;
			display: flex;
			align-items: center;

			>span>svg {
				margin-left: 20px;
				cursor: pointer;
			}
		}
	}

	&.editor-tablet {
		padding: 0 75px;

		.user-survey-alert {
			margin-bottom: 0;
		}
	}

	&.mobile {
		padding: 0 26px;
		flex-direction: column;

		.user-survey-alert {
			display: flex;
			flex-direction: column;
			text-align: center;
			padding: 16px 50px;

			>span {
				margin-left: 0;
				margin-bottom: 10px;
			}

			>.right-actions {
				margin-left: 0;

				>span>svg {
					position: absolute;
					top: 10px;
					right: 10px;
				}
			}
		}
	}
}

.user-survey-popup {
	position: fixed;
	background-color: #ffffff;
	border-radius: 20px;
	width: 496px;
	min-height: 320px;
	bottom: 24px;
	right: 24px;
	padding: 25px 32px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	z-index: 1000;

	&.submitted {
		min-height: 200px;
		text-align: center;
	}

	.user-survey-close-icon {
		position: absolute;
		top: 16px;
		right: 16px;
		cursor: pointer;
	}

	.user-survey-body {
		.user-survey-header-text {
			font-size: 22px;
			font-weight: 700;
			line-height: 30px;
			letter-spacing: 0px;
			text-align: center;
		}

		.rating-scale {
			margin-bottom: 20px;
		}

		textarea {
			color: #000;
		}

		.button-container {
			display: flex;
			justify-content: flex-end;

			>button {
				margin-left: 16px;
			}
		}
	}

	.user-survey-submitted {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		gap: 25px;

		h3 {
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			line-height: 25px;
			margin-bottom: 0;
		}

		.submit-again {
			color: var(--user-mode-color);
			font-weight: 400;
			text-decoration: underline;
			text-underline-offset: 4px;
			padding: 0;
		}

		.pray-emoji {
			font-size: 60px;
		}
	}

	&.v2 {
		font-family: var(--bodyFont);
		background-color: var(--user-mode-background);
		color: var(--user-mode-color);
		box-shadow: var(--user-mode-shadow);
		width: 480px;
		bottom: 16px;
		right: 16px;
		font-family: var(--bodyFont);
		background-color: var(--user-mode-background);
		color: var(--user-mode-color);
		min-height: fit-content;
		padding: 25px;

		.user-survey-close-icon {
			svg {
				path {
					fill: var(--user-mode-color);
				}
			}
		}

		.user-survey-body {
			.user-survey-header-text {
				font-family: var(--headingFont);
			}

			.field-group.textarea {
				margin-bottom: 16px;

				textarea {
					font-family: var(--bodyFont);
					color: var(--user-mode-color);
					font-size: 14px;

					&::placeholder {
						font-size: 12px;
					}
				}
			}

			.button-container {
				gap: 8px;
			}

			button {
				height: 30px;
				font-size: 11px;
				font-weight: 700;
				margin: 0;
			}
		}
	}
}

@mixin user-survey-responsive($screen) {
	@if $screen =="tablet" {
		.user-survey-alert {
			padding: 10px 15px;
		}
	}

	@if $screen =="mobile" {
		.user-survey-alert-container {
			padding-left: 75px;
			padding-right: 75px;
		}

		.user-survey-popup {
			bottom: 0px;
			right: 0px;
			width: unset;
			box-shadow: none;
			border-radius: 0;
			display: flex;
			justify-content: center;

			.user-survey-submitted {
				z-index: 1000;
				width: 100%;
				background-color: var(--user-mode-background);
			}

			&.submitted {
				width: 90%;
				margin: 20px;
				border-radius: 20px;
				box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
				margin: 0;
			}

			.user-survey-body {
				justify-content: space-between;
				width: 100%;

				.button-container {
					margin-left: 0;
					width: 48%;
				}
			}

			&.v2 {
				width: 100%;
				font-size: 16px;
				padding: 0;

				.user-survey-close-icon {
					z-index: 1002;
				}

				.user-survey-body {
					position: relative;
					z-index: 1000;
					padding: 24px 24px 48px 24px;
					background-color: var(--user-mode-background);

					.user-survey-header-text {
						font-size: 22px;
					}
				}

				.field-group.textarea {
					margin-bottom: 24px;

					textarea {
						font-size: 16px;

						&::placeholder {
							font-size: 16px;
						}
					}
				}

				.button-container {
					width: 100%;
					gap: 16px;

					button {
						flex: 1;
						padding: 25px 15px;
						font-size: 16px;
					}
				}
			}
		}
	}
}

@include media-query($med-down) {
	.live-event-container {
		@include user-survey-responsive("tablet");
	}
}

@include media-query($sm-down) {
	.live-event-container {
		@include user-survey-responsive("mobile");

		.user-survey-submitted {
			padding: 15px 0;
		}
	}
}

.dark-mode {
	.user-survey-submitted {
		.submit-again {
			color: $gray-300;
		}
	}
}