@import './src/scss/old-deps';

.session-module-controls {
  // height: 80px;
  position: absolute;
  top: -10px;
  right: -5px;
	margin-right: 20px;
	
	&.has-children {
		width: 100%;
		position: relative;
	}

  .button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // padding: 8px;
    opacity: 0;
    transition: 150ms opacity;
    
    button {
      height: 32px;
      pointer-events: none;
			margin-right: 16px;
			z-index: 1;
    }

    .arrow-up {
      margin-right: 8px;
    }
    .arrow-down {
      transform: rotate(180deg);
      margin-right: 0;
    }
  }
  &:hover {
    .button-container {
      opacity: 1;
      button {
        pointer-events: auto;
      }
    }
  }
}