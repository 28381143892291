@import "../../../../src/scss/abstracts/_colors";

.rating-scale {
	display: flex;
	flex-direction: row;
	align-content: center;
	gap: 8px;

	svg>path {
		fill: var(--accentColor);
	}

	button {
		background-color: transparent;
		border: none;
		padding: 0;
		margin: 0;



		@media screen and (max-width: 640px) {
			padding: 6px;
		}
	}
}