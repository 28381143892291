@import url("https://assets.brandlive.com/assets/fonts/replacement/familyOpenSans300300i400400i600600i700700i800800i.css");
@import url("https://assets.brandlive.com/assets/fonts/replacement/familyRobotoitalwght010003000400050007000900110013001400150017001900displayswap.css");
@import url("https://assets.brandlive.com/assets/fonts/replacement/familyRobotoMonodisplayswap.css");
@import url("https://assets.brandlive.com/assets/fonts/replacement/familyPoppinsitalwght010002000300040005000600070008000900110012001300140015001600170018001900displayswap.css");

// was pointing to SF Pro Display which has no presence on Google Fonts, but should be installed on Macs. Changing to a
// comparable font that actually works and making this font the fallback for all things that use SF Pro
@import url("https://assets.brandlive.com/assets/fonts/replacement/familySourceSansPro.css");

body {
	font-family: $font-family-poppins;
	color: $white;
}

h1,
h2,
h3,
h4,
h5,
h6,
[class*="ev-heading-"] {
	margin: 0 0 8px;
	font-weight: 700;
}

p {
	margin: 0 0 8px;
	line-height: 24px;
}

h1,
.ev-heading-1 {
	font-size: 46px;
	line-height: 56px;
}

h2,
.ev-heading-2 {
	font-size: 34px;
	line-height: 44px;
}

h3,
.ev-heading-3 {
	font-size: 26px;
	line-height: 32px;
}

h4,
.ev-heading-4 {
	font-size: 22px;
	line-height: 30px;
}

h5,
.ev-heading-5 {
	font-size: 18px;
	line-height: 21px;
}

h6,
.ev-heading-6 {
	font-size: 16px;
	line-height: 20px;
}

.ev-subtitle {
	font-size: 14px;
	line-height: 20px;
}

.ev-caption {
	font-size: 12px;
	line-height: 16px;
}

.ev-weight-bold {
	font-weight: 700;
}

.ev-weight-medium {
	font-weight: 500;
}

.text-icon {
	font-family: "SF Pro Display", "Source Sans Pro", sans-serif;
}

// line heights for editable text
.line-height-1 * {
	line-height: 1 !important;
}

.line-height-1_2 * {
	line-height: 1.2 !important;
}

.line-height-1_5 * {
	line-height: 1.5 !important;
}

.line-height-2 * {
	line-height: 2 !important;
}

.lemonade-text-color {
	background: linear-gradient(90deg, $lemonade, $pink-lemonade);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
}