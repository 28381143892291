@import "./src/scss/old-deps";
@import "./src/scss/deps";

.basic-input-container {
	position: relative;

	.basic-input-label-container {
		display: flex;
		align-items: center
	}

	.basic-input-field {
		+.input-character-count-container {
			display: none;
		}

		&:focus {
			+.input-character-count-container {
				display: block;
				position: absolute;
				top: -18px;
				right: 0;
				padding: 2px 4px;
				font-size: 11px;
				border-radius: 4px;
				background-color: $gray-400;

				.input-character-count {
					position: relative;

					&.show-tail {

						&::after {
							content: "";
							position: absolute;
							bottom: -7px;
							left: 0px;
							right: 0px;
							margin: 0 auto;
							border-width: 7px 7px 0;
							border-style: solid;
							border-color: $gray-400 transparent;
							display: block;
							width: 0;
						}

						&.left-tail {
							&::after {
								left: 1px;
								right: auto;
								margin: 0;
							}
						}

						&.right-tail {
							&::after {
								right: 1px;
								left: auto;
								margin: 0;
							}
						}
					}

				}
			}

		}
	}
}