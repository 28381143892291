@import "../../../scss/abstracts/colors";
@import "./src/scss/old-deps";
@import "./src/scss/deps";

.navigation-dropdown {
	position: relative;

	button {
		display: flex;
		align-items: center;

		&.no-style {
			padding: 0;
		}

		&.lemonade {
			background: $bl-lemonade;

			&:not(.solid-fill) {
				-webkit-background-clip: text;
				background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}

		.navdrop-arrow {
			margin-left: 10px;
			transition: 0.2s;
		}

		.icon {
			margin-left: 10px;
		}

		&.center-icon {
			svg {
				display: flex;
				height: 100%;
				margin: 0 5px;
			}
		}

		&.open {
			.navdrop-arrow {
				transform: rotateX(180deg);
			}
		}

		&.large {
			display: flex;
			padding: 24px 16px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 8px;
			align-self: stretch;
			color: $cyan;
			border-radius: 12px;
			background: rgba(49, 47, 56, 0.5);
		}
	}

	.navdrop-title {
		font-size: 14px;
		font-weight: 700;
	}

	.navdrop-list {
		position: absolute;
		z-index: 1;
		display: flex;
		flex-direction: column;
		min-width: 100%;
		margin-top: 10px;
		overflow: hidden;
		background-color: $image-gray;
		border-radius: 12px;
		box-shadow: 0 3px 5px 0 $gray-1000;
		opacity: 0;
		pointer-events: none;
		transition: 150ms opacity 32ms, 150ms transform 32ms;

		&.custompage-menu-dropdown {
			right: 0;
		}

		&>* {
			margin: 0;
			padding: 14px 20px;
			font-size: 14px;
			line-height: 20px;
			font-weight: 400;
			cursor: pointer;

			&.on {
				background-color: rgba($gray, 0.5);
			}

			&:hover {
				background-color: $gray;
			}
		}

		&.right {
			right: 0;
		}

		&.center {
			right: 50%;
			transform: translateX(50%);
		}

		&.top {
			bottom: 100%;
			margin-top: 0;
			margin-bottom: 10px;
			transform: translate(0, 20px);
		}

		&.bottom {
			top: 100%;
			margin-top: 10px;
			margin-bottom: 0;
			transform: translate(0, -20px);
		}

		&.async-nav {
			div:first-child {
				opacity: 0.65;
			}
		}

		button {
			outline: none;

			&.delete-option {
				color: $red;
			}

			&.sharp:first-child {
				border-radius: 12px 12px 0 0;
			}

			&.sharp:last-of-type {
				border-radius: 0 0 12px 12px;
			}

			&:focus {
				box-shadow: inset 0 0 1px 1px $cyan;
			}
		}

		.smart-tooltip {
			&:first-child {
				border-radius: 12px 12px 0 0;
			}

			&:last-child {
				border-radius: 0 0 12px 12px;
			}
		}
	}

	&.open {
		.navdrop-list {
			opacity: 1;
			pointer-events: auto;

			&.top {
				transition: 150ms opacity 32ms;
			}

			&:not(.center) {
				transform: translate(0, 0);
			}
		}
	}

	&.small-select-container {
		display: flex;
		align-items: center;
		border: 1px solid $border-med-gray;
		border-radius: 16px;

		button {
			display: flex;
			justify-content: space-between;
			min-width: 120px;
			padding: 0 16px;

			.navdrop-title {
				font-weight: 400;
			}
		}

		.navdrop-list {
			color: $med-light-gray;

			p:hover {
				color: $white;
			}
		}
	}

	&.menu-dropdown {
		.navdrop-list {
			margin-top: 4px;
			background-color: var(--containerColor);
			box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);

			button {
				@include get-typography(subtitle-3);
				display: flex;
				justify-content: flex-start;
				align-items: center;
				height: 40px;
				padding-right: 16px;
				padding-left: 16px;
				white-space: nowrap;
				color: var(--accentColor);
				border-radius: 0;

				&:hover {
					background-color: rgba(var(--accentColor-rgb), 0.1);
				}
			}
		}
	}

	&.session-panel-dropdown,
	&.registration-panel-dropdown {
		&>button {
			&:not(.large) {
				height: 40px;
				padding: 0 20px;
			}

			&.large {
				width: 100%;

				.navdrop-arrow {
					display: none;
				}

				span {
					&:before {
						content: "+";
						font-size: 20px;
						font-weight: 300;
						margin-right: 8px;
						margin-right: 6px;
						position: relative;
						top: 2px;
					}
				}
			}

			&.lemonade {
				span {
					background: $bl-lemonade;
					-webkit-background-clip: text;
					background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}
		}

		.navdrop-title {
			@include get-admin-typography(label-1);
		}

		.navdrop-arrow {
			width: 12px;
			height: 12px;
			margin-left: 8px;
		}

		.navdrop-list {
			box-shadow: 0 8px 16px rgb(0 0 0 / 0.16);
			z-index: 3;

			&>* {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding: 0 16px;
				white-space: nowrap;
			}

			span {
				@include get-admin-typography(label-3);
				height: 32px;
				color: $alt-gray;
				pointer-events: none;
			}

			button,
			a {
				@include get-admin-typography(paragraph-2);
				height: 36px;
				column-gap: 8px;
				color: $white;
				background-color: transparent;
				border-radius: 0;

				&:hover {
					background-color: $gray-active;
				}
			}
		}

		&.lighter {
			.navdrop-list {
				background-color: $gray-active;

				button {
					&:hover {
						background-color: $gray;
					}
				}
			}
		}
	}


	&.registration-panel-dropdown {
		&>button {
			width: 100%;
			border-radius: 12px;
			color: $cyan;
			height: 69px;
			background-color: rgba(49, 47, 56, 0.5);
			transition: 150ms background-color;

			span {
				background: var(--Gradient-Sunset, linear-gradient(90deg, #FFFD62 0%, #FF6AE6 100%));
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;

				&:before {
					content: "+";
					background: var(--Gradient-Sunset, linear-gradient(90deg, #FFFD62 0%, #FF6AE6 100%));
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}

			&:hover {
				background-color: $gray-800;
			}
		}
	}

}