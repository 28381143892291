
@import './src/scss/base/mixins';
@import './src/scss/deps';

.language-dropdown {

	.new-dropdown-value {
		text-transform: uppercase;
	}

	&.adjust-for-sign-in-btn {
		
		.new-dropdown-panel {
			min-width: 240px;
		}
	}

	.new-dropdown-panel {
		min-width: 332px;
	}

	.language-dropdown-title {
		@extend .language-evt-heading-4;
		margin-bottom: 35px;
	}

	.language-list {
		max-height: 500px;
		overflow-y: auto;
	}

	.language-list-item {
		padding: 10px 15px;
		font-size: 18px;
		line-height: 30px;
		cursor: pointer;

		&:hover,
		&.selected {
			color: var(--containerColor);
			background-color: var(--accentColor);
		}
	}

	// general ui
	.language-evt-heading-4 {
		@include eventHeading4();
	}
}

@include media-query($sm-down) {

	.language-dropdown {

		.language-dropdown-title {
			margin-bottom: 30px;
		}

		.new-dropdown-panel {
			position: fixed;
			top: 60px;
			display: flex;
			flex-direction: column;
			min-width: 0;
			width: 100%;
			height: calc(100% - 60px);
		}
		

		.language-list {
			max-height: calc(100vh - 160px);
		}

		&.#{$ev-template-classic},
		&.#{$ev-template-bold},
		&.#{$ev-template-fun} {

			.new-dropdown-panel {
				border-radius: 0;
				box-shadow: none;
			}
		}
	}
}
