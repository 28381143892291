@import "../../../../../../scss/deps";

.streams-action-button {
	position: relative;
	background: transparent;
	border: 2px solid $icon-gray;
	margin: 0;
	border-radius: 100px;
	display: flex;
	width: 40px;
	height: 40px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	padding: 0;
	transition: all 200ms;

	&:hover {
		border-color: white;
		background-color: transparent;
		scale: 1.1;
	}
	&:focus:not(.disabled) {
		border-color: white;
	}

	svg {
		margin: 0;
		path {
			fill: white;
		}
		&:not(:last-child) {
			margin-right: 0px;
		}
	}
}

.streams-action-button {
	&.dark-icon {
		background: transparent;
		border: 2px solid var(--bodyTextColor);

		svg {
			path {
				fill: var(--bodyTextColor);
			}
		}
		&:hover {
			border-color: var(--bodyTextColor);
			background-color: transparent;
		}
		&:focus:not(.disabled) {
			border-color: var(--bodyTextColor);
		}
	}
}

.admin-edit-social-link-icon-container {
	position: relative;

	&:hover {
		.admin-edit-social-link-icon {
			visibility: visible;
		}
	}

	.admin-edit-social-link-icon {
		position: absolute;
		top: -10px;
		right: -10px;
		visibility: hidden;
		cursor: pointer;
		background: $gray-700;
		border-radius: 100px;
		height: 28px;
		width: 28px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
