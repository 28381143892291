.tooltip-portal {
	--tooltip-portal-width: auto;
	// hard-coding --tooltip-portal-background-color until colors epic is merged into dev, then we can change it to use var(--gray-600)
	--tooltip-portal-background-color: #4f4c57;
	--tooltip-portal-color: inherit;
	--tooltip-portal-padding: 8px;
	--tooltip-portal-border-radius: 4px;
	--tooltip-portal-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
	--tooltip-portal-font-size: 14px;
	--tooltip-portal-line-height: 20px;
	--tooltip-portal-font-weight: 400;
	--tooltip-portal-tail-size: 7px;

	// height 0 and overflow hidden are important to prevent the portal from taking up space at the bottom of the screen
	height: 0;
	overflow: hidden;

	.tooltip-portal-content {
		&.light-mode {
			--tooltip-portal-background-color: #F5F7FF;
			--tooltip-portal-color: #081543;
		}

		z-index: 1000; // higher than modals so we can use tooltip in modals
		position: absolute;
		transition: opacity 0.2s;
		width: var(--tooltip-portal-width);
		padding: var(--tooltip-portal-padding);
		border-radius: var(--tooltip-portal-border-radius);
		border: 1px solid var(--tooltip-portal-background-color);
		background-color: var(--tooltip-portal-background-color);
		color: var(--tooltip-portal-color);
		box-shadow: var(--tooltip-portal-box-shadow);
		font-size: var(--tooltip-portal-font-size);
		line-height: var(--tooltip-portal-line-height);
		font-weight: var(--tooltip-portal-font-weight);


		.tooltip-portal-tail {
			content: "";
			position: absolute;
			left: 50%;
			bottom: calc(var(--tooltip-portal-tail-size, 7px) * -1);
			border-bottom: var(--tooltip-portal-tail-size, 7px) solid var(--tooltip-portal-background-color);
			border-right: var(--tooltip-portal-tail-size, 7px) solid transparent;
			border-left: var(--tooltip-portal-tail-size, 7px) solid transparent;
			transform: translate(-50%) rotate(180deg);
			height: var(--tooltip-portal-tail-size, 7px);
			width: var(--tooltip-portal-tail-size, 7px);
		}
	}
}

.tooltip-portal-wrapper {
	height: fit-content;

	.tooltip-portal-children {
		position: relative;
		svg {
			path {
				fill: var(--gray-400);
				transition: fill 0.15s ease;
			}
		}

		&.active {
			svg {
				path {
					fill: var(--gray-200);
				}
			}
		}
	}
}
